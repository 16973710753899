import React from 'react'

const ArrowRightIcon = () => {
    return (
        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.19613 9.16711C6.07115 9.0421 6.00094 8.87256 6.00094 8.69578C6.00094 8.519 6.07115 8.34947 6.19613 8.22445L9.05813 5.36245L1.33413 5.36245C1.15732 5.36245 0.987746 5.29221 0.862722 5.16718C0.737699 5.04216 0.66746 4.87259 0.66746 4.69578C0.66746 4.51897 0.737699 4.3494 0.862722 4.22438C0.987746 4.09935 1.15732 4.02911 1.33413 4.02911L9.05813 4.02911L6.19613 1.16711C6.07469 1.04138 6.00749 0.872977 6.00901 0.698179C6.01053 0.523382 6.08064 0.356174 6.20425 0.232568C6.32785 0.108962 6.49506 0.0388489 6.66986 0.0373306C6.84466 0.0358114 7.01306 0.103007 7.13879 0.224446L11.1388 4.22445C11.2638 4.34947 11.334 4.519 11.334 4.69578C11.334 4.87256 11.2638 5.0421 11.1388 5.16711L7.13879 9.16711C7.01377 9.29209 6.84424 9.3623 6.66746 9.3623C6.49068 9.3623 6.32115 9.29209 6.19613 9.16711Z" fill="black" />
        </svg>
    )
}

export default ArrowRightIcon