import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'toastr/build/toastr.min.css';
import 'toastr/build/toastr.min.js';
import * as serviceWorker from './serviceWorker';
import createHost from 'cross-domain-storage/host';

const guestOrigin =
  process.env.REACT_APP_GUEST_ORIGIN || 'http://localhost:4000';

createHost([
  {
    origin: guestOrigin,
    allowedMethods: ['get', 'set', 'remove'],
  },
]);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
