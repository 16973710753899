import { useHistory } from "react-router";
import { UserType } from "../constants/data";

export const isAdmin = () => {
    const authInfo = localStorage.getItem("authInfo");
  
    if (!authInfo) {
      window.location.href = '/login' ;
      return false;
    }
  
    let userDetail: any = JSON.parse(authInfo);
  
    return userDetail?.type === UserType.teamAdmin || userDetail?.type === UserType.admin;
  };