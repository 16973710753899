import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";

// Redux actions and store types
import { getProgramFormContextData, getProgramFormStructure, getProgramListContextData, submitProgramForm, queryFormValues } from '../../../redux/actions/programFormAction';
import { getMyGoalsData } from '../../../redux/actions/dashboardAction';

import { RootState } from '../../../redux/store';

// UI Components and styling
import ButtonAppBar from '../../../components/AppBar';
import { useStyles } from './style';
import FormControlComponent from './components/FormControl';
import HeaderNotificationIcon from '../../../components/HeaderNotification';
import { IProgramEnrollment } from '../../../types/programEnrollment';
import "./style.scss";
import { MoonLoader } from 'react-spinners';
import toastr from 'toastr';
import moment from 'moment';

import Button from '@material-ui/core/Button';

// Import Material-UI icons for statuses and navigation.
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import BlockIcon from '@material-ui/icons/Block';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ProgramFormFieldProcessor } from '../../../redux/services/programFormFieldProcessor';


// -----------------------------------------------------------------------------

/*
 * This file defines a dynamic form rendering and submission system within a React application.
 * It consists of two main components:
 *
 * 1. FormRenderer:
 *    - Dynamically renders a multi-page form based on a provided form configuration.
 *    - Manages user input, form state, validation, and error handling.
 *    - Implements helper functions to calculate percentages for scale-type questions.
 *    - Supports multi-page navigation (next/back) and handles form submission.
 *    - Exposes the handleSubmit method via a forwarded ref for external triggering.
 *
 * 2. BackPack:
 *    - Serves as a container component that fetches form structure and context data via Redux.
 *    - Extracts URL parameters to determine form, user, and context identifiers.
 *    - Renders an AppBar and conditionally displays a loading spinner, error message, or the FormRenderer.
 *
 * Additionally, the file includes a helper function (splitFormByPageBreak) that splits the form's questions
 * into separate pages based on page break markers, enabling a multi-step form experience.
 *
 * Overall, the page integrates React, Redux, and React Router to provide a flexible, context-aware dynamic form.
 */

// -----------------------------------------------------------------------------
// Type Definitions
// -----------------------------------------------------------------------------

// Define the expected props for the FormRenderer component.
// These props include details about the form configuration, user info, and additional flags.
type FormRenderProps = {
    formDetail: any,
    formResult: any,
    formId?: string,
    sourceFormId?: string,
    userId?: string,
    contextId?: string;
    programId?: string;
    isOnboarding?: boolean;
    year?: number;
    onCloseModal?: () => void;
    openModal?: boolean;
    requiredQuestions?: boolean
}

// -----------------------------------------------------------------------------
// Main Components
// -----------------------------------------------------------------------------

// FormRenderer Component:
// Renders a dynamic form based on provided configuration and handles user interactions,
// including input changes, validation, and submission. It supports multi-page forms
// and exposes the handleSubmit method via a forwarded ref.
export const FormRenderer = forwardRef(({
    formDetail,
    formResult, /* aka userformData */
    formId,
    sourceFormId,
    userId,
    contextId,
    programId,
    isOnboarding = false,
    year,
    openModal,
    onCloseModal,
    requiredQuestions
}: FormRenderProps, ref) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const location = useLocation();


    // Local state for form data, errors, current page index, and split questions.
    const [formData, setFormData] = useState<any>({});
    const [formErrors, setFormErrors] = useState({});
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
    const [formQuestions, setFormQuestions] = useState<any>([])

    // Redux state selectors for context data and loading state.
    const { programFormContextInfo, programFormContextUserInfo, singleProgram } = useSelector((state: RootState) => state.programReducer)
    const { loading } = useSelector((state: RootState) => state.programEnrollmentReducer)
    const { myGoalsData } = useSelector((state: RootState) => state.dashboardReducer);


    // A debug component to help see what's happening (you can remove this in production)
    const DebugGoalInfo = ({ myGoalsData }) => {
        const [showDebug, setShowDebug] = useState(false);

        if (!myGoalsData) return null;

        return (
            <div style={{
                position: 'fixed',
                bottom: showDebug ? '20px' : '0',
                right: '20px',
                backgroundColor: '#f0f0f0',
                padding: '10px',
                border: '1px solid #ccc',
                maxHeight: showDebug ? '400px' : '30px',
                overflow: 'auto',
                transition: 'all 0.3s',
                zIndex: 1000,
                borderRadius: '5px',
                width: showDebug ? '400px' : '150px'
            }}>
                <button
                    onClick={() => setShowDebug(!showDebug)}
                    style={{
                        marginBottom: showDebug ? '10px' : '0',
                        width: '100%',
                        backgroundColor: '#007bff',
                        color: 'white',
                        border: 'none',
                        padding: '5px',
                        cursor: 'pointer',
                        borderRadius: '3px'
                    }}
                >
                    {showDebug ? 'Hide' : 'Show'} Goals Debug
                </button>

                {showDebug && (
                    <div>
                        <h4 style={{ margin: '5px 0' }}>myGoalsData Groups</h4>
                        {myGoalsData.groups && myGoalsData.groups.map((group, i) => (
                            <div key={i} style={{ marginBottom: '10px' }}>
                                <h5 style={{ margin: '5px 0' }}>{group.group}</h5>
                                <ul style={{ margin: '0', paddingLeft: '20px' }}>
                                    {group.goals.map((goal, j) => (
                                        <li key={j} style={{
                                            color: goal.isActive ? 'green' : 'red',
                                            fontWeight: goal.isActive ? 'bold' : 'normal'
                                        }}>
                                            {goal.goalName}: {goal.goalLabel} ({goal.isActive ? 'Active' : 'Inactive'})
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    //A method that checks that active form to see if it's a source of goal information
    const isFormGoalsSource = (programInfo, sourceFormId) => {


        const formInfo = programInfo.phases
            .flatMap(phase => phase.forms)
            .find(form => form.sourceFormId === sourceFormId);

        if (!formInfo) return false;

        const isMatch = programInfo.phases
            .flatMap(phase => phase.forms)
            .some(form => form.goalsInfo?.useAsSource === true && form.sourceFormId === sourceFormId);

        if (isMatch) {
            console.log(">> Form is source of Goals. Will not do goal lookups or field hiding");
        }
    }

    // -------------------------------
    // Helper Functions for Calculations
    // -------------------------------

    // Calculate the percentage for a given scale-type question.
    // It computes the percentage of the selected value relative to the scale's total.
    const calculateScalePercentage = useCallback((selectedValue, scaleFrom, scaleAmount) => {
        return (selectedValue / scaleAmount) * 100;
    }, []);

    // Calculate the average percentage for all "control_scale" questions in the form.
    const calculateTotalScalePercentage = useCallback(() => {
        let totalPercentage = 0;
        let scaleCount = 0;

        Object.entries(formDetail.questions).forEach(([key, question]: any) => {
            if (question.type === 'control_scale') {
                const selectedValue = formData[question.name];
                const scaleFrom = parseInt(question.scaleFrom, 10);
                const scaleAmount = parseInt(question.scaleAmount, 10);

                if (selectedValue) {
                    totalPercentage += calculateScalePercentage(parseInt(selectedValue, 10), scaleFrom, scaleAmount);
                    scaleCount += 1;
                }
            }
        });

        return scaleCount > 0 ? (totalPercentage / scaleCount).toFixed(2) : '0';
    }, [formData, formDetail.questions, calculateScalePercentage]);

    // -------------------------------
    // Event Handlers and Form Methods
    // -------------------------------

    // Handle input changes: update form data and clear any associated error.
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));

        setFormErrors(prevErrors => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    // Callback to update formData from external changes, only if the data differs.
    const onFormDataChange = useCallback((data) => {
        setFormData((prevData) => {
            const isEqual = JSON.stringify(prevData) === JSON.stringify(data);
            if (!isEqual) {
                return data;
            }
            return prevData;
        });
    }, []);

    // Validate the form: check required questions and flag missing fields with error messages.
    const validateForm = () => {
        let errors = {};
        let isValid = true;
        Object.entries(formDetail.questions).forEach(([key, question]: any) => {
            const value = formData[question.name];
            if (question.required === 'Yes') {
                // Skip validation for hidden questions.
                if (question.hidden == 'Yes') {
                    isValid = true;
                } else if (value === undefined || value === null || value === '') {
                    errors[question.name] = `${question.text} is required.`;
                    isValid = false;
                }
            }
        });

        setFormErrors(errors);

        if (!isValid) {
            toastr.error("Error", "Please fill all the required fields");
        }

        return isValid;
    };

    // Form submission handler: validates and dispatches the form submission action.
    // Also handles a special case to discard inputs.
    const handleSubmit = async (e: React.FormEvent, result = null) => {
        e.preventDefault();
        if (result === 'leaveAndDiscard') {
            setFormData({});
            if (isOnboarding) {
                onCloseModal();
            }
        } else {
            if (validateForm()) {
                const payload = { data: formData };
                if (result) {
                    const parsedResult = JSON.parse(result);
                    if (parsedResult.skipped) {
                        payload.data.skipped = parsedResult.skipped
                    }
                } else {
                    delete payload.data.skipped
                }

                //toastr.info("Form submitting...");

                try {
                    // Await the dispatch of the thunk. Make sure your thunk returns a promise.
                    await dispatch(submitProgramForm({
                        programId,
                        sourceFormId,
                        userId,
                        contextId,
                        year: year ? year.toString() : '',
                        payload
                    }));
                    //toastr.info("Form submitted successfully.");

                    // Only clear the form data if submission succeeds.
                    setFormData(payload.data);

                    if (isOnboarding) {
                        onCloseModal();
                    }

                } catch (error) {
                    const errorsArray = parseErrorString(error.message);
                    const toastMessage = errorsArray.join('<br/>'); // or '\n' for newline in plain text
                    toastr.error("Error", toastMessage);
                }

                toastr.info("Form submitted...");

                // Uncomment below to close the modal upon successful submission in onboarding mode.
                // if (isOnboarding) {
                //     onCloseModal();
                // }
            }
        }
    };

    // Function to parse the error string
    function parseErrorString(errorStr) {
        // Regular expression to match errors of the form:
        // "Missing required field: <fieldName> (order=<number>)"
        const regex = /Missing required field:\s*([^()]+)\s*\(order=(\d+)\)/g;
        const errors = [];
        let match;

        // Loop through all matches in the string
        while ((match = regex.exec(errorStr)) !== null) {
            const fieldName = match[1].trim();
            const order = match[2].trim();
            // Create a simplified error message
            errors.push(`Missing field: ${fieldName}`);
        }

        return errors;
    }
    //START <!--- GENERATED--->
    // Merge form data from two sources, prioritizing the new data.
    const mergeFormData = useCallback((baseData, newData) => {
        const result = { ...baseData };

        Object.entries(newData).forEach(([key, value]) => {
            // Handle values that might be objects with 'value' property
            result[key] = value?.hasOwnProperty('value') ? value['value'] : (value || '');
        });

        return result;
    }, []);
    //END <!--- GENERATED--->

    // Expose handleSubmit to parent components via the ref.
    useImperativeHandle(ref, () => ({
        handleSubmit,
    }));

    // -------------------------------
    // Effects for Data Initialization and Updates
    // -------------------------------

    // Add this as a separate useEffect in your FormRenderer component
    useEffect(() => {
        //console.log("=== DEBUG: myGoalsData check ===");
        //console.log("myGoalsData available?", myGoalsData ? "YES" : "NO");
        if (myGoalsData) {
            console.log("myGoalsData content:", myGoalsData);
        }
    }, [myGoalsData]); // Only runs when myGoalsData changes

    // Reset formData when the URL or modal state changes.
    useEffect(() => {
        //console.log("EFFECT4 formResult=>", formResult);

        setFormData({});
        isFormDataInitialized.current = false; // Reset the initialization flag
    }, [location.search, openModal]); // Add contextId as a dependency


    // Update the scale percentage calculation when form data changes.
    useEffect(() => {
        const calculationResult = calculateTotalScalePercentage();
        if (formData.calculationshown !== calculationResult) {
            setFormData((prevData) => ({
                ...prevData,
                calculationshown: calculationResult,
            }));
        }
    }, [calculateTotalScalePercentage, formData.calculationshown]);

    // Track if form data has been initialized.
    const isFormDataInitialized = useRef(false);

    // Initialize form data with default values and split questions into pages when formDetail changes.
    useEffect(() => {
        //console.log("EFFECT3 formResult=>", formResult);

        // Only initialize if we have form details and haven't already initialized from server data
        if (formDetail && formDetail.questions && !isFormDataInitialized.current) {
            //console.log("=== DEBUG: Processing form questions with myGoalsData ===");
            const initialFormData = {};



            if (Object.keys(initialFormData).length > 0) {
                console.log(`Initializing form data with default values`, initialFormData);
                setFormData(initialFormData);
                isFormDataInitialized.current = true;
            }
        }

        // Process and split questions regardless of initialization state
        // Sort questions and split into pages based on page breaks

        //const isGoalSourceForm = isFormGoalsSource(singleProgram, sourceFormId);

        if (formDetail && formDetail.questions) {
            let processedQuestions = formDetail.questions;
            //currently we don't do any additional processing           
            const sortedQuestions = Object.entries(processedQuestions)
                .sort(([, a]: any, [, b]: any) => {
                    const orderA = parseInt(a.order, 10) || 0;
                    const orderB = parseInt(b.order, 10) || 0;
                    return orderA - orderB;
                })
                .map(([_, question]) => question)
                // Filter out questions that should be hidden based on inactive goals
                .filter((question: any) => question.hidden !== "Yes");

            const pages = splitFormByPageBreak(sortedQuestions);
            console.log("pagesiii=>>>>", pages);
            setFormQuestions(pages);
        }
    }, [formDetail, myGoalsData, formResult]); // Add formResult to dependencies


    // Merge context data (e.g., due date, user name) into formData.
    useEffect(() => {

        if (isFormDataInitialized.current && formData && programFormContextInfo && programFormContextUserInfo) {
            const updatedFormData = { ...formData };
            Object.entries(formData).forEach(([key, value]: any) => { //NOT SURE THIS SECTION IS NEEDED  NOW THAT WE HAVE LOOKUP FIELDS
                switch (key.toLowerCase()) {
                    case 'duedate':
                        if (!value) {
                            updatedFormData[key] = programFormContextInfo.dueDate;
                        }
                        break;
                    case 'name':
                        if (!value) {
                            updatedFormData[key] = programFormContextUserInfo.name;
                        }
                        break;
                    case 'lastname':
                        if (!value) {
                            updatedFormData[key] = programFormContextUserInfo.lastName;
                        }
                        break;
                    case 'currentdate':
                        if (!value) {
                            updatedFormData[key] = new Date();
                        }
                        break;
                    default:
                        updatedFormData[key] = value || '';
                        break;
                }
            });
            setFormData(updatedFormData);
        }
    }, [programFormContextInfo, programFormContextUserInfo]);


    // Modified EFFECT1 code with goal values prioritization
    useEffect(() => {

        if (formResult && formResult !== null && Object.keys(formResult).length > 0) {
            console.log("EFFECT1 formResult=>", formResult);
            //=======
            //    if (formResult && formResult !== null && isFormDataInitialized.current) {
            //>>>>>>> 67780eddee36828290a6241823ed98e9c068933b

            // Skip if the context doesn't match
            if (formResult.contextId && formResult.contextId !== contextId) {
                return;
            }
            else {
                console.log(`contexts match ${formResult.contextId} === ${contextId}`);

                // Create a fresh copy of form data with values from formResult
                const updatedFormData = mergeFormData({}, formResult);


                setFormData(updatedFormData);
                isFormDataInitialized.current = true; // Mark as initialized after setting server data
            }
            // =======

            //             const updatedFormData = { ...formData };
            //             Object.entries(formResult).forEach(([key, value]: any) => {
            //                 updatedFormData[key] = value?.hasOwnProperty('value') ? value?.value : (value || '');
            //             });
            //             setFormData(updatedFormData);
            // >>>>>>> 67780eddee36828290a6241823ed98e9c068933b
        }
    }, [formResult, contextId, mergeFormData, myGoalsData]); // Added myGoalsData to dependencies

    // -------------------------------
    // Page Navigation Handlers
    // -------------------------------

    const handleNextPage = (e) => {
        // Prevent default form submission if event object exists
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        let index = currentPageIndex + 1;
        setCurrentPageIndex(index);
    }

    const handleBackPage = (e) => {
        // Prevent default form submission if event object exists
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        let index = currentPageIndex - 1;
        setCurrentPageIndex(index);
    }
    const isOnboardingRoute = location.pathname.includes("/onboarding/phase/");

    // -------------------------------
    // Render the Form
    // -------------------------------
    return (
        <>
            <div className={!isOnboardingRoute ? classes.container : classes.onboardingContainer}>
                {loading && <div className={classes.loader} ><MoonLoader size={40} loading /></div>}
                <form onSubmit={handleSubmit}>
                    {formQuestions[currentPageIndex] && Array.isArray(formQuestions[currentPageIndex]) && formQuestions[currentPageIndex].length > 0 &&
                        formQuestions[currentPageIndex].map((question, key) => {
                            if (question.hidden === "Yes") {
                                return null;
                            }
                            return (
                                <FormControlComponent
                                    key={key}
                                    question={question}
                                    formData={formData}
                                    handleChange={handleChange}
                                    setFormData={setFormData}
                                    error={formErrors[question.name]}
                                    backButton={location.pathname === '/user/form/show'}
                                    onNext={handleNextPage}
                                    onBack={handleBackPage}
                                    currentPageIndex={currentPageIndex}
                                    onFormDataChange={onFormDataChange}
                                />
                            );
                        })
                    }
                    <div className="form-group">
                        {/* Additional form group content can go here if needed */}
                    </div>
                </form>
            </div>
            {/* ====================== ADDED CODE START ====================== */}
            {/* < DebugGoalInfo myGoalsData= />*/}
            {/* ====================== ADDED CODE END ====================== */}

        </>
    );
});


// BackPack Component:
// Acts as a container component that fetches form structure and context data,
// extracts URL parameters, and renders the AppBar along with the FormRenderer.
const BackPack = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();

    // Extract query parameters from the URL.
    const searchParams = new URLSearchParams(location.search);
    const sourceFormId: string = searchParams.get('sourceFormId');
    const formId: string = searchParams.get('formId');
    const userId: string = searchParams.get('userId');
    const contextId: string = searchParams.get('contextId');

    // Retrieve active program enrollment from local storage.
    const programEnrollment: IProgramEnrollment = JSON.parse(localStorage.getItem('activeProgramEnrollment')) || {};
    const year = programEnrollment?.dates?.activeEnrollmentYear;

    const programId = programEnrollment?.programId;

    // Access form structure, user form data, error, and loading state from Redux.
    const { formStructure, error, userformData, loading, contextList } = useSelector((state: RootState) => state.programFormReducer)

    // Access myGoalsData from the dashboardReducer
    const { myGoalsData } = useSelector((state: RootState) => state.dashboardReducer);
    console.log("BackPack - myGoalsData available?", myGoalsData ? "YES" : "NO");

    const isMobile = useMediaQuery('(max-width:600px)');

    // Helper function to map status to an icon.
    const getContextStatusIcon = (status) => {
        switch (status) {
            case 'Not Ready':
                return <BlockIcon style={{ marginRight: 4 }} />;
            case 'Pending':
                return <ScheduleIcon style={{ marginRight: 4 }} />;
            case 'Completed':
                return <CheckCircleIcon style={{ marginRight: 4 }} />;
            case 'Overdue':
                return <ErrorOutlineIcon style={{ marginRight: 4 }} />;
            case 'Unknown':
                return <HelpOutlineIcon style={{ marginRight: 4 }} />;
            case 'Incomplete':
                return <InfoOutlinedIcon style={{ marginRight: 4 }} />;
            case 'Skipped':
                return <SkipNextIcon style={{ marginRight: 4 }} />;
            default:
                return null;
        }
    };


    // On mount or when location changes, fetch form structure and context data.
    useEffect(() => {
        if (userId && sourceFormId) {
            dispatch(getMyGoalsData(programId, userId));
            dispatch(getProgramFormStructure(programId, sourceFormId, userId));
            dispatch(getProgramFormContextData(programId, sourceFormId, userId, contextId));
            dispatch(getProgramListContextData(programId, userId, year ? year.toString() : ''));
            return;
        }
    }, [location]);

    // Render error message if no form is found.
    if (error && error !== null) {
        console.error("\n\n==============\nError fetching form data:\n");
        console.error("      error : ", error);

        return <div className={classes.noDataFound}>No Form Found</div>
    }

    // Display a centered spinner while loading data.
    if (loading) {
        return (<>
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        </>

        )
    }



    const formContexts = (contextList && contextList.length > 0) ? contextList.filter((context) => context.sourceFormId === sourceFormId) : []
    const contextIndex = formContexts.findIndex((context) => context.contextId === contextId)

    if (contextIndex < 0) {
        return <div className={classes.noDataFound}>No Context Id Found = <b>{contextId}</b></div>
    }

    const currentContext = formContexts[contextIndex]

    const previousContext = contextIndex > 0 ? formContexts[contextIndex - 1] : null
    const nextContext = contextIndex < formContexts.length - 1 ? formContexts[contextIndex + 1] : null

    const previousContextAllowed = previousContext;
    const nextContextAllowed = nextContext && moment(nextContext.startDate).isBefore(moment())
    //find contexts that are due or overdue


    // Function to redirect to a new context by updating the query parameter
    const redirectToContext = (newContextId: string) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("contextId", newContextId);
        history.push(`${location.pathname}?${searchParams.toString()}`);
    };


    // Once data is ready, render the AppBar and FormRenderer with the necessary props.
    return (
        <>
            {userformData && <ButtonAppBar title={userformData?.data?.title} back={() => history.push('user/dashboard')} rightIcon={<HeaderNotificationIcon />} />}

            <br />
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: '12px 0'
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<ArrowBackIcon />}
                    onClick={() => {
                        if (previousContextAllowed && previousContext) {
                            redirectToContext(previousContext.contextId);
                        }
                    }}
                    disabled={!previousContextAllowed}
                    style={
                        !previousContextAllowed
                            ? { backgroundColor: '#e0e0e0', color: '#9e9e9e' }
                            : {}
                    }
                >
                    {!isMobile && previousContext && getContextStatusIcon(previousContext.status)}
                    {previousContext ? moment(previousContext.dueDate).format('MMM Do') : 'N/A'}
                </Button>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {getContextStatusIcon(currentContext.status)}
                    <span style={{ marginLeft: 8 }}>
                        Due: {moment(currentContext.dueDate).format('MMM Do YYYY')}
                    </span>
                </div>

                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<ArrowForwardIcon />}
                    onClick={() => {
                        if (nextContextAllowed && nextContext) {
                            redirectToContext(nextContext.contextId);
                        }
                    }}
                    disabled={!nextContextAllowed}
                    style={
                        !nextContextAllowed
                            ? { backgroundColor: '#e0e0e0', color: '#9e9e9e' }
                            : {}
                    }
                >
                    {!isMobile && nextContext && getContextStatusIcon(nextContext.status)}
                    {nextContext ? moment(nextContext.dueDate).format('MMM Do') : 'N/A'}
                </Button>
            </div>


            {formStructure && <FormRenderer
                formResult={userformData}
                formDetail={formStructure}
                sourceFormId={sourceFormId}
                userId={userId}
                formId={formId}
                contextId={contextId}
                programId={programId}
            />}
        </>
    )
};

// -----------------------------------------------------------------------------
// Utility Functions
// -----------------------------------------------------------------------------

// Helper function to split the list of questions into pages based on page break markers.
// It groups questions into subarrays where each subarray represents a page.
const splitFormByPageBreak = (questions: any) => {
    const pages: any = [];
    let currentPage: any = [];

    questions.forEach((question) => {
        if (question.type === 'control_pagebreak' && currentPage.length > 0) {
            currentPage.push(question);
            pages.push(currentPage);
            currentPage = [];
        } else {
            currentPage.push(question);
        }
    });

    if (currentPage.length > 0) {
        pages.push(currentPage);
    }
    return pages;
};

// Export BackPack as the default component for this module.
export default BackPack;
