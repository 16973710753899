import { Box, Grid, Typography } from '@material-ui/core'
import React from 'react'
import AccountDetailsForm from './components/AccountDetailsForm'
import AccountInfo from './components/AccountInfo'
import ButtonAppBar from '../../../components/AppBar'
import HeaderNotificationIcon from '../../../components/HeaderNotification'
import { useHistory } from 'react-router'
import ChangePasswordForm from './components/ChangePasswordForm'

const Profile = () => {
    const history = useHistory();
    return (
        <>
            <ButtonAppBar title='Update Profile' back={() => history.go(-1)} rightIcon={<HeaderNotificationIcon/>} />
            <Box p={3}>
                <Grid container spacing={3}>
                    <Grid item lg={4} md={6} xs={12}>
                        <AccountInfo />
                    </Grid>
                    <Grid item lg={8} md={6} xs={12}>
                        <AccountDetailsForm />
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        <ChangePasswordForm/>
                    </Grid>
                </Grid>
            </Box>
        </>

    )
}

export default Profile