import React, { useEffect, useState } from 'react'
import { Box, Button, InputAdornment, Paper, TableBody, IconButton, Table, TableRow, TableCell, TableContainer, TableHead, TextField, Typography, Collapse, Card, Grid } from '@material-ui/core'
import { DeleteOutline, Edit, ExpandLess, ExpandMore, Search } from '@material-ui/icons'
import { Pagination } from '@material-ui/lab'
import { useHistory, useParams } from 'react-router';
import { useStyles } from '../style';
import { useDispatch, useSelector } from 'react-redux';
import { getProgramById, getProgramFormList, updateFormInProgram } from '../../../../redux/actions/programAction';
import { RootState } from '../../../../redux/store';
import UpdateFormDialog from './components/UpdateFormDialog';
import { IProgramForm } from '../../../../types/program';
import { Link } from 'react-router-dom';

const ProgramDetails = () => {
    const { programId }: any = useParams();
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { singleProgram } = useSelector((state: RootState) => state.programReducer)
    const ITEMS_PER_PAGE = 5;

    const [openPhase, setOpenPhase] = useState(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedFormData, setSelectedFormData] = useState<any | null>(null);

    const handlePageChange = (event, page) => {
        event.stopPropagation();
        setCurrentPage(page);
    };

    const handlePhaseToggle = (phaseIndex) => {
        setOpenPhase(openPhase === phaseIndex ? null : phaseIndex);
        setCurrentPage(1);
    };

    const onEditFormData = (event, phaseNumber, formData) => {
        event.stopPropagation();
        setSelectedFormData({
            ...formData,
            phaseNumber
        });
        setOpenDialog(true);
    }

    const onSaveFormData = (updatedData) => {
        dispatch(updateFormInProgram(programId, updatedData))
    };

    useEffect(() => {
        if (programId) {
            dispatch(getProgramById(programId));
            dispatch(getProgramFormList(programId));
        }
    }, [dispatch, programId]);

    return (
        <>
        <div>
        {singleProgram && singleProgram.phases && singleProgram.phases.length > 0 ? (
            singleProgram.phases.map((phase: any, pIndex: number) => {
                const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
                const paginatedForms = phase.forms.slice(startIndex, startIndex + ITEMS_PER_PAGE);

                return (
                    <Card key={pIndex} className={classes.phaseCard} onClick={() => handlePhaseToggle(pIndex)}>
                        <TableContainer className={classes.tableContainer}>
                            <Table>
                                <TableHead>
                                    <TableRow style={{ cursor: 'pointer' }}>
                                        <TableCell style={{ width: '40%' }}>
                                            <Box display={'flex'} alignItems={'center'}>
                                                {`Phase ${phase?.phaseNumber}`}
                                                {openPhase === pIndex ? <ExpandLess /> : <ExpandMore />}
                                            </Box>
                                        </TableCell>
                                        <TableCell style={{ width: '20%' }}>
                                            {openPhase !== pIndex ? `${phase.forms.length} Forms` : 'Description'}
                                        </TableCell>
                                        {openPhase === pIndex && (
                                            <>
                                                <TableCell style={{ width: '20%' }}>Source</TableCell>
                                                <TableCell style={{ width: '20%' }}>Source Form Id</TableCell>
                                                <TableCell style={{ width: '20%' }}>Actions</TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                </TableHead>
                                {openPhase === pIndex && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{ padding: 0 }} colSpan={5}>
                                                <Collapse in={openPhase === pIndex} timeout="auto" unmountOnExit>
                                                    <Box margin={1}>
                                                        <Table size="small">
                                                            <TableBody>
                                                                {paginatedForms.map((form, fIndex) => (
                                                                    <TableRow key={fIndex}>
                                                                        <TableCell style={{ width: '40%' }}>{form.label}</TableCell>
                                                                        <TableCell style={{ width: '20%' }}>{form.description}</TableCell>
                                                                        <TableCell style={{ width: '20%' }}>{form?.source}</TableCell>
                                                                        <TableCell style={{ width: '20%' }}>
                                                                            <Link to={{ pathname: `https://form.jotform.com/${form?.sourceFormId}` }} target='_blank'>
                                                                                {form?.sourceFormId}
                                                                            </Link>
                                                                        </TableCell>
                                                                        <TableCell style={{ width: '20%' }}>
                                                                            <IconButton onClick={(e) => onEditFormData(e, phase?.phaseNumber, form)}>
                                                                                <Edit fontSize="small" style={{ color: '#333333' }} />
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </Box>
                                                    {/* Pagination for forms */}
                                                    <Box display="flex" padding={2} justifyContent="space-between" marginTop={2} marginBottom={2}>
                                                        <Button className={classes.addFormBtn}>
                                                            Add Form
                                                        </Button>
                                                        <Pagination
                                                            className={classes.pagination}
                                                            count={Math.ceil(phase.forms.length / ITEMS_PER_PAGE)}
                                                            page={currentPage}
                                                            onChange={handlePageChange}
                                                            variant="outlined"
                                                            shape="rounded"
                                                        />
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Card>
                );
            })
        ) : (
            <pre>{JSON.stringify(singleProgram, null, 2)}</pre>
        )}
    </div>
    <UpdateFormDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        data={selectedFormData}
        onSave={onSaveFormData}
    />
    </>
)
}
export default ProgramDetails