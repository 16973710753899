import React, { useCallback, useEffect, useState } from 'react'
import { Box, Paper, TableBody, IconButton, Table, TableRow, TableCell, TableContainer, TableHead, TextField } from '@material-ui/core'
import { DeleteOutline, Edit, Search, Visibility } from '@material-ui/icons'
import { Pagination } from '@material-ui/lab'
import { useHistory, useParams } from 'react-router';
import { useStyles } from '../style';
import { useDispatch, useSelector } from 'react-redux';
import { getProgramTeams, removeTeam } from '../../../../redux/actions/programAction';
import { RootState } from '../../../../redux/store';
import moment from 'moment';
import UpdateTeamDialog from './components/UpdateTeamDialog';
import { ITeam } from '../../../../types/team';
import { saveTeams } from '../../../../redux/actions/programAction';

const TeamList = () => {
    const { programId }: any = useParams();
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const teamList = useSelector((state: RootState) => state.programReducer.teamList)
    const [openModal, setOpenModal] = useState(false);
    const [editData, setEditData] = useState<ITeam | null>(null);
    const ITEMS_PER_PAGE = 5;
    const [currentPage, setCurrentPage] = useState<number>(1);

    useEffect(() => {
        programId && dispatch(getProgramTeams(programId));
    }, []);

    const handleDelete = (e, teamId) => {
        if (window.confirm('Are you sure want to delete ?')) {
            dispatch(removeTeam(teamId))
        }
    }

    const handlePageChange = (event, page) => {
        event.stopPropagation();
        setCurrentPage(page);
    };

    const onEditTeam = (data: ITeam) => {
        setOpenModal(true);
        setEditData(data);
    }

    const handleSave = useCallback(async (data) => {
        dispatch(saveTeams(data));
        setOpenModal(false);
    }, []);

    return (
        <div>
            <TableContainer className={classes.tableContainer} component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Team Name</TableCell>
                            <TableCell>No. of Members</TableCell>
                            <TableCell>Last Updated</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {teamList && teamList.length > 0 && teamList.map((team, index) => {

                            return (
                                <TableRow key={index}>
                                    <TableCell>{team?.name}</TableCell>
                                    <TableCell>n/a</TableCell>
                                    <TableCell>{moment(team?.updatedAt).format('MMM DD, YYYY')}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => history.push(`/admin/dashboard/program/${programId}/team/${team?._id}`)}>
                                            <Visibility fontSize='small' style={{ color: '#333333' }} />
                                        </IconButton>
                                        <IconButton onClick={() => onEditTeam(team)}>
                                            <Edit fontSize='small' style={{ color: '#333333' }} />
                                        </IconButton>
                                        <IconButton onClick={(e) => handleDelete(e, team?._id)}>
                                            <DeleteOutline fontSize='small' color='error' />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                        {(teamList?.length === 0) && (<TableRow> <TableCell align='center' colSpan={4}>No records found</TableCell> </TableRow>)}

                    </TableBody>
                </Table>
            </TableContainer>

            <Box style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                <Pagination className={classes.pagination} count={Math.ceil(teamList?.length / ITEMS_PER_PAGE)}
                    page={currentPage}
                    onChange={handlePageChange} variant="outlined" shape="rounded" />
            </Box>

            <UpdateTeamDialog
                open={openModal}
                handleClose={() => setOpenModal(!openModal)}
                data={editData}
                onSave={handleSave}
            />

        </div>
    )
}

export default TeamList