// ProgramFormFieldProcessor.ts
import { queryFormValuesApi } from '../../api'; // adjust the path as needed

// Define an interface for the match details we collect
interface FieldMatch {
    questionId: string;
    prop: 'label' | 'name' | 'hint' | 'value' | 'description' | 'subLabel' | 'hoverText';
    fieldName: string;
}

interface GroupData {
    sourceFormId: string;
    period: string;
    fields: Set<string>;
    matches: FieldMatch[];
}

export class ProgramFormFieldProcessor {
    /**
     * Process the form definition by searching for special field markers.
     * For every match in question properties (name, hint, value) that match the pattern
     * {sourceFormId:fieldName:period}, an API call is made to get the lookup value.
     * For control_text questions, the matched property value is replaced with the looked up value.
     *
     * @param programId The program ID
     * @param userId The user ID
     * @param sourceFormId The source form ID (from the form details)
     * @param formDefinition The complete form definition object
     * @returns The processed form definition
     */
    public static async processFormDefinition(
        programId: string,
        userId: string,
        sourceFormId: string,
        formDefinition: any,
        customProcessor?: (question: any) => void
    ): Promise<any> {
        // This regex matches tokens of the form:
        // { sourceFormId : fieldName : period }
        // allowing optional whitespace.
        const regexString = '\\{\\s*([^:}]+)\\s*:\\s*([^:}]+)\\s*:\\s*([^:}]+)\\s*\\}';
        // The list of properties to check on each question.
        const propsToCheck = ['label', 'name', 'hint', 'value', 'text', 'description', 'subLabel', 'hoverText'];
    
        // Process each question individually.
        for (const questionId in formDefinition.questions) {
            if (!Object.prototype.hasOwnProperty.call(formDefinition.questions, questionId)) continue;
            const question = formDefinition.questions[questionId];
            
            // Apply custom processor if provided
            if (customProcessor) {
                customProcessor(question);
            }

            // Collect tokens from this question.
            // Each token object has: token (the full matched string),
            // sourceFormId, fieldName, period, and the property in which it was found.
            const tokens: Array<{ token: string; sourceFormId: string; fieldName: string; period: string; prop: string }> = [];
            for (const prop of propsToCheck) {
                if (typeof question[prop] === 'string') {
                    const regex = new RegExp(regexString, 'g');
                    let match: RegExpExecArray | null;
                    while ((match = regex.exec(question[prop])) !== null) {
                        tokens.push({
                            token: match[0],
                            sourceFormId: match[1],
                            fieldName: match[2],
                            period: match[3],
                            prop
                        });
                        console.log(`Question ${questionId}: Found token in "${prop}": ${match[0]}`);
                    }
                }
            }
            if (tokens.length === 0) continue; // No tokens, skip this question.
    
            // Group tokens by composite key (sourceFormId_period) for this question.
            const groups: { [key: string]: { sourceFormId: string; period: string; fields: Set<string>; tokens: Array<typeof tokens[0]> } } = {};
            tokens.forEach(t => {
                const key = `${t.sourceFormId}_${t.period}`;
                if (!groups[key]) {
                    groups[key] = { sourceFormId: t.sourceFormId, period: t.period, fields: new Set(), tokens: [] };
                }
                groups[key].fields.add(t.fieldName);
                groups[key].tokens.push(t);
            });
    
            // For each group in this question, call the lookup API and update tokens.
            for (const groupKey in groups) {
                const group = groups[groupKey];
                const fieldsArray = Array.from(group.fields).map(fieldName => ({ name: fieldName }));
                const onDate = new Date();
                const year = 2025;
                console.log(`Question ${questionId}: Querying API for group ${groupKey} with fields: ${JSON.stringify(fieldsArray)}`);
                let apiResult: any;
                try {
                    apiResult = await queryFormValuesApi({
                        programId,
                        userId,
                        sourceFormId: group.sourceFormId,
                        fields: fieldsArray,
                        year,
                        onDate,
                        period: group.period,
                    });
                } catch (error) {
                    console.error(`Question ${questionId}: Error querying API for group ${groupKey}`, error);
                    continue;
                }
                const valueMap = new Map<string, any>();
                if (apiResult && Array.isArray(apiResult.values)) {
                    apiResult.values.forEach((v: { fieldName: string; value: any }) => {
                        valueMap.set(v.fieldName, v.value);
                    });
                    console.log(`Question ${questionId}: API returned values: ${JSON.stringify(apiResult.values)}`);
                } else {
                    console.log(`Question ${questionId}: No API values returned for group ${groupKey}`);
                    continue;
                }
                // Now update each token in this group.
                group.tokens.forEach(token => {
                    let newValue = valueMap.get(token.fieldName);
                    newValue = (newValue) ? newValue : '';

                    if (newValue !== undefined && newValue!==null && newValue !== 'null') {
                        // If the property exactly equals the token string, use the original processor.
                        if (question[token.prop] === token.token) {
                            if (token.prop === 'name' && question.name === token.fieldName) {
                                ProgramFormFieldProcessor.processValue('name', newValue, question);
                                console.log(`Question ${questionId}: processValue updated name to "${newValue}"`);
                            }
                            ProgramFormFieldProcessor.processValue(token.prop, newValue, question);
                            console.log(`Question ${questionId}: processValue updated property "${token.prop}" from token "${token.token}" to "${newValue}"`);
                        } else {
                            // Otherwise, perform an embedded replacement.
                            // (This assumes the token appears verbatim in the property.)
                            const tokenRegex = new RegExp(token.token, 'g');
                            question[token.prop] = question[token.prop].replace(tokenRegex, newValue);
                            console.log(`Question ${questionId}: Replaced embedded token "${token.token}" in property "${token.prop}" with "${newValue}"`);
                        }
                    }
                });
            }
        }
        return formDefinition;
    }



    // public static async processFormDefinitionOld2(
    //     programId: string,
    //     userId: string,
    //     sourceFormId: string,
    //     formDefinition: any
    // ): Promise<any> {
    //     // Updated regex pattern with optional whitespace.
    //     // If you don't need to allow whitespace, you can revert to:
    //     // const regexString = '\\{([^:}]+):([^:}]+):([^:}]+)\\}';
    //     const regexString = '\\{\\s*([^:}]+)\\s*:\\s*([^:}]+)\\s*:\\s*([^:}]+)\\s*\\}';
        
    //     // Define a type for grouping matches.
    //     type GroupData = {
    //         sourceFormId: string;
    //         period: string;
    //         fields: Set<string>;
    //         // Each match stores details.
    //         matches: Array<{ questionId: string; prop: string; fieldName: string; period: string }>;
    //         // Will store the lookup values after the API call.
    //         valueMap?: Map<string, any>;
    //     };
    
    //     // Group matches by composite key: `${patternSourceFormId}_${period}`
    //     const groups = new Map<string, GroupData>();
    
    //     console.log(`\n\n\n############# Processing form definition for sourceFormId: ${sourceFormId}\n\n\n###########\n\n\n`);
    
    //     // FIRST PASS: Extract tokens from each question property.
    //     if (formDefinition.questions) {
    //         for (const questionId in formDefinition.questions) {
    //             if (Object.prototype.hasOwnProperty.call(formDefinition.questions, questionId)) {
    //                 const question = formDefinition.questions[questionId];
    //                 // Process these properties.
    //                 const propertiesToCheck: Array<'label' | 'name' | 'hint' | 'value' | 'text' | 'description' | 'subLabel' > = ['label', 'name', 'hint', 'value', 'text','description','subLabel'];
                    
    //                 propertiesToCheck.forEach((prop) => {
    //                     const propValue = question[prop];
    //                     if (typeof propValue === 'string') {
    //                         // Create a fresh regex instance for extraction.
    //                         const extractionRegex = new RegExp(regexString, 'g');
    //                         let match: RegExpExecArray | null;
    //                         while ((match = extractionRegex.exec(propValue)) !== null) {
    //                             // match[1]=sourceFormId, match[2]=fieldName, match[3]=period.
    //                             const patternSourceFormId = match[1];
    //                             const fieldName = match[2];
    //                             const period = match[3];
    //                             const groupKey = `${patternSourceFormId}_${period}`;
    //                             if (!groups.has(groupKey)) {
    //                                 groups.set(groupKey, {
    //                                     sourceFormId: patternSourceFormId,
    //                                     period,
    //                                     fields: new Set<string>(),
    //                                     matches: [],
    //                                 });
    //                             }
    //                             const group = groups.get(groupKey)!;
    //                             group.fields.add(fieldName);
    //                             group.matches.push({ questionId, prop, fieldName, period });
    //                             console.log(`Match found: ${JSON.stringify({ questionId, prop, fieldName, patternSourceFormId, period })}`);
    //                         }
    //                     }
    //                 });
    //             }
    //         }
    //     }
    
    //     // SECOND PASS: For each group, call the lookup API to fetch values.
    //     for (const group of groups.values()) {
    //         try {
    //             const fieldsArray = [];
    //             for (const fieldName of group.fields) {
    //                 fieldsArray.push({ name: fieldName });
    //             }
    //             const onDate = new Date();
    //             const year = 2025; // Fixed year.
    //             console.log(`Querying API for group: ${JSON.stringify({
    //                 sourceFormId: group.sourceFormId,
    //                 period: group.period,
    //                 fields: fieldsArray,
    //                 matches: group.matches
    //             })}`);
    //             const apiResult: any = await queryFormValuesApi({
    //                 programId,
    //                 userId,
    //                 sourceFormId: group.sourceFormId,
    //                 fields: fieldsArray,
    //                 year,
    //                 onDate,
    //                 period: group.period,
    //             });
    //             if (apiResult && apiResult.values && Array.isArray(apiResult.values)) {
    //                 console.log(`\n\n\n@@@@@@@@@@ API returned values for group: ${JSON.stringify(apiResult.values)}`);
    //                 const valueMap = new Map<string, any>();
    //                 apiResult.values.forEach((v: { fieldName: string; value: any }) => {
    //                     valueMap.set(v.fieldName, v.value);
    //                 });
    //                 group.valueMap = valueMap;
    //             } else {
    //                 console.log("@@@@@@@@@@ No values returned from query. Skipping update for group:", group);
    //             }
    //         } catch (error) {
    //             console.error('Error querying form values for group:', group, error);
    //         }
    //     }
        
    //     // THIRD PASS: For each match where the entire property exactly equals the token, call processValue.
    //     for (const group of groups.values()) {
    //         if (group.valueMap) {
    //             group.matches.forEach((m) => {
    //                 const question = formDefinition.questions[m.questionId];
    //                 // Reconstruct the token string (without extra whitespace).
    //                 const tokenStr = `{${group.sourceFormId}:${m.fieldName}:${m.period}}`;
    //                 if (question[m.prop] === tokenStr) {
    //                     const newValue = group.valueMap.get(m.fieldName);
    //                     if (m.prop === 'name' && question.name === m.fieldName) {
    //                         ProgramFormFieldProcessor.processValue('name', newValue, question);
    //                         console.log(`@@@@@@@@@@@@ Updated question ID: ${m.questionId}, [${question.type}] property: NAME, new value: ${newValue} ->`);
    //                         console.log(`            @@@@@@@@@@> ${JSON.stringify(question)}`);
    //                     }
    //                     ProgramFormFieldProcessor.processValue(m.prop, newValue, question);
    //                     console.log(`@@@@@@@@@@@@ Updated question ID: ${m.questionId}, [${question.type}] property: ${m.prop}, new value: ${newValue} ->`);
    //                     console.log(`            @@@@@@@@@@> ${JSON.stringify(question)}`);
    //                 }
    //             });
    //         }
    //     }
    
    //     // FOURTH PASS: Replace embedded tokens within string properties.
    //     // Updated regex to optionally match quotes around the token.
    //     // (["']?) -> optional opening quote (captured as group1)
    //     // (\{\s*([^:}]+)\s*:\s*([^:}]+)\s*:\s*([^:}]+)\s*\}) -> group2 is the token; group3: sourceFormId, group4: fieldName, group5: period.
    //     // \1 ensures matching closing quote.
    //     const embeddedRegex = /(["']?)(\{\s*([^:}]+)\s*:\s*([^:}]+)\s*:\s*([^:}]+)\s*\})\1/g;
    //     // Now include 'text' in the list.
    //     const propertiesForReplacement: Array<'label' | 'name' | 'hint' | 'value' | 'text' | 'description' | 'subLabel' > = ['label', 'name', 'hint', 'value', 'text', 'description', 'subLabel'];
    //     if (formDefinition.questions) {
    //         for (const questionId in formDefinition.questions) {
    //             if (Object.prototype.hasOwnProperty.call(formDefinition.questions, questionId)) {
    //                 const question = formDefinition.questions[questionId];
    //                 propertiesForReplacement.forEach((prop) => {
    //                     if (typeof question[prop] === 'string') {
    //                         let hadMatch = false;
    //                         const newValue = question[prop].replace(embeddedRegex, (match, quote, token, sourceFormId, fieldName, period) => {
    //                             const groupKey = `${sourceFormId}_${period}`;
    //                             const group = groups.get(groupKey);
    //                             if (group && group.valueMap && group.valueMap.has(fieldName)) {
    //                                 const replacement = group.valueMap.get(fieldName);
    //                                 console.log(`Replacing token "${match}" in property "${prop}" of question "${questionId}" with value "${replacement}"`);
    //                                 hadMatch = true;
    //                                 // Return the replacement wrapped in the same quote (if any).
    //                                 return quote + replacement + quote;
    //                             }
    //                             return match;
    //                         });
    //                         if (hadMatch) {
    //                             question[prop] = newValue;
    //                             console.log(`After replacement, question "${questionId}" property "${prop}" is now: ${question[prop]}`);
    //                         }
    //                     }
    //                 });
    //             }
    //         }
    //     }
    
    //     if (groups.size === 0) {
    //         console.log("No matches found in form definition. Skipping lookup.");
    //     }
        
    //     return formDefinition;
    // }
    
    

    // public static async processFormDefinitionOld(
    //     programId: string,
    //     userId: string,
    //     sourceFormId: string,
    //     formDefinition: any
    // ): Promise<any> {
    //     // Regular expression to match {sourceFormId:fieldName:period}
    //     const regex = /{([^:}]+):([^:}]+):([^:}]+)}/g;

    //     // We'll group matches by a composite key: `${patternSourceFormId}_${period}`
    //     const groups = new Map<string, GroupData>();

    //     console.log(`\n\n\n############# Processing form definition for sourceFormId: ${sourceFormId}\n\n\n###########\n\n\n`);

    //     // Iterate over questions.
    //     // Assumes formDefinition.questions is an object where each key is a question ID.
    //     if (formDefinition.questions) {
    //         for (const questionId in formDefinition.questions) {
    //             if (Object.prototype.hasOwnProperty.call(formDefinition.questions, questionId)) {
    //                 const question = formDefinition.questions[questionId];

    //                 // List of properties to check on each question.
    //                 const propertiesToCheck: Array<'label' | 'name' | 'hint' | 'value' | 'description' | 'subLabel' > = ['label','name', 'hint', 'value', 'description', 'subLabel'];
                    
    //                 propertiesToCheck.forEach((prop) => {
    //                     const propValue = question[prop];
    //                     if (typeof propValue === 'string') {
    //                         const match = regex.exec(propValue);
    //                         if (match) {
    //                             // Extract parts from the match.
    //                             const patternSourceFormId = match[1];
    //                             const fieldName = match[2];
    //                             const period = match[3];

    //                             // Create a group key for grouping by sourceFormId and period.
    //                             const groupKey = `${patternSourceFormId}_${period}`;
    //                             if (!groups.has(groupKey)) {
    //                                 groups.set(groupKey, {
    //                                     sourceFormId: patternSourceFormId,
    //                                     period,
    //                                     fields: new Set<string>(),
    //                                     matches: [],
    //                                 });
    //                             }
    //                             const group = groups.get(groupKey)!;
    //                             group.fields.add(fieldName);
    //                             group.matches.push({ questionId, prop, fieldName });

    //                             // Debug logging
    //                             console.log(`Match found: ${JSON.stringify({ questionId, prop, fieldName, patternSourceFormId, period })}`);
    //                         }
    //                     }
    //                 });
    //             }
    //         }
    //     }

    //     // Process each group: call the lookup API and update questions.
    //     for (const group of groups.values()) {
    //         try {
    //             // Prepare the fields array in the required format.
    //             const fieldsArray = [];
    //             for(const fieldName of group.fields){
    //                 fieldsArray.push({name: fieldName});
    //             } 
                 
    //             // Use now as the onDate.
    //             const onDate = new Date();
    //             const year = 2025; // Use a fixed year for now.

    //             // Call the query API.
    //             console.log(`Querying API for group: ${JSON.stringify(group)}`);
    //             const apiResult: any = await queryFormValuesApi({
    //                 programId,
    //                 userId,
    //                 sourceFormId: group.sourceFormId,
    //                 fields: fieldsArray,
    //                 year,
    //                 onDate,
    //                 period: group.period,
    //             });

    //             // If the API returns values, create a mapping of fieldName to lookup value.
    //             if (apiResult && apiResult.values && Array.isArray(apiResult.values)) {
    //                 console.log(`\n\n\n@@@@@@@@@@ API returned values for group: ${JSON.stringify(apiResult.values)}`);
    //                 const valueMap = new Map<string, any>();
    //                 apiResult.values.forEach((v: { fieldName: string; value: any }) => {
    //                     valueMap.set(v.fieldName, v.value);
    //                 });

    //                 // For each match in the group, update the question if its control type is control_text.
    //                 group.matches.forEach((m) => {
    //                     const question = formDefinition.questions[m.questionId];
    //                     const newValue = valueMap.get(m.fieldName);
                        
    //                     if(question.name === m.fieldName){
    //                         ProgramFormFieldProcessor.processValue('name', newValue, question);
    //                         console.log(`@@@@@@@@@@@@ Updated question ID: ${m.questionId},  [${question.type}]     property: NAME, new value: ${newValue} ->`);
    //                         console.log(`            @@@@@@@@@@> ${JSON.stringify(question)}`);
    //                     }

    //                     ProgramFormFieldProcessor.processValue(m.prop, newValue, question);
    //                     console.log(`@@@@@@@@@@@@ Updated question ID: ${m.questionId},  [${question.type}]     property: ${m.prop}, new value: ${newValue} ->`);
    //                     console.log(`            @@@@@@@@@@> ${JSON.stringify(question)}`);
                        
                       
    //                 });
    //             }
    //             else{
    //                 console.log("@@@@@@@@@@ No values returned from query. Skipping update for group:", group);
    //             }

    //         } catch (error) {
    //             console.error('Error querying form values for group:', group, error);
    //             // On error, skip updating values for this group.
    //         }
    //     }

    //     if(groups.size === 0){
    //         console.log("No matches found in form definition. Skipping lookup.");
    //         return formDefinition;
    //     }
        
    //     return formDefinition;
    // }


    private static processValue = (prop: string, newValue: any, question: any) => {
        
        if(prop==='hint'){
            ProgramFormFieldProcessor.processHintValue(prop, newValue, question);
        }
        else if(prop==='text'){
            ProgramFormFieldProcessor.processTextValue(newValue, question);
        }
        else if(prop==='name'){
            ProgramFormFieldProcessor.processNameValue(newValue, question);
        }
        else if(prop==='subLabel'){
            ProgramFormFieldProcessor.processSubLabelValue(newValue, question);
        }
        else if(prop==='description'){
            ProgramFormFieldProcessor.processDescriptionValue(newValue, question);
        }
        else if(prop==='hoverText'){
            ProgramFormFieldProcessor.processHoverTextValue(newValue, question);
        }
        else{
            console.log(`            @@@@@@ ${prop} @@@@@ > !!!!!!!!! Processing ${prop} : (old='${question[prop]}') new = '${newValue}' [${question.type}] !!!!!!`);
            question[prop] = newValue;
        }
    }
    

    private static processHintValue = (prop: string, newValue: any, question: any) => {
        if(newValue){
            console.log(`            @@@@@@ HINT @@@@@ > Processing hint : (old='${question['hint']}') new = '${newValue}' [${question.type}]`);
            question['hint'] = newValue;
        }
    }
    
    private static processTextValue = (newValue: any, question: any) => {
        if(newValue){
            console.log(`            @@@@@@ TEXT @@@@@ > Processing text : (old='${question['text']}') new = '${newValue}' [${question.type}]`);
            question['text'] = newValue;
            question['defaultValue'] = newValue;
        }
    }

    private static processNameValue = (newValue: any, question: any) => {
        if(newValue){
            //if the name is a match for the quesion, then we update the text
            console.log(`            @@@@@@ TEXT @@@@@ > Processing name match (implies text/default) : (old=${question['text']}) new = ${newValue} [${question.type}]`);
            question['text'] = newValue;
            question['defaultValue'] = newValue;
        }
    }

    private static processSubLabelValue = (newValue: any, question: any) => {
        if(newValue){
            //if the name is a match for the quesion, then we update the text
            console.log(`            @@@@@@ SUBLABEL @@@@@ > Processing subLabel match : (old=${question['subLabel']}) new = ${newValue} [${question.type}]`);
            question['subLabel'] = newValue;
        }
    }


    private static processDescriptionValue = (newValue: any, question: any) => {
        if(newValue){
            //if the name is a match for the quesion, then we update the text
            console.log(`            @@@@@@ DESCRIPTION @@@@@ > Processing description match : (old=${question['description']}) new = ${newValue} [${question.type}]`);
            question['description'] = newValue;
        }
    }
    private static processHoverTextValue = (newValue: any, question: any) => {
        if(newValue){
            //if the name is a match for the quesion, then we update the text
            console.log(`            @@@@@@ HOVERTEXT @@@@@ > Processing hovertext match : (old=${question['description']}) new = ${newValue} [${question.type}]`);
            question['hoverText'] = newValue;
        }
    }

        //A method that checks that active form to see if it's a source of goal information
    public static isFormGoalsSource = ( programInfo, sourceFormId) => {
    
        const formInfo = programInfo.phases
            .flatMap(phase => phase.forms)
            .find(form => form.sourceFormId === sourceFormId);

        if (!formInfo) return false;

        const isMatch = programInfo.phases
            .flatMap(phase => phase.forms)
            .some(form => form.goalsInfo?.useAsSource === true && form.sourceFormId === sourceFormId);

        if(isMatch){
            console.log(">> Form is source of Goals. Will not do goal lookups or field hiding");
        }
    }

}
