import {
  getNotificationsAPI,
  updateNotificationStateAPI,
  updateNotificationStatesWithIdsAPI,
} from '../../api/index';
import { NotificationState } from '../../constants/data';
import { INotification } from '../../types/notification';

export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAIL = 'GET_NOTIFICATION_FAIL';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const UPDATE_NOTIFICATION_STATE_SUCCESS =
  'UPDATE_NOTIFICATION_STATE_SUCCESS';
export const UPDATE_NOTIFICATION_STATE_FAIL = 'UPDATE_NOTIFICATION_STATE_FAIL';
export const CLOSE_NOTIFICATIONS_WITH_IDS_SUCCESS =
  'CLOSE_NOTIFICATIONS_WITH_IDS_SUCCESS';
export const CLOSE_NOTIFICATIONS_WITH_IDS_FAIL =
  'CLOSE_NOTIFICATIONS_WITH_IDS_FAIL';
export const SET_LOADING = 'SET_LOADING';

// actions
const setLoading = (loading: boolean) => ({
  type: SET_LOADING,
  payload: loading,
});

const getNotificationsSuccess = (payload: INotification[]) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload,
});

const getNotificationsFail = (error: string) => ({
  type: GET_NOTIFICATIONS_FAIL,
  payload: error
});

const updateNotificationStateSuccess = (payload: INotification) => ({
  type: UPDATE_NOTIFICATION_STATE_SUCCESS,
  payload,
});

const updateNotificationStateFail = (error: string) => ({
  type: UPDATE_NOTIFICATION_STATE_FAIL,
  payload: error,
});

const closeNotificationsSuccess = () => ({
  type: CLOSE_NOTIFICATIONS_WITH_IDS_SUCCESS,
  payload: null,
});

const closeNotificationsFail = (error: string) => ({
  type: CLOSE_NOTIFICATIONS_WITH_IDS_FAIL,
  payload: error,
});

// dispatch
export const getNotifications = () => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
      const res = await getNotificationsAPI();
      if (res.success) {
        dispatch(getNotificationsSuccess(res.data));
      } else {
        dispatch(getNotificationsFail(res.message));
      }
    } catch (error) {
      dispatch(getNotificationsFail(error.toString()));
    }
    dispatch(setLoading(false));
  };
};

export const closeNotifications = (notifications: INotification[]) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
      const ids = notifications.map((item) => item._id);
      const res = await updateNotificationStatesWithIdsAPI(
        NotificationState.closed,
        ids
      );
      if (res.success) {
        dispatch(closeNotificationsSuccess());
        dispatch(getNotifications());
      } else {
        dispatch(closeNotificationsFail(res.message));
      }
    } catch (error) {
      dispatch(closeNotificationsFail(error.toString()));
    }
    dispatch(setLoading(false));
  };
};

export const updateNotificationState = (state: string, id: string) => {
  return async (dispatch: any) => {
    try {
      const res = await updateNotificationStateAPI(state, id);
      if (res.success) {
        dispatch(updateNotificationStateSuccess(res.data));
      } else {
        dispatch(updateNotificationStateFail(res.message));
      }
    } catch (error) {
      dispatch(updateNotificationStateFail(error.toString()));
    }
  };
};
