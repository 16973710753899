import { SET_CHANNEL_MEMBER_MODAL } from "../../types";
import { IRoomUserMEOS } from "../../types/app_chat";
import { IAppChatState, IChannel, IChatState, IDirectMessage } from "../../types/chat";
import { CANCEL_IS_EDIT_MESSAGE_MEOS, CREATE_CHANNEL_MEOS, CREATE_CHANNEL_MEOS_ERROR, CREATE_CHANNEL_MEOS_LOADING, DELETE_MESSAGE_MEOS, GET_AUTOCOMPLETE_USERS_LIST_ERROR_MEOS, GET_AUTOCOMPLETE_USERS_LIST_MEOS, GET_CHANNEL_INFO_ERROR_MEOS, GET_CHANNEL_INFO_MEOS, GET_JOINED_CHANNELS_LIST_ERROR_MEOS, GET_JOINED_CHANNELS_LIST_MEOS, GET_MESSAGES_ERROR_MEOS, GET_MESSAGES_MEOS, GET_ROOM_DATA_MEOS, GET_CHAT_USERS_INFO_ERROR_MEOS, GET_CHAT_USERS_INFO_MEOS, SEND_MESSAGE_MEOS, SET_ENTERED_TEXT_MEOS, SET_IS_EDIT_MESSAGE_MEOS, SET_MESSAGE_ID_MEOS, UPDATE_MESSAGE_MEOS, UPLOAD_FILE_TO_ROOM_MEOS, GET_USER_PROFILE_INFO_MEOS, GET_USER_PROFILE_INFO_ERROR_MEOS, UPDATE_MESSAGE_LIST_SO_MEOS, CHAT_MEMBERS, GET_CHANNEL_MEMBER_INFO_ERROR, GET_PRIVATE_CHAT_INFO_MEOS, GET_PRIVATE_CHAT_INFO_ERROR_MEOS, GET_ACTIVE_MESSAGE, CHANNEL_MEMBERS, SEND_DIRECT_MESSAGE_MEOS, GET_LATEST_CHAT_USER, SET_ACTIVE_MESSAGE, SET_EDIT_MESSAGE, DELETE_DIRECT_MESSAGE_MEOS, LAST_CHAT_ROOM, ACTIVE_CHAT_ROOM } from "../actions/app_chat";

const initialState: IAppChatState = {
  directMessagesList: [],
  editMessageId: "",
  latestChatUser: [],
  lastChatRoom: null,
  channelMembers: [],
  activeMessages: [],
  messages: [],
  channels: [],
  autoCompleteUsersList: [],
  chatUserInfo: null,
  userProfileInfo: null,
  chatChannelInfo: null,
  error: "",
  loading: false,
  isEditMessage: false,
  enteredText: "",
  messageId: "",
  members: [],
  memberModal:false,
  activeChannelId: null,
};

const chatReducer = (state = initialState, action): IAppChatState => {
  switch (action.type) {
    case SET_CHANNEL_MEMBER_MODAL:
      return {
        ...state,
        memberModal: action.payload
      };
    case SET_EDIT_MESSAGE:
      return {
        ...state,
        editMessageId: action.payload
      };
    case ACTIVE_CHAT_ROOM:
      return {
          ...state,
          activeChannelId: action.payload
        };
    case LAST_CHAT_ROOM:
      return {
        ...state,
        error: "",
        lastChatRoom: action.payload
      };

    case GET_PRIVATE_CHAT_INFO_MEOS:
      return {
        ...state,
        error: "",
        directMessagesList: action.payload,
        loading: false
      };
    case SEND_DIRECT_MESSAGE_MEOS:
      return {
        ...state,
        error: "",
        directMessagesList: [...state.directMessagesList, action.payload]
      };
    case GET_LATEST_CHAT_USER:
      return {
        ...state,
        error: "",
        latestChatUser: action.payload
      };


    case CHANNEL_MEMBERS:
      return {
        ...state,
        error: "",
        channelMembers: action.payload,
        loading: false
      };

    case GET_ACTIVE_MESSAGE:
      return {
        ...state,
        error: "",
        activeMessages: action.payload
      };
    case SET_ACTIVE_MESSAGE:
      return {
        ...state,
        error: "",
        activeMessages: action.payload
      };
    case GET_PRIVATE_CHAT_INFO_ERROR_MEOS:
      return {
        ...state,
        error: action.payload,
      };
    case GET_MESSAGES_MEOS:
      return {
        ...state,
        error: "",
        messages: action.payload,
      };
    case GET_MESSAGES_ERROR_MEOS:
      return {
        ...state,
        error: action.payload,
      };
    case SEND_MESSAGE_MEOS:
      return {
        ...state,
        error: "",
        messages: [...state.messages, action.payload],
      };
    case UPDATE_MESSAGE_MEOS:
      return {
        ...state,
        messages: state.messages.map((message) =>
          message._id === action.payload._id ? action.payload : message
        ),
        isEditMessage: false,
        enteredText: "",
        messageId: ""
      };
    case DELETE_MESSAGE_MEOS:
      return {
        ...state,
        messages: state.messages.filter((el) => el._id !== action.payload._id)
      };
    case DELETE_DIRECT_MESSAGE_MEOS:
      return {
        ...state,
        directMessagesList: state.directMessagesList.filter((el) => el._id !== action.payload._id)
      };
    case GET_JOINED_CHANNELS_LIST_MEOS:
      return {
        ...state,
        channels: action.payload as IRoomUserMEOS[],
      };
    case GET_JOINED_CHANNELS_LIST_ERROR_MEOS:
      return {
        ...state,
        error: action.payload,
      };
    case CREATE_CHANNEL_MEOS_LOADING:
      return {
        ...state,
        loading: true,
        error: ""
      };
    case CREATE_CHANNEL_MEOS:
      return {
        ...state,
        channels: [...state.channels, ...action.payload.data],
        loading: false
      };
    case CREATE_CHANNEL_MEOS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_AUTOCOMPLETE_USERS_LIST_MEOS:
      return {
        ...state,
        autoCompleteUsersList: action.payload,
      };
    case GET_AUTOCOMPLETE_USERS_LIST_ERROR_MEOS:
      return {
        ...state,
        error: action.payload,
      };
    case GET_CHAT_USERS_INFO_MEOS:
      return {
        ...state,
        chatUserInfo: action.payload,
      };
    case GET_CHAT_USERS_INFO_ERROR_MEOS:
      return {
        ...state,
        error: action.payload,
      };
    case GET_USER_PROFILE_INFO_MEOS:
      return {
        ...state,
        userProfileInfo: action.payload,
      };
    case GET_USER_PROFILE_INFO_ERROR_MEOS:
      return {
        ...state,
        error: action.payload,
      };
    case GET_CHANNEL_INFO_MEOS:
      return {
        ...state,
        error: "",
        chatChannelInfo: action.payload,
      };
    case GET_CHANNEL_INFO_ERROR_MEOS:
      return {
        ...state,
        error: action.payload,
      };
    case UPLOAD_FILE_TO_ROOM_MEOS:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case SET_IS_EDIT_MESSAGE_MEOS:
      return {
        ...state,
        isEditMessage: action.payload,
      };
    case CANCEL_IS_EDIT_MESSAGE_MEOS:
      return {
        ...state,
        isEditMessage: action.payload,
        enteredText: "",
        messageId: ""
      };
    case SET_ENTERED_TEXT_MEOS:
      return {
        ...state,
        enteredText: action.payload,
      };
    case SET_MESSAGE_ID_MEOS:
      return {
        ...state,
        messageId: action.payload,
      };
    case UPDATE_MESSAGE_LIST_SO_MEOS: {
      return {
        ...state,
        messages: [...state.messages, action.payload]
      };
    };
    case CHAT_MEMBERS: {
      return {
        ...state,
        members: [...action.payload]
      };
    }
    default:
      return state;
  }
};

export default chatReducer
