import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  root: {
    fontFamily: 'Nunito Sans',
  },
  card: {
    fontFamily: 'Nunito Sans',
    backgroundColor: '#FFFFFF',
    padding: theme.spacing(2),
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.05)',
    borderRadius: '8px',
    marginBottom: theme.spacing(2),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  avatar: {
    width: '45px',
    height: '45px',
    marginRight: theme.spacing(1),
  },
  title: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold',
    fontSize: '16px',
    width: "100%"
  },
  content: {
    color: '#6B7280',
    fontSize: '14px',
  },
  moreInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    color: '#A0AEC0',
  },
  typography: {
    fontFamily: 'Nunito Sans',
    fontSize: '18px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    gap: "10px",
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: '10px'
  },
  upcoming: {
    color: '#00FF00',
  },
  overdue: {
    color: "#1E1E1E",
    fontWeight: "bold",
    fontSize: "16px",
    display: 'flex',
    alignItems: 'center',
    fontFamily: "Nunito Sans",
    marginLeft: "5px"
  },
  dueText: {
    color: "#B3261E",
  },
  checkInbutton: {
    color: "#D35400",
    fontSize: "14px",
    cursor: "pointer"
  },
  flex: {
    display: "flex"
  },
  gap_10: {
    gap: "10px"
  },
  itemsCenter: {
    alignItems: "center"
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  moreButton: {
    marginLeft: theme.spacing(2),
  },
  actionCard: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    fontFamily: "Nunito Sans",
    // marginBottom: "10px",
    marginTop: "10px",
    boxShadow: "none"
  },
  actionCardHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  scheduleCard: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    fontSize: "14px",
    marginBottom: "10px",
    boxShadow: "none"
  },
  caption: {
    color: '#757575',
    fontSize: '14px',
    textAlign: 'center',
    fontWeight: 600,
    marginBottom: 0,
  },
  moreInfoButton: {
    color: '#202A44',
    fontWeight: 500,
    textDecoration: 'underline',
    background: 'transparent !important',
    textTransform: 'capitalize'
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    fontSize: '0.875rem',
    color: theme.palette.text.secondary,
  },
  videoContainer:{
    width: '100%',
    height: 350,
    border: "1px solid #ccc",
    borderRadius: 15,
    boxShadow:'0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
  }
}));
