import { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setSelectedDateRange } from '../redux/actions/dashboardAction';

const useDateRange = (defaultRange) => {
    const dispatch = useDispatch();
    const [dateRange, setDateRange] = useState<any>();
    const [selectedRange, setSelectedRange] = useState(defaultRange);
    const [customStartDate, setCustomStartDate] = useState(moment().subtract(4, 'weeks').format('YYYY-MM-DD'));
    const [customEndDate, setCustomEndDate] = useState(moment().format('YYYY-MM-DD'));

    useEffect(() => {
        updateDateRange(defaultRange);
    }, [defaultRange]);

    const updateDateRange = (range) => {
        let newDateRange = {};
        switch (range) {
            case 'last-4-weeks':
                newDateRange = {
                    start: moment().subtract(4, 'weeks').startOf('week').format('YYYY-MM-DD'),
                    end: moment().subtract(0, 'week').endOf('week').format('YYYY-MM-DD'),
                };
            break;
            case 'last-8-weeks':
                newDateRange = {
                    start: moment().subtract(8, 'weeks').startOf('week').format('YYYY-MM-DD'),
                    end: moment().subtract(0, 'week').endOf('week').format('YYYY-MM-DD'),
                };
                break;
            case 'this-quarter':
                newDateRange = {
                    start: moment().startOf('quarter').format('YYYY-MM-DD'),
                    end: moment().endOf('quarter').format('YYYY-MM-DD'),
                };
                break;
            case 'last-quarter':
                newDateRange = {
                    start: moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'),
                    end: moment().subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD'),
                };
                break;
            case 'custom':
                newDateRange = {
                    start: customStartDate,
                    end: customEndDate,
                };
                break;
            default:
                break;
        }

        setDateRange(newDateRange);
    };

    const handleRangeChange = (event) => {
        const range = event.target.value;
        dispatch(setSelectedDateRange(range));
        setSelectedRange(range);
        updateDateRange(range);
    };

    const handleCustomStartDateChange = (date) => {
        if (date) {
            const formattedDate = moment(date).format('YYYY-MM-DD');
            setCustomStartDate(formattedDate);
            if (selectedRange === 'custom') {
                setDateRange((prevRange) => ({ ...prevRange, start: formattedDate }));
            }
        }
    };

    const handleCustomEndDateChange = (date) => {
        if (date) {
            const formattedDate = moment(date).format('YYYY-MM-DD');
            setCustomEndDate(formattedDate);
            if (selectedRange === 'custom') {
                setDateRange((prevRange) => ({ ...prevRange, end: formattedDate }));
            }
        }
    };

    return {
        dateRange,
        setDateRange,
        selectedRange,
        customStartDate,
        customEndDate,
        handleRangeChange,
        handleCustomStartDateChange,
        handleCustomEndDateChange,
    };
};

export default useDateRange;
