import {
  getTeamApi,
  getTeamUsersApi,
  getUserStateApi,
  getUserPolicyApi,
  saveUserApi, createUserApi,
  changeUserPasswordApi,
  getAllTeamsApi,
  archieveUserApi,
  saveTeamsApi
} from '../../api';
import { ITeam } from '../../types/team';
import { IUser, IUserState } from '../../types/user';
import { logger, format } from '../../datadogLogger';
import toastr from 'toastr';

export const GET_TEAM = 'GET_TEAM';
export const GET_TEAM_ERROR = 'GET_TEAM_ERROR';
export const GET_TEAM_LOADER = 'GET_TEAM_LOADING';
export const TEAM_USER_SAVE = 'TEAM_USER_SAVE';
export const TEAM_USER_SUCCESS = 'TEAM_USER_SUCCESS';
export const TEAM_USER_ERROR = 'TEAM_USER_ERROR';
export const TEAM_USERS_STATE = 'TEAM_USERS_STATE';
export const PENDING_TEAM_STATUS = 'PENDING_TEAM_STATUS';
export const TEAM_USERS = 'TEAM_USERS';
export const GET_TEAM_USER_LOADER = 'GET_TEAM_USER_LOADER';
export const GET_USER_POLICY_LOADING = 'GET_USER_POLICY_LOADING';
export const USER_POLICY = 'USER_POLICY';
export const USER_STATUS_ERROR = 'USER_STATUS_ERROR';
export const TEAM_USER_CREATE = 'TEAM_USER_CREATE';
export const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD';
export const CHANGE_USER_PASSWORD_ERROR = 'CHANGE_USER_PASSWORD_ERROR';
export const CHANGE_USER_PASSWORD_LOADER = 'CHANGE_USER_PASSWORD_LOADER';
export const GET_ALL_TEAMS = 'GET_ALL_TEAMS';
export const GET_ALL_TEAMS_ERROR = 'GET_ALL_TEAMS_ERROR';
export const GET_ALL_TEAMS_LOADER = 'GET_ALL_TEAMS_LOADER';
export const ARCHIVE_USER = 'ARCHIVE_USER';
export const ARCHIVE_USER_ERROR = 'ARCHIVE_USER_ERROR';
export const ARCHIVE_USER_LOADER = 'ARCHIVE_USER_LOADER';


export const getTeam = (userId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(getTeamLoader(true));
      const res: any = await getTeamApi(userId);
      if (res.success) {
        dispatch(getTeamSuccess(res?.data));
      } else {
        dispatch(getTeamError(res));
      }
      dispatch(getTeamLoader(false));
      logger.info(format(`Get a Team info for the user=${userId}`));
    } catch (error) {
      logger.error(
        format(`Failed to get team for the user=${userId}`),
        error
      );
      dispatch(getTeamError(error));
      dispatch(getTeamLoader(false));
    }
  };
};

export const getAllTeams = () => {
  return async (dispatch: any) => {
    try {
      dispatch(getAllTeamsLoader(true));
      const res = await getAllTeamsApi();
      if (res.success) {
        dispatch(getAllTeamsSuccess(res.data));
      } else {
        dispatch(getAllTeamsError(res));
      }
      dispatch(getTeamLoader(false));
      logger.info(format(`Get a Teams`));
    } catch (error) {
      logger.error(
        format(`Failed to get teams`),
        error
      );
      dispatch(getAllTeamsError(error));
      dispatch(getAllTeamsLoader(false));
    }
  };
};

const getAllTeamsLoader = (isLoading: boolean) => ({
  type: GET_ALL_TEAMS_LOADER,
  payload: isLoading,
});

const getAllTeamsSuccess = (payload: ITeam[]) => ({
  type: GET_ALL_TEAMS,
  payload,
});

const getAllTeamsError = (error: any) => ({
  type: GET_ALL_TEAMS_ERROR,
  payload: error,
});

const getTeamLoader = (isLoading: boolean) => ({
  type: GET_TEAM_LOADER,
  payload: isLoading,
});

const getTeamSuccess = (payload: ITeam) => ({
  type: GET_TEAM,
  payload,
});

const getTeamError = (error: any) => ({
  type: GET_TEAM_ERROR,
  payload: error,
});

// Users list in Team

export const getTeamUsers = (teamId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(pendingTeamState(true));
      const res = await getTeamUsersApi(teamId);

      if (res.success) {
        dispatch({
          type: TEAM_USERS,
          payload: res.data,
        });
        // dispatch(getUsersState(res.data));
        dispatch(pendingTeamState(false));
      } else {
        dispatch(teamUserError(res));
        dispatch(pendingTeamState(false));
      }
    } catch (error) {
      logger.error(format(`Failed to get users for team=${teamId}`), error);
      dispatch(teamUserError(error));
      dispatch(pendingTeamState(false));
    }
  };
};

const teamUserError = (error: any) => ({
  type: TEAM_USER_ERROR,
  payload: error,
});

// get team users details
// export const getUserDetails = userId => {
//   return async dispatch => {
//     try {
//       dispatch(userDetailLoader(true));
//       const res = await getUerDetailApi(userId);
//       console.log("res in userDetail", res);
//     } catch (error) {
//       console.log("error in get user detail", error);
//     }
//   };
// };

// const userDetailLoader = (isLoading)=>({
//   type:
//   payload: isLoading
// })

export const saveTeamUser = (user: IUser) => {
  return async (dispatch: any) => {
    try {
      const res = await saveUserApi(user);

      if (res.success) {
        dispatch({ type: TEAM_USER_SAVE, payload: res.data });
        localStorage.setItem("authInfo", JSON.stringify(res?.data));
        toastr.success("Profile updated successfully");
      } else {
        dispatch({ type: TEAM_USER_ERROR, payload: res });
        toastr.success("Unable to update profile");
      }
      dispatch(getUserPolicyLoading(false));
    } catch (error) {
      logger.error(
        format(`Failed to save user for team=${user._team}`),
        error
      );
      dispatch({ type: TEAM_USER_ERROR, payload: error });

      dispatch(getUserPolicyLoading(false));
    }
  };
};

export const createTeamUser = (user: IUser) => {
  return async (dispatch: any) => {
    try {
      const res = await createUserApi(user);

      if (res.success) {
        dispatch({ type: TEAM_USER_CREATE, payload: res.data });
      } else {
        dispatch({ type: TEAM_USER_ERROR, payload: res });
      }
      dispatch(getUserPolicyLoading(false));
    } catch (error) {
      logger.error(format(`Failed to save user for team=${user._team}`), error)
      dispatch({ type: TEAM_USER_ERROR, payload: error });

      dispatch(getUserPolicyLoading(false));
    }
  };
};

export const getUsersState = (users: IUser[]) => {
  return async (dispatch: any) => {
    try {
      dispatch(getTeamUserLoader(true));
      const res = await getUserStateApi(users);
      const dataToStore: IUserState[] = res.map((item, index) => {
        const userId = users[index]._id;

        return {
          userId,
          userState: item.data,
        };
      });
      dispatch({
        type: TEAM_USERS_STATE,
        payload: dataToStore,
      });

      dispatch(pendingTeamState(false));
    } catch (error) {
      logger.error(format(`Failed to get users state`), error);
      dispatch(getTeamUserLoader(false));
      dispatch(pendingTeamState(false));
      dispatch(errorInUserState(error));
    }
  };
};

export const changeUserPassword = (userId: string, payload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(ChangePasswordLoader(true));

      const res = await changeUserPasswordApi(userId, payload);

      if (res.success) {
        dispatch({ type: CHANGE_USER_PASSWORD, payload: res.data });
        toastr.success("Password updated successfully");
      } else {
        dispatch({ type: CHANGE_USER_PASSWORD_ERROR, payload: res });
        toastr.error(res.message || "Unable to update password");
      }

      dispatch(ChangePasswordLoader(false));
    } catch (error) {
      logger.error(`Failed to change password for user=${userId}`, error);
      dispatch(errorInChangePasswordState(error));
      dispatch(ChangePasswordLoader(false));
      toastr.error(error?.response?.data?.message || "An error occurred while updating the password.");
    }
  };
};

const ChangePasswordLoader = (isLoading: boolean) => ({
  type: CHANGE_USER_PASSWORD_LOADER,
  payload: isLoading,
});

export const errorInChangePasswordState = (error: any) => ({
  type: CHANGE_USER_PASSWORD_ERROR,
  payload: error,
});



const getTeamUserLoader = (isLoading: boolean) => ({
  type: GET_TEAM_USER_LOADER,
  payload: isLoading,
});

const pendingTeamState = (isLoading: boolean) => {
  return {
    type: PENDING_TEAM_STATUS,
    payload: isLoading,
  };
};

export const errorInUserState = (error: any) => ({
  type: USER_STATUS_ERROR,
  payload: error,
});

export const getUserPolicy = (userId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(getUserPolicyLoading(true));
      const res = await getUserPolicyApi(userId);

      dispatch({
        type: USER_POLICY,
        payload: res.data,
      });

      // console.log("my user policy is here", res.data);

      dispatch(getUserPolicyLoading(false));
    } catch (error) {
      logger.error(format('Failed to get user policy'), error);
      dispatch(getUserPolicyLoading(false));
    }
  };
};


const getUserPolicyLoading = (isLoading: boolean) => ({
  type: GET_USER_POLICY_LOADING,
  payload: isLoading,
});

export const archiveUser = (userId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(archiveUserLoader(true));
      const res = await archieveUserApi(userId);

      if (res.success) {
        dispatch({ type: ARCHIVE_USER, payload: userId });
        toastr.success("User archived successfully");
      } else {
        dispatch({ type: ARCHIVE_USER_ERROR, payload: res });
        toastr.error("Failed to archive user");
      }

      dispatch(archiveUserLoader(false))
    } catch (error) {
      logger.error(format(`Failed to archive user=${userId}`), error);
      dispatch({ type: ARCHIVE_USER_ERROR, payload: error });
      dispatch(archiveUserLoader(false));
    }
  };
};

const archiveUserLoader = (isLoading: boolean) => ({
  type: ARCHIVE_USER_LOADER,
  payload: isLoading,
});


