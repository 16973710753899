import { combineReducers } from 'redux';
import drawerReducer from './drawerReducer';
import auth from './auth';
import teamReducer from './teamReducer';
import devicesReducer from './devicesReducer';
import childReducer from './childReducer';
import policyReducer from './policyReducer';
import defaultDownTimeReducer from './defaultDownTimeReducer';
import navigationHistoryReducer from './navigationHistoryReducer';
import notificationReducer from './notification';
import policyRequests from './policyRequestReduer';
import chatReducer from './chatReducer';
import programFormReducer from './programFormReducer';
import programReducer from './programReducer';
import dashboardReducer from './dashboardReducer';
import myResultReducer from "./myResultReducer";
import programEnrollmentReducer from './programEnrollmentReducer';

export default combineReducers({
  drawerReducer,
  auth,
  teamReducer,
  devicesReducer,
  childReducer,
  policyReducer,
  defaultDownTimeReducer,
  navigationHistoryReducer,
  notificationReducer,
  policyRequests,
  chatReducer,
  programReducer,
  programFormReducer,
  programEnrollmentReducer,
  dashboardReducer,
  myResultReducer
});
