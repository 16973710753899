import React from 'react'

const UsersIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.1" d="M12.5 9.5C12.5 11.1569 11.1569 12.5 9.5 12.5C7.84314 12.5 6.5 11.1569 6.5 9.5C6.5 7.84314 7.84314 6.5 9.5 6.5C11.1569 6.5 12.5 7.84314 12.5 9.5Z" stroke="white" />
            <path d="M15.6309 7.15517C15.9015 7.05482 16.1943 7 16.4999 7C17.8806 7 18.9999 8.11929 18.9999 9.5C18.9999 10.8807 17.8806 12 16.4999 12C16.1943 12 15.9015 11.9452 15.6309 11.8448" stroke="white" stroke-linecap="round" />
            <path d="M3 19C3.69137 16.6928 5.46998 16 9.5 16C13.53 16 15.3086 16.6928 16 19" stroke="white" stroke-linecap="round" />
            <path d="M17 15C19.403 15.095 20.5292 15.6383 21 17" stroke="white" stroke-linecap="round" />
            <path d="M13 9.5C13 11.433 11.433 13 9.5 13C7.567 13 6 11.433 6 9.5C6 7.567 7.567 6 9.5 6C11.433 6 13 7.567 13 9.5Z" stroke="white" />
        </svg>
    )
}

export default UsersIcon