import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Grid, Button, Card, CardActions, CardContent, CardHeader, Divider, TextField, Typography } from '@material-ui/core';
import { saveTeamUser } from '../../../../../redux/actions/teamAction';
import { ErrorMessage } from '@hookform/error-message';
import { RootState } from '../../../../../redux/store';

export default function AccountDetailsForm() {
    const dispatch = useDispatch();
    const user: any = useSelector((state: RootState) => state.auth.user)

    const { control, handleSubmit, errors } = useForm();

    const onSubmit = (data: any) => {
        console.log("data", data)
        const updatedUser = {
            ...user,
            name: data.name,
            lastName: data.lastName,
            email: data.email
        };

        dispatch(saveTeamUser(updatedUser));
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
                <CardHeader subheader="The information can be edited" title="Profile Details" />
                <Divider />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <Controller
                                variant="outlined"
                                name="name"
                                control={control}
                                rules={{
                                    required: 'First Name is required.',
                                }}
                                defaultValue={user.name || ''}
                                as={
                                    <TextField
                                        label="First Name"
                                        variant="outlined"
                                        fullWidth
                                    />
                                }
                            />
                            <ErrorMessage
                                as={<Typography color="error" />}
                                errors={errors}
                                name="name"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Controller
                                variant="outlined"
                                name="lastName"
                                control={control}
                                rules={{
                                    required: 'Last Name is required.',
                                }}
                                defaultValue={user.lastName || ''}
                                as={
                                    <TextField
                                        label="Last Name"
                                        variant="outlined"
                                        fullWidth
                                    />
                                }
                            />
                            <ErrorMessage
                                as={<Typography color="error" />}
                                errors={errors}
                                name="lastName"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Controller
                                variant="outlined"
                                name="email"
                                control={control}
                                defaultValue={user.email || ''}
                                rules={{
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                                        message: 'Enter a valid email address'
                                    }
                                }}
                                as={
                                    <TextField
                                        label="Email Address"
                                        variant="outlined"
                                        fullWidth
                                    />
                                }
                            />
                            <ErrorMessage
                                as={<Typography color="error" />}
                                errors={errors}
                                name="email"
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <CardActions style={{ justifyContent: 'flex-end' }}>
                    <Button variant="contained" color="primary" type="submit">
                        Save Changes
                    </Button>
                </CardActions>
            </Card>
        </form>
    );
}
