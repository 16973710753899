import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3)
    },
    textField: {
        width: '100%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#717171',
                borderRadius: 8,
                fontSize: 16,
            },
            '& input': {
                padding: '10.5px',
                fontSize: 16,
                color: '#717171'
            },
        },
    },
    button: {
        width: '100%',
        height: '100%',
        background: '#1F2937',
        color: 'white',
        fontWeight: 700,
        textTransform: "capitalize",
        borderRadius: 8
    },
    searchIcon: {
        color: '#717171'
    },
    pagination: {
        '& .MuiPagination-ul': {
            '& li:first-child ': {
                '& button': {
                    backgroundColor: '#1F2937',
                    color: "white"
                }
            },
            '& li:last-child ': {
                '& button': {
                    backgroundColor: '#1F2937',
                    color: "white"
                }
            }
        }
    },
    tableContainer: {
        '& .MuiTable-root': {
            '& .MuiTableCell-head': {
                fontWeight: 700
            },
            '& .MuiTableCell-root:last-child': {
                textAlign: 'right'
            },
            '& .MuiTableCell-root.MuiTableCell-alignCenter:last-child': {
                textAlign: 'center'
            }
        }
    },
    tableHeading: {
        marginBottom: theme.spacing(3)
    },
    tabsDiv: {
        '& .MuiTabs-fixed': {
            marginBottom: 20,
            ' & .MuiButtonBase-root': {
                textTransform: 'capitalize',
                color: '#1E1E1E',
                fontSize: 16
            },
            '& .MuiTab-textColorPrimary.Mui-selected': {
                color: '#202A44',
                fontWeight: 700
            },
            '& .PrivateTabIndicator-colorPrimary-35': {
                backgroundColor: '#7CA0BB !important'
            }

        }
    },
    countCardContentDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '16px !important',
        '& .MuiTypography-body1 ': {
            marginBottom: 0
        },
        '& .MuiTypography-body1': {
            color: '#27272E',
            fontWeight: 700,
            fontSize: 16
        },
        '& .MuiTypography-h6': {
            color: '#7CA0BB',
            fontWeight: 700,
            fontSize: 16
        }
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    saveButton: {
        background: '#1F2937',
        color: 'white',
        fontWeight: 700,
        textTransform: "capitalize",
        borderRadius: 8,
        width: '100%',
        padding: '10px 0',
        '& .MuiButton-root.Mui-disabled': {
            color: 'white !important'
        }
    },
    prevButton: {
        background: 'transparent',
        color: '#1F2937',
        border: '1px solid #1F2937',
        fontWeight: 700,
        textTransform: "capitalize",
        borderRadius: 8,
        width: '100%',
        padding: '10px 0'
    },
    drawer: {
        '& .MuiDrawer-paper': {
            width: 400
        }
    },
    stepper: {
        '& .MuiStepIcon-root': {
            color: '#D9D9D9'
        },
        '& .MuiStepIcon-root.MuiStepIcon-completed': {
            color: '#1F2937'
        }
    },
    formRow: {
        paddingLeft: theme.spacing(4),
    },
    phaseCard: {
        marginBottom: 20
    },
    addFormBtn: {
        background: 'transparent',
        border: '1px solid #1F2937',
        color: '#1F2937',
        fontWeight: 700,
        textTransform: "capitalize",
        borderRadius: 8,
        padding: '0 15px'
    },
}));