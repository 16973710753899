import React from 'react';
import { AddressFieldsProps, FormGroupProps, InputFieldProps, SelectFieldProps, TextAreaFieldProps } from './type';
import { TextField, FormControl, FormControlLabel, Radio, RadioGroup, FormLabel, Button, Input, Slider } from '@material-ui/core';
import { useStyles } from '../../style';

const FormGroup: React.FC<FormGroupProps> = ({ key, label, children, classes = {}, error }: any) => (
    <div className={classes.formGroup || 'form-group'} key={key}>
        {label && <label className={classes.label} htmlFor={key}>{label}</label>}
        {children}
        {error && <span style={{ color: 'red' }}>{error}</span>}
    </div>
);

const FormGroupInner: React.FC<FormGroupProps> = ({ key, label, children, classes = {} }: any) => (
    <div className={classes.formGroupInner || 'form-group'} key={key}>
        {label && <label className={classes.label} htmlFor={key}>{label}</label>}
        {children}
    </div>
);

const InputField: React.FC<InputFieldProps> = ({ type, id, name, className, value, onChange, required, placeholder, readOnly, accept, min, max }) => (
    <input
        type={type}
        id={id}
        name={name}
        className={className}
        value={value}
        onChange={onChange}
        required={required}
        placeholder={placeholder}
        readOnly={readOnly}
        accept={accept}
        min={min}
        max={max}
    />
);

const TextAreaField: React.FC<TextAreaFieldProps> = ({ type, id, name, className, value, onChange, required, placeholder, rows, cols }) => (
    <textarea
        id={id}
        name={name}
        className={className}
        value={value}
        onChange={onChange}
        required={required}
        placeholder={placeholder}
        rows={rows}
        cols={cols}
    />
);

const SelectField: React.FC<SelectFieldProps> = ({
    name,
    value = '',
    options,
    onChange,
    className,
    required = false,
    id,
    placeholder,
}) => {
    return (
        <select
            name={name}
            value={value}
            onChange={onChange}
            className={className}
            required={required}
            id={id}
        >
            {placeholder && (
                <option value="" disabled>
                    {placeholder}
                </option>
            )}
            {options.map((option, index) => (
                <option key={index} value={option.value}>
                    {option.text}
                </option>
            ))}
        </select>
    );
};

const AddressFields: React.FC<AddressFieldsProps> = ({ commonProps, key, value, required }) => {
    const addressArr = ['street', 'city', 'state', 'zip', 'country']
    return (
        <>
            {
                addressArr.map((el, index) => (
                    <InputField
                        key={index}
                        type='text'
                        {...commonProps}
                        id={`${key}-${el}`}
                        name={`${key}[${el}]`}
                        value={value[el] || ''}
                        required={required}
                        placeholder={el}
                    />
                ))
            }

        </>
    )
};

const FormControlComponent = ({ question, formData, handleChange, setFormData, error }) => {
    const { type, key, name, text, settings, required, readOnly, options, mcolumns, mrows, dcolumns, columns, rows, value } = question;
    const classes = useStyles();

    const commonProps = {
        id: key,
        name,
        className: classes.textField,
        value: formData[name] || '',
        onChange: handleChange,
        required: required === 'Yes' ? true : false,
        readOnly: readOnly === 'Yes' ? true : false,
    };

    // if (question.hidden === "Yes") {
    //     return null;
    // }

    switch (type) {
        case 'control_textbox':
            return (
                <FormGroup error={error} classes={classes} key={key} label={text}>
                    <InputField
                        type='text'
                        {...commonProps}
                    />
                </FormGroup>
            );
        case 'control_textarea':
            return (
                <FormGroup error={error} classes={classes} key={key} label={text}>
                    <TextAreaField
                        {...commonProps}
                        cols={settings?.cols || 50}
                        rows={settings?.rows || 2}
                    />
                    {question.subLabel && <p className={classes.label}>{question.subLabel}</p>}
                </FormGroup>
            );
        case 'control_button':
            return (
                <div className="form-group">
                    <button type="submit" className="btn btn-primary">
                        {text || 'Submit'}
                    </button>
                </div>
            );
        case 'control_head':
            return (
                <div className="form-group">
                    <h2 className={classes.controlHeading1}>{text || ''}</h2>
                    <h4>{question.subHeader || ''}</h4>
                    {settings?.subHeader && <p>{settings.subHeader}</p>}
                </div>
            );
        case 'control_radio':
            const radioOptions = options ? options.split('|') : [];
            return (
                <FormGroup error={error} classes={classes} key={key} label={text}>
                    {radioOptions.map((option, index) => (
                        <label key={index}>
                            <input
                                type="radio"
                                name={name}
                                value={option}
                                checked={formData[name] === option}
                                onChange={handleChange}
                            />
                            {option}
                        </label>
                    ))}
                </FormGroup>
            );
        case 'control_checkbox':
            const checkboxOptions = options || [];
            return (
                <FormGroup error={error} classes={classes} label={text} key={key}>
                    {checkboxOptions.map((option, index) => (
                        <label key={index}>
                            <input
                                type="checkbox"
                                name={name}
                                value={option}
                                checked={formData[name]?.includes(option) || false}
                                onChange={handleChange}
                            />
                            {option}
                        </label>
                    ))}
                </FormGroup>
            );
        case 'control_dropdown':
            return (
                <FormGroup error={error} classes={classes} key={key} label={text}>
                    <select {...commonProps}>
                        {options.map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                        ))}
                    </select>
                </FormGroup>
            );
        case 'control_number':
            return (
                <FormGroup error={error} classes={classes} key={key} label={text}>
                    <InputField
                        type="number"
                        {...commonProps}
                        min={0}
                    />
                </FormGroup>
            );
        case 'control_datetime':
            return (
                <FormGroup error={error} classes={classes} key={key} label={text}>
                    <InputField
                        type="datetime-local"
                        {...commonProps}
                    />
                </FormGroup>
            );
        case 'control_fileupload':
            return (
                <FormGroup error={error} key={key} label={text}>
                    <InputField
                        type="file"
                        {...commonProps}
                    />
                </FormGroup>
            );
        case 'control_rating':
            return (
                <div className="form-group">
                    {text && <label>{text}</label>}
                    <div>
                        {Array.from({ length: 5 }, (_, index) => (
                            <span key={index} className={index < value ? 'star filled' : 'star'}>
                                ★
                            </span>
                        ))}
                    </div>
                </div>
            );
        case 'control_scale':
            const scaleRange = Array.from(
                { length: Number(question.scaleAmount) - Number(question.scaleFrom) + 1 },
                (_, i) => i + Number(question.scaleFrom)
            );

            return (
                <FormGroup error={error} key={key} label={text}>
                    <div className="scale-container">
                        <div className="scale-buttons">
                            {scaleRange.map((value) => (
                                <label key={value} className={`scale-button ${Number(formData[name]) === Number(value) ? 'selected' : ''}`}>
                                    <input
                                        type="radio"
                                        {...commonProps}
                                        id={name}
                                        name={name}
                                        value={(value)}
                                        checked={Number(formData[name]) === Number(value)}
                                        onChange={handleChange}
                                        required={required !== 'Yes' ? true : false}
                                    />
                                    {value}
                                </label>
                            ))}
                        </div>
                        <div className="scale-labels">
                            <span className='scale-labels-text text-1'>{question.fromText}</span>
                            <span className='scale-labels-text text-2'>{question.toText}</span>
                        </div>

                    </div>
                </FormGroup>
            );

        case 'control_matrix':
            const matrixColumns = mcolumns ? mcolumns.split('|') : [];
            const matrixRows = mrows ? mrows.split('|') : [];
            const matrixColumnConfig = dcolumns ? JSON.parse(dcolumns) : [];

            const handleMatrixChange = (e, rowIndex, colIndex) => {
                const { name, value, type, checked } = e.target;

                setFormData(prevData => ({
                    ...prevData,
                    [question.name]: {
                        ...prevData[question.name],
                        [rowIndex]: {
                            ...prevData[question.name]?.[rowIndex],
                            [colIndex]: type === 'checkbox' ? checked : value
                        }
                    }
                }));

            };

            const handleMatrixRadioChange = (e, rowIndex, colIndex) => {
                const { name, value } = e.target;

                setFormData((prevData) => {
                    return {
                        ...prevData,
                        [question.name]: {
                            ...prevData[question.name],
                            [rowIndex]: value,
                        },
                    };
                });
            };


            return (
                <FormGroup error={error} key={key} label={text} classes={classes}>
                    <div className={classes.tableScoll}>
                        <table className="form-matrix">
                            <thead>
                                <tr>
                                    <th></th>
                                    {matrixColumns.map((col, index) => (
                                        <th key={index}>{col}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {matrixRows.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        <td>{row}</td>
                                        {matrixColumns.map((col, colIndex) => {
                                            const column = matrixColumnConfig[colIndex] || {};
                                            const inputType = question.inputType;
                                            const matrixValueInputValue = formData[name]?.[rowIndex]?.[colIndex];
                                            const matrixValue = (typeof matrixValueInputValue === 'object' && matrixValueInputValue !== null) 
                                                ? matrixValueInputValue.value 
                                                : matrixValueInputValue;

                                            switch (inputType) {
                                                case 'Radio Button':
                                                    const matrixRadioValue = formData[name]?.[rowIndex];
                                                    const matrixValueRadio = (typeof matrixRadioValue === 'object') 
                                                        ? matrixRadioValue?.value 
                                                        : matrixRadioValue;

                                                    return (
                                                        <td key={colIndex}>
                                                            <FormControlLabel
                                                                {...commonProps}
                                                                id={name}
                                                                className={classes.radioButton}
                                                                label={''}
                                                                name={`${name}[${rowIndex}]`}
                                                                value={col}
                                                                checked={matrixValueRadio === col}
                                                                onChange={(e) => handleMatrixRadioChange(e, rowIndex, colIndex)} control={<Radio />} />
                                                        </td>
                                                    );
                                                case 'Check Box':
                                                    return (
                                                        <td key={colIndex}>
                                                            <input
                                                                type="checkbox"
                                                                name={`${name}`}
                                                                checked={matrixValue || false}
                                                                onChange={(e) => handleMatrixChange(e, rowIndex, colIndex)}
                                                            />
                                                        </td>
                                                    );
                                                case 'Drop Down':
                                                    return (
                                                        <td key={colIndex}>
                                                            <select
                                                                className={classes.textField}
                                                                name={`${name}`}
                                                                value={matrixValue || ''}
                                                                onChange={(e) => handleMatrixChange(e, rowIndex, colIndex)}
                                                            >
                                                                {column.options.split('|').map((option, optionIndex) => (
                                                                    <option key={optionIndex} value={option}>{option}</option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                    );
                                                case 'Text Box':
                                                    return (
                                                        <td key={colIndex}>
                                                            <InputField
                                                                type='text'
                                                                {...commonProps}
                                                                name={`${name}`}
                                                                value={matrixValue || ''}
                                                                onChange={(e) => handleMatrixChange(e, rowIndex, colIndex)}
                                                            />
                                                        </td>
                                                    );
                                                case 'Dynamic':
                                                    return (
                                                        <td key={colIndex}>
                                                            <InputField
                                                                type='text'
                                                                {...commonProps}
                                                                name={`${name}`}
                                                                value={matrixValue || ''}
                                                                onChange={(e) => handleMatrixChange(e, rowIndex, colIndex)}
                                                            />
                                                        </td>
                                                    );
                                                default:
                                                    return <td key={colIndex}>Unknown Type</td>;
                                            }
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </FormGroup>
            );

        case 'control_slider':
            return (
                <div className="form-group" key={key}>
                    {question.text && <label htmlFor={key}>{question.text}</label>}
                    <input
                        type="range"
                        {...commonProps}
                        min={question?.min || '0'}
                        max={question?.max || '100'}
                        defaultValue={question.value || '50'}
                    />
                </div>
            );
        case 'control_spinner':
            return (
                <div className="form-group" key={key}>
                    {question.text && <label htmlFor={key}>{question.text}</label>}
                    <input
                        type="number"
                        {...commonProps}
                        min={question.settings?.min || 0}
                        max={question.settings?.max || 100}
                        defaultValue={question.value || '10'}
                    />
                </div>
            );
        case 'control_fullname':
            return (
                <FormGroup error={error} classes={classes} key={key} label={question.text}>
                    <InputField
                        {...commonProps}
                        type="text"
                    />
                </FormGroup>
            );
        case 'control_phone':
            return (
                <div className="form-group" key={key}>
                    {question.text && <label htmlFor={key}>{question.text}</label>}
                    <input
                        type="tel"
                        {...commonProps}
                        defaultValue={question.value || ''}
                    />
                </div>
            );
        case 'control_email':
            return (
                <div className="form-group" key={key}>
                    {question.text && <label htmlFor={key}>{question.text}</label>}
                    <input
                        type="email"
                        {...commonProps}
                        defaultValue={question.value || ''}
                    />
                </div>
            );
        case 'control_address':
            return (
                <FormGroup error={error} key={key} label={question.text}>
                    <AddressFields commonProps={commonProps} key={key} value={question.value} />
                </FormGroup>
            );
        case 'control_payment':
            return (
                <div className="form-group" key={key}>
                    {question.text && <label>{question.text}</label>}
                    <input
                        type="text"
                        name={`${key}[cardNumber]`}
                        placeholder="Card Number"
                        defaultValue={question.value?.cardNumber || ''}
                        required={question.required}
                    />
                    <input
                        type="text"
                        name={`${key}[expirationDate]`}
                        placeholder="Expiration Date"
                        defaultValue={question.value?.expirationDate || ''}
                        required={question.required}
                    />
                    <input
                        type="text"
                        name={`${key}[cvv]`}
                        placeholder="CVV"
                        defaultValue={question.value?.cvv || ''}
                        required={question.required}
                    />
                </div>
            );
        case 'control_signature':
            return (
                <FormGroup error={error} key={key} label={question.text} classes={classes}>
                    <InputField
                        {...commonProps}
                        type='file'
                        accept=".png, .jpg, .jpeg"
                    />
                </FormGroup>
            );
        case 'control_image':
            return (
                <div className="form-group" key={key}>
                    {
                        question.src === "" ?
                            (
                                <>
                                    <FormGroup error={error} classes={classes} key={key} label={question.text}>
                                        <InputField
                                            type='file'
                                            {...commonProps}
                                            accept=".jpg, .jpeg, .png"
                                        />
                                    </FormGroup>
                                </>
                            ) :
                            <img className={classes.image} width={question.width} height={question.height} src={question.src} alt={question.text} />
                    }

                </div>
            );
        case 'control_googlemap':
            return (
                <FormGroup error={error} classes={classes} key={key} label={question.text}>
                    <div>
                        <InputField
                            {...commonProps}
                            type='text'
                            name={`${key}[latitude]`}
                            placeholder="Latitude"
                        // defaultValue={question.value?.latitude || ''}
                        />
                        <InputField
                            {...commonProps}
                            type='text'
                            name={`${key}[longitude]`}
                            placeholder="Longitude"
                        // defaultValue={question.value?.longitude || ''}
                        />
                    </div>
                </FormGroup>
            );
        case 'control_matrixdynamic':
            return (
                <FormGroup error={error} classes={classes} key={key} label={question.text}>
                    <table className="form-matrix">
                        <thead>
                            <tr>
                                {question.columns.map((col, index) => (
                                    <th key={index}>{col.text}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {question.rows.map((row, index) => (
                                <tr key={index}>
                                    {question.columns.map((col, colIndex) => (
                                        <td key={colIndex}>
                                            <InputField
                                                {...commonProps}
                                                type='text'
                                                name={`${key}[${index}][${colIndex}]`}
                                            />
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </FormGroup>
            );
        case 'control_matrixdropdown':
            return (
                <FormGroup error={error} key={key} label={question.text}>
                    <table className="form-matrix">
                        <thead>
                            <tr>
                                {question.columns.map((col, index) => (
                                    <th key={index}>{col.text}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {question.rows.map((row, index) => (
                                <tr key={index}>
                                    {question.columns.map((col, colIndex) => (
                                        <td key={colIndex}>
                                            <select
                                                name={`${key}[${index}][${colIndex}]`}
                                                defaultValue={question.value[index]?.[colIndex] || ''}
                                            >
                                                {col.options.map((option, optIndex) => (
                                                    <option key={optIndex} value={option.text}>
                                                        {option.text}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </FormGroup>
            );
        case 'control_calculation':
            return (
                <FormGroup error={error} classes={classes} key={key} label={question.text}>
                    <InputField
                        {...commonProps}
                        type="text"
                        readOnly={true}
                    />
                </FormGroup>
            );
        case 'control_text':
            return (
                <FormGroup error={error} key={key}>
                    <label>
                        {question.text && (
                            <div dangerouslySetInnerHTML={{ __html: question.text }} />
                        )}
                    </label>
                </FormGroup>
            );
        case 'control_widget':
            if (question.fields) {
                const fields = question.fields.split('\n');
                return (
                    <FormGroup error={error} classes={classes} key={key} label={question.text}>
                        {fields.map((field, index) => (
                            <FormGroupInner classes={classes} label={field} key={`${key}-${index}`}>
                                <InputField
                                    {...commonProps}
                                    type="text"
                                    id={`${question.name}-${index}`}
                                    name={`${question.name}-${index}`}
                                    value={formData[`${question.name}-${index}`] || ''}

                                />
                            </FormGroupInner>
                        ))}
                    </FormGroup>
                );
            }
            else {
                return null
            }
        default:
            return null;
    }
};

export default FormControlComponent;