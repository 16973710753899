import React from 'react'
import {  IconButton } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';

const HeaderNotificationIcon = () => {
  return (
    <IconButton
        aria-label="Notification"
        color="primary"
        style={{
            border: "1px solid #D9D9D9",
            borderRadius: "50%",
            padding: 10
        }}
    >
        <NotificationsIcon style={{ color: "black" }} color='action' fontSize='small' />
    </IconButton>
  )
}

export default HeaderNotificationIcon