import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Card, CardActions, CardContent, Divider, Typography, Grid } from '@material-ui/core';
import { uploadProfileAvatar } from '../../../../../api';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../../../../redux/actions/auth';
import { RootState } from '../../../../../redux/store';
import toastr from "toastr";

const user = {
  name: 'Sofia Rivers',
  avatar: '/assets/avatar.png',
  jobTitle: 'Senior Developer',
  country: 'USA',
  city: 'Los Angeles',
  timezone: 'GTM-7',
} as const;

export default function AccountInfo() {
  const dispatch = useDispatch();
  const userId = localStorage.getItem('user');
  const userData = useSelector((state: RootState) => state.auth.user);
  const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:3000';

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      let selectedFile = event.target.files[0];

      const formData = new FormData();
      formData.append('file', selectedFile);

      try {
        const resp: any = await uploadProfileAvatar(userId, formData);
        if (resp) {
          dispatch(getUser())
          toastr.success('File uploaded successfully')
        }
        console.log('File uploaded successfully:', resp);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  useEffect(() => {
    // dispatch(getUser());
  }, [])

  return (
    <Card>
      <CardContent>
        <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
          <Avatar src={process.env.REACT_APP_API_URL + '/' + userData.avatarUrl} style={{ height: '80px', width: '80px' }} />
          <Box mt={2}>
            <Typography variant="h5">{userData.name + ' ' + userData.lastName}</Typography>
          </Box>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="raised-button-file"
          type="file"
          onChange={handleFileSelect}
        />
        <label style={{ width: '100%' }} htmlFor="raised-button-file">
          <Button fullWidth variant='text' component='span'>
            Upload picture
          </Button>
        </label>
      </CardActions>
    </Card>
  );
}
