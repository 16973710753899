import React, { useEffect, useState } from 'react';
import {
    Grid,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Avatar,
    IconButton,
    Box,
} from '@material-ui/core';
import { Edit, DeleteOutline, CameraAlt } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import ButtonAppBar from '../../../../components/AppBar';
import HeaderNotificationIcon from '../../../../components/HeaderNotification';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { getUserInfoAndState } from '../../../../redux/actions/childAction';
import { getUserApi, getUserTeamsApi } from '../../../../api';
import { IUser } from '../../../../types/user';
import toastr from "toastr";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3),
    },
    heading: {
        fontWeight: 700,
        color: '#000000DE'
    },
    profileSection: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(3),
        textAlign: 'center',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    avatar: {
        width: theme.spacing(12),
        height: theme.spacing(12),
        marginBottom: theme.spacing(2),
    },
    editButton: {
        position: 'absolute',
        top: 10,
        right: 10,
    },
    statusActive: {
        color: 'green',
        fontWeight: 600,
    },
    statusInactive: {
        color: 'red',
        fontWeight: 600,
    },
    tableContainer: {
        marginTop: theme.spacing(2),
    },
}));

const UserRecord = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const { userId }: any = useParams();
    const [userData, setUserData] = useState<IUser>(null);
    const [userTeams, setUserTeams] = useState<any>(null);

    const getUser = async () => {
        try {
            const user = await getUserApi(userId);
            if (user.success) {
                setUserData(user?.data)
            } else {
                toastr.warning('Unexpected response structure.');
            }
        }
        catch (error) {
            toastr.error(error.response?.data.message);
            console.error(error);
        }
    };

    const getUserTeams = async () => {
        try {
            const response: any = await getUserTeamsApi(userId);
            if (response) {
                setUserTeams(response?.teams);
            } else {
                toastr.warning('Unexpected response structure.');
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Failed to fetch user teams';
            toastr.error(errorMessage);
            console.error('Error fetching user teams:', error);
        }
    };

    useEffect(() => {
        if (userId) {
            getUser();
            getUserTeams();
        }
    }, [userId]);

    return (
        <>
            <ButtonAppBar title='User Record' back={() => history.go(-1)} rightIcon={<HeaderNotificationIcon />} />
            <Box className={classes.container}>
                <Grid container spacing={4}>
                    {/* General Info Section */}
                    <Grid item xs={12} md={4}>
                        <Typography className={classes.heading} variant="h6" gutterBottom>
                            General Info
                        </Typography>
                        <Paper elevation={3} className={classes.profileSection}>
                            <label style={{
                                width: '100px',
                                height: '100px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative',
                                marginBottom: '20px'
                            }}>
                                {userData && userData?.avatarUrl ? (
                                    <img src={`${process.env.REACT_APP_API_URL}/${userData.avatarUrl}`} alt="Selected" style={{
                                        width: '100px',
                                        height: '100px',
                                        borderRadius: '50%',
                                        objectFit: 'cover',
                                    }} />
                                ) : (
                                    <div style={{
                                        width: '100px',
                                        height: '100px',
                                        borderRadius: '50%',
                                        backgroundColor: '#e0e0e0',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'relative'
                                    }}>
                                    </div>
                                )}
                            </label>

                            <Typography variant="h6">{userData ? (userData.name + ' ' + userData?.lastName) : 'N/A'}</Typography>
                            <Typography variant="body2">{userData?.email ?? "N/A"}</Typography>
                        </Paper>
                    </Grid>

                    {/* Membership Details Section */}
                    <Grid item xs={12} md={8}>
                        <Typography className={classes.heading} variant="h6" gutterBottom>
                            Membership Details
                        </Typography>
                        <TableContainer component={Paper} className={classes.tableContainer}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Team Name</TableCell>
                                        <TableCell>Country</TableCell>
                                        <TableCell>Join Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {userTeams && userTeams.length > 0 && userTeams.map((team: any, index: number) => (
                                        <TableRow key={index}>
                                            <TableCell>{team?.name}</TableCell>
                                            <TableCell>{team?.country ?? 'N/A'}</TableCell>
                                            <TableCell>{moment(team?.createdAt).format('MMM DD, YYYY')}</TableCell>
                                        </TableRow>
                                    ))}
                                    {(userTeams?.length === 0 || !userTeams) && (<TableRow> <TableCell align='center' colSpan={3}>No records found</TableCell> </TableRow>)}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>
        </>

    );
};

export default UserRecord;
