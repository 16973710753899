import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    card: {
        padding: '20px',
        borderRadius: '10px',
        marginTop: theme.spacing(2)
    },
    cardInnerWrapper: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
    },
    circularProgressWrapper: {
        position: 'relative',
        width: 120,
        height: 120,
        background: 'radial-gradient(59.8% 58.75% at 50% 50%, rgba(67, 81, 117, 0.03) 50.81%, rgba(67, 81, 117, 0.55) 99.02%)',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    innerCircle: {
        width: 85,
        height: 85,
        backgroundColor: '#000',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    scoreText: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#fff',
    },
    legend: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: '20px',
        width: '100%',
        gap: '20px'
    },
    legendItem: {
        fontSize: 12,
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: "column",
        color: "#757575",
        gap: "5px",
    },
    legendColorBox: {
        width: '18px',
        height: '11px',
        marginRight: '5px',
        marginLeft: "2px",
        borderRadius: '4px',
    },
    titleWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '10px',
    },
    typography: {
        fontFamily: 'Nunito Sans',
        fontSize: '18px',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
    caption: {
        color: '#757575',
        fontSize: '14px',
        textAlign: 'center',
        fontWeight: 600,
        marginBottom: 0,
    },
    poorSpan: {
        backgroundColor: '#9A9898',
    },
    fairSpan: {
        backgroundColor: '#736E6B',
    },
    goodSpan: {
        backgroundColor: '#5F5D55',
    },
    vgoodSpan: {
        backgroundColor: '#394953',
    },
    excellentSpan: {
        backgroundColor: '#191D1B',
    },
    questionHeading: {
        color: "#757575",
        fontSize: "14px"
    },
    moreInfoButton :{
        color: '#202A44',
        fontWeight: 500,
        textDecoration: 'underline',
        background: 'transparent !important',
        textTransform: 'capitalize'
    }
}));