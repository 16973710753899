import { Box, Button, DialogContent, Drawer, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CustomDialogTitle from './DialogTitle';
import { useStyles } from '../../style';
import { CameraAlt } from '@material-ui/icons';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { createUserApi, saveUserApi, uploadFile } from '../../../../../api';
import { useParams } from 'react-router';
  import toastr from 'toastr';
import { UserType } from '../../../../../constants/data';
import { IUser } from '../../../../../types/user';
import { Link } from 'react-router-dom';
import { getTeamUsers } from '../../../../../redux/actions/teamAction';
import { useDispatch } from 'react-redux';

type CreateMemberProps = {
  modalOpen: boolean;
  handleClose: () => void;
  initialData: IUser;
}

const CreateMember = (props: CreateMemberProps) => {
  const { teamId }: any = useParams();
  const { modalOpen, handleClose } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");

  const { control, handleSubmit, errors, reset } = useForm({
    defaultValues: {
      _id: props.initialData?._id || '',
      name: props.initialData?.name || '',
      lastName: props.initialData?.lastName || '',
      email: props.initialData?.email || '',
      type: props.initialData?.type || '',
    },
  });

  const allowedUserTypes = [UserType.coach, UserType.client, UserType.teamAdmin, UserType.admin];

  useEffect(() => {
    if (modalOpen) {
      reset({
        _id: props.initialData?._id || '',
        name: props.initialData?.name || '',
        lastName: props.initialData?.lastName || '',
        email: props.initialData?.email || '',
        type: props.initialData?.type || '',
      });
      setImageUrl(props.initialData?.avatarUrl || null);
      setUserId(props.initialData?._id);
    }
  }, [props.initialData, modalOpen, reset]);

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      let selectedFile = event.target.files[0];

      const formData = new FormData();
      formData.append('file', selectedFile);

      try {
        const resp: any = await uploadFile(formData);
        if (resp) {
          setImageUrl(resp?.url)
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    let user = {
      ...data,
      avatarUrl: imageUrl,
      _team: teamId,
      _id: userId
    };
    try {
      if (props.initialData) {
        await saveUserApi(user);
        toastr.success('Team Member Updated Successfully');
      } else {
        await createUserApi(user);
        toastr.success('Team Member Created Successfully');
      }
      handleClose();
      reset();
      setImageUrl('');
      setUserId('');
      dispatch(getTeamUsers(teamId));
    }
    catch (error) {
      toastr.error(error?.response?.data?.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer className={classes.drawer} anchor={'right'} open={modalOpen} onClose={handleClose}>
      <CustomDialogTitle onClose={handleClose}>{props?.initialData ? 'Edit Member' : 'Create New Member'} </CustomDialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="column" style={{ gap: 20 }}>
            <Box display="flex" justifyContent="center" mb={2}>
              <label style={{
                width: '100px',
                height: '100px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative'
              }}>
                {imageUrl ? (
                  <img src={process.env.REACT_APP_API_URL + '/' + imageUrl} alt="Selected" style={{
                    width: '100px',
                    height: '100px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                  }} />
                ) : (
                  <div style={{
                    width: '100px',
                    height: '100px',
                    borderRadius: '50%',
                    backgroundColor: '#e0e0e0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative'
                  }}></div>
                )}
                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '24px', height: '24px', padding: '3px', background: '#1F2937', color: 'white', borderRadius: '50%', position: 'absolute', bottom: '-10px', right: 5 }}>
                  <input type="file" hidden accept="image/*" onChange={handleImageChange} />
                  <CameraAlt fontSize='inherit' />
                </span>
              </label>
            </Box>

            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: 'First Name is required.',
                }}
                defaultValue=""
                as={
                  <TextField
                    id="name"
                    aria-describedby="outlined-name-helper-text"
                    inputProps={{
                      'aria-label': 'name',
                    }}
                    placeholder="First Name"
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    error={!!errors.name}
                  />
                }
              />
              <ErrorMessage
                as={<Typography color="error" />}
                errors={errors}
                name="name"
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: 'Last Name is required.',
                }}
                defaultValue=""
                as={
                  <TextField
                    id="outlined-name-weight"
                    aria-describedby="outlined-name-helper-text"
                    inputProps={{
                      'aria-label': 'lastName',
                    }}
                    placeholder="Last Name"
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    error={!!errors.lastName}
                  />
                }
              />
              <ErrorMessage
                as={<Typography color="error" />}
                errors={errors}
                name="lastName"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: 'Email is required.',
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: 'Entered value does not match email format'
                  }
                }}
                defaultValue=""
                as={
                  <TextField
                    id="outlined-name-weight"
                    aria-describedby="outlined-name-helper-text"
                    inputProps={{
                      'aria-label': 'name',
                    }}
                    placeholder="Email"
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                    error={!!errors.email}
                  />
                }
              />
              <ErrorMessage
                as={<Typography color="error" />}
                errors={errors}
                name="email"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="type"
                control={control}
                rules={{
                  required: 'Role is required.',
                }}
                defaultValue=""
                as={
                  <TextField
                    label="Select Role"
                    variant="outlined"
                    fullWidth
                    select
                    error={!!errors.type}
                  >
                    {Object.entries(UserType)
                      .filter(([key]: any) => allowedUserTypes.includes(key))
                      .map(([key, value]) => (
                        <MenuItem key={key} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                  </TextField>
                }
              />
              <ErrorMessage
                as={<Typography color="error" />}
                errors={errors}
                name="type"
              />
            </Grid>

            {
              props?.initialData && <Link
                className='text-center'
                to={`/admin/dashboard/userRecord/${props.initialData._id}`}
                style={{ textAlign: 'center', fontSize: 'small', color: '#202A44' }}
              >
                View Full User Record
              </Link>
            }

            <Button type="submit" variant='contained' className={classes.saveButton}>Save</Button>
          </Box>
        </form>
      </DialogContent>
    </Drawer>
  );
};

export default CreateMember;
