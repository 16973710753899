import React from 'react';
import Grid from '@material-ui/core/Grid';
import Announcements from '../../../components/Announcements';
import ActionItems from '../../../components/ActionItems';
import { makeStyles } from '@material-ui/core/styles';
import Schedule from '../Schedule';
import MonthlyPerformanceCard from '../../../components/MonthlyPerformance';
import FeedbackView from '../MyResults/components/FeedBackView';
import PerformanceScoreCard from '../../../components/Results';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    backgroundColor: '#F7F9FC',
  },
}));

const ClientDashboard = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid item xs={12}>
        <Announcements />
      </Grid>
      <Grid item xs={12}>
        <ActionItems />
      </Grid>
      <Grid container spacing={2} >
        <Grid item xs={12} md={6} sm={12}>
          <PerformanceScoreCard />
        </Grid>
        <Grid item xs={12} md={6} sm={12}>
          <Schedule />
        </Grid>
      </Grid>
      <Grid container spacing={2} >
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MonthlyPerformanceCard />
        </Grid>
      </Grid>
    </div>
  );
};

export default ClientDashboard;
