import moment from "moment";
import {
    SET_ACTIVE_TAB,
    SET_HIGHLIGHT_TAB,
    SET_WEEKLY_DATA,
} from "../actions/myResultAction";
import { IAction } from "../../types/generics";

const initState = {
    weeklyData: [],
    activeTab: 'heat-map',
    highLightTab: null
};

export default function (state = initState, action: IAction) {
    switch (action.type) {
        case SET_WEEKLY_DATA:
            return {
                ...state,
                weeklyData: action.payload,
            };
        case SET_HIGHLIGHT_TAB: {
            return {
                ...state,
                highLightTab: state.highLightTab !== action.payload ? action.payload : null
            }
        }
        case SET_ACTIVE_TAB: {
            return {
                ...state,
                activeTab: action.payload
            }
        }
        default:
            return state;
    }
};
