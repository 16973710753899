import { IUserPolicy } from './policy';

interface IUserActivityUsage {
  action: string;
  time: string;
}

interface IUserActivityApp {
  identifier: string;
  usage: IUserActivityUsage;
}

interface IUserActivity {
  date: string;
  user: string;
  device: string;
  apps: IUserActivityApp[];
}

export interface IUserActivityCounterAppBase {
  identifier: string;
  minutes: number;
}
export interface IUserActivityCounterApp extends IUserActivityCounterAppBase {
  change?: number;
}

export interface IUserActivityCounterDevice {
  identifier: string;
  apps: IUserActivityCounterApp[];
  change?: number;
}

export interface IUserActivityCounterDate {
  date: string;
  devices: IUserActivityCounterDevice[];
  change?: number;
}

export interface IUserActivityCounter {
  user: string;
  counters: IUserActivityCounterDate[];
}

export interface IUserPolicyCounterTotal {
  overall: number;
  gaming: number;
  entertainment: number;
  social: number;
  uncategorized?: number;
  devices: any;
  apps: any;
  appInfo?:IUserPolicyCounterTotalAppInfo;
}

export interface IUserPolicyCounterTotalSnapshot {
  overall: number;
  gaming: number;
  entertainment: number;
  social: number;
  uncategorized?: number;
  devices: any;
  apps: any;
  timestamp: string; // same as IUserPolicyCounterTotal other than this field
}

export interface IUserPolicyCounterTotalAppInfo{
  gaming: any,
  entertainment: any,
  social:any
  uncategorized?: any
}

export interface IUserPolicyCounter {
  recent: [IUserPolicyCounterTotalSnapshot];
  recentSummary: any;
  date: string;
  today: IUserPolicyCounterTotal;
  yesterday?: IUserPolicyCounterTotal;
  thisWeek: IUserPolicyCounterTotal;
}

export interface IProcessActivityResponse {
  userActivityCounter: IUserActivityCounter;
  userPolicy: IUserPolicy;
  thisRequestActivity: IUserActivityCounterDate;
}

export interface IEnforcePolicyActionItem {
  item: string;
  max: number;
  current: number;
  device: string;
  app: string;
}

export enum eDowntimeStatus {
  ok = 'ok',
  blocked = 'blocked',
  unknown = 'unknown', // ideally, this should never be needed
}

export enum ePolicyState {
  unblocked = 'unblocked',
  blocked = 'blocked',
  partiallyBlocked = 'partially-blocked',
  unknown = 'unknown', // ideally, this should never be needed
}

export interface IDowntimePeriodStatus {
  label: string;
  from: string;
  until: string;
  status: eDowntimeStatus;
}

export interface IDowntimeStatus {
  status: eDowntimeStatus;
  statusText?: string;
  limits?: IDowntimePeriodStatus[];
}

export interface ITimeLeft {
  overall: number;
  gaming: number;
  entertainment: number;
  social: number;
}

export interface IEnforcePolicyResponse {
  message: string;
  counter: IUserPolicyCounter;
  limits: IEnforcePolicyActionItem[];
  items: IEnforcePolicyActionItem[];
  downtimeStatus: IDowntimeStatus;
}

export interface IUserPolicyState extends IEnforcePolicyResponse {
  state: string;
  timeLeft?: ITimeLeft;
}

export interface IDeviceIdList {
  devices: [string];
}

export interface IUserActivityData {
  date: string;
  user: string;
  device: string;
  apps: [any];
}
export interface IUserActivityDataAppUsageAction {
  action: string;
  minutes: number;
  since?: string;
}
export interface IUserActivityDataAppUsage {
  identifier: string;
  usage: IUserActivityDataAppUsageAction;
}

export interface IDeviceStateData {
  device: string;
}

export enum eAcdActionStatus {
  requested = 'requested',
  ack = 'ack', // acknowledged by the ACD
  unknown = 'unknown', // ideally, this should never be needed
}

export interface IAcdDeviceAppState {
  name: string;
  label: string;
  identifier: string;
  state: string; // on, off or unavailable
  user: string; // the user who is using the device
  category: string;
  started?: string; // the timestamp of when the user started using the app
  status: eAcdActionStatus;
  inactive: boolean;
  acknowledgedAt?: string;
}

export interface IAcdDeviceState extends IDeviceStateData {
  apps: [IAcdDeviceAppState];
  offline?: boolean;
  expired?: boolean;
  timeRemaining: IAcdTimeRemaining; // simple way for the ACD to enforce policy if it goes offline
}

export interface IAcdTimeRemaining {
  minutes: number;
  at: string;
}
