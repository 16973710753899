import { GET_NAVIGATION_HISTORY } from '../../types';
import { IAction, INavigationHistory } from '../../types/generics';

const initialState: INavigationHistory = {
  navigationRoute: {
    from: 'Bedtime',
    to: 'Bedtime',
    resetView: false,
  },
  isLoading: false,
  // error: null,
};

const navigationHistoryReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_NAVIGATION_HISTORY:
      return {
        ...state,
        isLoading: false,
        navigationRoute: action.payload,
      };
    // case FAILURE_NAVIGATION_HISTORY:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     error: action.payload,
    //   };
    default:
      return state;
  }
};

export default navigationHistoryReducer;
