import React, { useEffect, useState } from 'react'
import ButtonAppBar from '../../../components/AppBar'
import { Box, Button, InputAdornment, Paper, TableBody, IconButton, Table, TableRow, TableCell, TableContainer, TableHead, TextField, Grid } from '@material-ui/core'
import { DeleteOutline, Edit, Search } from '@material-ui/icons'
import { Pagination } from '@material-ui/lab'
import { useStyles } from './style'
import HeaderNotificationIcon from '../../../components/HeaderNotification'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { getAllPrograms } from '../../../redux/actions/programAction'
import { RootState } from '../../../redux/store'
import moment from 'moment';

const ITEMS_PER_PAGE = 5;

const ProgramList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const { allPrograms } = useSelector((state: RootState) => state.programReducer)
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        dispatch(getAllPrograms());
    }, []);


    const totalPages = Math.ceil(allPrograms.length / ITEMS_PER_PAGE);

    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    const currentItems = allPrograms.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    return (
        <div>
            <ButtonAppBar title='Program List' back={() => history.go(-1)} rightIcon={<HeaderNotificationIcon />} />
            <Box className={classes.container}>
                <TableContainer className={classes.tableContainer} component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Programs</TableCell>
                                <TableCell>No. of Phases</TableCell>
                                <TableCell>Last Updated</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentItems && currentItems.length > 0 && currentItems.map((program, index) => (
                                <TableRow key={index}>
                                    <TableCell>{program?.programName}</TableCell>
                                    <TableCell>{program?.phases?.length}</TableCell>
                                    <TableCell>{moment(program.updatedAt).format('MMM DD,YYYY')}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => history.push(`/admin/dashboard/program/${program?._id}`)}>
                                            <Edit fontSize='small' style={{ color: '#333333' }} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                        className={classes.pagination}
                    />
                </Box>

            </Box>
        </div>
    )
}

export default ProgramList