import React, { useEffect } from 'react';
import { Grid, Card, Typography, Button } from '@material-ui/core';
import { useStyles } from '../../screens/User/ClientDashboard/style';
import ShieldIcon from '../Icons/ShieldIcon';
import ArrowDownIcon from '../Icons/ArrowDownIcon';
import RedDotIcon from '../Icons/RedDotIcon';
import GreenDotIcon from '../Icons/GreenDotIcon';
import { useDispatch, useSelector } from 'react-redux';
import { getProgramActionItemsData, getProgramFormContextUserData, setProgramFormContext } from '../../redux/actions/programAction';
import { RootState } from '../../redux/store';
import { useHistory } from 'react-router';
import Skeleton from '@material-ui/lab/Skeleton';
import { staticActiveProgramEnrollment } from '../../constants/data';

const ActionItems = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { contexts, contextLoading } = useSelector((state: RootState) => state.programReducer);
  const userdId = localStorage.getItem("user");
  const {programId} = JSON.parse(localStorage.getItem('activeProgramEnrollment')) || {};

  const handleNavigateContext = (item) => {
    const {sourceFormId, formId, userId, contextId} = item;
    history.push(`/user/form/show?sourceFormId=${sourceFormId}&userId=${userId}&contextId=${contextId}`)
    dispatch(setProgramFormContext(item));
    dispatch(getProgramFormContextUserData(userId))
  }

  useEffect(() => {
    dispatch(getProgramActionItemsData(programId, userdId));
  }, []);

  const today = new Date();
  const twoDaysAgo = new Date(today);
  twoDaysAgo.setDate(today.getDate() - 2);

  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(today.getDate() - 7);

  const tenDaysLater = new Date(today);
  tenDaysLater.setDate(today.getDate() + 10);

  const filterByDateRange = (itemDate: string) => {
    const dueDate = new Date(itemDate);
//    return dueDate >= twoDaysAgo && dueDate <= tenDaysLater;
    return dueDate >= sevenDaysAgo && dueDate <= tenDaysLater;

  };

  const filteredOverdueContexts = contexts?.overDueContexts.filter(item => filterByDateRange(item.dueDate)) || [];
  let filteredUpcomingContexts = contexts?.upcomingContexts.filter(item => filterByDateRange(item.dueDate)) || [];
  const filteredPendingContexts = contexts?.pendingContexts.filter(item => filterByDateRange(item.dueDate)) || [];

  //merge Pending with Upcoming
  filteredUpcomingContexts = [...filteredPendingContexts, ...filteredUpcomingContexts];
  
  return (
    <div>
      <div className={classes.root}>
        <div className={classes.titleWrapper}>
          <Typography variant="h4" className={classes.typography} gutterBottom>
            <ShieldIcon />
            <span>Action required</span>
            <ArrowDownIcon />
          </Typography>

        <Button className={classes.moreInfoButton} onClick={() => history.push(`action-items`)} >
          More  
        </Button>
        </div>
      </div>
      <Grid container spacing={2} className={classes.divider}>
        <Grid item xs={12} md={6}>
          <Typography className={classes.caption} variant="caption" >Past due ({filteredOverdueContexts?.length})</Typography>
          {contextLoading ? (
            <>
              {[...Array(3)].map((_, index) => (
                <Skeleton key={index} variant="rect" height={70} style={{ marginBottom: '10px' }} />
              ))}
            </>
          ) : filteredOverdueContexts?.length > 0 ?
            filteredOverdueContexts?.map((item: any) => {
              const { label, sourceFormId, _id, userId, contextId } = item;
              return (
                <Card className={classes.actionCard}>
                  <div className={classes.actionCardHeader}>
                    <Typography variant="body1" className={classes.overdue}><span style={{ marginRight: '10px' }}><RedDotIcon /></span> {label}</Typography>
                    <span className={classes.checkInbutton}
                      onClick={() => handleNavigateContext(item)}>Checkin now</span>
                  </div>
                  <Typography variant="caption" className={classes.dueText}> {new Date(item.dueDate).toDateString()} (Overdue)</Typography>
                </Card>
              )
            })
            : (
              <Typography className={classes.actionCard}>No overdue action items available.</Typography>
            )
          }
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className={classes.caption} variant="caption" >Upcoming ({filteredUpcomingContexts?.length})</Typography>
          {contextLoading ? (
            <>
              {[...Array(3)].map((_, index) => (
                <Skeleton key={index} variant="rect" height={70} style={{ marginBottom: '10px' }} />
              ))}
            </>
          ) : filteredUpcomingContexts?.length > 0 ? filteredUpcomingContexts?.map((item: any) => {
            const { label, sourceFormId, _id, userId, contextId, dueDate } = item;
            const daysUntilDue = Math.ceil((new Date(dueDate).getTime() - Date.now()) / (1000 * 60 * 60 * 24));

            return <Card className={classes.actionCard}>
              <div className={classes.actionCardHeader}>
                <Typography variant="body1" className={classes.overdue}><span style={{ marginRight: '10px' }}><GreenDotIcon /></span>  {label}</Typography>
                <span className={classes.checkInbutton} onClick={() => handleNavigateContext(item)} >Checkin now</span>
              </div>
              <Typography variant="caption" > Due in {daysUntilDue} days</Typography>
            </Card>
          }) :
            (
              <Typography className={classes.actionCard}>No upcoming action items available.</Typography>
            )
          }
        </Grid>
      </Grid>
    </div>
  );
};

export default ActionItems;
