import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { useStyles } from '../../style';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import CustomDialogTitle from '../../../../Admin/ProgramList/ProgramDrillDown/components/DialogTitle';

type ActionButtonsProps = {
    onLearnMore?: () => void;
    onNext?: () => void;
    onBack?: () => void;
    backText?: string;
    learnMoreText?: string;
    nextText?: string;
    isDisabledNext?: boolean;
    isDisabledPrevious?: boolean;
    phaseNumber?: number
}

const phaseContent: { [key: number]: string } = {
    1: `Congratulations on completing the foundational steps of discovering your purpose and understanding the 6 Essentials. In this next phase, you’ll take what you’ve learned and start creating a clear and actionable plan. The Goal Setting phase will guide you in reviewing your purpose statement, envisioning where you want to be in 10 years, 3 years, and 1 year, and defining specific, meaningful goals. This step is about translating your insights into a forward-moving strategy to help you achieve balance and purpose in your daily life and long-term aspirations.`,
    2: `Now that you’ve set your goals, the next step is creating an accountability plan to help you stay on track. In this phase, you’ll determine the reflection schedule that works best for you, whether it’s weekly check-ins to assess your progress and set intentions for the week ahead, daily reflections to maintain focus, or monthly and quarterly retrospectives to review and adjust your long-term plans. You’ll also have the opportunity to customize your communication preferences, ensuring you receive reminders and guidance in the way that suits you best. This phase is all about building consistent habits to execute your plan effectively.`,
    3: `As you move into the execution phase of the program, your dashboard becomes your central hub for tracking progress and staying accountable. Here, you’ll find a clear snapshot of your current standing, including upcoming or overdue tasks to keep you on schedule. A heat map will visually illustrate your progress, helping you quickly see patterns and identify areas for improvement. This phase is designed to ensure you have the tools and insights needed to stay aligned with your goals and maintain momentum as you execute your plan.`,
  };

function ActionButtons({ onLearnMore, onNext, onBack, phaseNumber, backText, isDisabledNext = true, isDisabledPrevious = false, learnMoreText = "Learn More", nextText = "Next Goal Setting" }: ActionButtonsProps) {
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogContent, setDialogContent] = React.useState("");

    const handleLearnMore = () => {
        if (phaseNumber) {
            setDialogContent(phaseContent[phaseNumber] || "Content not available.");
            setDialogOpen(true);
        }
        if (onLearnMore) onLearnMore();
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <>


            <Box className={classes.actionButtons}>
                <Box>
                    {backText && <Button
                        variant="text"
                        size="small"
                        className={classes.backBtn}
                        onClick={onBack}
                        disabled={isDisabledPrevious}
                    >
                        {backText}
                    </Button>}

                </Box>
                <Box display={"flex"}>
                    <Button
                        variant="text"
                        size="small"
                        className={classes.learnMoreBtn}
                        onClick={handleLearnMore}
                    >
                        {learnMoreText}
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        className={classes.nextBtn}
                        onClick={onNext}
                        disabled={isDisabledNext}
                    >
                        {nextText}
                    </Button>
                </Box>
            </Box>
            <Dialog onClose={handleDialogClose} open={dialogOpen}>
                <CustomDialogTitle onClose={handleDialogClose}>Learn More</CustomDialogTitle>
                <DialogContent dividers>
                    <Typography>{dialogContent}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ActionButtons;
