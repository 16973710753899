import React, { useEffect, useState } from 'react'
import { Box,  Paper, TableBody, IconButton, Table, TableRow, TableCell, TableContainer, TableHead, TextField, Grid } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { Pagination } from '@material-ui/lab'
import { useStyles } from '../ProgramList/style'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';
import { RootState } from '../../../redux/store'
import ButtonAppBar from '../../../components/AppBar'
import HeaderNotificationIcon from '../../../components/HeaderNotification'
import { getChannelMembersInfoMEO } from '../../../redux/actions/app_chat'
import ActionItemFormModal from './ActionItemModal'
import { closeProgramFormModal, openProgramFormModal } from '../../../redux/actions/programFormAction'

const ITEMS_PER_PAGE = 25;

const UserList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const channelMembers = useSelector((state: RootState) => state.appChatReducer.members);
    const [currentPage, setCurrentPage] = useState(1);
    const [clickUserId, setClickUserId]=useState(null);
    const formModal = useSelector((state: RootState) => state.programFormReducer.formModal)

    useEffect(() => {
        dispatch(getChannelMembersInfoMEO())
    }, []);

    const totalPages = Math.ceil(channelMembers.length / ITEMS_PER_PAGE);
    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    const currentItems = channelMembers.slice(indexOfFirstItem, indexOfLastItem);
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };
      const handleCloseModal = () => {
            dispatch(closeProgramFormModal());
        };
    const clickEdit =(id:string)=>{
            setClickUserId(id)
            dispatch(openProgramFormModal());
    }    
    return (<>
        <div>
            <ButtonAppBar title='User List' back={() => history.go(-1)} rightIcon={<HeaderNotificationIcon />} />
            <Box className={classes.container}>
                <TableContainer className={classes.tableContainer} component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Last Updated</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentItems && currentItems.length > 0 && currentItems.map((user, index) => (
                                <TableRow key={index}>
                                    <TableCell>  {user?.name ? user.name.charAt(0).toUpperCase() + user.name.slice(1) : ""} 
                                    {user?.lastName}</TableCell>
                                    <TableCell>{user?.email}</TableCell>
                                    <TableCell>{user?.type}</TableCell>

                                    <TableCell>{moment(user.updatedAt).format('MMM DD,YYYY')}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={()=>clickEdit(user?.['_id'])} >
                                            <Edit fontSize='small' style={{ color: '#333333' }} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                        className={classes.pagination}
                    />
                </Box>

            </Box>
        </div>
        { <ActionItemFormModal
            isModal={formModal}
            closeModal={handleCloseModal}
            userId={clickUserId} sourceFormId={''} contextId={''} programId={''}            />}

        </>
    )
}

export default UserList