import { getProgramContextListApi, getProgramFormContextDataApi, getProgramFormStructureApi, getProgramMyBackpackApi, submitProgramFormApi, queryFormValuesApi } from '../../api';
import toastr from "toastr";

import { getUserProgramEnrollmentData, closeEnrollmentLoading, getUserProgramEnrollmentLoading } from './programEnrollmentAction';

import { ProgramFormFieldProcessor } from '../services/programFormFieldProcessor';

import { logger } from '../../datadogLogger';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { getMyGoalsData } from './dashboardAction';


export const GET_PROGRAM_FORM_STRUCTURE = 'GET_PROGRAM_FORM_STRUCTURE';
export const GET_PROGRAM_FORM_STRUCTURE_CACHE = 'GET_PROGRAM_FORM_STRUCTURE_CACHE';
export const GET_PROGRAM_FORM_STRUCTURE_ERROR = 'GET_PROGRAM_FORM_STRUCTURE_ERROR';
export const GET_PROGRAM_FORM_STRUCTURE_LOADING = 'GET_PROGRAM_FORM_STRUCTURE_LOADING';
export const GET_PROGRAM_FORM_CONTEXT_DATA = 'GET_PROGRAM_FORM_CONTEXT_DATA';
export const GET_PROGRAM_FORM_CONTEXT_CACHE = 'GET_PROGRAM_FORM_CONTEXT_DATA_CACHE';
export const GET_PROGRAM_FORM_CONTEXT_DATA_ERROR = 'GET_PROGRAM_FORM_CONTEXT_DATA_ERROR';
export const GET_PROGRAM_FORM_CONTEXT_DATA_LOADING = 'GET_PROGRAM_FORM_CONTEXT_DATA_LOADING';
export const GET_PROGRAM_CONTEXT_LIST = 'GET_PROGRAM_CONTEXT_LIST';
export const GET_PROGRAM_CONTEXT_LIST_ERROR = 'GET_PROGRAM_CONTEXT_LIST_ERROR';
export const GET_PROGRAM_CONTEXT_LIST_LOADING = 'GET_PROGRAM_CONTEXT_LIST_LOADING';
export const RESET_USER_FORM_DATA = "RESET_USER_FORM_DATA";
export const RESET_PROGRAM_FORM_STRUCTURE = "RESET_PROGRAM_FORM_STRUCTURE";
export const OPEN_PROGRAM_FORM_MODAL = "OPEN_PROGRAM_FORM_MODAL";
export const CLOSE_PROGRAM_FORM_MODAL = "CLOSE_PROGRAM_FORM_MODAL";


export const getProgramFormStructure = (programId: string, sourceFormId: string, userId: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(resetProgramFormStructure());
        dispatch(getProgramFormStructureLoading());
        dispatch(getMyGoalsData(programId, userId));
        try {
            const res: any = await getProgramFormStructureApi(programId, sourceFormId, userId);
            res.formDefinition.details.programId = programId;
            res.formDefinition.details.sourceFormId = sourceFormId;

            console.log("getProgramFormStructure!!!!! ", res);

            const goalsData = getState().dashboardReducer.myGoalsData;
            let isFormGoalsSource = false;

            if (goalsData) {
                for (const group of goalsData.groups) {
                    for (const goal of group.goals) {
                        if (goal.sourceFormId === sourceFormId) {
                            isFormGoalsSource = true;
                            break;   
                        }
                    }
                }
            }
            
            let fnHideInactiveGoalFields = (question: any) => {
                const goalsData = getState().dashboardReducer.myGoalsData; // Fetch the goals data

                console.log("hideInactiveGoalFields!!!!!- question = ", question);

                // Loop through each group and goal to check if the question should be hidden
                for (const group of goalsData.groups) {
                    for (const goal of group.goals) {
                        if (question['name'].startsWith(goal.goalName)) {
                            console.log("hideInactiveGoalFields <<YES>> MATCH " + question['name'] + " != " + goal.goalName);

                            if (!goal.isActive) {
                                console.log("hideInactiveGoalFields!!!!! ", question['name'], goal.goalName);
                                question['hidden'] = 'Yes'; // Hide the question if conditions are met
                                break; // Exit the loop once the first match is found
                            }
                        }
                    }
                }
                return question; // Return the updated question object
            };

            if (isFormGoalsSource) {
                fnHideInactiveGoalFields = (question: any) => question; // If the form is the goals source or doNotHideGoalFields is true, don't hide any fields
            }

            // This will modify the form definition and run any calculations that are required
            await ProgramFormFieldProcessor.processFormDefinition(programId, userId, sourceFormId, res.formDefinition, fnHideInactiveGoalFields);

            dispatch(getProgramFormStructureSuccess(res));
            dispatch(getProgramFormStructureCache(res));
        } catch (error) {
            if (error.response) {
                dispatch(getProgramFormStructureError(error.response.data.message || 'An error occurred'));
                return;
            }
            dispatch(getProgramFormStructureError(error.message || 'An error occurred'));
        }
    };
};



export const queryFormValues = (programId: string, userId:string, sourceFormId: string, fields:[string],year:number, onDate:Date, period:string) => {
    return async (dispatch: any) => {
        try {
            const res: any = await queryFormValuesApi({ programId,userId,  sourceFormId, fields, year, onDate,period});
            return res;
        } catch (error) {
            console.error(error);
        }
    };
};

export const submitProgramForm = ({
    programId,
    sourceFormId,
    userId,
    contextId,
    year,
    payload,
}: {
    programId: string;
    sourceFormId: string;
    userId: string;
    contextId: string;
    year?: string;
    payload: any;
}) => {
    return async (dispatch: any) => {
        try {
            dispatch(getUserProgramEnrollmentLoading());
            const response: any = await submitProgramFormApi(programId, sourceFormId, userId, contextId, payload);
            if (response) {
                if (year && year !== undefined) {
                    dispatch(getProgramListContextData(programId, userId, year));
                }
                dispatch(resetUserFormData());
                dispatch(getUserProgramEnrollmentData(programId, userId));
                dispatch(closeEnrollmentLoading());
                dispatch(closeProgramFormModal());
                if (!payload.data.skipped) {
                    // toastr.success("Form submitted successfully");
                }
                return response;
            } else {
                throw new Error("There was no response from the server");
            }
        } catch (error) {
            // Extract the error message from the response payload if available,
            // otherwise fallback to error.message or a default message.
            const errorMsg =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                'An error occurred posting to the server';
            //toastr.error(errorMsg);
            console.error(errorMsg);
            dispatch(closeEnrollmentLoading());
            throw new Error(errorMsg)
        }
    };
};


export const getProgramFormContextData = (programId: string, sourceFormId: string, userId: string, contextId: string) => {
    return async (dispatch: any) => {
        dispatch(getProgramFormContextDataLoading());
        try {
            let res: any = await getProgramFormContextDataApi(programId, sourceFormId, userId, contextId);
            const data = res?.data;
            data['userId'] = userId;
            data['programId'] = programId;
            data['contextId'] = contextId;
            data['sourceFormId'] = sourceFormId;
            
            dispatch(getProgramFormContextDataSuccess(data));
            dispatch(getProgramFormContextDataCache(data));

        } catch (error) {
            if (error?.response) {
                dispatch(getProgramFormContextDataError(error.response?.data?.message || 'An error occurred'));
                return;
            }
            dispatch(getProgramFormContextDataError(error.message || 'An error occurred'));
        }
    };
};

export const getProgramListContextData = (programId: string, userId: string, year: string) => {
    return async (dispatch: any) => {
        dispatch(getProgramContextListLoading());
        try {
            const res: any = await getProgramContextListApi(programId, userId, year);
            dispatch(getProgramContextListSuccess(res));
        } catch (error) {
            if (error?.response) {
                dispatch(getProgramContextListError(error.response?.data?.message || 'An error occurred'));
                return;
            }
            dispatch(getProgramContextListError(error.message || 'An error occurred'));
        }
    };
};

export const getProgramFormStructureLoading = () => ({
    type: GET_PROGRAM_FORM_STRUCTURE_LOADING,
});

export const openProgramFormModal = () => ({
    type: OPEN_PROGRAM_FORM_MODAL,
});

export const closeProgramFormModal = () => ({
    type: CLOSE_PROGRAM_FORM_MODAL,
});


export const getProgramFormStructureSuccess = (payload) => ({
    type: GET_PROGRAM_FORM_STRUCTURE,
    payload,
});

const getProgramFormStructureCache = (payload) => ({
    type: GET_PROGRAM_FORM_STRUCTURE_CACHE,
    payload,
});


const getProgramFormStructureError = (error: any) => ({
    type: GET_PROGRAM_FORM_STRUCTURE_ERROR,
    payload: error,
});

const getProgramFormContextDataLoading = () => ({
    type: GET_PROGRAM_FORM_CONTEXT_DATA_LOADING,
});

const getProgramFormContextDataSuccess = (payload) => ({
    type: GET_PROGRAM_FORM_CONTEXT_DATA,
    payload,
});

const getProgramFormContextDataCache = (payload) => ({
    type: GET_PROGRAM_FORM_CONTEXT_CACHE,
    payload,
});


const getProgramFormContextDataError = (error: any) => ({
    type: GET_PROGRAM_FORM_CONTEXT_DATA_ERROR,
    payload: error,
});

const getProgramContextListLoading = () => ({
    type: GET_PROGRAM_CONTEXT_LIST_LOADING,
});

const getProgramContextListSuccess = (payload) => ({
    type: GET_PROGRAM_CONTEXT_LIST,
    payload,
});

const getProgramContextListError = (error: any) => ({
    type: GET_PROGRAM_CONTEXT_LIST_ERROR,
    payload: error,
});

const resetUserFormData = () => ({
    type: RESET_USER_FORM_DATA,
})

export const resetProgramFormStructure = () => ({
    type: RESET_PROGRAM_FORM_STRUCTURE
});

