import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

datadogLogs.init({
  clientToken: 'pub2c38fc46472f9d90b87eb31d9f035433',
  site: 'datadoghq.com',
  service: `DEFAULT_WEB`,
  env: process.env.REACT_APP_ENV,
  forwardErrorsToLogs: true,
  sampleRate: 100,
});

datadogRum.init({
  applicationId: 'bae95fc8-b813-4b9d-bbd4-bb72f1d2e9d0',
  clientToken: 'pube8ee7e2576835e453644b12d9859eba6',
  site: 'datadoghq.com',
  service: 'DEFAULT_WEB',
  env: process.env.REACT_APP_ENV,
  //version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true,
});

export const format = (message: string, device: string = '') => {
  const user = localStorage.getItem('user');
  const team = localStorage.getItem('team');
  const prefix = device ? `DEVICE_ID[${device}] ` : '';
  const postfix = user ? `USER_ID[${user} TEAM_ID[${team}]` : '';

  return `${prefix} ${message} ${postfix}`;
};

export const logger = datadogLogs.logger;
