import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#f5f7fa',
        minHeight: '100vh',
        // padding: theme.spacing(2),
    },
    header: {
        justifyContent: 'center',
        // padding: theme.spacing(2),
        '& .MuiTypography-h4': {
            fontSize: '2.75rem',
            textAlign: "center",
            fontWeight: 700,
            marginBottom: "0",
            lineHeight: "unset"
        }
    },
    progressBar: {
        margin: theme.spacing(2, 0),
        height: '10px',
        borderRadius: 0,
        backgroundColor: '#fff',
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: '#7CA0BB',
            borderRadius: 6
        }
    },
    section: {
        marginBottom: theme.spacing(2),
        borderRadius: '8px',
    },
    sectionHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        fontWeight: 700
    },
    chipCompleted: {
        color: '#47AF59',
        background: 'transparent',
        cursor: 'pointer'
    },
    chipPending: {
        color: 'rgb(255, 156, 0)',
        fontWeight: 600,
        border: "1px solid rgb(255, 156, 0)",
        background: 'transparent',
        borderRadius: '20px',
        cursor: 'pointer'
    },
        listWrapper: {
        background: 'white',
        marginBottom: theme.spacing(2),
        outline: 'none',
        borderRadius: 8,
        padding: '10px 15px',
        boxShadow: 'none',
        '&::before': {
            background: 'transparent'
        },
    },
    chipIncomplete: {
        backgroundColor: '#f8d7da',
        color: '#721c24',
    },
    profileName: {
        textTransform: 'capitalize'
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: `${theme.spacing(2)}px`,
        marginTop: theme.spacing(4),
        padding: `${theme.spacing(3)}px 0`,
        borderTop: '1px solid #717171',
        width: '100%',
        maxWidth: '1100px',
        margin: '0 auto'
    },
    avatar: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#6c757d',
        color: '#fff',
        marginLeft: theme.spacing(1),
    },
    boxWrapper: {
        width: '100%',
        maxWidth: "930px",
        margin: '0 auto',
        padding: `${theme.spacing(3)}px 0`
    },
    welcomeTitle: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: 'center'
    },
    accordianTitle: {
        // fontSize: 16,
        fontWeight: 700,
        color: "#1E1E1E"
    },
    accWrapper: {
        marginBottom: theme.spacing(2),
        outline: 'none',
        borderRadius: 8,
        padding: '0 15px',
        boxShadow: 'none',
        '&::before': {
            background: 'transparent'
        },
        '& .MuiList-root': {
            width: '100%',
            '& .MuiListItem-root': {
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                padding: 0,
                paddingBottom: theme.spacing(2),
                marginBottom: theme.spacing(2),
                borderBottom: '1px solid #F1F1F1',
            },
            '& .MuiListItem-root:last-child': {
                borderBottom: 'transparent',
                paddingBottom: theme.spacing(0),
                marginBottom: theme.spacing(0),
            }
        }
    },
    formLabel: {
        display: 'block',
        width: '100%',
        color: '#1E1E1E',
        fontSize: 16,
        marginBottom: theme.spacing(0.5)
    },
    formDescription: {
        display: 'block',
        width: '100%',
        color: '#686677',
        fontSize: 14
    },
    learnMoreBtn: {
        background: 'transparent',
        color: '#1F2937',
        textTransform: 'capitalize',
        textDecoration: 'underline',
        '&:hover': {
            background: "transparent"
        },
        '& .MuiButton-label': {
            fontWeight: 600,
        }
    },
    nextBtn: {
        background: '#1F2937',
        color: '#fff',
        textTransform: 'capitalize',
        borderRadius: 6,
        padding: '11px 35px',
        marginLeft: "10px",
        '&:hover': {
            background: '#2c3b50'
        },
    },
    backBtn: {
        background: 'transparent',
        border: '1px solid #1F2937',
        color: '#1F2937',
        textTransform: 'capitalize',
        borderRadius: 6,
        fontWeight: 500,
        padding: '11px 35px',
        // '&:hover': {
        //     background: '#2c3b50'
        // },
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        width: '100%',
        maxWidth: '1000px',
        padding: theme.spacing(4),
        gap: '16px',
        margin: 'auto',
        boxShadow:
            'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
        borderRadius: theme.spacing(1),
        backgroundColor: '#FFF',
        marginBottom: theme.spacing(3),
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        // backgroundColor: '#EDF2F7',
        padding: theme.spacing(2),
    },
    title: {
        fontSize: '2.75rem',
        fontWeight: 700,
        textAlign: 'center',
        marginBottom: theme.spacing(2)
    },
    subTitle: {
        color: '#100F14',
        fontSize: '1.45rem',
        fontWeight: 600,
        textAlign: 'center',
    },
    titlePara: {
        width: '75%',
        margin: 'auto',
        fontSize: '1rem',
        color: '#49475A',
        textAlign: 'center',
        marginBottom: theme.spacing(2),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        '& .MuiOutlinedInput-root': {
            borderRadius: 6
        },
        '& .MuiFormLabel-root': {
            color: '#9794AA',
            marginBottom: theme.spacing(1),
        }
    },
    submitBtn: {
        width: '100%',
        maxWidth: '295px',
        background: '#1F2937',
        color: '#fff',
        textTransform: 'capitalize',
        borderRadius: 6,
        padding: '11px 11px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        '&:hover': {
            background: '#2c3b50'
        },
    },
    stepCard: {
        position: 'relative',
        border: '1px solid #717171',
        borderRadius: '16px',
        textAlign: 'center',
        padding: theme.spacing(2),
        boxShadow: 'none',
        '&:hover': {
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
        },
    },
    icon: {
        width: '50px',
        height: '50px',
        marginBottom: theme.spacing(1),
    },
    stepTitle: {
        fontWeight: 'bold',
        marginTop: theme.spacing(1),
    },
    stepDescription: {
        color: '#1E1E1E',
        fontWeight: 700
    },
    greenTickIcon: {
        position: 'absolute',
        right: '20px',
        top: '20px'
    },
    listItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: '1px solid #F1F1F1',
        paddingBottom: theme.spacing(1),
        marginBottom: theme.spacing(1),
        borderRadius: "16px",
        padding: '10px 20px'
    },
    previewLink: {
        color: '#1F2937',
        textDecoration: 'underline',
        cursor: 'pointer',
    },

}));