import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { PulseLoader } from 'react-spinners';
import toastr from 'toastr';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Typography from '@material-ui/core/Typography';
import { ErrorMessage } from '@hookform/error-message';
import Box from '@material-ui/core/Box';
import { Button, makeStyles, Theme, Link, InputAdornment, IconButton } from '@material-ui/core';

import { colors } from '../../../constants/colors';
import { resetPasswordApi, sendVerificationCodeApi, verifyCodeApi } from '../../../api';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const TAB_EMAIL = 0;
const TAB_CODE = 1;
const TAB_PASSWORD = 2;

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  form: {
    maxWidth: 320,
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(5),
    margin: 'auto',
  },
  titleCon: {
    paddingTop: 12,
    fontFamily: 'MontserratSemiBold',
  },
  loginTitle: {
    color: '#3b3b3b',
    fontSize: 20,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  inputStyle: {
    border: 0,
    backgroundColor: 'white',
    color: '#9d9d9d',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

function Register() {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(TAB_EMAIL);
  const classes = useStyles();
  const history = useHistory();

  // useEffect(() => {
  //   if (userStatus) {
  //     history.push('/access');
  //   }
  // }, [userStatus, history])

  const handleSendCode = (data) => {
    setLoading(true);
    setEmail(data.email);
    sendVerificationCodeApi(data)
      .then((res) => {
        toastr.success(res.message);
        setTab(TAB_CODE);
      })
      .catch((res) => {
        if (res.response) {
          toastr.error(res.response.data.message);
        } else {
          toastr.error(res.toString());
        }
      })
      .finally(() => setLoading(false))
  };

  const handleVerifyCode = (data) => {
    setLoading(true);
    setCode(data.code);
    verifyCodeApi({ ...data, email })
      .then((res) => {
        toastr.success(res.message);
        setTab(TAB_PASSWORD);
      })
      .catch((res) => {
        if (res.response) {
          toastr.error(res.response.data.message);
        } else {
          toastr.error(res.toString());
        }
      })
      .finally(() => setLoading(false))
  }

  const handleResetPassword = (data) => {
    setLoading(true);
    resetPasswordApi({ ...data, email, code })
      .then((res) => {
        toastr.success('Password updated successfully.');
        history.push('/')
      })
      .catch((res) => {
        if (res.response) {
          toastr.error(res.response.data.message);
        } else {
          toastr.error(res.toString());
        }
      })
      .finally(() => setLoading(false))
  }

  const { control, handleSubmit, errors, watch } = useForm();
  const password = watch('password', '');

  return (
    <Grid container style={{ justifyContent: 'center' }}>
      <Grid item xs={12} style={{ marginTop: '5vh' }}>
        <div style={{ textAlign: 'center' }}>
          <img
            style={{ width: 220 }}
            src="../../assets/logo.png"
            alt="user-avatar"
          />
        </div>
        <div className={classes.titleCon}>
          <h1 className={classes.loginTitle}>Reset your password</h1>
        </div>
      </Grid>
      <TabPanel value={tab} index={TAB_EMAIL}>
        <form className={classes.form} onSubmit={handleSubmit(handleSendCode)}>
          <Grid>
            <Typography variant='body2'>Enter your user accout's verified email address and we will send you a password verification code.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              className={classes.textField}
              variant="outlined"
              name="email"
              control={control}
              rules={{
                required: 'Email is required.',
              }}
              defaultValue=""
              as={
                <OutlinedInput
                  id="outlined-adornment-weight"
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'email',
                  }}
                  labelWidth={0}
                  placeholder="Email address  "
                  className={classes.inputStyle}
                />
              }
            />
            <ErrorMessage
              as={<Typography color="error" />}
              errors={errors}
              name="email"
            />
          </Grid>

          <Grid item xs={12} style={{ textAlign: 'center' }}>
            {loading ? (
              <PulseLoader
                size={10}
                color={colors.appColor}
                loading={loading}
              />
            ) : (
              <Button type="submit" variant='contained' color='primary' fullWidth>
                Send password reset email
              </Button>
            )}
          </Grid>
        </form>
      </TabPanel>

      <TabPanel value={tab} index={TAB_CODE}>
        <form className={classes.form} onSubmit={handleSubmit(handleVerifyCode)}>
          <Grid>
            <Typography variant='body2'>Please enter the 6 digits code from the email.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              className={classes.textField}
              variant="outlined"
              name="code"
              control={control}
              rules={{
                required: 'Code is required.',
              }}
              defaultValstyleue=""
              as={
                <OutlinedInput
                  id="code"
                  aria-describedby="code-helper-text"
                  inputProps={{
                    'aria-label': 'code',
                  }}
                  labelWidth={0}
                  placeholder="Code"
                  className={classes.inputStyle}
                />
              }
            />
            <ErrorMessage
              as={<Typography color="error" />}
              errors={errors}
              name="code"
            />
          </Grid>

          <Grid item xs={12} style={{ textAlign: 'center' }}>
            {loading ? (
              <PulseLoader
                size={10}
                color={colors.appColor}
                loading={loading}
              />
            ) : (
              <Button type="submit" variant='contained' color='primary' fullWidth>
                Verify
              </Button>
            )}
          </Grid>
          <Grid>
            <Link href="#" onClick={() => handleSendCode({ email })} variant="body2">
              Don't receive email? Resend
            </Link>
          </Grid>
        </form>
      </TabPanel>

      <TabPanel value={tab} index={TAB_PASSWORD}>
        <form className={classes.form} onSubmit={handleSubmit(handleResetPassword)}>
          <Grid>
            <Typography variant='body2'>Please enter new password.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              className={classes.textField}
              variant="outlined"
              name="password"
              control={control}
              rules={{
                required: 'Password is required.',
              }}
              defaultValue=""
              as={
                <OutlinedInput
                  name="password"
                  className={classes.inputStyle}
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                        style={{
                          color: '#D3C3E6',
                        }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={0}
                  placeholder="Password"
                />
              }
            />
            <ErrorMessage
              as={<Typography color="error" />}
              errors={errors}
              name="password"
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              className={classes.textField}
              variant="outlined"
              name="password-confirm"
              control={control}
              rules={{
                validate: value =>
                  value === password || "The passwords do not match"
              }}
              defaultValue=""
              as={
                <OutlinedInput
                  name="password-confirm"
                  className={classes.inputStyle}
                  id="password-confirm"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                        style={{
                          color: '#D3C3E6',
                        }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={0}
                  placeholder="Confirm Password"
                />
              }
            />
            <ErrorMessage
              as={<Typography color="error" />}
              errors={errors}
              name="password-confirm"
            />
          </Grid>

          <Grid item xs={12} style={{ textAlign: 'center' }}>
            {loading ? (
              <PulseLoader
                size={10}
                color={colors.appColor}
                loading={loading}
              />
            ) : (
              <Button type="submit" variant='contained' color='primary' fullWidth>
                Set Password
              </Button>
            )}
          </Grid>
        </form>
      </TabPanel>
    </Grid>
  );
}


export default Register;
