import React from 'react'

const CloseModalIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_101_2173)">
                <path d="M15 9L9 15" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9 9L15 15" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_101_2173">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default CloseModalIcon