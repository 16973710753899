import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import TimeDurationPicker from '../../../../components/DurationPicker';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import IconButton from '@material-ui/core/IconButton';
import { DialogActions } from '@material-ui/core';
import { IPolicyRequest } from '../../../../types/policyRequest';
import { useDispatch, useSelector } from 'react-redux';
import { approveTimeExtension } from '../../../../redux/actions/policyRequestAction';
import { RootState } from '../../../../redux/store';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((them: Theme) => ({
  paper: {
    width: '100%',
    borderRadius: '20px 20px 0px 0px',
    margin: 0,
    boxShadow: ' 0px 0px 4px rgba(0, 0, 0, 0.25)',
    height: 'calc(100% - 16px)',
  },
  scrollPaper: {
    alignItems: 'flex-end',
  },
  actions: {
    position: 'absolute',
    bottom: 10,
    width: '100%',
    boxSizing: 'border-box',
    justifyContent: 'center'
  },
  actionButton: {
    textTransform: 'none',
    flex: 1,
  },
  title: {
    padding: '16px 16px 8px 16px',
    fontWeight: `bold`,
  },
}))

interface PropsType {
  isOpen: boolean;
  handleClose: () => any;
  request: IPolicyRequest;
}

export function ApproveRequest(props: PropsType) {
  const { handleClose, isOpen, request } = props;
  const authUser = useSelector((state: RootState) => state.auth.user);
  const [duration, setDuration] = React.useState(30);
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleDailyDurationChange = (duration) => {
    const { hours, minutes } = duration;
    setDuration(hours * 60 + minutes);
  };

  const handleApproveRequest = () => {
    const data = {
      status: 'approved',
      processedBy: authUser._id,
      reason: request.requester.reason,
      data: {
        minutes: duration,
        category: request.requester.reason,
        scope: "today"
      }
    }
    dispatch((approveTimeExtension(request._id, data)));
    handleClose();
  }

  return (
    <Dialog
      classes={{ paper: classes.paper, scrollPaper: classes.scrollPaper }}
      transitionDuration={600}
      TransitionComponent={Transition}
      onClose={() => handleClose()}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
    >
      <DialogTitle id="simple-dialog-title" className={classes.title}>
        <Box display="flex" alignItems="center">
          <IconButton onClick={() => handleClose()} style={{ padding: 0 }}>
            <CloseOutlinedIcon color="primary" />
          </IconButton>
          <Typography>Extra time Request</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <Box p={2}>
        <Typography variant="h3">
          How much extra time would you like to give?
        </Typography>
        <Box
          mt={2}
          display="flex"
          justifyContent="center"
          p={3}
          border={1}
          borderColor="rgba(46, 13, 82, 0.25)"
          borderRadius={5}
        >
          <TimeDurationPicker
            onChange={handleDailyDurationChange}
            initialDuration={{
              hours: request?.requester?.data?.minutes ? Math.floor(request?.requester?.data?.minutes / 60) : 0,
              minutes: request?.requester?.data?.minutes ? request?.requester?.data?.minutes % 60 : 30,
              seconds: 0,
            }}
          />
        </Box>
      </Box>
      <DialogActions classes={{ root: classes.actions }}>
        <Button autoFocus onClick={handleClose} className={classes.actionButton} size='large' color="primary" variant='outlined'>
          Cancel
        </Button>
        <Button onClick={handleApproveRequest} className={classes.actionButton} size='large' color="primary" variant='contained'>
          Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ApproveRequest;
