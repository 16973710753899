import React, { useEffect, useRef, useState } from 'react'
import { Dialog, TextField, Button, Container, Typography, Box, DialogTitle, DialogContent, IconButton, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomDialogTitle from '../ProgramList/ProgramDrillDown/components/DialogTitle';
import { RootState } from '../../../redux/store';
import confirm from "../../../components/ConfirmDialog"
import { useStyles } from '../../User/Backpack/style';

interface PropsType {
  isModal: boolean;
  closeModal: () => any;
  sourceFormId: string;
  userId: string;
  contextId: string;
  programId: string;
  year?: number
}
// const useStyles = makeStyles((theme) => ({
//   onboardingSpinnerLoader: {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     position: "fixed",
//     top: 0,
//     left: 0,
//     width: "100vw",
//     height: "100vh",
//     backgroundColor: "rgba(255, 255, 255, 0.8)",
//     zIndex: 9999,
//   },
//   form: {
//     display: "flex",
//     flexDirection: "column",
//     gap: theme.spacing(2),
//     padding: theme.spacing(2),
//   },
//   formSkipWrapper: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//     width: "100%",
//   },
// }));
const ActionItemFormModal = ({ isModal, closeModal, userId }: PropsType) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { formStructure, error, userformData } = useSelector((state: RootState) => state.programFormReducer)
  const [cacheFormStructure, setCacheFormStructure] = useState(formStructure);
  const formRendererRef = useRef<{ handleSubmit: (event: React.FormEvent, data?: string) => void }>(null);
  const formState = useSelector((state: RootState) => state.programFormReducer)
  const loading = useSelector((state: RootState) => state.programFormReducer.loading)
  const [formData, setFormData] = useState({
    type: '',
    name: '',
    lastName: '',
    email: '',
  });

  const skipForm = (e) => {
    let skip = (JSON.stringify({ skipped: true }))
    formRendererRef?.current?.handleSubmit(e, skip);
    //closeModal()
  }

  const closeFormModal = (e) => {
    if (!cacheFormStructure) {
      closeModal()
    }
    else {
      confirm("You have unsaved changes", "There are unsaved changes.You can save your changes,cancel to continue editing,or leave and discard changes.", { formAlert: true }).then((result) => {
        if (result === 'leaveAndDiscard') {
          formRendererRef?.current?.handleSubmit(e, result);
        }
        else {
          formRendererRef?.current?.handleSubmit(e);
        }
        closeModal()
      })
    }

  }
  const requireQuestions = cacheFormStructure ? Object.values(cacheFormStructure?.questions)?.filter((data: any) => data?.required === 'Yes' && data?.hidden !== 'Yes').length > 0 : false;
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //onSubmit(formData);
  };

  return (
    <>
      {loading && isModal ? (
        <div className={classes.onboardingSpinnerLoader}>
          <CircularProgress size={40} />
        </div>
      ) : (
        <Dialog
          open={isModal}
          onClose={closeFormModal}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth
        >
          <CustomDialogTitle onClose={(e) => closeFormModal(e)} style={{display:'flex',justifyContent:'center',alignItem:'center'}}>
            Update User
          </CustomDialogTitle>
          <DialogContent dividers>
            <Container maxWidth="sm">

              <Box component="form" onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", gap: 16 }}>
             
                <TextField
                  label="First Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  required
                  className={classes.inputField}

                />
                <TextField
                  label="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  fullWidth
                  required
                  className={classes.inputField}

                />
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  required
                  className={classes.inputField}

                />
              <FormControl className={classes.formControl}>
              <InputLabel id="type-label">Type</InputLabel>
              <Select
                labelId="type-label"
                name="type"
                value={formData.type}
                onChange={handleChange}
                fullWidth
                required
                className={classes.selectWrapper}
>
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="member">Member</MenuItem>
                <MenuItem value="coach">Coach</MenuItem>
              </Select>
            </FormControl>
                <Button variant="contained" color="primary" type="submit"
                style={{color:'white', background:'black',height:'60px',margin:'20px 0px 20px 0px'}}
                
                >
                  <Typography  style={{color:'white'}}>Update</Typography>
                </Button>
              </Box>
            </Container>
          </DialogContent>
        </Dialog>
      )}
    </>
  );

}

export default ActionItemFormModal