import {
  getChildStateApi,
  getTeamUsersApi,
  saveUserApi,
  getUserApi,
  getDailyUserStateApi,
} from '../../api';
import toastr from 'toastr';
// import { childScheduleAction } from './childSchedule';
import { IAction } from '../../types/generics';
import { IUser } from '../../types/user';
import { logger, format } from '../../datadogLogger';

export const GET_USER_STATE = 'GET_USER_STATE';
export const GET_STATE_LOADING = 'GET_STATE_LOADING';
export const GET_STATE_ERROR = 'GET_STATE_ERROR';
export const TEAM_USER_SAVE = 'TEAM_USER_SAVE';
export const TEAM_USER_GET = 'TEAM_USER_GET';
export const TEAM_USER_ERROR = '_USER_ERROR';
export const SET_USER = 'SET_USER';
export const GET_DAILY_ACTIVITY_SUCCESS = 'GET_DAILY_ACTIVITY_SUCCESS';

export const getState = (userId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(userStateLoading(true));
      const res = await getChildStateApi(userId);
      if (res.success) {
        dispatch(userStateSuccess(res.data));
        // check it
        // dispatch(getTeamUsers(teamId));
        // dispatch(childScheduleAction());
        logger.info(format('Get user state'), res.data);
      } else {
        dispatch(userStateError(res));
      }
    } catch (error) {
      logger.error(format('Failed to get user state'), error);
      dispatch(userStateError(error));
      dispatch(userStateLoading(false));
    }
  };
};

export const getUserInfoAndState = (userId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(userStateLoading(true));
      const user = await getUserApi(userId);
      dispatch(setUser(user.data));
      // const dailyState = await getDailyUserStateApi(userId);
      // if (dailyState.success) {
      //   dispatch({
      //     type: GET_DAILY_ACTIVITY_SUCCESS,
      //     payload: dailyState.data,
      //   });
      // }
      const userState = await getChildStateApi(userId);
      if (userState.success) {
        dispatch(userStateSuccess(userState.data));
      } else {
        toastr.error(userState.message)
        dispatch(userStateError(userState));
      }
    } catch (error) {
      logger.error(format('Failed to get user info and state'), error);
      toastr.error(error.response?.data.message)
      dispatch(userStateError(error));
      dispatch(userStateLoading(false));
    }
  };
};

const setUser = (user: IUser): IAction => ({
  type: SET_USER,
  payload: user,
});

const userStateLoading = (isLoading: boolean): IAction => ({
  type: GET_STATE_LOADING,
  payload: isLoading,
});
export const userStateSuccess = (payload) => ({
  type: GET_USER_STATE,
  payload,
});
export const userStateError = (error: any) => ({
  type: GET_STATE_ERROR,
  payload: error,
});

export const getTeamUsers = (teamId: string) => {
  return async (dispatch: any) => {
    try {
      const res = await getTeamUsersApi(teamId);

      if (res.success) {
        dispatch(teamUserSuccess(res.data));
      } else {
        dispatch(teamUserError(res));
      }
      dispatch(userStateLoading(false));
      logger.info(format('Get team Users'), {});
    } catch (error) {
      logger.error('error while getting team user', error);
      dispatch(teamUserError(error));

      dispatch(userStateLoading(false));
    }
  };
};

const teamUserSuccess = (payload: any) => ({
  type: TEAM_USER_GET,
  payload,
});

const teamUserError = (error: any) => ({
  type: TEAM_USER_ERROR,
  payload: error,
});

export const saveTeamUser = (user: any) => {
  return async (dispatch: any) => {
    try {
      const res = await saveUserApi(user);

      if (res.success) {
        dispatch({ type: TEAM_USER_SAVE, payload: res.data });
      } else {
        dispatch({ type: TEAM_USER_ERROR, payload: res });
      }
      dispatch(userStateLoading(false));
      logger.info(format('Save Team User', res.data));
    } catch (error) {
      logger.error(format('error while saving team user'), error);
      dispatch({ type: TEAM_USER_ERROR, payload: error });
      dispatch(userStateLoading(false));
    }
  };
};
