import { IAction } from '../../types/generics';
import { IProgramFormState } from '../../types/programForm';
import { GET_PROGRAM_CONTEXT_LIST, GET_PROGRAM_CONTEXT_LIST_ERROR, GET_PROGRAM_CONTEXT_LIST_LOADING, GET_PROGRAM_FORM_CONTEXT_DATA, GET_PROGRAM_FORM_CONTEXT_DATA_ERROR, GET_PROGRAM_FORM_CONTEXT_DATA_LOADING, GET_PROGRAM_FORM_STRUCTURE, GET_PROGRAM_FORM_STRUCTURE_ERROR, GET_PROGRAM_FORM_STRUCTURE_LOADING, RESET_PROGRAM_FORM_STRUCTURE, RESET_USER_FORM_DATA } from '../actions/programFormAction';

const initState: IProgramFormState = {
    formStructure: null,
    userformData: null,
    error: null,
    errorProgramData: null,
    loading: false,
    contextListLoading: false,
    contextListError: '',
    contextList: null,
};

export default function (state = initState, action: IAction) {
    switch (action.type) {
        case GET_PROGRAM_FORM_STRUCTURE_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_PROGRAM_FORM_STRUCTURE:
            return {
                ...state,
                formStructure: action.payload.formDefinition.formDefinition || action.payload.formDefinition,
                loading: false,
                error: null,
            };
        case GET_PROGRAM_FORM_STRUCTURE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_PROGRAM_FORM_CONTEXT_DATA_LOADING:
            return {
                ...state,
                loading: true,
                errorProgramData: null,
            };
        case GET_PROGRAM_FORM_CONTEXT_DATA:
            return {
                ...state,
                userformData: action.payload,
                loading: false,
                errorProgramData: null,
            };
        case GET_PROGRAM_FORM_CONTEXT_DATA_ERROR:
            return {
                ...state,
                loading: false,
                errorProgramData: action.payload,
            };
        case GET_PROGRAM_CONTEXT_LIST_LOADING:
            return {
                ...state,
                contextListLoading: true,
                contextListError: null,
            };
        case GET_PROGRAM_CONTEXT_LIST:
            return {
                ...state,
                contextList: action.payload,
                contextListLoading: false,
                contextListError: null,
            };
        case GET_PROGRAM_CONTEXT_LIST_ERROR:
            return {
                ...state,
                contextListLoading: false,
                contextListError: action.payload,
            };
        case RESET_USER_FORM_DATA:
            return {
                ...state,
                userformData: null
            }
        case RESET_PROGRAM_FORM_STRUCTURE:
            return {
                ...state,
                formStructure: null
            }
        default:
            return state;
    }
}
