import React from 'react'
import { Dialog, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import CustomDialogTitle from '../../../screens/Admin/ProgramList/ProgramDrillDown/components/DialogTitle';

interface PropsType {
  isModal: boolean;
  closeModal: () => any;
  title: string;
  description: string;

}
const useStyles = makeStyles((theme) => ({
  
    subTitle: {
        color: '#100F14',
        fontSize: '1.45rem',
        fontWeight: 600,
        textAlign: 'center',
    },
    customDialog: {
      '& .MuiDialog-paperFullWidth': {
        width: 'calc(100% - 64px)',
        height:"300px",
        '@media (max-width: 600px)': {
          height: 'unset',
        },
      },
      '& .makeStyles-textWrapper-67': {
            padding:"150px"
      },
    },
  
    infoTitlePara: {
        fontSize: '1rem',
        color: '#49475A',
        textAlign: 'justify',
        marginBottom: theme.spacing(2),
        marginTop: '20px',
    },
    textWrapper:{
      padding:"35px"
    }
    
  }));
const ActionItemFormModal = ({ isModal, closeModal,title,description }: PropsType) => {
  const classes = useStyles();
  const closeFormModal = (e) => {
    closeModal()
  }
  return (
   <>
   <div className='infoModal'>
    <Dialog
        open={isModal}
        onClose={closeFormModal}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        className={classes.customDialog}
      >
        <CustomDialogTitle onClose={(e) => closeFormModal(e)} > 
          <div className={classes.textWrapper}>
          <Typography className={classes.subTitle}>
                    {title}
            </Typography>
              <Typography className={classes.infoTitlePara}>
                {description}
            </Typography>
            
          </div>
          </CustomDialogTitle>

      </Dialog>
      </div>
   </>
  );

}

export default ActionItemFormModal