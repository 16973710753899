import { FormControl, Grid, NativeSelect, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useStyles } from './style';
import "./style.scss";
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import HeatMapPerformanceTable, { CategoryGroup, CategoryInfo } from './components/table/heatMapTable';
import { dateRangeOptions, staticActiveProgramEnrollment } from '../../constants/data';
import useDateRange from '../../util/useDateRange';
import { useDispatch, useSelector } from 'react-redux';
import { getHeatMapData, getMyGoalsData } from '../../redux/actions/dashboardAction';
import { RootState } from '../../redux/store';

const MonthlyPerformanceCard = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const userId = (localStorage.getItem("user"));
    const { heatMapData, selectedDateRange, myGoalsData } = useSelector((state: RootState) => state.dashboardReducer)
    const {programId} = JSON.parse(localStorage.getItem('activeProgramEnrollment')) || {};  // TODO: Replace with service call

    const {
        dateRange,
        selectedRange,
        customStartDate,
        customEndDate,
        handleRangeChange,
        handleCustomStartDateChange,
        handleCustomEndDateChange,
    } = useDateRange('last-4-weeks');

    useEffect(() => {
        if(programId && userId) {
            dispatch(getMyGoalsData(programId, userId));
        }
    }, [programId, userId, dispatch]);

    useEffect(() => {
        if(dateRange){
            dispatch(getHeatMapData(programId, userId, dateRange.start, dateRange.end))        
        }
    }, [dateRange, programId, userId, dispatch]);

    // Dynamically build categoryGroups from myGoalsData
    const categoryGroups: Record<string, CategoryGroup> = {};
    const categoryInfo: Record<string, CategoryInfo> = {};

    // Process myGoalsData to build the category structures
    if (myGoalsData && myGoalsData.groups) {
        console.log('=== DEBUG: myGoalsData ===', myGoalsData);
        
        // Hardcoded mappings for essential aliases (temporary)
        const essentialAliasMap = {
            "essential1Score": "mind,p1",
            "essential2Score": "body,p2",
            "essential3Score": "soul,p3",
            "essential4Score": "nutrition,p4",
            "essential5Score": "money,p5",
            "essential6Score": "relationships,p6"
        };
        
        myGoalsData.groups.forEach(group => {
            console.log(`=== DEBUG: Processing group "${group.group}" ===`);
            const dimensions: string[] = [];
            const aliases: Record<string, string> = {};
            
            // Get all goalNames and add them as dimensions with "Score" suffix
            // Only add active goals to dimensions
            group.goals.forEach(goal => {
                //console.log(`DEBUG: Processing goal:`, goal);
                
                // Skip inactive goals - don't add them to dimensions
                if (!goal.isActive) {
                    //console.log(`DEBUG: Skipping inactive goal "${goal.goalName}"`);
                    return;
                }
                
                const dimension = `${goal.goalName}Score`;
                dimensions.push(dimension);
                
                // Use hardcoded aliases for essentials, dynamic ones for others
                if (essentialAliasMap[dimension]) {
                    aliases[dimension] = essentialAliasMap[dimension];
                    console.log(`DEBUG: Using hardcoded alias "${essentialAliasMap[dimension]}" for "${dimension}"`);
                } else {
                    // For non-essential goals like rocks, create dynamic aliases
                    const goalType = goal.goalName.replace(/[0-9]/g, '');
                    const goalNumber = goal.goalName.match(/\d+/)?.[0] || '';
                    const alias = `${goal.goalLabel.toLowerCase()},${goalType}${goalNumber}`;
                    aliases[dimension] = alias;
                    console.log(`DEBUG: Created dynamic alias "${alias}" for "${dimension}"`);
                }
                
                // Add corresponding categoryInfo entry
                categoryInfo[dimension] = {
                    label: goal.goalLabel,
                    isActive: goal.isActive
                };
            });
            
            // Only add group if it has active dimensions
            if (dimensions.length > 0) {
                // Add group to categoryGroups
                categoryGroups[group.group] = {
                    label: group.group,
                    dimensions,
                    aliases
                };
                
                console.log(`DEBUG: Added categoryGroup "${group.group}" with ${dimensions.length} active dimensions:`, categoryGroups[group.group]);
            } else {
                console.log(`DEBUG: Group "${group.group}" has no active dimensions, skipping`);
            }
        });
        
        console.log('=== DEBUG: Final categoryGroups ===', categoryGroups);
        console.log('=== DEBUG: Final categoryInfo ===', categoryInfo);
    }

    return (
        <div className='monthly_performance_card'>
            <div className={classes.titleWrapper}>
            <Typography className={classes.header} variant="h6">Monthly Performance</Typography>
            <FormControl className={classes.formControl}>
                <NativeSelect
                className={classes.select}
                value={selectedRange}
                onChange={handleRangeChange}
                placeholder='Select Date Range'
                >
                <option value="" disabled>Select Date Range</option>
                {dateRangeOptions.map((el: any, index: number) => (
                    <option key={index} value={el.value}>{el?.label}</option>
                ))}
                </NativeSelect>
            </FormControl>
            </div>
            <div>
            {selectedRange === 'custom' && (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify='flex-end' spacing={2} direction="row">
                    <Grid item xs={4}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-start"
                        label="Start Date"
                        value={customStartDate ? moment(customStartDate).toDate() : null}
                        onChange={handleCustomStartDateChange}
                        KeyboardButtonProps={{
                        'aria-label': 'change date',
                        }}
                    />
                    </Grid>
                    <Grid item xs={4}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-end"
                        label="End Date"
                        value={customEndDate ? moment(customEndDate).toDate() : null}
                        onChange={handleCustomEndDateChange}
                        KeyboardButtonProps={{
                        'aria-label': 'change date',
                        }}
                    />
                    </Grid>
                </Grid>
                </MuiPickersUtilsProvider>
            )} 
            </div>

            <HeatMapPerformanceTable
            weeklyData={heatMapData}
            isDashboard={true}
            categoryGroups={categoryGroups}
            categoryInfo={categoryInfo}
            />

        </div>
    )
}

export default MonthlyPerformanceCard