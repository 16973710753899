import React from 'react'

const GreenTickIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="15" stroke="#47AF59" stroke-width="2" />
            <path d="M22.1056 10.1001C21.9645 10.1601 20.4255 11.7147 17.8284 14.4168L13.7691 18.6334L11.8966 16.6852C9.90867 14.6303 9.87019 14.5969 9.27381 14.5969C8.93394 14.5969 8.40168 14.9038 8.20289 15.2107C8.00409 15.5243 7.93997 16.1114 8.06181 16.5051C8.12594 16.7119 8.64536 17.2857 10.4602 19.1872C11.7299 20.5149 12.8842 21.6824 13.0188 21.7825C13.3138 21.996 13.8717 22.0627 14.2565 21.936C14.4617 21.8692 15.3915 20.9352 19.0788 17.1055C21.5926 14.4968 23.7216 12.2417 23.805 12.095C24.2154 11.3544 23.9589 10.4937 23.215 10.1001C22.9585 9.96664 22.407 9.96664 22.1056 10.1001Z" fill="#47AF59" />
        </svg>
    )
}

export default GreenTickIcon