import { IChannel, IChatState, IDirectMessage } from "../../types/chat";
import { CANCEL_IS_EDIT_MESSAGE_RC, CREATE_CHANNEL_RC, CREATE_CHANNEL_RC_ERROR, CREATE_CHANNEL_RC_LOADING, DELETE_MESSAGE_RC, GET_AUTOCOMPLETE_USERS_LIST_ERROR_RC, GET_AUTOCOMPLETE_USERS_LIST_RC, GET_CHANNEL_INFO_ERROR_RC, GET_CHANNEL_INFO_RC, GET_JOINED_CHANNELS_LIST_ERROR_RC, GET_JOINED_CHANNELS_LIST_RC, GET_MESSAGES_ERROR_RC, GET_MESSAGES_RC, GET_ROOM_DATA_RC, GET_CHAT_USERS_INFO_ERROR_RC, GET_CHAT_USERS_INFO_RC, GET_USERS_LIST_RC, SEND_MESSAGE_RC, SET_ENTERED_TEXT_RC, SET_IS_EDIT_MESSAGE_RC, SET_MESSAGE_ID_RC, UPDATE_MESSAGE_RC, UPLOAD_FILE_TO_ROOM_RC, GET_USER_PROFILE_INFO_RC, GET_USER_PROFILE_INFO_ERROR_RC, UPDATE_MESSAGE_LIST_SO_RC } from "../actions/chat";

const initialState: IChatState = {
  directMessagesList: [],
  messages: [],
  channels: [],
  autoCompleteUsersList: [],
  chatUserInfo: null,
  userProfileInfo: null,
  chatChannelInfo: null,
  error: "",
  loading: false,
  isEditMessage: false,
  enteredText: "",
  messageId: ""
};

const chatReducer = (state = initialState, action): IChatState => {
  switch (action.type) {
    case GET_USERS_LIST_RC:
      return {
        ...state,
        error: "",
        directMessagesList: action.payload as IDirectMessage[],
      };
    case GET_MESSAGES_RC:
      return {
        ...state,
        error: "",
        messages: action.payload,
      };
    case GET_MESSAGES_ERROR_RC:
      return {
        ...state,
        error: action.payload,
      };
    case SEND_MESSAGE_RC:
      return {
        ...state,
        error: "",
        messages: [...state.messages, action.payload],
      };
    case UPDATE_MESSAGE_RC:
      return {
        ...state,
        messages: state.messages.map((message) =>
          message._id === action.payload._id ? action.payload : message
        ),
        isEditMessage: false,
        enteredText: "",
        messageId: ""
      };
    case DELETE_MESSAGE_RC:
      return {
        ...state,
        messages: state.messages.filter((el) => el._id !== action.payload._id)
      };
    case GET_JOINED_CHANNELS_LIST_RC:
      return {
        ...state,
        channels: action.payload as IChannel[],
      };
    case GET_JOINED_CHANNELS_LIST_ERROR_RC:
      return {
        ...state,
        error: action.payload,
      };
    case CREATE_CHANNEL_RC_LOADING:
      return {
        ...state,
        loading: true,
        error: ""
      };
    case CREATE_CHANNEL_RC:
      return {
        ...state,
        channels: [...state.channels, action.payload],
        loading: false
      };
    case CREATE_CHANNEL_RC_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_AUTOCOMPLETE_USERS_LIST_RC:
      return {
        ...state,
        autoCompleteUsersList: action.payload,
      };
    case GET_AUTOCOMPLETE_USERS_LIST_ERROR_RC:
      return {
        ...state,
        error: action.payload,
      };
    case GET_CHAT_USERS_INFO_RC:
      return {
        ...state,
        chatUserInfo: action.payload,
      };
    case GET_CHAT_USERS_INFO_ERROR_RC:
      return {
        ...state,
        error: action.payload,
      };
    case GET_USER_PROFILE_INFO_RC:
      return {
        ...state,
        userProfileInfo: action.payload,
      };
    case GET_USER_PROFILE_INFO_ERROR_RC:
      return {
        ...state,
        error: action.payload,
      };
    case GET_CHANNEL_INFO_RC:
      return {
        ...state,
        error: "",
        chatChannelInfo: action.payload,
      };
    case GET_CHANNEL_INFO_ERROR_RC:
      return {
        ...state,
        error: action.payload,
      };
    case UPLOAD_FILE_TO_ROOM_RC:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case SET_IS_EDIT_MESSAGE_RC:
      return {
        ...state,
        isEditMessage: action.payload,
      };
    case CANCEL_IS_EDIT_MESSAGE_RC:
      return {
        ...state,
        isEditMessage: action.payload,
        enteredText: "",
        messageId: ""
      };
    case SET_ENTERED_TEXT_RC:
      return {
        ...state,
        enteredText: action.payload,
      };
    case SET_MESSAGE_ID_RC:
      return {
        ...state,
        messageId: action.payload,
      };
      case UPDATE_MESSAGE_LIST_SO_RC: {
        return {
          ...state,
          messages: [...state.messages, action.payload]
        };
      }
    default:
      return state;
  }
};

export default chatReducer
