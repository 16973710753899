import React, { memo, useEffect, useState } from 'react'
import {
    Box,
    Button,
    TextField,
    DialogContent,
    MenuItem,
    Drawer,
    Stepper,
    Step,
    StepLabel,
    Grid,
    Typography,
} from '@material-ui/core';
import CustomDialogTitle from './DialogTitle';
import { useStyles } from '../../style';
import { CameraAlt } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { getAllPrograms, getProgramTeams } from '../../../../../redux/actions/programAction';
import { UserType } from '../../../../../constants/data';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { inviteUserOnProgram, uploadFile } from '../../../../../api';
import toastr from 'toastr';

interface IProgramWizardValue {
    invitation: string,
    teamNameOverride: string,
    name: string,
    lastName: string,
    email: string,
    password: string,
}

const initialValues = {
    invitation: '',
    teamNameOverride: '',
    name: '',
    lastName: '',
    email: '',
    password: '',
}

const ProgramWizardDrawer = ({ open, handleClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { allPrograms } = useSelector((state: RootState) => state.programReducer);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [currentStep, setCurrentStep] = useState(1);
    const roles = [UserType.coach, UserType.client, UserType.teamAdmin, UserType.admin];
    const { teamList } = useSelector((state: RootState) => state.programReducer);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState<IProgramWizardValue>(initialValues);

    const { control, handleSubmit, watch, reset, errors } = useForm({
        defaultValues: initialValues,
    });

    const selectedProgram = watch('invitation');
    const selectedTeam = watch('teamNameOverride');

    useEffect(() => {
        dispatch(getAllPrograms());
    }, [dispatch]);

    useEffect(() => {
        selectedProgram && dispatch(getProgramTeams(selectedProgram));
    }, [selectedProgram]);

    const handleNext = () => {
        const values = control.getValues();
        setFormData(prevData => ({ ...prevData, ...values }));
        if (currentStep < 3) {
            setCurrentStep((prevStep) => prevStep + 1);
        }
    };

    const handleBack = () => {
        if (currentStep > 1) {
            setCurrentStep((prevStep) => prevStep - 1);
        }
    };

    const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            let selectedFile = event.target.files[0];

            const formData = new FormData();
            formData.append('file', selectedFile);

            try {
                const resp: any = await uploadFile(formData);
                if (resp) {
                    setImageUrl(resp?.url)
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };

    const onSubmit = async (data: any) => {
        setLoading(true);

        const payload: any = {
            ...formData, ...data,
            teamNameOverride: formData?.teamNameOverride,
            invitation: 'D2G-test-team',
        };

        try {
            const response = await inviteUserOnProgram(payload);

            console.log(response, "response")

            if (response) {
                handleClose();
                toastr.success('User registered on program successfully');
                reset();
                setImageUrl('');
                setFormData(initialValues)
            }

        } catch (error) {
            toastr.error(error?.response?.data?.message || "An error occurred");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        reset(initialValues);
        setCurrentStep(1);
        setFormData(initialValues);
    }, [open]);

    console.log(control.getValues(), "form values");

    return (
        <Drawer className={classes.drawer} anchor={'right'} open={open} onClose={handleClose}>
            <CustomDialogTitle onClose={() => handleClose()}>Create New Member</CustomDialogTitle>
            <DialogContent dividers>

                <Box style={{ width: '100%' }}>
                    <Stepper className={classes.stepper} activeStep={currentStep} alternativeLabel>
                        {['Select Program', 'Select Team', 'Add Member Details'].map((label, index) => (
                            <Step key={index}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {
                        currentStep === 1 &&
                        <Box key={1} display="flex" flexDirection="column" style={{ gap: 20 }}>
                            <Controller
                                name="invitation"
                                control={control}
                                rules={{
                                    required: 'Program is required.',
                                }}
                                defaultValue=""
                                as={
                                    <TextField
                                        id="invitation"
                                        aria-describedby="outlined-name-helper-text"
                                        inputProps={{
                                            'aria-label': 'name',
                                        }}
                                        select
                                        placeholder="Select Program"
                                        label="Programs"
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.invitation}
                                    >
                                        {allPrograms && allPrograms.length > 0 && allPrograms.map((program) => (
                                            <MenuItem key={program?._id} value={program?._id}>
                                                {program?.programName}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                }
                            />
                            <ErrorMessage
                                as={<Typography color="error" />}
                                errors={errors}
                                name="invitation"
                            />

                            <Button type='button' onClick={handleNext}
                                disabled={!selectedProgram} className={classes.saveButton}>
                                Next
                            </Button>
                        </Box>
                    }

                    {
                        currentStep === 2 &&
                        <Box key={2} display="flex" flexDirection="column" style={{ gap: 20 }}>

                            <Controller
                                name="teamNameOverride"
                                control={control}
                                rules={{
                                    required: 'Team is required.',
                                }}
                                defaultValue=""
                                as={
                                    <TextField
                                        id="teamNameOverride"
                                        aria-describedby="outlined-name-helper-text"
                                        inputProps={{
                                            'aria-label': 'name',
                                        }}
                                        select
                                        placeholder="Select Team"
                                        label="Teams"
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.teamNameOverride}
                                    >
                                        {teamList && teamList.length > 0 && teamList.map((data: any, index: number) => (
                                            <MenuItem key={index} value={data.name}>
                                                {data.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                }
                            />
                            <ErrorMessage
                                as={<Typography color="error" />}
                                errors={errors}
                                name="teamNameOverride"
                            />

                            <Button type='button' className={classes.prevButton} onClick={handleBack}>
                                Back
                            </Button>
                            <Button type='button' className={classes.saveButton} disabled={!selectedTeam} onClick={handleNext}>
                                Next
                            </Button>
                        </Box>
                    }

                    {
                        currentStep === 3 &&
                        <Box display="flex" flexDirection="column" style={{ gap: 20 }}>
                            <Box display="flex" justifyContent="center" mb={2}>
                                <label style={{
                                    width: '100px',
                                    height: '100px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'relative'
                                }}>
                                    {imageUrl ? (
                                        <img src={process.env.REACT_APP_API_URL + '/' + imageUrl} alt="Selected" style={{
                                            width: '100px',
                                            height: '100px',
                                            borderRadius: '50%',
                                            objectFit: 'cover',
                                        }} />
                                    ) : (
                                        <div style={{
                                            width: '100px',
                                            height: '100px',
                                            borderRadius: '50%',
                                            backgroundColor: '#e0e0e0',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            position: 'relative'
                                        }}></div>
                                    )}
                                    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '24px', height: '24px', padding: '3px', background: '#1F2937', color: 'white', borderRadius: '50%', position: 'absolute', bottom: '-10px', right: 5 }}>
                                        <input type="file" hidden accept="image/*" onChange={handleImageChange} />
                                        <CameraAlt fontSize='inherit' />
                                    </span>
                                </label>
                            </Box>

                            <Grid item xs={12}>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: 'First Name is required.',
                                    }}
                                    defaultValue=""
                                    as={
                                        <TextField
                                            id="name"
                                            aria-describedby="outlined-name-helper-text"
                                            inputProps={{
                                                'aria-label': 'name',
                                            }}
                                            placeholder="First Name"
                                            label="First Name"
                                            variant="outlined"
                                            fullWidth
                                            error={!!errors.name}
                                        />
                                    }
                                />
                                <ErrorMessage
                                    as={<Typography color="error" />}
                                    errors={errors}
                                    name="name"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    name="lastName"
                                    control={control}
                                    rules={{
                                        required: 'Last Name is required.',
                                    }}
                                    defaultValue=""
                                    as={
                                        <TextField
                                            id="outlined-name-weight"
                                            aria-describedby="outlined-name-helper-text"
                                            inputProps={{
                                                'aria-label': 'lastName',
                                            }}
                                            placeholder="Last Name"
                                            label="Last Name"
                                            variant="outlined"
                                            fullWidth
                                            error={!!errors.lastName}
                                        />
                                    }
                                />
                                <ErrorMessage
                                    as={<Typography color="error" />}
                                    errors={errors}
                                    name="lastName"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{
                                        required: 'Email is required.',
                                        pattern: {
                                            value: /^\S+@\S+$/i,
                                            message: 'Entered value does not match email format'
                                        }
                                    }}
                                    defaultValue=""
                                    as={
                                        <TextField
                                            id="outlined-name-weight"
                                            aria-describedby="outlined-name-helper-text"
                                            inputProps={{
                                                'aria-label': 'name',
                                            }}
                                            placeholder="Email"
                                            label="Email Address"
                                            variant="outlined"
                                            fullWidth
                                            error={!!errors.email}
                                        />
                                    }
                                />
                                <ErrorMessage
                                    as={<Typography color="error" />}
                                    errors={errors}
                                    name="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="password"
                                    control={control}
                                    rules={{
                                        required: 'Password is required.',
                                    }}
                                    defaultValue=""
                                    as={
                                        <TextField
                                            id="outlined-name-weight"
                                            aria-describedby="outlined-name-helper-text"
                                            inputProps={{
                                                'aria-label': 'name',
                                            }}
                                            type='password'
                                            placeholder="Password"
                                            label="Password"
                                            variant="outlined"
                                            fullWidth
                                            error={!!errors.password}
                                        />
                                    }
                                />
                                <ErrorMessage
                                    as={<Typography color="error" />}
                                    errors={errors}
                                    name="password"
                                />
                            </Grid>
                            <Button type='button' className={classes.prevButton} onClick={handleBack}>
                                Back
                            </Button>
                            <Button disabled={loading} type="submit" className={classes.saveButton}>Save</Button>
                        </Box>
                    }
                </form>
            </DialogContent>
        </Drawer>
    )
}

export default memo(ProgramWizardDrawer);