import { Box, Card, FormControl, Grid, NativeSelect, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { dateRangeOptions, staticActiveProgramEnrollment } from '../../../../../constants/data';
import { useStyles } from '../../style';
import ArrowDownIcon from '../../../../../components/Icons/ArrowDownIcon';
import "../../style.scss";
import { useDispatch, useSelector } from 'react-redux';
import { setActiveTab, setHighLightTab } from '../../../../../redux/actions/myResultAction';
import useDateRange from '../../../../../util/useDateRange';
import { Bar } from 'react-chartjs-2';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { getBarChartData, getHeatMapData, getMyGoalsData } from '../../../../../redux/actions/dashboardAction';
import { RootState } from '../../../../../redux/store';
import HeatMapPerformanceTable, { CategoryGroup, CategoryInfo } from '../../../../../components/MonthlyPerformance/components/table/heatMapTable';

const ProgressCmp = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {programId} = JSON.parse(localStorage.getItem('activeProgramEnrollment')) || {};
    const userId = localStorage.getItem("user");
    const { heatMapData, barChartData, selectedDateRange, myGoalsData } = useSelector((state: RootState) => state.dashboardReducer)
    const { activeTab } = useSelector((state: RootState) => state.myResultReducer);
    const defaultDateRange = selectedDateRange || 'last-8-weeks';
    const {
        dateRange,
        selectedRange,
        customStartDate,
        customEndDate,
        handleRangeChange,
        handleCustomStartDateChange,
        handleCustomEndDateChange,
    } = useDateRange(defaultDateRange);

    const labels = barChartData && barChartData.length > 0 && barChartData?.map((week: any) => `Week ${week?.weekNumber}`);

    const categories = barChartData?.length > 0
        ? barChartData[0]?.categories
        : [];

    const data = {
        labels,
        datasets: categories.map((category, index) => ({
            label: category,
            data: barChartData?.map((week: any) => week.scores[index]),
            backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.5)`,
        })),
    };

    const options = {
        plugins: {
            title: {
                display: true,
                text: 'Weekly Summary Stacked Bar Chart',
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
            legend: {
                position: 'top',
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                beginAtZero: true,
                max: 5,
            },
        },
    };

    const changeActiveTab = (name) => {
        dispatch(setActiveTab(name));
        dispatch(setHighLightTab(null));
    }

    // Fetch myGoalsData when component mounts
    useEffect(() => {
        if(programId && userId) {
            dispatch(getMyGoalsData(programId, userId));
        }
    }, [programId, userId, dispatch]);

    useEffect(() => {
        if (dateRange) {
            if (activeTab === 'bar-chart') {
                dispatch(getBarChartData(programId, userId, dateRange.start, dateRange.end))
                return;
            }
            else if (activeTab === 'heat-map') {
                dispatch(getHeatMapData(programId, userId, dateRange.start, dateRange.end))
                return;
            }
        }
    }, [dateRange, activeTab, programId, userId, dispatch]);

    // Log heatMapData structure to debug
    useEffect(() => {
        if (heatMapData) {
            console.log('=== DEBUG: Progress - heatMapData structure ===', heatMapData);
        }
    }, [heatMapData]);

    // Dynamically build categoryGroups from myGoalsData
    const categoryGroups: Record<string, CategoryGroup> = {};
    const categoryInfo: Record<string, CategoryInfo> = {};

    // Hardcoded mappings for essential aliases (temporary)
    const essentialAliasMap = {
        "essential1Score": "mind,p1",
        "essential2Score": "body,p2",
        "essential3Score": "soul,p3",
        "essential4Score": "nutrition,p4",
        "essential5Score": "money,p5",
        "essential6Score": "relationships,p6"
    };
    
    // Process myGoalsData to build the category structures
    if (myGoalsData && myGoalsData.groups) {
        console.log('=== DEBUG: Progress - myGoalsData ===', myGoalsData);
        
        myGoalsData.groups.forEach(group => {
            console.log(`=== DEBUG: Progress - Processing group "${group.group}" ===`);
            const dimensions: string[] = [];
            const aliases: Record<string, string> = {};
            
            // Get all goalNames and add them as dimensions with "Score" suffix
            // Only add active goals to dimensions
            group.goals.forEach(goal => {
                //console.log(`DEBUG: Progress - Processing goal:`, goal);
                
                // Skip inactive goals - don't add them to dimensions
                if (!goal.isActive) {
                    //console.log(`DEBUG: Progress - Skipping inactive goal "${goal.goalName}"`);
                    return;
                }
                
                const dimension = `${goal.goalName}Score`;
                dimensions.push(dimension);
                
                // Use hardcoded aliases for essentials, dynamic ones for others
                if (essentialAliasMap[dimension]) {
                    aliases[dimension] = essentialAliasMap[dimension];
                    console.log(`DEBUG: Progress - Using hardcoded alias "${essentialAliasMap[dimension]}" for "${dimension}"`);
                } else {
                    // For non-essential goals like rocks, create dynamic aliases
                    const goalType = goal.goalName.replace(/[0-9]/g, '');
                    const goalNumber = goal.goalName.match(/\d+/)?.[0] || '';
                    const alias = `${goal.goalLabel.toLowerCase()},${goalType}${goalNumber}`;
                    aliases[dimension] = alias;
                    console.log(`DEBUG: Progress - Created dynamic alias "${alias}" for "${dimension}"`);
                }
                
                // Add corresponding categoryInfo entry
                categoryInfo[dimension] = {
                    label: goal.goalLabel,
                    group: group.group,
                    info: goal.goalDescription || `This is the info for ${goal.goalLabel}`,
                    isActive: goal.isActive
                };
            });
            
            // Only add group if it has active dimensions
            if (dimensions.length > 0) {
                // Add group to categoryGroups
                categoryGroups[group.group] = {
                    label: group.group,
                    dimensions,
                    aliases
                };
                
                console.log(`DEBUG: Progress - Added categoryGroup "${group.group}" with ${dimensions.length} active dimensions:`, categoryGroups[group.group]);
            } else {
                console.log(`DEBUG: Progress - Group "${group.group}" has no active dimensions, skipping`);
            }
        });
        
        console.log('=== DEBUG: Progress - Final categoryGroups ===', categoryGroups);
        console.log('=== DEBUG: Progress - Final categoryInfo ===', categoryInfo);
    }

    return (
        <div >
            <div className={classes.titleWrapper}>
                <Typography variant="h4" className={classes.typography} gutterBottom>
                    <span>My Progress</span>
                    <ArrowDownIcon />
                </Typography>
            </div>

            <div className={classes.titleWrapper}>
                <FormControl className={`${classes.formControl} formcontrol_select_input`}>
                    <NativeSelect
                        value={defaultDateRange}
                        onChange={handleRangeChange}
                        placeholder='Select Date Range'
                        variant='outlined'
                    >
                        <option value="" disabled>Select Date Range</option>
                        {dateRangeOptions.map((el: any, index: number) => (
                            <option key={index} value={el.value}>{el?.label}</option>
                        ))}
                    </NativeSelect>
                </FormControl>

                <Box display="flex">
                    <span className={`${activeTab === 'heat-map' && classes.activeTab}`} onClick={() => changeActiveTab("heat-map")}>
                        <VerifiedUserIcon className={classes.tabIcons} />
                    </span>
                    <span className={`${activeTab === 'bar-chart' && classes.activeTab}`} onClick={() => changeActiveTab("bar-chart")}>
                        <AssessmentIcon className={classes.tabIcons} />
                    </span>
                </Box>
            </div>
            <div>
                {selectedRange === 'custom' && (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify='flex-end' spacing={2} direction="row">
                            <Grid item xs={4}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-start"
                                    label="Start Date"
                                    value={customStartDate ? moment(customStartDate).toDate() : null}
                                    onChange={handleCustomStartDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-end"
                                    label="End Date"
                                    value={customEndDate ? moment(customEndDate).toDate() : null}
                                    onChange={handleCustomEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                )}
            </div>

            {
                activeTab === "heat-map" && <HeatMapPerformanceTable
                    weeklyData={heatMapData}
                    categoryGroups={categoryGroups}
                    categoryInfo={categoryInfo}
                />
            }

            {
                activeTab === "bar-chart" &&
                <Card className={classes.card}>
                    <Bar
                        data={data}
                        options={options} />
                </Card>
            }


        </div>
    )
}

export default ProgressCmp;