import React, { useState } from 'react';
import { Box, Button, Chip, DialogContent, Drawer, Grid, TextField, Typography } from '@material-ui/core';
import CustomDialogTitle from './DialogTitle';
import { useStyles } from '../../style';
import { Autocomplete } from '@material-ui/lab';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useForm } from 'react-hook-form';
import { inviteMembers } from '../../../../../api';
import toastr from 'toastr';

const CreateInvitationForm = ({ modalOpen, handleClose }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const { control, handleSubmit, errors, reset } = useForm({
        defaultValues: {
            emailList: '',
            invitationCode: '',
            teamNameOverride: '',
            subject: '',
            description: '',
        },
    });

    const handleEmailChange = (e, value) => {
        control.setValue('emailList', value);
    }

    const onSubmit = async (data) => {
        setLoading(true);
        const payload = {
            emailList: data.emailList,
            invitationCode: data.invitationCode,
            teamNameOverride: data.teamNameOverride || null,
            subject: data.subject,
            description: data.description,
        };
        try {
            const res = await inviteMembers(payload);
            toastr.success(res?.message || 'Users invited successfully');
            handleClose();
        } catch (error) {
         toastr.error(error?.response?.data?.message || 'An error occurred');

        } finally {
            setLoading(false);
        }
    };

    return (
        <Drawer className={classes.drawer} anchor={'right'} open={modalOpen} onClose={handleClose}>
            <CustomDialogTitle onClose={handleClose}>Invite Members</CustomDialogTitle>
            <DialogContent dividers>
                <Box display="flex" flexDirection="column" alignItems="center" maxWidth={400} m="auto">
                    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                        <Controller
                            name="emailList"
                            control={control}
                            rules={{ required: 'Emails are required' }}
                            render={({ field }: any) => (
                                <Autocomplete
                                    {...field}
                                    multiple
                                    id="tags-filled"
                                    options={[]}
                                    freeSolo
                                    onChange={handleEmailChange}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip label={option} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Emails"
                                            placeholder="Add Email"
                                            error={!!errors.emailList}
                                        />
                                    )}
                                />
                            )}
                        />
                        <ErrorMessage
                            as={<Typography color="error" />}
                            errors={errors}
                            name="emailList"
                        />

                        <Controller
                            name="invitationCode"
                            control={control}
                            rules={{ required: 'Invitation Code is required' }}
                            as={
                                <TextField
                                    id="outlined-name-weight"
                                    aria-describedby="outlined-name-helper-text"
                                    inputProps={{
                                        'aria-label': 'invitationCode',
                                    }}
                                    label="Invitation Code"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    error={!!errors.invitationCode}
                                />
                            }
                        />
                        <ErrorMessage
                            as={<Typography color="error" />}
                            errors={errors}
                            name="invitationCode"
                        />

                        <Controller
                            name="teamNameOverride"
                            control={control}
                            as={
                                <TextField
                                    id="outlined-name-weight"
                                    aria-describedby="outlined-name-helper-text"
                                    inputProps={{
                                        'aria-label': 'teamNameOverride',
                                    }}
                                    label="Team Name Override"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    error={!!errors.teamNameOverride}
                                />
                            }
                        />

                        <Controller
                            name="subject"
                            control={control}
                            rules={{ required: 'Subject is required' }}
                            as={
                                <TextField
                                    id="outlined-name-weight"
                                    aria-describedby="outlined-name-helper-text"
                                    inputProps={{
                                        'aria-label': 'subject',
                                    }}
                                    label="Subject"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    error={!!errors.subject}
                                />
                            }
                        />
                        <ErrorMessage
                            as={<Typography color="error" />}
                            errors={errors}
                            name="subject"
                        />

                        <Controller
                            name="description"
                            control={control}
                            as={
                                <TextField
                                    id="outlined-name-weight"
                                    aria-describedby="outlined-name-helper-text"
                                    inputProps={{
                                        'aria-label': 'descrption',
                                    }}
                                    label="Description"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    multiline
                                    rows={4}
                                />
                            }
                        />

                        <Button
                            disabled={loading}
                            className={classes.saveButton}
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            style={{ marginTop: '20px' }}
                        >
                            Send Invitation
                        </Button>
                    </form>
                </Box>
            </DialogContent>
        </Drawer>
    );
};

export default React.memo(CreateInvitationForm);
