import { IPolicyRequestState } from "../../types/policyRequest";
import { IAction } from "../../types/generics";
import {
  GET_POLICYREQUESTS_SUCCESS,
  GET_POLICYREQUESTS_FAIL,
  POST_POLICYREQUEST_SUCCESS,
  POST_POLICYREQUEST_FAIL,
  SHOW_REQUEST_SUCCESS_MODAL,
  SET_LOADING
} from "../actions/policyRequestAction";

const initialState: IPolicyRequestState = {
  requests: [],
  request: null,
  success: true,
  loading: false,
  error: '',
  openModal: false,
}

const notificationReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case GET_POLICYREQUESTS_SUCCESS:
      return {
        ...state,
        requests: action.payload,
        status: true,
        error: ''
      };
    case GET_POLICYREQUESTS_FAIL:
      return {
        ...state,
        status: false,
        error: action.payload
      };
    case POST_POLICYREQUEST_SUCCESS:
      return {
        ...state,
        requests: [...state.requests, action.payload],
        request: action.payload,
        status: true,
        openModal: true,
        error: ''
      }
    case POST_POLICYREQUEST_FAIL:
      return {
        ...state,
        status: false,
        error: action.payload
      }
    case SHOW_REQUEST_SUCCESS_MODAL:
      return {
        ...state,
        openModal: action.payload
      }
    default:
      return state;
  }
}

export default notificationReducer;