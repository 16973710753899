import {
  LOGIN_LOADER,
  LOGIN_SUCCESS,
  LOGIN_ERR,
  GET_USER_LOADER,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  CHANGE_STATUS,
} from '../actions/auth';
import { IAuthState, IAuthAction } from '../../types/auth';

const getInitialState = (): IAuthState => {
  const authInfo = JSON.parse(localStorage.getItem('authInfo') || null);
  const token = localStorage.getItem('token');
  return authInfo
    ? {
      loginLoader: true,
      user: authInfo,
      token,
      userStatus: true,
      authError: '',
      getUserLoader: true,
      getUserError: '',
      getUserStatus: 'done',
    }
    : {
      loginLoader: false,
      user: null,
      token: '',
      userStatus: false,
      authError: '',
      getUserLoader: true,
      getUserError: '',
      getUserStatus: 'not Done',
    };
};

export default function (state = getInitialState(), action: IAuthAction) {
  switch (action.type) {
    case LOGIN_LOADER: {
      return {
        ...state,
        loginLoader: action.payload,
      };
    }
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.data,
        token: action.tokens.data.token,
        userStatus: action.payload.success,
      };
    case LOGIN_ERR:
      return {
        ...state,
        authError: action.payload,
      };
    case GET_USER_LOADER:
      return {
        ...state,
        getUserLoader: action.payload,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.data,
        token: action.token,
        userStatus: action.payload.success,
        getUserStatus: 'done',
      };
    case GET_USER_ERROR:
      return {
        ...state,
        getUserError: action.payload,
        getUserStatus: 'not Found',
      };
    case CHANGE_STATUS:
      return {
        ...state,
        getUserStatus: 'not done',
      };
    default:
      return state;
  }
}
