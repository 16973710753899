import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IMessageRc } from '../../../../types/chat';
import { deleteDmMessage, getChatMessages, getRCChannelInfo, getRCUserInfo, setMessageEdit, sendDmMessage, uploadFileToRoom, setEnteredText, cancelMessageEdit, setMessageId, updateDmMessage, updateMessageListSoRc, createRC_DM } from '../../../../redux/actions/chat';
import { RootState } from '../../../../redux/store';
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItemIcon, makeStyles, Menu, MenuItem, MenuList, Paper, TextField, Tooltip, Typography } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import { useHistory, useLocation } from 'react-router-dom';
import { AttachFile, Create, FiberManualRecord, InsertDriveFile, Search } from '@material-ui/icons';
import moment from 'moment';
import UploadFile from './components/modal/uploadFile';
import { useChatStyle } from "./style";
import { messageMap, statusColors } from '../../../../constants/data';
// import InfiniteScroll from "react-infinite-scroll-component";

const Chat = () => {
    const ITEM_HEIGHT = 48;
    const classes = useChatStyle();
    const dispatch = useDispatch();
    const history: any = useHistory();
    const location = useLocation<any>();
    const { userId } = location.state || {};
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('roomId');
    const roomType = searchParams.get('type');
    const [selectedFile, setSelectedFile] = useState(null);
    const [file, setFile] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [offset, setOffset] = useState<number>(0);
    const { messages, chatUserInfo, chatChannelInfo, isEditMessage, enteredText, messageId, error } = useSelector((state: RootState) => state.chatReducer);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const ref = useRef(null);
    const ws = useRef<WebSocket | null>(null);
    const heartbeatInterval = useRef(null);
    const rcUserId = localStorage.getItem("rcUserId");
    const rcToken = localStorage.getItem("rcToken");
    const count = 50;

    const handleClick = (event: React.MouseEvent<HTMLElement>, messageId: string) => {
        setAnchorEl(event.currentTarget);
        dispatch(setMessageId(messageId))
    };

    const sendHeartbeat = () => {
        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
            ws.current.send(JSON.stringify({ msg: 'ping' }));
        }
    };

    const handleClose = () => {
        setAnchorEl(null)
    };

    const handleEdit = (message: IMessageRc) => {
        let messageText = message.msg || '';

        if (message.attachments && message.attachments.length > 0) {
            const attachmentsDescription = message.attachments.map(att => att.description || '').join('\n');
            messageText += `\n${attachmentsDescription}`;
        }

        dispatch(setMessageEdit());
        dispatch(setEnteredText(messageText));
        handleClose();
    }

    const handleSendMessage = () => {
        if (isEditMessage && messageId) {
            const payload = {
                roomId: id,
                msgId: messageId,
                text: enteredText.trim()
            };
            dispatch(updateDmMessage(payload));
        }
        else {
            const payload = {
                rid: id,
                msg: enteredText.trim()
            };
            dispatch(sendDmMessage(payload));
        }

        // const payload = {
        //     msg: 'method',
        //     method: 'sendMessage',
        //     id: '423',
        //     params: [
        //         {
        //             _id: Date.now().toString(), 
        //             rid: id, 
        //             msg: enteredText.trim()
        //         }
        //     ]
        // };

        // if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        //     ws.current.send(JSON.stringify(payload));
        // } else {
        //     console.error("WebSocket is not open.");
        // }

        dispatch(setEnteredText(""));
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    const deleteMessage = (msgId: string) => {
        dispatch(deleteDmMessage(id, msgId))
        handleClose();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(URL.createObjectURL(file));
            setFile(file);
            setIsModalOpen(true);
        }
    };

    const handleChangeModal = () => {
        setIsModalOpen(!isModalOpen);
    }

    const fetchMoreMessages = async () => {
        try {
            if (id) {
                const result = await dispatch(getChatMessages(id, roomType, offset, count));
                if (result.length < count) {
                    setHasMore(false); 
                } else {
                    setOffset(prevOffset => prevOffset + count);
                }
            }
        } catch (error) {
            console.error("Error fetching more messages:", error);
        }
    };

    const handleNavigateToRoom = async (username: string, userId: string) => {
        try {
            const response: any = await dispatch(createRC_DM(username));
            console.log("response", response)
            const roomId = response?.rid;

            if (roomId) {
                history.push({
                    pathname: '/chat',
                    search: `?roomId=${roomId}&type=direct`,
                    state: {
                        userId: userId
                    }
                });
            } else {
                console.error("Failed to get DM room details.");
            }
        } catch (error) {
            console.error("Error creating DM session.");
        }
    };

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollIntoView({ behaviour: 'smooth' });
        }
    }, [messages]);

    useEffect(() => {
        let intervalId;

        const fetchInitialMessages = async () => {
            if (id) {
                await dispatch(getChatMessages(id, roomType));
                intervalId = setInterval(() => {
                    dispatch(getChatMessages(id, roomType));
                }, 10000);
            }
        };

        fetchInitialMessages();

        if (id && roomType === "direct") {
            dispatch(getRCUserInfo(userId));
        }
        if (id && roomType === "channel") {
            dispatch(getRCChannelInfo(id))
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [id, roomType, dispatch]);

    const subscribeToRoom = (roomId) => {
        const subMsg = JSON.stringify({
            "msg": "sub",
            "id": Date.now().toString(),
            "name": "stream-room-messages",
            "params": [roomId, false]
        });
        ws.current.send(subMsg);
    }

    const connectWebSocket = () => {
        ws.current = new WebSocket("ws://localhost:4000/websocket");

        ws.current.onopen = () => {
            console.log('WebSocket connected');

            ws.current?.send(JSON.stringify({
                msg: "connect",
                version: "1",
                support: ["1", "pre2", "pre1"]
            }));

            ws.current?.send(JSON.stringify({
                msg: "method",
                method: "login",
                id: "42",
                params: [{ "resume": rcToken }]
            }));

            if (id) {

                subscribeToRoom(id);

                ws.current?.send(JSON.stringify({
                    msg: "sub",
                    id: Date.now().toString(),
                    name: "stream-notify-room",
                    params: [`${id}/deleteMessage`, false]
                }));
            }

            heartbeatInterval.current = setInterval(sendHeartbeat, 30000);
        };

        ws.current.onmessage = (event) => {
            const data = JSON.parse(event.data);

            if (data.msg === 'changed' && data.collection === 'stream-room-messages') {
                const newMessage = data.fields.args[0];
                console.log("newMessage", newMessage);
                let isExistMessage = messages?.some(message => message._id === newMessage._id);
                if (!isExistMessage) {
                    dispatch(updateMessageListSoRc(newMessage));
                }
            }

            if (data.msg === 'changed' && data.collection === 'stream-notify-room') {
                const deletedMessage = data.fields.args[0];
                console.log('Message deleted:', deletedMessage);
                // dispatch(removeMessageFromList(deletedMessage._id));
            }

            if (data.msg === 'ping') {
                ws.current.send(JSON.stringify({ msg: 'pong' }));
            }
        };

        ws.current.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        return () => {
            ws.current.onclose = () => {
                console.log('WebSocket connection closed. Attempting to reconnect...');
                clearInterval(heartbeatInterval.current);
                setTimeout(connectWebSocket, 5000);
            };
        }

    };

    useEffect(() => {
        connectWebSocket();

        return () => {
            if (ws.current) {
                ws.current.close();
            }
            clearInterval(heartbeatInterval.current);
        };
    }, [id, roomType]);

    if (error && (error === 'error-room-not-found' || error === 'invalid-channel')) {
        return <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow={1}>
            <Search />
            <Typography variant="h6">Room not found</Typography>
            <Typography variant="body2">
                The room does not exist or you may not have access permission.
            </Typography>
        </Box>
    }

    return (
        <>
            <Paper elevation={3} className={classes.chatWrapper}>
                {
                    (chatUserInfo || chatChannelInfo) && <Box
                        display="flex"
                        alignItems="center"
                        borderBottom="1px solid lightgray"
                        paddingBottom="10px"
                    >
                        <Avatar
                            style={{ width: '45px', height: '45px' }}
                            alt={chatUserInfo?.username || chatChannelInfo?.name}
                            src={
                                roomType === 'direct'
                                    ? `https://apiexplorer.support.rocket.chat/avatar/${chatUserInfo?.username}`
                                    : `https://apiexplorer.support.rocket.chat/avatar/room/${chatChannelInfo?._id}`
                            }
                        />
                        <Box marginLeft="1rem" display="flex" alignItems="center">
                            {
                                roomType === 'direct' &&
                                <FiberManualRecord
                                    style={{
                                        color: statusColors[chatUserInfo?.status || 'offline'],
                                        fontSize: '16px',
                                        marginRight: '5px'
                                    }}
                                />
                            }
                            <Typography variant="h6">
                                {roomType === 'direct'
                                    ? chatUserInfo?.username
                                    : `# ${chatChannelInfo?.name}`}
                            </Typography>
                        </Box>
                    </Box>
                }

                <Box overflow="auto" flexGrow={1} marginY="1rem">
                {/* <InfiniteScroll
                dataLength={messages.length}
                next={fetchMoreMessages}  
                hasMore={hasMore}         
                inverse={true}        
                loader={<h4>Loading more messages...</h4>}
                scrollableTarget="scrollableDiv"      
            > */}
                    {messages?.map((message: IMessageRc) => {
                        const isSystemMessage = !message?.msg && !message?.attachments;
                        const messageContent = message?.msg;

                        const systemMessageType = message.t;

                        const systemMessage =
                            systemMessageType && messageMap[systemMessageType]
                                ? messageMap[systemMessageType](message.u.username)
                                : null;

                        return (
                            <Box
                                key={message._id}
                                display="flex"
                                alignItems="center"
                                marginBottom="1rem"
                            >
                                <Avatar
                                    style={{ width: '35px', height: '35px' }}
                                    alt={message.u.username}
                                    src={`https://apiexplorer.support.rocket.chat/avatar/${message.u.username}`}
                                />
                                <Box marginLeft="1rem" flexGrow={1}>
                                    <Box display="flex" alignItems="center">
                                        <Typography variant="subtitle1" onClick={() => handleNavigateToRoom(message.u.username, message.u._id)}
                                            style={{ fontWeight: 'bold', fontSize: "14px", cursor: "pointer" }}>
                                            {message.u.username}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                            style={{ marginLeft: '10px' }}
                                        >
                                            {moment(message.ts).format("LT")}
                                        </Typography>
                                        {
                                            message.editedAt && message.editedBy &&
                                            <Tooltip title={`Message has beed edited at ${message.editedAt}`}>
                                                <Create style={{ width: "20px", height: "20px", marginLeft: "10px" }} />
                                            </Tooltip>
                                        }

                                    </Box>
                                    {systemMessage ? (
                                        <Typography variant="body2" style={{ wordBreak: "break-all", fontSize: "14px", fontStyle: 'italic' }}>
                                            {systemMessage}
                                        </Typography>
                                    ) : (
                                        // Render regular message content
                                        messageContent && (
                                            <Typography variant="body1" style={{ wordBreak: "break-all", fontSize: "14px" }}>
                                                {messageContent}
                                            </Typography>
                                        )
                                    )}

                                    {message.attachments && renderAttachments(message.attachments)}
                                </Box>
                                {!isSystemMessage && message?.u?._id === rcUserId && (
                                    <>
                                        <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            onClick={(event) => handleClick(event, message._id)}
                                        >
                                            <MoreVert />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={open && messageId === message._id}
                                            onClose={handleClose}
                                            PaperProps={{
                                                style: {
                                                    maxHeight: ITEM_HEIGHT * 4.5,
                                                    width: '15ch',
                                                },
                                            }}
                                        >
                                            <MenuList>
                                                <MenuItem button onClick={() => handleEdit(message)}>
                                                    <ListItemIcon>
                                                        <CreateIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <Typography variant="inherit">Edit</Typography>
                                                </MenuItem>
                                                <MenuItem button onClick={() => {
                                                    deleteMessage(message._id)
                                                }}>
                                                    <ListItemIcon>
                                                        <DeleteIcon color="error" fontSize="small" />
                                                    </ListItemIcon>
                                                    <Typography color="error" variant="inherit">Delete</Typography>
                                                </MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </>
                                )}
                            </Box>
                        );
                    })}
                    {/* </InfiniteScroll> */}
                    <div ref={ref} />
                </Box>
                <Box display="flex" alignItems="center" padding="10px 10px 0" borderTop="1px solid lightgray">
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Message"
                        value={enteredText}
                        onChange={(e) => dispatch(setEnteredText(e.target.value))}
                        onKeyDown={handleKeyDown}
                        multiline
                        rowsMax={4}
                    />
                    <Tooltip title="Upload file">
                        <IconButton component="label">
                            <AttachFile />
                            <input
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </IconButton>
                    </Tooltip>
                    {isEditMessage &&
                        <Button
                            variant="contained"
                            onClick={() => dispatch(cancelMessageEdit())}
                            style={{ marginLeft: '10px' }}
                        >
                            Cancel
                        </Button>}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSendMessage}
                        style={{ marginLeft: '10px' }}
                        disabled={!enteredText}
                    >
                        Send
                    </Button>
                </Box>

            </Paper>

            <UploadFile
                roomId={id}
                isModalOpen={isModalOpen}
                handleChangeModal={handleChangeModal}
                selectedImg={selectedFile}
                file={file}
                setFile={setFile}
                setSelectedImg={setSelectedFile}
            />
        </>
    );
};

export default Chat;

const getFileUrl = (fileId) => {
    return `https://apiexplorer.support.rocket.chat${fileId}`;
};

const renderAttachments = (attachments) => {
    return attachments.map((attachment, index) => {
        const { title, image_url, image_dimensions, video_url, audio_url, type, description } = attachment;

        if (image_url) {
            return (
                <>
                    <Typography variant="body1" style={{ marginTop: '5px', wordBreak: "break-all" }}>
                        {description}
                    </Typography>

                    <img
                        key={index}
                        src={getFileUrl(image_url)}
                        alt={description || title}
                        style={{ maxWidth: '100%', borderRadius: '8px', marginTop: '10px' }}
                        width={image_dimensions?.width}
                        height={image_dimensions?.height}
                    />
                </>
            );
        }

        if (video_url) {
            return (
                <>
                    <Typography variant="body1" style={{ marginTop: '5px', wordBreak: "break-all" }}>
                        {description}
                    </Typography>
                    <video
                        key={index}
                        controls
                        src={getFileUrl(video_url)}
                        style={{ maxWidth: '100%', borderRadius: '8px', marginTop: '10px' }}
                    >
                        Your browser does not support the video tag.
                    </video>
                </>

            );
        }

        if (audio_url) {
            return (
                <>
                    <Typography variant="body1" style={{ marginTop: '5px', wordBreak: "break-all" }}>
                        {description}
                    </Typography>
                    <audio
                        key={index}
                        controls
                        src={getFileUrl(audio_url)}
                        style={{ width: '100%', marginTop: '10px' }}
                    >
                        Your browser does not support the audio element.
                    </audio>
                </>
            );
        }

        if (type === 'file') {
            return (
                <>
                    <Typography variant="body1" style={{ marginTop: '5px', wordBreak: "break-all" }}>
                        {description}
                    </Typography>
                    <a
                        key={index}
                        href={getFileUrl(attachment.title_link)}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ display: 'block', marginTop: '10px', textDecoration: 'none' }}
                    >
                        <Button variant="outlined" startIcon={<InsertDriveFile />}>
                            {title}
                        </Button>
                    </a>
                </>
            );
        }

        return null;
    });
};

