import React from 'react'

const GreenDotIcon = () => {
    return (
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4.5" cy="4.69531" r="4" fill="#558B2F" />
        </svg>
    )
}

export default GreenDotIcon