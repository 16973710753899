import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#E6E8F3',
      paper: 'white',
    },
    primary: {
      main: '#343C6A',
      light: '#9B51E0',
      dark: '#2C2F3A',
    },
    secondary: {
      main: '#00239D',
      light: 'white',
    },
    info: {
      main: '#49B8CC',
    },
    warning: {
      main: '#F2C94C',
    },
    error: {
      main: '#E35555',
    },
    text: {
      primary: '#2C2F3A',
      secondary: 'rgba(44, 47, 58, 0.75)',
    },
  },
  typography: {
    fontFamily: 'Nunito Sans',
    fontSize: 16,
    allVariants: {
      color: '#2C2F3A',
    },
    h2: {
      fontSize: 22,
      fontWeight: 'bold',
    },
    h3: {
      fontSize: 20,
    },
    h4: {
      fontSize: 20,
      lineHeight: '24px',
      fontWeight: 'normal'
    },
    button: {
      fontSize: 12,
    },
    body1: {
      fontSize: 18,
      color: 'black',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '0.75rem',
      lineHeight: 1.57,
      fontWeight: 500,
      letterSpacing: '0.08em',
      textTransform: 'uppercase',
      color: 'rgba(44, 47, 58, 0.75)',
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: '15px'
    }
  },
});

export default theme;
