import React from "react";
import Sidebar from "./Sidebar";
import "./style.css";
import Chat from "./Chat";
import Header from "./Header";
import ButtonAppBar from "../../../components/AppBar";
import HeaderNotificationIcon from "../../../components/HeaderNotification";
import { useHistory } from "react-router";

function MeosChat() {
    const history = useHistory();
    return (
        <>
            <ButtonAppBar title={"Message"} back={() => history.push('user/dashboard')} rightIcon={<HeaderNotificationIcon />} />
            <div className="chat-container">
                <Sidebar />
                <Chat />
            </div>
        </>
    )
};
export default MeosChat;