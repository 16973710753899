import React from 'react'

const ShieldIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 9.69528V11.6953M12 15.6953H12.01M20.618 6.67928C17.4561 6.8472 14.3567 5.75389 12 3.63928C9.64327 5.75389 6.5439 6.8472 3.382 6.67928C3.12754 7.6644 2.99918 8.67783 3 9.69528C3 15.2863 6.824 19.9853 12 21.3173C17.176 19.9853 21 15.2873 21 9.69528C21 8.65328 20.867 7.64328 20.618 6.67928Z" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  )
}

export default ShieldIcon