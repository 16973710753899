import { getProgramEnrollmentUserApi, getProgramEnrollmentHealthCheckApi } from "../../api";
export const GET_USER_PROGRAM_ENROLLMENT = 'GET_USER_PROGRAM_ENROLLMENT';
export const GET_USER_PROGRAM_ENROLLMENT_ERROR = 'GET_USER_PROGRAM_ENROLLMENT_ERROR';
export const GET_USER_PROGRAM_ENROLLMENT_LOADING = 'GET_USER_PROGRAM_ENROLLMENT_LOADING';

export const GET_USER_PROGRAM_ENROLLMENT_HEALTH_CHECK = 'GET_USER_PROGRAM_ENROLLMENT_HEALTH_CHECK';
export const GET_USER_PROGRAM_ENROLLMENT_HEALTH_CHECK_ERROR = 'GET_USER_PROGRAM_ENROLLMENT_HEALTH_CHECK_ERROR';
export const GET_USER_PROGRAM_ENROLLMENT_HEALTH_CHECK_LOADING = 'GET_USER_PROGRAM_ENROLLMENT_HEALTH_CHECK_LOADING';
export const SHOW_HEALTH_CHECK_MODAL = 'SHOW_HEALTH_CHECK_MODAL';
export const HIDE_HEALTH_CHECK_MODAL = 'HIDE_HEALTH_CHECK_MODAL';

export const SET_ACTIVE_PHASE_NUMBER = "SET_ACTIVE_PHASE_NUMBER";
export const CLOSE_USER_PROGRAM_ENROLLMENT_LOADING = "CLOSE_USER_PROGRAM_ENROLLMENT_LOADING";
export const PROGRAM_INFO_POPUP_OPENED='PROGRAM_INFO_POPUP_OPENED'

export const setProgramInfoPopup=()=>({
    type:PROGRAM_INFO_POPUP_OPENED
})

export const closeEnrollmentLoading = () => ({
    type: CLOSE_USER_PROGRAM_ENROLLMENT_LOADING
})

export const getUserProgramEnrollmentHealthCheck = (programId: string, userId: string) => {
    return async (dispatch: any) => {
        try {
            //toastr.info('Health Check', 'Checking for issues in your program enrollment. Please wait...');
            
            const res: any = await getProgramEnrollmentHealthCheckApi(programId, userId);
            
            //toastr.info('Health Check', res);
            //debugger;
            // Assuming the API response includes an 'issues' field with an array of issues.
            if (res && res.issues && res.issues.length > 0) {
                // Dispatch the action to show the modal with the issues.
                dispatch(showHealthCheckModal(res.issues));
            } else {
                // No issues, proceed with the normal success action.
                dispatch(getUserProgramEnrollmentHealthCheckSuccess(res));
            }
            // Return the response so it can be captured by the caller
            return res;
        } catch (error) {
            if (error.response) {
                dispatch(getUserProgramEnrollmentHealthCheckError(error.response.data.message || 'An error occurred'));
                return error.response.data;
            }
            dispatch(getUserProgramEnrollmentHealthCheckError(error.message || 'An error occurred'));
            return error;
        }
    };
};


const getUserProgramEnrollmentHealthCheckSuccess = (payload) => ({
    type: GET_USER_PROGRAM_ENROLLMENT_HEALTH_CHECK,
    payload,
});

const getUserProgramEnrollmentHealthCheckError = (error: any) => ({
    type: GET_USER_PROGRAM_ENROLLMENT_HEALTH_CHECK_ERROR,
    payload: error,
});



export const getUserProgramEnrollmentData = (programId: string, userId: string) => {
    return async (dispatch: any) => {
        dispatch(getUserProgramEnrollmentLoading());
        try {
            const res: any = await getProgramEnrollmentUserApi(programId, userId);
            dispatch(getUserProgramEnrollmentSuccess(res));
        } catch (error) {
            if (error.response) {
                dispatch(getUserProgramEnrollmentError(error.response.data.message || 'An error occurred'));
                return;
            }
            dispatch(getUserProgramEnrollmentError(error.message || 'An error occurred'));
        }
    };
};

export const getUserProgramEnrollmentLoading = () => ({
    type: GET_USER_PROGRAM_ENROLLMENT_LOADING,
});

const getUserProgramEnrollmentSuccess = (payload) => ({
    type: GET_USER_PROGRAM_ENROLLMENT,
    payload,
});

const getUserProgramEnrollmentError = (error: any) => ({
    type: GET_USER_PROGRAM_ENROLLMENT_ERROR,
    payload: error,
});




export const setActivePhaseNumber = (payload) => ({
    type: SET_ACTIVE_PHASE_NUMBER,
    payload
})


export const showHealthCheckModal = (issues: any) => ({
    type: SHOW_HEALTH_CHECK_MODAL,
    payload: issues,
});

export const hideHealthCheckModal = () => ({
    type: HIDE_HEALTH_CHECK_MODAL, payload: false
});
