import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import List from '@material-ui/core/List';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';
import toastr from 'toastr';
import { ListItem, ListItemText, makeStyles, Theme, Divider, ListItemIcon, Checkbox, DialogActions, Button, Box, TextField } from '@material-ui/core';

import TimeDurationPicker from '../../../../components/DurationPicker';
import { RootState } from '../../../../redux/store';
import { CATEGORY_OPTIONS } from '../../../../constants/data';
import { requestTimeExtension } from '../../../../redux/actions/policyRequestAction';


const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100%',
    borderRadius: '20px 20px 0px 0px',
    margin: 0,
    boxShadow: ' 0px 0px 4px rgba(0, 0, 0, 0.25)',
    height: '100%',
  },
  scrollPaper: {
    alignItems: 'flex-end',
  },
  title: {
    padding: '16px 16px 8px 16px',
    fontWeight: `bold`,
  },
  actions: {
    position: 'absolute',
    bottom: 10,
    width: '100%',
    boxSizing: 'border-box',
    justifyContent: 'center'
  },
  actionButton: {
    textTransform: 'none',
    flex: 1,
  }
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
  handleClose: () => void,
  isOpen: boolean,
}

export function ExtraTimeRequest(props: IProps) {
  const authUser = useSelector((state: RootState) => state.auth.user);
  const classes = useStyles();
  const { handleClose, isOpen } = props;
  const [checked, setChecked] = useState('');
  const [amount, setAmount] = useState(30);
  const [reason, setReason] = useState('');
  const dispatch = useDispatch();

  const handleSend = (category: string) => {
    if (!category) {
      toastr.warning('You have to select a category you want.');
      return;
    }
    dispatch(requestTimeExtension({ reason, category, minutes: amount, requestedBy: authUser._id }));
    handleClose();
  }

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  }

  const handleDailyDurationChange = (duration) => {
    const { hours, minutes } = duration;
    setAmount(hours * 60 + minutes);
  };

  return (
    <Dialog
      classes={{ paper: classes.paper, scrollPaper: classes.scrollPaper }}
      transitionDuration={600}
      TransitionComponent={Transition}
      onClose={() => handleClose()}
      aria-labelledby="extra-time-request-titles"
      open={isOpen}
    >
      <DialogTitle id="extra-time-request-title" className={classes.title}>
        {`Extra Time Request`}
      </DialogTitle>
      <Divider />
      <List>
        <ListItem divider>
          <ListItemText>For which activity would you like extra time?</ListItemText>
        </ListItem>
        {CATEGORY_OPTIONS.map((category) => (
          <ListItem key={category.value} dense button divider onClick={() => setChecked(category.value)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={checked === category.value}
                tabIndex={-1}
                disableRipple
                color='primary'
                inputProps={{ 'aria-labelledby': `checkbox-list-label-${category.value}` }}
              />
            </ListItemIcon>
            <ListItemText id={`checkbox-list-label-${category.value}`} primary={category.label} />
          </ListItem>
        ))}
        {/* <ListItem dense button divider onClick={() => setChecked('daily')}>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={checked === 'daily'}
              tabIndex={-1}
              disableRipple
              color='primary'
              inputProps={{ 'aria-labelledby': `checkbox-list-label-daily` }}
            />
          </ListItemIcon>
          <ListItemText id={`checkbox-list-label-daily`} primary='Daily Limit' />
        </ListItem> */}
      </List>
      <Box
        m={2}
        display="flex"
        justifyContent="center"
        border={1}
        borderColor="rgba(46, 13, 82, 0.25)"
        borderRadius={5}
      >
        <TimeDurationPicker
          onChange={handleDailyDurationChange}
          initialDuration={{
            hours: 0,
            minutes: 30,
            seconds: 0,
          }}
          maxHours={23}
          maxMins={59}
        />
      </Box>
      <Box
        m={2}
      >
        <TextField
          fullWidth
          id="outlined-multiline-static"
          label="Reason"
          multiline
          rows={2}
          value={reason}
          onChange={handleReasonChange}
          variant="outlined"
        />
      </Box>
      <DialogActions classes={{ root: classes.actions }}>
        <Button autoFocus onClick={handleClose} className={classes.actionButton} size='large' color="primary" variant='outlined'>
          Cancel
        </Button>
        <Button onClick={() => handleSend(checked)} className={classes.actionButton} size='large' color="primary" variant='contained'>
          Send
        </Button>
      </DialogActions>
    </Dialog >
  );
}

export default ExtraTimeRequest
