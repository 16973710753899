import * as React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import Navigation from './navigation/index';
import theme from './theme';
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from './redux/store';

function App() {
  const { store, persistor } = configureStore();
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Navigation />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
