import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../constants/colors';

export default () => {
  return makeStyles((theme) => ({
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      color: "#343C6A",
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      fontSize: 22,
      paddingLeft: 30
    },
    appBar: {
      backgroundColor: '#ffff',
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarNav: {
      paddingLeft: theme.spacing(2),
    },
    expandIcon: {
      padding: 5,
    },
    icons: { color: colors.appColor },
    acdDeviceButton: {
      padding: '8px 16px',
      borderRadius: 20,
      marginRight: 6,
      marginBottom: 4,
      borderColor: '#727792',
      color: '#454859',

      '&.active': {
        color: 'white',
        backgroundColor: '#727792',
      },
    },
    badge: {
      backgroundColor: theme.palette.primary.light,
      border: '2px solid white',
      minWidth: 16,
      padding: 0,
      height: 16,
      right: 12,
      bottom: 8,
    },
    avatarTab: {
      padding: 6,
      paddingTop: 0,
      paddingBottom: 3,

      '&.active': {
        borderBottom: `5px solid ${theme.palette.primary.main}`,
      },
    },
    bellIconNotification:{
      borderRadius: "50%",
      width: "5px",
      height: "5px",
      padding: "8px",
      background: "#9B51E0",
      color: "#fff",
      textAlign: "center",
      font: "15px Arial, sans-serif",
      position: "absolute",
      top: "0px",
      right: "0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "inherit",
  }
  }));
};
