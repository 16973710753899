import { Typography } from '@material-ui/core'
import React from 'react'
import BookIcon from '../../../../../components/Icons/BookIcon'
import { useStyles } from '../../style'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux/store'
import InfoIcon from '../../../../../components/Icons/InfoIcon'

const UserWelcomeDiv = () => {
    const classes = useStyles();
    const user = useSelector((state: RootState) => state.auth.user);
    return (
        <>
            <Typography variant="h6" align="center" className={classes.welcomeTitle}>
                <BookIcon />
                <span className={classes.profileName}>Welcome, {`${user.name} ${user.lastName}`}! </span>
                <InfoIcon />
            </Typography>
            <Typography variant="body1" align="center" color="textSecondary">
                Let's get started on your course. Complete the steps below to finish this phase of the program.
            </Typography>
        </>
    )
}

export default UserWelcomeDiv