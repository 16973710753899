import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import { useStyles } from './style';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';

import { repairContexts, repairFormStructures, regenerateReports } from '../../api';

export interface IProgramEnrollmentHealthIssue {
  type: 'required-form-info' | 'context-repair-needed' | 'report-calculation-needed' | 'data-errors' | 'form-structure-repair-needed' | 'regenerate-reports';
  severity: 'critical' | 'error' | 'warning' | 'info';
  label: string;
  nextAction: { infoText: string; actionType: 'navigate' | 'form' | 'none' | 'other' | 'show'; data?: any };
}

interface MyEnrollmentHealthCheckModalProps {
  issues: IProgramEnrollmentHealthIssue[];
  onClose: () => void;
  autoRunRepairs?: boolean;
}

const MyEnrollmentHealthCheckModal: React.FC<MyEnrollmentHealthCheckModalProps> = ({ issues, onClose, autoRunRepairs = false }) => {
  const classes = useStyles();
  
  // Track repair status for each issue by index:
  const [repairStatuses, setRepairStatuses] = useState<{ [key: number]: 'idle' | 'loading' | 'success' }>({});

  const programEnrollment = JSON.parse(localStorage.getItem('activeProgramEnrollment')) || {};
  const programId = programEnrollment?.programId;
  const userId = programEnrollment?.userId;

  // Function to initialize repair statuses based on issues
  const initializeRepairStatuses = () => {
    const newStatuses: { [key: number]: 'idle' | 'loading' | 'success' } = {};
    issues.forEach((_, index) => {
      newStatuses[index] = 'idle'; // Initialize all statuses to "idle"
    });
    setRepairStatuses(newStatuses);
  };

  // Initialize repair statuses when issues change
  useEffect(() => {
    if (issues && issues.length > 0) {
      initializeRepairStatuses();
    }
  }, [issues]);

  // Auto-run repairs if flag is true
  useEffect(() => {
    if (autoRunRepairs && issues.length > 0) {
      const runRepairs = async () => {
        for (let index = 0; index < issues.length; index++) {
          // Pause for 2 seconds before each repair starts
          await new Promise(resolve => setTimeout(resolve, 2000));

          // Start repair based on issue type
          if (issues[index].type === 'context-repair-needed') {
            await handleRepairContexts(index);
          } else if (issues[index].type === 'form-structure-repair-needed') {
            await handleRepairFormStructures(index);
          } else if (issues[index].type === 'regenerate-reports') {
            await handleRegenerateReports(index);
          }
        }
        onClose(); // Close modal after all repairs are complete
      };

      runRepairs();
    }
  }, [autoRunRepairs, issues, onClose]);

  const handleRepairContexts = async (index: number) => {
    setRepairStatuses(prev => ({ ...prev, [index]: 'loading' }));
    try {
      await repairContexts(programId, userId);
      setRepairStatuses(prev => ({ ...prev, [index]: 'success' }));
    } catch (error) {
      setRepairStatuses(prev => ({ ...prev, [index]: 'idle' }));
    }
  };

  const handleRepairFormStructures = async (index: number) => {
    setRepairStatuses(prev => ({ ...prev, [index]: 'loading' }));
    try {
      await repairFormStructures(programId, userId);
      setRepairStatuses(prev => ({ ...prev, [index]: 'success' }));
    } catch (error) {
      setRepairStatuses(prev => ({ ...prev, [index]: 'idle' }));
    }
  };

  const handleRegenerateReports = async (index: number) => {
    setRepairStatuses(prev => ({ ...prev, [index]: 'loading' }));
    try {
      await regenerateReports(programId, userId);
      setRepairStatuses(prev => ({ ...prev, [index]: 'success' }));
    } catch (error) {
      setRepairStatuses(prev => ({ ...prev, [index]: 'idle' }));
    }
  };

  // Early return if there are no issues
  if (!issues || issues.length === 0) {
    return null;
  }

  return (
    <Dialog open={true} onClose={onClose} classes={{ paper: classes.dialogPaper }}>
      <DialogTitle>
        Program Enrollment Health Issues
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {issues.length > 0 ? (
          <List>
            {issues.map((issue, index) => (
              <ListItem key={index} className={classes.issueItem}>
                <ListItemText
                  primary={issue.label}
                  secondary={`Type: ${issue.type}, Severity: ${issue.severity}. Next Action: ${issue.nextAction.infoText}`}
                />
                {issue.type === 'context-repair-needed' && (
                  <Button
                    onClick={() => handleRepairContexts(index)}
                    disabled={repairStatuses[index] === 'loading' || repairStatuses[index] === 'success'}
                    variant="contained"
                    color="primary"
                  >
                    {repairStatuses[index] === 'success' ? (
                      <CheckIcon style={{ color: 'green' }} />
                    ) : repairStatuses[index] === 'loading' ? (
                      'Repairing...'
                    ) : (
                      'Repair'
                    )}
                  </Button>
                )}
                {issue.type === 'form-structure-repair-needed' && (
                  <Button
                    onClick={() => handleRepairFormStructures(index)}
                    disabled={repairStatuses[index] === 'loading' || repairStatuses[index] === 'success'}
                    variant="contained"
                    color="primary"
                  >
                    {repairStatuses[index] === 'success' ? (
                      <CheckIcon style={{ color: 'green' }} />
                    ) : repairStatuses[index] === 'loading' ? (
                      'Repairing...'
                    ) : (
                      'Repair'
                    )}
                  </Button>
                )}
                {issue.type === 'regenerate-reports' && (
                  <Button
                    onClick={() => handleRegenerateReports(index)}
                    disabled={repairStatuses[index] === 'loading' || repairStatuses[index] === 'success'}
                    variant="contained"
                    color="primary"
                  >
                    {repairStatuses[index] === 'success' ? (
                      <CheckIcon style={{ color: 'green' }} />
                    ) : repairStatuses[index] === 'loading' ? (
                      'Repairing...'
                    ) : (
                      'Repair'
                    )}
                  </Button>
                )}
              </ListItem>
            ))}
          </List>
        ) : (
          <div>No issues found.</div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MyEnrollmentHealthCheckModal;
