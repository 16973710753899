import React, { useEffect } from 'react';
import { Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from '../redux/store';
import { UserType } from '../constants/data';

interface ProtectedRouteProps {
  component: React.ComponentType<any>;
  userStatus: boolean;
  userRole?: string;
  requiredRole?: string | string[];
  exact?: boolean;
  activePhaseNumber: number;
  path: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  userStatus,
  userRole,
  requiredRole,
  activePhaseNumber,
  ...rest
}) => {

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    const onBoardingStep = JSON.parse(localStorage.getItem("onBoardingStep"));

    if (pathname.startsWith('/user') && activePhaseNumber !== 3) {
      history.push(`/onboarding/phase/${activePhaseNumber}`);
      return;
    }

    // if (pathname.startsWith('/admin') && userStatus && requiredRole !== UserType.admin && activePhaseNumber !== 3) {
    //   history.push(`/onboarding/phase/${activePhaseNumber}`);
    //   return;
    // }

    if (activePhaseNumber === 3  && (pathname === '/onboarding/phase/1') ){
      history.push('/user/dashboard');
      return;
    }

    if (activePhaseNumber === 3 && onBoardingStep !== 2 && (pathname === '/onboarding/phase/2') ){
      history.push('/user/dashboard');
      return;
    }

    if (activePhaseNumber === 3 && !onBoardingStep) {
      history.push('/user/dashboard');
      return;
    }

  }, [activePhaseNumber, history]);

  return <Route
    {...rest}
    render={(props) =>
      userStatus ? (
        requiredRole ? (
          Array.isArray(requiredRole) ? (
            requiredRole.includes(userRole) ? (
              <Component {...props} />
            ) : (
              <Redirect to="/login" />
            )
          ) : (
            userRole === requiredRole ? (
              <Component {...props} />
            ) : (
              <Redirect to="/login" />
            )
          )
        ) : (
          <Component {...props} />
        )
      ) : (
        <Redirect to="/login" />
      )
    }
  />
};

const mapStateToProps = (state: RootState) => ({
  userStatus: state.auth.userStatus,
  userRole: state.auth.user?.type,
  activePhaseNumber: state.programEnrollmentReducer.activePhaseNumber,
});

export default connect(mapStateToProps)(ProtectedRoute);