import { archieveTeamApi, getAllProgramsApi, getProgramAnnouncementsApi, getProgramApi, getProgramFormListApi, getProgramMyActionItemsApi, getProgramMyBackpackApi, getProgramTeamsApi, getProgramTeamUsersApi, getUserApi, saveTeamsApi, updateFormInProgramPhase } from '../../api';
import { logger, format} from '../../datadogLogger';
import { ITeam } from '../../types/team';
import toastr from 'toastr';

export const GET_PROGRAM_FORMS_LIST = 'GET_PROGRAM_FORMS_LIST';
export const GET_PROGRAM_FORMS_LIST_ERROR = 'GET_PROGRAM_FORMS_LIST_ERROR';
export const GET_PROGRAM_FORMS_LIST_LOADING = 'GET_PROGRAM_FORMS_LIST_LOADING';
export const GET_PROGRAM_BACKPACK_DATA = 'GET_PROGRAM_BACKPACK_DATA';
export const GET_PROGRAM_BACKPACK_DATA_ERROR = 'GET_PROGRAM_BACKPACK_DATA_ERROR';
export const GET_PROGRAM_BACKPACK_DATA_LOADING = 'GET_PROGRAM_BACKPACK_DATA_LOADING';
export const GET_PROGRAM_ACTION_ITEMS = 'GET_PROGRAM_ACTION_ITEMS';
export const GET_PROGRAM_ACTION_ITEMS_ERROR = 'GET_PROGRAM_ACTION_ITEMS_ERROR';
export const GET_PROGRAM_ACTION_ITEMS_LOADING = 'GET_PROGRAM_ACTION_ITEMS_LOADING';
export const GET_PROGRAM_ANNOUNCEMENTS = 'GET_PROGRAM_ANNOUNCEMENTS';
export const GET_PROGRAM_ANNOUNCEMENTS_ERROR = 'GET_PROGRAM_ANNOUNCEMENTS_ERROR';
export const GET_PROGRAM_ANNOUNCEMENTS_LOADING = 'GET_PROGRAM_ANNOUNCEMENTS_LOADING';
export const SET_PROGRAM_FORM_CONTEXT = 'SET_PROGRAM_FORM_CONTEXT';
export const RESET_PROGRAM_FORM_CONTEXT = 'RESET_PROGRAM_FORM_CONTEXT';
export const GET_PROGRAM_FORM_CONTEXT_USER_INFO = 'GET_PROGRAM_FORM_CONTEXT_USER_INFO';
export const GET_PROGRAM_FORM_CONTEXT_USER_INFO_LOADING = 'GET_PROGRAM_FORM_CONTEXT_USER_INFO_LOADING';
export const GET_PROGRAM_FORM_CONTEXT_USER_INFO_ERROR = 'GET_PROGRAM_FORM_CONTEXT_USER_INFO_ERROR';
export const GET_ALL_PROGRAMS = 'GET_ALL_PROGRAMS';
export const GET_ALL_PROGRAMS_ERROR = 'GET_ALL_PROGRAMS_ERROR';
export const GET_ALL_PROGRAMS_LOADING = 'GET_ALL_PROGRAMS_LOADING';
export const GET_SINGLE_PROGRAM = 'GET_SINGLE_PROGRAM';
export const GET_SINGLE_PROGRAM_ERROR = 'GET_SINGLE_PROGRAM_ERROR';
export const GET_SINGLE_PROGRAM_LOADING = 'GET_SINGLE_PROGRAM_LOADING';
export const UPDATE_PROGRAM_FORM = 'UPDATE_PROGRAM_FORM';
export const UPDATE_PROGRAM_FORM_LOADING = 'UPDATE_PROGRAM_FORM_LOADING';
export const UPDATE_PROGRAM_FORM_ERROR = 'UPDATE_PROGRAM_FORM_ERROR';
export const GET_PROGRAM_TEAMS = 'GET_PROGRAM_TEAMS';
export const GET_PROGRAM_TEAMS_LOADING = 'GET_PROGRAM_TEAMS_LOADING';
export const GET_PROGRAM_TEAMS_ERROR = 'GET_PROGRAM_TEAMS_ERROR';
export const REMOVE_TEAM = 'REMOVE_TEAM';
export const REMOVE_TEAM_LOADING = 'REMOVE_TEAM_LOADING';
export const REMOVE_TEAM_ERROR = 'REMOVE_TEAM_ERROR';
export const SAVE_TEAM = "SAVE_TEAM";
export const SAVE_TEAM_ERROR = "SAVE_TEAM_ERROR";
export const SAVE_TEAM_LOADER = "SAVE_TEAM_LOADER";
export const GET_PROGRAM_TEAM_USERS = 'GET_PROGRAM_TEAM_USERS';
export const GET_PROGRAM_TEAM_USERS_LOADING = 'GET_PROGRAM_TEAM_USERS_LOADING';
export const GET_PROGRAM_TEAM_USERS_ERROR = 'GET_PROGRAM_TEAM_USERS_ERROR';

export const getProgramFormList = (programId) => {
  return async (dispatch: any) => {
    dispatch(programFormLoading());

    try {
      const res: any = await getProgramFormListApi(programId);
      if (res) {
        dispatch(programFormSuccess(res.forms));
      } else {
        dispatch(programFormError(res.message || 'An error occurred'));
      }
    } catch (error) {
      dispatch(programFormError(error.message || 'An error occurred'));
    }
  };
};

export const getAllPrograms = () => {
  return async (dispatch: any) => {
    dispatch(getAllProgramsLoading());
    try {
      const res: any = await getAllProgramsApi();
      if (res) {
        dispatch(getAllProgramsSuccess(res));
      } else {
        dispatch(getAllProgramsError(res.message || 'An error occurred'));
      }
    } catch (error) {
      dispatch(getAllProgramsError(error.message || 'An error occurred'));
    }
  };
};

export const getProgramById = (programId: string) => {
  return async (dispatch: any) => {
    dispatch(getProgramLoading());
    try {
      const res: any = await getProgramApi(programId);
      if (res) {
        dispatch(getProgramSuccess(res));
      } else {
        dispatch(getProgramError(res.message || 'An error occurred'));
      }
    } catch (error) {
      dispatch(getProgramError(error.message || 'An error occurred'));
    }
  };
};

export const getProgramMyBackpackData = (programId: string, userId: string) => {
  return async (dispatch: any) => {
    dispatch(getProgramBackpackLoading());
    try {
      const res: any = await getProgramMyBackpackApi(programId, userId);
      dispatch(getProgramBackpackSuccess(res?.backpack));
    } catch (error) {
      if (error?.response) {
        dispatch(getProgramBackpackError(error.response?.data?.message || 'An error occurred'));
        return;
      }
      dispatch(getProgramBackpackError(error.message || 'An error occurred'));
    }
  };
};

export const getProgramActionItemsData = (programId: string, userId: string) => {
  return async (dispatch: any) => {
    dispatch(getProgramActionItemsLoading());
    try {
      const res: any = await getProgramMyActionItemsApi(programId, userId);
      dispatch(getProgramActionItemsSuccess(res));
    } catch (error) {
      if (error?.response) {
        dispatch(getProgramActionItemsError(error.response?.data?.message || 'An error occurred'));
        return;
      }
      dispatch(getProgramActionItemsError(error.message || 'An error occurred'));
    }
  };
};

export const getProgramAnnouncementData = (programId: string, userId: string) => {
  return async (dispatch: any) => {
    dispatch(getProgramAnnouncementLoading());
    try {
      const res: any = await getProgramAnnouncementsApi(programId, userId);
      dispatch(getProgramAnnouncementSuccess(res));
    } catch (error) {
      if (error?.response) {
        dispatch(getProgramAnnouncementError(error.response?.data?.message || 'An error occurred'));
        return;
      }
      dispatch(getProgramAnnouncementError(error.message || 'An error occurred'));
    }
  };
};

export const getProgramFormContextUserData = (userId) => {
  return async (dispatch: any) => {
    try {

      if (userId != null) {
        const res = await getUserApi(userId);
        dispatch(getProgramContextUserSuccess(res));
      } else {
        dispatch(getProgramContextUserError('No user Found'));
      }
      dispatch(getProgramContextUserLoading(false));
    } catch (error) {
      dispatch(getProgramContextUserLoading(false));
      dispatch(getProgramContextUserError(error));
    }
  };
};

export const updateFormInProgram = (programId, data) => {
  return async (dispatch) => {
    dispatch(updateProgramFormLoading());
    try {
      const res: any = await updateFormInProgramPhase(programId, data);
      if (res) {
        dispatch(updateProgramFormSuccess(res));
      } else {
        dispatch(updateProgramFormError('Update failed'));
      }
    } catch (error) {
      if (error.response) {
        dispatch(updateProgramFormError(error.response.data.message || 'An error occurred'));
      } else {
        dispatch(updateProgramFormError(error.message || 'An error occurred'));
      }
    }
  };
};

export const getProgramTeams = (programId: string) => {
  return async (dispatch: any) => {
    dispatch(getProgramTeamLoading());
    try {
      const res: any = await getProgramTeamsApi(programId);
      dispatch(getProgramTeamSuccess(res));
    } catch (error) {
      if (error?.response) {
        dispatch(getProgramTeamError(error.response?.data?.message || 'An error occurred'));
        return;
      }
      dispatch(getProgramTeamError(error.message || 'An error occurred'));
    }
  };
};

export const removeTeam = (teamId: string) => {
  return async (dispatch: any) => {
    dispatch(removeTeamLoading());
    try {
      const res = await archieveTeamApi(teamId);
      dispatch(removeTeamSuccess(teamId));
    } catch (error) {
      logger.error('Error removing team:', error);
      toastr.error(error?.response?.data?.message || 'Failed to Archieve team')
      dispatch(removeTeamError(error.message));
    }
  }
};

export const saveTeams = (team: ITeam) => {
  return async (dispatch: any) => {
    dispatch(setSaveTeamLoading(true));

    try {
      const res = await saveTeamsApi(team);

      if (res.success) {
        dispatch(setSaveTeamSuccess(res.data));
        toastr.success("Team updated successfully");
      } else {
        dispatch(setSaveTeamError(res));
        toastr.error("Unable to update team");
      }
    } catch (error) {
      logger.error(format(`Failed to save team=${team._id}`), error);
      dispatch(setSaveTeamError(error));
      toastr.error("An error occurred while updating the profile");
    } finally {
      dispatch(setSaveTeamLoading(false));
    }
  };
};

export const getProgramTeamUsers = (programId: string, teamId: string) => {
  return async (dispatch: any) => {
    dispatch(getProgramTeamUserLoading());
    try {
      const res: any = await getProgramTeamUsersApi(programId, teamId);
      if(res?.success){
        dispatch(getProgramTeamUserSuccess(res?.data));
      }
    } catch (error) {
      if (error?.response) {
        dispatch(getProgramTeamUserError(error.response?.data?.message || 'An error occurred'));
        return;
      }
      dispatch(getProgramTeamUserError(error.message || 'An error occurred'));
    }
  };
};

const getProgramTeamLoading = () => ({
  type: GET_PROGRAM_TEAMS_LOADING,
});

const getProgramTeamSuccess = (payload) => ({
  type: GET_PROGRAM_TEAMS,
  payload,
});

const getProgramTeamError = (error) => ({
  type: GET_PROGRAM_TEAMS_ERROR,
  payload: error,
});

const getProgramTeamUserLoading = () => ({
  type: GET_PROGRAM_TEAM_USERS_LOADING,
});

const getProgramTeamUserSuccess = (payload) => ({
  type: GET_PROGRAM_TEAM_USERS,
  payload,
});

const getProgramTeamUserError = (error) => ({
  type: GET_PROGRAM_TEAM_USERS_ERROR,
  payload: error,
});

const updateProgramFormLoading = () => ({
  type: UPDATE_PROGRAM_FORM_LOADING,
});

const updateProgramFormSuccess = (payload) => ({
  type: UPDATE_PROGRAM_FORM,
  payload,
});

const updateProgramFormError = (error) => ({
  type: UPDATE_PROGRAM_FORM_ERROR,
  payload: error,
});

const getProgramLoading = () => ({
  type: GET_SINGLE_PROGRAM_LOADING,
});

const getProgramError = (error: any) => ({
  type: GET_SINGLE_PROGRAM_ERROR,
  payload: error,
});

const getProgramSuccess = (payload) => ({
  type: GET_SINGLE_PROGRAM,
  payload,
});

const getAllProgramsLoading = () => ({
  type: GET_ALL_PROGRAMS_LOADING,
});

const getAllProgramsSuccess = (payload) => ({
  type: GET_ALL_PROGRAMS,
  payload,
});

const getAllProgramsError = (error: any) => ({
  type: GET_ALL_PROGRAMS_ERROR,
  payload: error,
});

const getProgramContextUserLoading = (isLoading: boolean) => ({
  type: GET_PROGRAM_FORM_CONTEXT_USER_INFO_LOADING,
  payload: isLoading
});

const getProgramContextUserSuccess = (payload) => ({
  type: GET_PROGRAM_FORM_CONTEXT_USER_INFO,
  payload,
});

const getProgramContextUserError = (error: any) => ({
  type: GET_PROGRAM_FORM_CONTEXT_USER_INFO_ERROR,
  payload: error,
});

export const setProgramFormContext = (payload) => ({
  type: SET_PROGRAM_FORM_CONTEXT,
  payload
});

export const resetProgramFormContext = () => ({
  type: RESET_PROGRAM_FORM_CONTEXT,
});

const getProgramAnnouncementLoading = () => ({
  type: GET_PROGRAM_ANNOUNCEMENTS_LOADING,
});

const getProgramAnnouncementSuccess = (payload) => ({
  type: GET_PROGRAM_ANNOUNCEMENTS,
  payload,
});

const getProgramAnnouncementError = (error: any) => ({
  type: GET_PROGRAM_ANNOUNCEMENTS_ERROR,
  payload: error,
});

const getProgramActionItemsLoading = () => ({
  type: GET_PROGRAM_ACTION_ITEMS_LOADING,
});

const getProgramActionItemsSuccess = (payload) => ({
  type: GET_PROGRAM_ACTION_ITEMS,
  payload,
});

const getProgramActionItemsError = (error: any) => ({
  type: GET_PROGRAM_ACTION_ITEMS_ERROR,
  payload: error,
});

const getProgramBackpackLoading = () => ({
  type: GET_PROGRAM_BACKPACK_DATA_LOADING,
});

const getProgramBackpackSuccess = (payload) => ({
  type: GET_PROGRAM_BACKPACK_DATA,
  payload,
});

const getProgramBackpackError = (error: any) => ({
  type: GET_PROGRAM_BACKPACK_DATA_ERROR,
  payload: error,
});

const programFormLoading = () => ({
  type: GET_PROGRAM_FORMS_LIST_LOADING,
});

const programFormSuccess = (payload) => ({
  type: GET_PROGRAM_FORMS_LIST,
  payload,
});

const programFormError = (error: any) => ({
  type: GET_PROGRAM_FORMS_LIST_ERROR,
  payload: error,
});

const setSaveTeamLoading = (isLoading: boolean) => ({
  type: SAVE_TEAM_LOADER,
  payload: isLoading,
});

const setSaveTeamSuccess = (data: any) => ({
  type: SAVE_TEAM,
  payload: data,
});

const setSaveTeamError = (error: any) => ({
  type: SAVE_TEAM_ERROR,
  payload: error,
});

export const removeTeamLoading = () => ({ type: REMOVE_TEAM_LOADING });
export const removeTeamSuccess = (teamId: string) => ({ type: REMOVE_TEAM, payload: teamId });
export const removeTeamError = (error: string) => ({ type: REMOVE_TEAM_ERROR, payload: error });
