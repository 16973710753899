import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from '../reducers/index';
import { IAuthState } from '../../types/auth';
import { IDeviceState } from '../../types/device';
import { IDrawerState } from '../../types/drawer';
import { ITeamState } from '../../types/team';
//import { IPolicyState } from '../../types/policy';
//import { IChildState } from '../../types/child';
import { INavigationHistory } from '../../types/generics';
import { INotificationState } from '../../types/notification';
import { IPolicyRequestState } from '../../types/policyRequest';
import { IAppChatState, IChatState } from '../../types/chat';
import { IProgramState } from '../../types/program';
import { IProgramFormState } from '../../types/programForm';
import { IDashboardState } from '../../types/dashboard';
import { IProgramEnrollmentState } from '../../types/programEnrollment';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
export interface RootState {
  drawerReducer: IDrawerState;
  auth: IAuthState;
  teamReducer: ITeamState;
  //devicesReducer: IDeviceState;
  //childReducer: IChildState;
  //policyReducer: IPolicyState;
  //defaultDownTimeReducer: any;
  navigationHistoryReducer: INavigationHistory;
  notificationReducer: INotificationState;
  //policyRequests: IPolicyRequestState;
  chatReducer: IChatState;
  appChatReducer : IAppChatState;
  programReducer: IProgramState;
  programFormReducer: IProgramFormState;
  dashboardReducer: IDashboardState;
  myResultReducer: any;
  programEnrollmentReducer: IProgramEnrollmentState
}

const persistConfig = {
  key: 'root',
  storage,
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer(persistConfig, reducers)

export default () => {
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk))
  );
  const persistor = persistStore(store);
  return { store, persistor };
};