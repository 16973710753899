import { IAction } from '../../types/generics';
import { IProgramEnrollmentState } from '../../types/programEnrollment';
import { 
    GET_USER_PROGRAM_ENROLLMENT, 
    GET_USER_PROGRAM_ENROLLMENT_ERROR, 
    GET_USER_PROGRAM_ENROLLMENT_LOADING, 
    SET_ACTIVE_PHASE_NUMBER, 
    CLOSE_USER_PROGRAM_ENROLLMENT_LOADING,  
    GET_USER_PROGRAM_ENROLLMENT_HEALTH_CHECK, 
    GET_USER_PROGRAM_ENROLLMENT_HEALTH_CHECK_ERROR, 
    GET_USER_PROGRAM_ENROLLMENT_HEALTH_CHECK_LOADING,
    SHOW_HEALTH_CHECK_MODAL,
    HIDE_HEALTH_CHECK_MODAL
} from '../actions/programEnrollmentAction';

const initState: IProgramEnrollmentState = {
    enrollment: null,
    loading: false,
    error: null,
    activePhaseNumber: null,
    healthCheckData: null,
    healthCheckIssues: [],
    showHealthCheckModal: false
};


export default function (state = initState, action: IAction) {
    switch (action.type) {
        // User Program Enrollment Actions
        case GET_USER_PROGRAM_ENROLLMENT_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_USER_PROGRAM_ENROLLMENT:
            const response = action.payload;
            return {
                ...state,
                enrollment: response,
                activePhaseNumber: response?.status?.activeProgramPhase?.phaseNumber,
                loading: false,
                error: null,
            };
        case GET_USER_PROGRAM_ENROLLMENT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        //Enrollment Related

        case SET_ACTIVE_PHASE_NUMBER:
            console.log(">>>>>>>>>>>>", action.payload);
            return {
                ...state,
                activePhaseNumber: action.payload,
            };
        case CLOSE_USER_PROGRAM_ENROLLMENT_LOADING:
            return {
                ...state,
                loading: false,
            };

        // User Program Enrollment Health Check Actions
        case GET_USER_PROGRAM_ENROLLMENT_HEALTH_CHECK_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_USER_PROGRAM_ENROLLMENT_HEALTH_CHECK:
            return {
                ...state,
                healthCheckData: action.payload,                    
                loading: false,
                error: null,
            };
        case GET_USER_PROGRAM_ENROLLMENT_HEALTH_CHECK_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case SHOW_HEALTH_CHECK_MODAL:
                return {
                    ...state,
                    healthCheckIssues: action.payload, // add a field to store issues
                    showHealthCheckModal: true,         // flag to show the modal
                    loading: false,
                    error: null,
                };
        
        case HIDE_HEALTH_CHECK_MODAL:
            return {
                ...state,
                showHealthCheckModal: false, // flag to hide the modal
            };  
        
        
        default:
            return state;
    }
}
