import { Card as MuiCard, Box, Button, CssBaseline, Typography, Grid, Card, CardContent } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import OnBoardingPhase1Icon from '../../../../components/Icons/OnBoardingPhase1Icon';
import OnBoardingPhase3Icon from '../../../../components/Icons/OnBoardingPhase3Icon';
import OnBoardingPhase2Icon from '../../../../components/Icons/OnBoardingPhase2Icon';
import { useStyles } from '../style';

export default function OnBoarding() {
    const classes = useStyles();
    const history = useHistory();
    
    const steps = [
        {
            id: 1,
            icon: <OnBoardingPhase1Icon />,
            title: 'Step 1',
            description: 'Develop Your Purpose',
        },
        {
            id: 2,
            icon: <OnBoardingPhase2Icon />,
            title: 'Step 2',
            description: 'Define Long Term Goals',
        },
        {
            id: 3,
            icon: <OnBoardingPhase3Icon />,
            title: 'Step 3',
            description: 'Establish Balance & Focus',
        }
    ]

    return (
        <>
            <CssBaseline />
            <div className={classes.container}>
                <MuiCard className={classes.card}>
                    <Typography variant="h1" className={classes.title}>
                        Logo
                    </Typography>
                    <Typography className={classes.subTitle}>
                        Welcome to Driven to Guided
                    </Typography>
                    <Typography className={classes.titlePara}>
                        Welcome! We’re here to help you get started on your journey. Follow the steps to complete your profile, set your goals, and make the most of this platform.
                    </Typography>

                    <Grid container spacing={3}>
                        {steps.map((step) => (
                            <Grid item xs={12} sm={6} md={4} key={step.id}>
                                <Card className={classes.stepCard}>
                                    <CardContent>
                                        <Box>
                                            {step.icon}
                                        </Box>
                                        <Typography variant="h6" className={classes.stepTitle}>
                                            {step.title}
                                        </Typography>
                                        <Typography variant="body2" className={classes.stepDescription}>
                                            {step.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    <Box display={"flex"} justifyContent={"center"}>
                        <Button
                            type="submit"
                            fullWidth
                            size="small"
                            className={classes.submitBtn}
                            onClick={() => history.push(`/onboarding/phase/1`)}
                        >
                            Get Started
                        </Button>
                    </Box>
                </MuiCard>
            </div>
        </>
    );
}
