import React, { useEffect, useRef, useState } from 'react';
import { Typography, Box, FormControl, Card, Avatar, CardContent, NativeSelect } from '@material-ui/core';
import moment from 'moment';
import { useStyles } from './style';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import ArrowDownIcon from '../../../../../components/Icons/ArrowDownIcon';

const FeedbackView = () => {
    const classes = useStyles();
    const feedbackRefs = useRef([]);
    const { highLightTab, activeTab } = useSelector((state: RootState) => state.myResultReducer);
    const { heatMapData, barChartData} = useSelector((state: RootState) => state.dashboardReducer)
    const [selectedType, setSelectedType] = useState('myFeedback');
    let data = activeTab === 'heat-map' ? heatMapData : barChartData;

    const handleFeedBackChange = (e) => {
        setSelectedType(e.target.value);
    }

    const feedbackTypes = {
        myFeedback: 'My Feedback',
        teamFeedback: 'Team Feedback',
        coachFeedback: 'Coach Feedback',
        myPlan: 'My Plan',
    };

    const isHighLightCard = (index: number) => {
        return highLightTab === index ? classes.highLightCard : ''
    }

    useEffect(() => {
        if (highLightTab !== null && feedbackRefs.current[highLightTab]) {
            feedbackRefs.current[highLightTab].scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [highLightTab]);

    return (
        <>
            <div className={classes.titleWrapper}>
                <Typography variant="h4" className={classes.typography} gutterBottom>
                    <span>My Feedback</span>
                    <ArrowDownIcon />
                </Typography>
                <div>
                    <FormControl className={`${classes.formControl} formcontrol_select_input`}>
                        <NativeSelect
                            placeholder='Select Feedback'
                            variant='outlined'
                            value={selectedType}
                            onChange={handleFeedBackChange}
                        >
                            <option selected disabled>Select Feedback</option>
                            {Object.entries(feedbackTypes).map(([key, value]) => (
                                <option key={key} value={key}>
                                    {value}
                                </option>
                            ))}
                        </NativeSelect>
                    </FormControl>
                </div>
            </div>
            <Card className={classes.card}>
                {data && data.length > 0 && data.map((el, feedBackIndex) => (
                    <div ref={el => feedbackRefs.current[feedBackIndex] = el} key={feedBackIndex} className={`${classes.cardContainer} ${isHighLightCard(feedBackIndex)}`}>
                        <Avatar className={classes.avatar}></Avatar>
                        <CardContent className={classes.content}>
                            <span className={classes.feedbackTitle}>
                                <strong>Week {el?.weekNumber} Feedback</strong> <span className={classes.date}>{moment(el?.start).format("dddd, MMM D")}</span>
                            </span>
                            <Typography className={classes.feedbackText}>

                                {selectedType === "myFeedback" && <span>{el.myFeedback}</span>}
                                {selectedType === "teamFeedback" && <span>{el.teamFeedback}</span>}
                                {selectedType === "coachFeedback" && <span>{el.coachFeedback}</span>}
                                {selectedType === "myPlan" && <span>{el.myPlan}</span>}

                            </Typography>
                        </CardContent>
                    </div>
                ))}
                {heatMapData && heatMapData?.length === 0 &&
                    <Box display="flex" height="100" alignItems={'center'} justifyContent={"center"}> <Typography style={{ textAlign: "center" }} className={classes.feedbackTitle}>
                        No Feedback Found
                    </Typography></Box>
                }
            </Card>
        </>
    );
};

export default FeedbackView;
