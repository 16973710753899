import toastr from 'toastr';
import {
  requestTimeExtensionApi,
  getTimeExtensionsApi, approveTimeExtensionApi
} from "../../api";
import { IPolicyRequest } from "../../types/policyRequest";

export const GET_POLICYREQUESTS_SUCCESS = 'GET_POLICYREQUESTS_SUCCESS';
export const GET_POLICYREQUESTS_FAIL = 'GET_POLICYREQUESTS_FAIL';
export const POST_POLICYREQUEST_SUCCESS = 'UPDATE_POLICYREQUEST_STATE_SUCCESS';
export const POST_POLICYREQUEST_FAIL = 'UPDATE_POLICYREQUEST_STATE_FAIL';
export const APPROVE_POLICYREQUEST_SUCCESS = 'APPROVE_POLICYREQUEST_SUCCESS';
export const APPROVE_POLICYREQUEST_FAIL = 'APPROVE_POLICYREQUEST_SUCCESS';

export const SET_LOADING = 'SET_LOADING';
export const SHOW_REQUEST_SUCCESS_MODAL = 'SHOW_REQUEST_SUCCESS_MODAL';

// actions
const setLoading = (loading: boolean) => ({
  type: SET_LOADING,
  payload: loading,
});

const getPolicyRequestsSuccess = (payload: IPolicyRequest[]) => ({
  type: GET_POLICYREQUESTS_SUCCESS,
  payload
});

const getPolicyRequestsFail = (error: string) => ({
  type: GET_POLICYREQUESTS_FAIL,
  payload: error
});

const requestTimeExtensionSuccess = (payload: IPolicyRequest) => ({
  type: POST_POLICYREQUEST_SUCCESS,
  payload
});

const requestTimeExtensionFail = (error: string) => ({
  type: POST_POLICYREQUEST_FAIL,
  payload: error
});

// const approvePolicyRequestSuccess = () => ({
//   type: APPROVE_POLICYREQUEST_SUCCESS,
// });

const approvePolicyRequestFail = (error: string) => ({
  type: APPROVE_POLICYREQUEST_FAIL,
  payload: error
});

export const showModal = (open: boolean) => ({
  type: SHOW_REQUEST_SUCCESS_MODAL,
  payload: open
})

// dispatch
export const getPolicyRequests = () => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
      const res = await getTimeExtensionsApi();
      if (res.success) {
        dispatch(getPolicyRequestsSuccess(res.data));
      } else {
        dispatch(getPolicyRequestsFail(res.message));
        toastr.error(res.message);
      }
    } catch (error) {
      dispatch(getPolicyRequestsFail(error.toString()))
      toastr.error(error.toString())
    }
    dispatch(setLoading(false));
  }
}

export const requestTimeExtension = (data) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
      const res = await requestTimeExtensionApi(data);
      if (res.success) {
        dispatch(requestTimeExtensionSuccess(res.data));
      } else {
        dispatch(requestTimeExtensionFail(res.message));
      }
    } catch (error) {
      dispatch(requestTimeExtensionFail(error.toString()))
    }
    dispatch(setLoading(false));
  }
}

export const approveTimeExtension = (id, data) => {
  return async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
      const res = await approveTimeExtensionApi(id, data);
      if (res.success) {
        dispatch(getPolicyRequests());
      } else {
        dispatch(approvePolicyRequestFail(res.message));
      }
    } catch (error) {
      dispatch(approvePolicyRequestFail(error.toString()))
    }
    dispatch(setLoading(false));
  }
}
