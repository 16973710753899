import { Box, Button, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import ImgIcon from '../../../components/ImgIcon';
import Item from './RequestItem';
import Alert from './ExtraTimeRequest/Alert';
import ExtraTimeRequest from './ExtraTimeRequest';
import { getPolicyRequests, showModal } from '../../../redux/actions/policyRequestAction';
import { RootState } from '../../../redux/store';
import { isAllowedUser } from '../../../util/lib';

const useStyles = makeStyles((theme: Theme) => ({
  solid: {
    backgroundColor: '#727792',
    color: 'white'
  },
  white: {
    backgroundColor: 'white',
    color: '#727792'
  },
  realTimeButton: {
    boxShadow: '0px 3px 6px rgba(46, 13, 82, 0.16)',
    borderRadius: 5,
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 'bold',
    textTransform: 'none',
    textAlign: 'left',
    width: '100%'
  },
  buttonText: {
    marginLeft: 10,
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 500
  },
}));

function Child() {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  // const [openSpecialAccess, setOpenSpecialAccess] = useState<boolean>(false);
  const dispatch = useDispatch();
  const authUser = useSelector((state: RootState) => state.auth.user);
  const requests = useSelector((state: RootState) => state.policyRequests.requests);
  const isOpen = useSelector((state: RootState) => state.policyRequests.openModal);

  useEffect(() => {
    dispatch(getPolicyRequests());
  }, [dispatch]);

  return (
    <Box padding={2}>
      {isAllowedUser(authUser, 'hidden', 'screen', 'realTimeRequests') && <Button fullWidth component='div' onClick={() => setOpen(true)}>
        <Box
          className={clsx(classes.realTimeButton, classes.solid)}
          padding={2}
          display='flex'
          alignItems='center'
        >
          <ImgIcon name='Time-Add' width={32} />
          <Typography component='span' color='inherit' className={classes.buttonText} >Request extra time</Typography>
        </Box>
      </Button>}
      {/* <Button fullWidth component='div' onClick={() => setOpenSpecialAccess(true)}>
        <Box
          className={clsx(classes.realTimeButton, classes.white)}
          padding={2}
          display='flex'
          alignItems='center'
          marginTop={2}
        >
          <ImgIcon name='Time-Access' width={32} />
          <Typography component='span' color='inherit' className={classes.buttonText} >Request access during a restricted time</Typography>
        </Box>
      </Button> */}
      <Box marginTop={2}>
        <Typography variant='h2'>Requests</Typography>
        <Typography variant='caption'>Real Time requests are cleared each day at midnight</Typography>
        {requests.map((request) => (
          <Item
            onClick={() => { }}
            key={request._id}
            requester={request.requester}
            type={request.requestType}
            pending={request.state === 'requested'}
          />
        ))}
      </Box>
      <ExtraTimeRequest isOpen={open} handleClose={() => setOpen(false)} />
      <Alert open={isOpen} handleClose={() => dispatch(showModal(false))} />
    </Box>
  );
}

export default Child;