import React from 'react'

const CalenderIcon = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 3.69531V1.69531M6 3.69531V1.69531M1.75 6.69531H19.25M1.5 8.73931C1.5 6.62431 1.5 5.56631 1.936 4.75831C2.33003 4.03745 2.94184 3.45959 3.684 3.10731C4.54 2.69531 5.66 2.69531 7.9 2.69531H13.1C15.34 2.69531 16.46 2.69531 17.316 3.10731C18.069 3.46931 18.68 4.04731 19.064 4.75731C19.5 5.56731 19.5 6.62531 19.5 8.74031V13.6523C19.5 15.7673 19.5 16.8253 19.064 17.6333C18.67 18.3542 18.0582 18.932 17.316 19.2843C16.46 19.6953 15.34 19.6953 13.1 19.6953H7.9C5.66 19.6953 4.54 19.6953 3.684 19.2833C2.94199 18.9313 2.33019 18.3538 1.936 17.6333C1.5 16.8233 1.5 15.7653 1.5 13.6503V8.73931Z" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default CalenderIcon