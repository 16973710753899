import React, { useEffect } from 'react';
import { Card, Typography, Grid, Box, Avatar, Link, Divider } from '@material-ui/core';
import SpeakerIcon from '../Icons/SpeakerIcon';
import ArrowDownIcon from '../Icons/ArrowDownIcon';
import { useStyles } from '../../screens/User/ClientDashboard/style';
import { useDispatch, useSelector } from 'react-redux';
import { getProgramAnnouncementData } from '../../redux/actions/programAction';
import { RootState } from '../../redux/store';
import { IProgramAnnouncement } from '../../types/program';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';

const Announcements = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {programId} = JSON.parse(localStorage.getItem('activeProgramEnrollment')) || {};  // TODO: Replace with service call

  const { _id: userId } = useSelector((state: RootState) => state.auth.user);
  const { announcementList, announcementLoading } = useSelector((state: RootState) => state.programReducer)

  useEffect(() => {
    dispatch(getProgramAnnouncementData(programId, userId))
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.titleWrapper}>
        <Typography variant="h4" className={classes.typography} gutterBottom>
          <SpeakerIcon />
          <span>Announcements</span>
          <ArrowDownIcon />
        </Typography>
        {/* <Link href="#" variant="caption">
          More info
        </Link> */}
      </div>

      {announcementLoading ?
        <>
          {[...Array(3)].map((_, index) => (
            <Skeleton key={index} variant="rect" height={100} style={{ marginBottom: '10px' }} />
          ))}
        </> :
        announcementList && announcementList?.length > 0 && announcementList?.slice(0, 3).map((announcement: IProgramAnnouncement) => (
          <Card key={announcement._id} className={classes.card}>
            <div className={classes.titleContainer}>
              <Avatar
                // src={announcement.image || 'default-image.jpg'}
                // alt={announcement.title}
                className={classes.avatar}
              />
              <div>
                <Typography variant="h6" className={classes.title}>
                  {announcement.label}
                </Typography>
                <div 
                  className={classes.content} 
                  dangerouslySetInnerHTML={{ __html: announcement.body }} 
                />
              </div>
            </div>
            <Divider />
            <div className={classes.moreInfo}>
              <Typography variant="caption">
                {moment(announcement.createdAt).calendar()}
              </Typography>
              <Typography variant="caption">
                More info
              </Typography>
            </div>
          </Card>
        ))
      }
      {!announcementLoading && announcementList?.length === 0 && <Typography className={classes.actionCard}>No Announcement Found</Typography>}
    </div>
  );
};

export default Announcements;
