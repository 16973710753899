import { Dictionary } from '../types/generics';
import { IACDDevice } from '../types/device';
import { isEmpty, isEqual, xorWith } from 'lodash';
import { UserType } from '../constants/data';
import { IUser } from '../types/user';

import featureFlag from "../config/feature-flag.json";

export function optClasses(classes: string, opts: Dictionary<boolean>): string {
  let out = classes;

  for (const key in opts) {
    if (opts[key] === true) {
      out += ` ${key}`;
    }
  }

  return out.trim();
}

// check if hub has active device
export function isDeviceOn(device: IACDDevice): boolean {
  const { apps } = device;
  return apps.filter((app) => app.state === 'on').length > 0;
}

export function formatTime(seconds: number): string {
  seconds -= parseInt(localStorage.getItem('timeDelta'));
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = (seconds % 3600) % 60;

  return `${h}h ${m < 10 ? `0${m}` : m}m ${s < 10 ? `0${s}` : s}s`;
}

export function isArrayEqual(x, y) {
  return isEmpty(xorWith(x, y, isEqual));
}

export function isTeamAdmin(type: string): boolean {
  return type === UserType.parent || type === UserType.teamAdmin
}

export function isAllowedUser(user: IUser, type: string, feature: string, subFeature?: string): boolean {
  const tags = featureFlag[type]?.[feature]?.usertags || featureFlag[type]?.[feature]?.[subFeature]?.usertags;

  if (tags) {
    for (let i = 0; i < tags.length; i++) {
      for( let j = 0; j < user?.tags?.length || 0; j++) {
        if(tags[i] === user?.tags[j]) return true;
      }
    }
    return false;
  }
  return true;
}
