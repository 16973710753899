import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
let env_check = true || process.env.NODE_ENV === 'production';

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  reset: () => {
    if (env_check) mixpanel.reset();
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set({
        ...props,
        Email: props.email
      });
    },
  },
  
};

export let Mixpanel = actions;