import React from 'react'

const SpeakerIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.9995 5.882V19.24C10.9992 19.6545 10.8526 20.0555 10.5856 20.3725C10.3186 20.6896 9.94831 20.9022 9.53993 20.9729C9.13154 21.0437 8.71129 20.968 8.35321 20.7593C7.99512 20.5506 7.72219 20.2222 7.58248 19.832L5.43548 13.682M5.43548 13.682C4.5867 13.3211 3.88866 12.6791 3.45951 11.8627C3.03035 11.0463 2.89643 10.1067 3.08041 9.2029C3.26439 8.29912 3.75497 7.48665 4.46913 6.903C5.18329 6.31935 6.07716 6.00035 6.99948 6H8.83148C12.9315 6 16.4565 4.766 17.9995 3V17C16.4565 15.234 12.9325 14 8.83148 14H6.99948C6.46211 14.0008 5.93013 13.892 5.43548 13.682ZM17.9995 13C18.7951 13 19.5582 12.6839 20.1208 12.1213C20.6834 11.5587 20.9995 10.7956 20.9995 10C20.9995 9.20435 20.6834 8.44129 20.1208 7.87868C19.5582 7.31607 18.7951 7 17.9995 7" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
  )
}

export default SpeakerIcon