import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        padding: theme.spacing(2),
    },
    card: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        overflowX: 'auto',
        boxShadow: "none"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: '#00000099',
        boxShadow: 'none',
    },
    paperHeader: {
        padding: theme.spacing(1),
        textAlign: 'center',
        fontSize: "14px",
        fontWeight: 500,
        color: "#424242",
        marginBottom: "3px",
        boxShadow: "none"
    },
    header: {
        fontSize: 14,
        fontWeight: 500,
        color: "#757575",
        textAlign: 'center',
        marginBottom: theme.spacing(0),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    selectEmpty: {
        marginTop: theme.spacing(0),
    },
    titleWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: theme.spacing(1),
    },
    categoryIcon: {
        width: 15,
        height: 15
    },
    weekHeader: {
        backgroundColor: '#F5F7FA',
        padding: theme.spacing(1),
        textAlign: 'center',
        fontWeight: 700,
        borderRadius: 0,
        marginBottom: "3px",
        boxShadow: "none",
        textDecoration: "underline",
        fontFamily: "Nunito Sans",
        position: 'sticky',
        top: 0,
        zIndex: 2
    },
    paperCategory: {
        textAlign: 'center',
        fontSize: "14px",
        color: "#424242",
        marginBottom: "3px",
        boxShadow: "none",
    },
    cell: {
        padding: theme.spacing(2),
        textAlign: 'center',
        borderRadius: 0,
        marginBottom: "3px",
    },
    grade: {
        backgroundColor: '#F5F7FA',
        padding: theme.spacing(2),
        textAlign: 'center',
        fontWeight: 500,
        borderRadius: 0,
        boxShadow: "none",
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        overflowX: 'auto',
    },
    tableHeader: {
        borderBottom: "none",
        padding: theme.spacing(1),
        textAlign: 'center',
        fontWeight: 'bold',
        position: 'sticky',
        top: 0,
        zIndex: 1
    },
    tableCell: {
        padding: theme.spacing(0.07),
        textAlign: 'center',
    },
    categoryName: {
        fontSize: 12,
        color: "#00000099",
        background: '#fff',
        zIndex: 1,

    },
    scoreCell: (props: any) => ({
        backgroundColor: props.backgroundColor,
        padding: theme.spacing(1),
        textAlign: 'center',
        borderRadius: 0,
        boxShadow: "none",
    }),
    typography: {
        fontFamily: 'Nunito Sans',
        fontSize: '18px',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        gap: "10px",
    },
    activeTab: {
        background: "#D9DFE4"
    },
    tabIcons : {
        margin: "0 5px",
        padding: 5,
        width: 24,
        height: 24,
    }
}));
