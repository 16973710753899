import {
  getUserStateApi,
  getAcdDevicesStates,
  getDailyUserStateApi,
  getChildStateApi,
  getAcdDeviceApi,
} from '../../api';
import { errorInUserState, TEAM_USERS_STATE } from './teamAction';
import { dataParsing, getAcdDevicesError, getAcdDevicesSuccess} from './devicesAction';
import { RootState } from '../store';
import { IUserState } from '../../types/user';
import {
  GET_DAILY_ACTIVITY_SUCCESS,
  userStateSuccess,
  userStateError,
} from './childAction';
import { IDevice } from '../../types/device';
import toastr from 'toastr';
import { UserType } from '../../constants/data';
import { logger, format } from '../../datadogLogger';

export const GET_ACD_DEVICES_SCHEDULE = 'GET_ACD_DEVICES_SCHEDULE';

// get team users state

export const getUsersState = () => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const state = getState();

      const users = state.teamReducer.teamUsers;

      const res = await getUserStateApi(users);
      const dataToStore: IUserState[] = res.map((item, index) => {
        const userId = users[index]._id;
        return {
          userId,
          userState: item.data,
        };
      });
      // console.log('datatoStore in schedule', dataToStore);

      dispatch({
        type: TEAM_USERS_STATE,
        payload: dataToStore,
      });
    } catch (error) {
      // console.log('error in user state', error);

      dispatch(errorInUserState(error));
    }
  };
};

export const getUserState = (userId: string) => {
  return async (dispatch: any) => {
    try {
      const dailyState = await getDailyUserStateApi(userId);
      if (dailyState.success) {
        dispatch({
          type: GET_DAILY_ACTIVITY_SUCCESS,
          payload: dailyState.data,
        });
      }
      const userState = await getChildStateApi(userId);
      if (userState.success) {
        dispatch(userStateSuccess(userState.data));
      } else {
        toastr.error(userState.message);
        dispatch(userStateError(userState));
      }
    } catch (error) {
      toastr.error(error.response?.data.message);
      dispatch(userStateError(error));
    }
  };
};

// acdDevice state

export const getAcdDevices = (teamId: any) => {
    return async (dispatch: any, getState: () => RootState) => {
      try {
        if(!teamId) return;
  
        const res = await getAcdDeviceApi(teamId);
        if (res.success) {
          const devicesIds: string[] = [];
          const state = getState();
          const { user } = state.auth;
          const userDevices: IDevice[] = [];
          let newDevices: IDevice[] = [];
          if (user.type === UserType.child) {
            // eslint-disable-next-line array-callback-return
            res.data.map((device: IDevice) => {
              if (device.users.length === 0 || device.users.includes(user._id)) {
                devicesIds.push(device._id);
                userDevices.push(device);
              }
            });
          } else {
            res.data.forEach((device: IDevice) => {
              devicesIds.push(device._id);
              userDevices.push(device);
              if (
                !device.settings?.deviceSettings?._isConfigured &&
                device.apps.find((app) => !app.category) &&
                device.apps.find((app) => !app.label)
              ) {
                newDevices.push(device);
              }
            });
          }
          const allDevices = JSON.parse(JSON.stringify(userDevices));
          const response = await getAcdDevicesStates(devicesIds);
  
          const data = dataParsing(userDevices, response);
  
          dispatch(
            getAcdDevicesSuccess({
              acdDevices: data,
              allDevices,
              devicesIds,
              newDevices,
            })
          );
        } else {
          dispatch(getAcdDevicesError(res));
        }
        logger.info(format(`Get acd devices list  for the team=${teamId}`));
      } catch (error) {
        logger.error(format('Failed to get acd devices'), error);
        dispatch(getAcdDevicesError(error));
      }
    };
  };
