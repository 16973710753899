import React from 'react';
import {
    IconButton,
    Typography,
    withStyles,
    WithStyles,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseModalIcon from '../../../../../components/Icons/closeModalIcon';

const styles: any = (theme) => ({
    root: {
        margin: 0,
        position: 'relative',
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    modalTitle: {
        color: '#000000DE',
        fontWeight: 700
    }
});

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const CustomDialogTitle = withStyles(styles)((props: any) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography className={classes.modalTitle} variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseModalIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default CustomDialogTitle;