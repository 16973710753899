export function formatTimeTo24hour(timeStr: string): string {
  timeStr = timeStr.toUpperCase();
  const [time, modifier] = timeStr.split(' ');
  let [hours, minutes] = time.split(':');
  if (hours === '12') {
    hours = '00';
  }
  if (modifier === 'PM') {
    hours = `${parseInt(hours, 10) + 12}`;
  }
  hours = `${hours}`.length === 1 ? `0${hours}` : hours;
  minutes = `${minutes}`.length === 1 ? `0${minutes}` : minutes;
  return `${hours}:${minutes}`;
}

export function format24HourTimeToAMPM(timeStr: string, caps = false): string {
  let [hour, minutes] = timeStr.split(':');
  let ampm = '';

  let hours = parseFloat(hour);
  if (hours === 0) {
    ampm = ' am';
    hours = 12;
  } else if (hours === 12) {
    ampm = ' pm';
  } else if (hours > 12) {
    ampm = ' pm';
    hours -= 12;
  } else {
    ampm = ' am';
  }

  hour = `${hours}`.length === 1 ? `0${hours}` : `${hours}`;
  minutes = `${minutes}`.length === 1 ? `0${minutes}` : minutes;

  let result = `${hour}:${minutes}${ampm}`;
  if (caps) {
    result = result.toUpperCase();
  }
  return result;
}

export function formatMinsWithAMPM(date: Date, caps = false): string {
  const hours = date.getHours();
  const mins = date.getMinutes();
  const ampm = hours >= 12 ? ' pm' : ' am';
  let result = mins + ampm;
  if (caps) {
    result = result.toUpperCase();
  }
  return result;
}

export function formatMinsWithZeroWithAMPM(date: Date, caps = false): string {
  const hours = date.getHours();
  const mins =
    date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;
  const ampm = hours >= 12 ? ' pm' : ' am';
  let result = mins + ampm;
  if (caps) {
    result = result.toUpperCase();
  }
  return result;
}

export function formatAMPM(date: Date): string {
  let hours = date.getHours();
  hours %= 12;
  hours = hours || 12;
  return `${hours}`;
}

export const formatAMPMWithZero = (date: Date): string => {
  let hours = date.getHours();
  hours %= 12;
  hours = hours || 12;
  return `${hours}`.length === 1 ? `0${hours}` : `${hours}`;
};

export const formatMins = (date: Date): string => {
  const hours = date.getHours();
  const mins = date.getMinutes();
  const ampm = hours >= 12 ? ' pm' : ' am';
  return mins + ampm;
};

export const formatMinsWithZero = (date: Date): string => {
  const hours = date.getHours();
  const mins =
    date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;
  const ampm = hours >= 12 ? ' pm' : ' am';
  return mins + ampm;
};

export const convertMinsToHour = (value: number, extend?: boolean): string => {
  // console.log(value)
  let hourExtension = extend ? 'hrs' : 'h';
  let minExtension = extend ? 'min' : 'm';
  if (value === 0) return `0 ${hourExtension} 0 m`;
  return `${Math.floor(value / 60)} ${hourExtension} ${Math.floor(value % 60) ? Math.floor(value % 60) + minExtension : ''
    }`;
};

export const hourString = (value: number) => {
  return value % 120 === 0 ? `${value / 60}h` : '';
};


const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

function isClose(a, b) {
  return days.indexOf(a) === days.indexOf(b) - 1 || days.indexOf(a) === days.indexOf(b) + 1
}

export function convert(arr) {
  return arr.reduce((str, day, index) => {
    console.log('result: ', str);
    if (index === 0)
      return day;
    else {
      if (!isClose(day, arr[index - 1])) {
        return str += ' ' + day;
      }
      if (isClose(day, arr[index - 1]) && isClose(day, arr[index + 1]) && str[str.length - 1] !== '-') {
        return str += ' -';
      }
      if (isClose(day, arr[index - 1]) && !isClose(day, arr[index + 1])) {
        return str += ' ' + day;
      }
      return str
    }
  }, '')
}

export function getTimeBasedGreeting (){
  const currentHour = new Date().getHours();

  if (currentHour >= 5 && currentHour < 12) {
    return 'Good Morning';
  } else if (currentHour >= 12 && currentHour < 17) {
    return 'Good Afternoon';
  } else if (currentHour >= 17 && currentHour < 21) {
    return 'Good Evening';
  } else {
    return 'Good Night';
  }
};