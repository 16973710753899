import moment from 'moment';
import { createTeamApi, getLogIn, getUserApi, getUserProgramEnrollmentsApi, initializeChannel } from '../../api';
import { ITeamRegForm, ILoginForm } from '../../types/auth';
import { logger, format } from '../../datadogLogger';
import { Mixpanel } from '../../util/mixpanel';
import toastr from 'toastr';
import { setAxiosHeadersRC } from '../../api/endpoint';
import { UserType } from '../../constants/data';

export const LOGIN_LOADER = 'LOGIN_LOADER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERR = 'LOGIN_ERROR';
export const GET_USER_LOADER = 'GET_USER_LOADER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const CHANGE_STATUS = 'CHANGE_STATUS';

// sign up team action
export const createTeam = (data: ITeamRegForm) => {
  return async (dispatch: any) => { 
    try {
      dispatch(loginLoader(true));
      const res = await createTeamApi(data);
      if (res.data.user) localStorage.setItem('user', res.data.user);
      if (res.data.team) localStorage.setItem('team', res.data.team);
      if (res.data.token) localStorage.setItem('token', res.data.token);
      if (res.data.refreshToken) localStorage.setItem('refreshToken', res.data.refreshToken);
      if (res.data?.rcToken) localStorage.setItem('rcToken', res.data?.rcToken);
      if (res.data?.rcUserId) localStorage.setItem('rcUserId', res.data.rcUserId);  
      if((res.data?.rcToken) && res.data?.rcUserId)
      {
        setAxiosHeadersRC(res?.data?.rcToken, res?.data?.rcUserId);
      }
      const timeDelta = moment().diff(moment(res.timestamp), 'seconds');
      localStorage.setItem('timeDelta', timeDelta.toString());
      // todo: consider rename local storage variables to something a bit less obvious / more crypti 
      toastr.success(res.message)
      const userData = await getUserApi(res.data.user);
      localStorage.setItem('authInfo', JSON.stringify(userData?.data));
      const userEnrollment: any = await getUserProgramEnrollmentsApi(userData?.data?._id);
      if (userEnrollment && userEnrollment?.length > 0) {
        localStorage.setItem("activeProgramEnrollment", JSON.stringify(userEnrollment[0]));
      }
      dispatch(loginSuccess(res, userData));
      dispatch(loginLoader(false));
      logger.info(format('Team account Register'), { ...userData.data });
      Mixpanel.identify(userData.data._id);
      Mixpanel.people.set(userData.data);
    } catch (error) {
      logger.error(format('Team account register failed.'), error);
      dispatch(loginLoader(false));
      dispatch(loginError(error.message));
      toastr.error(error.response?.data.message)
    }
  }
}

// log in user action


export const startLogin = (authData: ILoginForm) => {
  return async (dispatch: any) => {
    try {
      console.log('login module start=<><<<<')
      dispatch(loginLoader(true));
      const res: any = await getLogIn(authData);
      if (res.data.user) localStorage.setItem('user', res.data.user);
      if (res.data.team) localStorage.setItem('team', res.data.team);
      if (res.data.token) localStorage.setItem('token', res.data.token);
      if (res.data.refreshToken) localStorage.setItem('refreshToken', res.data.refreshToken);
      if (res.data?.rcToken) localStorage.setItem('rcToken', res.data.rcToken);
      if (res.data?.rcUserId) localStorage.setItem('rcUserId', res.data.rcUserId);  
      if((res.data.rcToken) && (res.data.rcUserId))
      {
        setAxiosHeadersRC(res?.data?.rcToken, res?.data?.rcUserId);

      }
      const timeDelta = moment().diff(moment(res.timestamp), 'seconds');
      localStorage.setItem('timeDelta', timeDelta.toString());
      // todo: consider rename local storage variables to something a bit less obvious / more cryptic
      toastr.success("Login Successfully");
      const userData = await getUserApi(res.data.user);
      localStorage.setItem('authInfo', JSON.stringify(userData?.data));
      
      const userEnrollment: any = await getUserProgramEnrollmentsApi(userData?.data?._id);
      console.log("userEnrollment=>>>>>>",userEnrollment)

      if (userEnrollment && userEnrollment?.length > 0) {
        localStorage.setItem("activeProgramEnrollment", JSON.stringify(userEnrollment[0]));
      }

      dispatch(loginSuccess(res, userData));
      dispatch(loginLoader(false));
      logger.info(format('User logged in'), { ...userData.data });

      Mixpanel.identify(userData.data._id);
      Mixpanel.people.set(userData.data);

      const phaseNumber = userEnrollment[0]?.status?.activeProgramPhase?.phaseNumber;
      if ((userData?.data?.type === UserType.admin || userData?.data?.type ===  UserType.teamAdmin ) && userEnrollment?.length === 0) {
        window.location.href = '/admin/dashboard';
      } else if (res?.data?.isFirstTimeLogin === 'false' || !res?.data?.isFirstTimeLogin) {
        window.location.href = '/set-password';
      } else if (phaseNumber > 0 && phaseNumber <= 2) {
        window.location.href = `/onboarding/phase/${userEnrollment[0]?.status?.activeProgramPhase?.phaseNumber}`;
      } else if (phaseNumber === 3) {
        window.location.href = `/user/dashboard`;
      }

    } catch (error) {
      logger.error(format('User logged in failed.'), error);
      dispatch(loginLoader(false));
      dispatch(loginError(error.message));
    }
  };
};

const loginLoader = (isLoading: boolean) => {
  return {
    type: LOGIN_LOADER,
    payload: isLoading,
  };
};
const loginSuccess = (tokens: any, userData: any) => {
  return {
    type: LOGIN_SUCCESS,
    payload: userData,
    tokens,
  };
};
const loginError = (payload: any) => {
  return {
    type: LOGIN_ERR,
    payload,
  };
};

// get user action

export const getUser = () => {
  return async (dispatch: any) => {
    try {
      const userId = localStorage.getItem('user');
      const token = localStorage.getItem('token');

      if (userId != null) {
        const res = await getUserApi(userId);

        localStorage.setItem('authInfo', JSON.stringify(res.data));
        dispatch(getUserSuccess(res, token));
        logger.info(format('Get user info'), { ...res.data });
      } else {
        dispatch(getUserError('No user Found'));
      }
      dispatch(getUserLoader(false));
    } catch (error) {
      logger.error(format('Failed to get User info'), error);
      dispatch(getUserLoader(false));
      dispatch(getUserError(error));
    }
  };
};

const getUserLoader = (isLoading: boolean) => {
  return {
    type: GET_USER_LOADER,
    payload: isLoading,
  };
};

const getUserSuccess = (user: any, token: string | null) => {
  return {
    type: GET_USER_SUCCESS,
    payload: user,
    token,
  };
};

const getUserError = (error: any) => {
  return {
    type: GET_USER_ERROR,
    payload: error,
  };
};

// change user status
export const changeStatus = () => {
  return {
    type: CHANGE_STATUS,
  };
};

const navigateToOnboarding = (phaseNumber: number) => {
  switch (phaseNumber) {
    case 0:
      window.location.href = `/onboarding/welcome`;
      break;
    case 1:
      window.location.href = `/onboarding/phase/1`;
      break;
    case 2:
      window.location.href = `/onboarding/phase/2`;
      break;
    default:
      console.warn('Invalid phase number');
  }
};
