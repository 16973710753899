import { Box, Card, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './style';
import soulImg from "../../../../assets/soul.svg";
import moneyImg from "../../../../assets/money.svg";
import mindImg from "../../../../assets/smile.svg";
import relationshipImg from "../../../../assets/relationship.svg";
import nutritionImg from "../../../../assets/nutrition.svg";
import bodyImg from "../../../../assets/body.svg";
import moment from 'moment';
import scoreImg from "../../../../assets/score.svg";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { setHighLightTab } from '../../../../redux/actions/myResultAction';

export type CategoryInfo = {
  label?: string;
  isActive?: boolean;
  info?: string;
  group?:string;
}

export type CategoryGroup = {
  label: string;
  dimensions: string[];
  icon?: string;
  aliases?: Record<string, string>;
}

type WeeklyDataItem = {
  week: number;
  date: string | Date;
  categories: string[];
  scores: number[];
  other?: {
    weekGrade?: string;
    weekGradeScore?: number;
  };
  weekGrade?: string;
}

type HeatMapPerformanceTableProps = {
  weeklyData: WeeklyDataItem[];
  isDashboard?: boolean;
  categoryGroups: Record<string, CategoryGroup>;
  categoryInfo: Record<string, CategoryInfo>;
}

const HeatMapPerformanceTable: React.FC<HeatMapPerformanceTableProps> = ({ 
  weeklyData, 
  isDashboard = false, 
  categoryGroups, 
  categoryInfo 
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { highLightTab } = useSelector((state: RootState) => state.myResultReducer);
  const weeks = weeklyData?.map(weekData => weekData.date);
  const categories = weeklyData && weeklyData.length > 0 && weeklyData[0]?.categories 
    ? weeklyData[0].categories 
    : [];

  const categoryIcons: Record<string, string> = {
    "essential1Score": mindImg,
    "essential2Score": bodyImg,
    "essential3Score": soulImg,
    "essential4Score": moneyImg,
    "essential5Score": relationshipImg,
    "essential6Score": nutritionImg,
    "rock1Score": scoreImg,
    "rock2Score": scoreImg,
    "rock3Score": scoreImg,
    "rock4Score": scoreImg,
    "rock5Score": scoreImg
  };

  const scoreColor: Record<number, string> = {
    1: "#FE4F4F",
    2: "#FFA66E",
    3: "#FBD230",
    4: "#3CAFF2",
    5: "#69E8B4"
  };

  const highLightCell = (index: number): string => {
    return highLightTab === index ? classes.highLightCell : '';
  }

  // Group categories based on categoryGroups
  const groupedCategories: Record<string, Array<{name: string; index: number; isActive: boolean}>> = {};
  
  // Initialize groups with empty arrays
  Object.keys(categoryGroups || {}).forEach(groupKey => {
    groupedCategories[groupKey] = [];
  });
  
  // // Fill groups with corresponding categories
  // categories.forEach((category, index) => {
  //   // Find which group this category belongs to
  //   const groupKey = Object.keys(categoryGroups || {}).find(key => 
  //     categoryGroups[key].dimensions?.includes(category)
  //   );
    
  //   if (groupKey && groupedCategories[groupKey]) {
  //     groupedCategories[groupKey].push({
  //       name: category,
  //       index: index,
  //       isActive: categoryInfo?.[category]?.isActive !== false // Default to true if not specified
  //     });
  //   }
  // });

  // Fill groups with corresponding categories, checking aliases
  categories.forEach((category, index) => {
    let foundGroupKey: string | undefined;
    let originalCategory = category;

    // Try direct match first
    foundGroupKey = Object.keys(categoryGroups).find(key => 
        categoryGroups[key].dimensions?.includes(category)
    );

    // If not found, try alias lookup
    if (!foundGroupKey) {
        for (const key of Object.keys(categoryGroups)) {
            const aliases = categoryGroups[key].aliases || {};
            const aliasEntry = Object.entries(aliases).find(([original, aliasCsv]) =>
                aliasCsv.split(',').includes(category) // Check if category is an alias
            );

            if (aliasEntry) {
                foundGroupKey = key;
                originalCategory = aliasEntry[0]; // Get the original category name
                break;
            }
        }
    }

    if (foundGroupKey && groupedCategories[foundGroupKey]) {
        groupedCategories[foundGroupKey].push({
            name: originalCategory, // Store original category name
            index: index,
            isActive: categoryInfo?.[category]?.isActive !== false // Default to true if not specified
        });
    }
  });

  // Check if a group should be visible (if any dimension in the group is active)
  const isGroupActive = (groupItems: Array<{name: string; index: number; isActive: boolean}>): boolean => {
    return groupItems.some(item => item.isActive);
  };

  return (
    <Card className={classes.card}>
      {!weeks || weeks.length === 0 ? (
        <Typography style={{ textAlign: "center" }}>No data Found</Typography>
      ) : (
        <table className={classes.table}>
          <thead>
            <tr>
              <th className={classes.tableHeader}></th>
              {weeks.map((week, index) => (
                <th 
                  key={index} 
                  onClick={() => !isDashboard && dispatch(setHighLightTab(index))} 
                  className={`${classes.weekHeader} ${highLightCell(index)}`}
                >
                  {moment(week).format("MMM DD")}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className={classes.categoryName}>
                {weeklyData && (isDashboard ?
                  'Weekly Performance' :
                  <Box display="flex" alignItems="center" flexDirection="column">
                    <img className={classes.categoryIcon} src={scoreImg} alt="Score" />
                    <span className={classes.categoryName}>Score</span>
                  </Box>)}
              </th>
              {weeks.map((week, index) => (
                <td key={`week-${index}`}>
                  <Paper className={`${classes.grade} ${highLightCell(index)}`}>
                    {weeklyData[index]?.other?.weekGrade || weeklyData[index]?.weekGrade}
                  </Paper>
                </td>
              ))}
            </tr>

            {/* Render each group and its categories */}
            {Object.keys(groupedCategories).map((groupKey) => {
              const groupItems = groupedCategories[groupKey];
              
              // Skip rendering this group if all items are inactive
              if (!isGroupActive(groupItems)) {
                return null;
              }
              
              return (
                <React.Fragment key={groupKey}>
                  {/* Group header/separator */}
                  <tr className={classes.groupSeparator}>
                    <td colSpan={weeks ? weeks.length + 1 : 1} className={classes.groupHeader}>
                      <Box py={1}>
                        <Typography variant="subtitle2" className={classes.groupTitle}>
                          {categoryGroups[groupKey].label || groupKey}
                        </Typography>
                      </Box>
                    </td>
                  </tr>
                  
                  {/* Categories in this group */}
                  {groupItems.map((item) => {
                    // Skip inactive dimensions
                    if (!item.isActive) {
                      return null;
                    }
                    
                    return (
                      <tr key={item.name}>
                        <td className={classes.tableCell}>
                          <Box display="flex" alignItems="center" flexDirection="column">
                            <img 
                              className={classes.categoryIcon} 
                              src={categoryIcons[item.name]} 
                              alt={item.name} 
                            />
                            <span className={classes.categoryName}>
                              {categoryInfo?.[item.name]?.label || item.name}
                            </span>
                          </Box>
                        </td>
                        {weeks.map((week, weekIndex) => {
                          const dataForWeek = weeklyData[weekIndex];
                          const score = dataForWeek ? dataForWeek.scores[item.index] : null;
                          const backgroundColor = score ? scoreColor[score] : 'transparent';
                          const isHighlight = weekIndex === highLightTab ? "gray" : backgroundColor;
                          
                          return (
                            <td key={`${item.name}-${weekIndex}`} className={classes.tableCell}>
                              <Paper 
                                className={`${classes.scoreCell} ${highLightCell(weekIndex)}`} 
                                style={{ backgroundColor: isHighlight }}
                              >
                                {score}
                              </Paper>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      )}
    </Card>
  );
};

export default HeatMapPerformanceTable;