import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import SidebarOption from './sidebarOption';
import { Avatar, Collapse, List, ListItem, ListItemText, Tooltip } from '@material-ui/core';
import { getRCDmList, getRCJoinedChannelList, getRCUserProfileInfo } from '../../../../redux/actions/chat';
import { RootState } from '../../../../redux/store';
import { useHistory, useLocation } from 'react-router';
import { IChannel } from '../../../../types/chat';
import AddChannelModal from './components/AddChannelModal';
import "../style.css"
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const Sidebar = () => {
  const dispatch = useDispatch();
  const history: any = useHistory();
  const [isChannelListOpen, setIsChannelListOpen] = useState(true);
  const [isMessageListOpen, setIsMessageListOpen] = useState(true);
  const [open, setOpen] = useState(false);
  const { directMessagesList, channels, userProfileInfo } = useSelector((state: RootState) => state.chatReducer);
  const location = useLocation<any>();
  const searchParams = new URLSearchParams(location.search);
  const roomId = searchParams.get('roomId');
  const myRcId = localStorage.getItem("rcUserId");

  const collapseChannelsHandler = () => {
    setIsChannelListOpen(!isChannelListOpen);
  };

  const collapseMessageHandler = () => {
    setIsMessageListOpen(!isMessageListOpen);
  }

  const handleClose = () => {
    setOpen(!open);
  };

  const handleNavigate = (url, type) => {

  }

  useEffect(() => {
    dispatch(getRCDmList());
    dispatch(getRCJoinedChannelList());
    dispatch(getRCUserProfileInfo());
  }, []);

  return (
    <>
      <div className={`sidebar `}>

        <div className="sidebar__section">
          <div className={`sidebarOption sidebar__group`}   >
            <InsertCommentIcon fontSize='small' className='sidebarOption__icon' />
            <h3 className='sidebarOption__title'>Threads</h3>
          </div>
          <div className={`sidebarOption sidebar__group`}   >
            <AlternateEmailIcon fontSize='small' className='sidebarOption__icon' />
            <h3 className='sidebarOption__title'>Mentions & reactions</h3>
          </div>
          <div className={`sidebarOption sidebar__group`}   >
            <TurnedInNotIcon fontSize='small' className='sidebarOption__icon' />
            <h3 className='sidebarOption__title'>Saved items</h3>
          </div>
          <div className={`sidebarOption sidebar__group`}   >
            <MoreVertIcon fontSize='small' className='sidebarOption__icon' />
            <h3 className='sidebarOption__title'>More</h3>
          </div>
        </div>

        <button
          onClick={collapseChannelsHandler}
          className='sidebar__collapse--button'
        >
          <SidebarOption
            Icon={isChannelListOpen ? ArrowDropDownIcon : ArrowRightIcon}
            title='Channels'
            addGroupOption
            channelId={null}
            addChannelOption={false}
          />
        </button>

        <Collapse in={isChannelListOpen} timeout='auto' unmountOnExit>
          <List component='div' disablePadding style={{ marginLeft: "10px" }}>
            {channels.map((channel: IChannel) => (
              <div key={channel._id} onClick={() => history.push(`/chat?roomId=${channel._id}&type=channel`)}>
                <ListItem button className={`${roomId === channel._id ? 'active' : ''}`}>
                  {/* <Avatar style={{ width: "28px", height: "28px" }} src={`https://apiexplorer.support.rocket.chat/avatar/room/${channel._id}`} /> */}
                  <ListItemText
                    primary={`# ${channel.name}`}
                    primaryTypographyProps={{ style: { paddingLeft: "7px", fontSize: "13px" } }}
                  />
                </ListItem>
              </div>
            ))}
            <div
              className={`sidebarOption`}
              onClick={() => setOpen(true)}
            >
              <AddBoxIcon className='sidebarOption__icon' />
              <h3 className='sidebarOption__title'>Add Channel</h3>
            </div>
          </List>
        </Collapse>

        <div>
          <button
            onClick={collapseMessageHandler}
            className='sidebar__collapse--button'
          >
            <SidebarOption
              Icon={isMessageListOpen ? ArrowDropDownIcon : ArrowRightIcon}
              title='Direct messages'
              addGroupOption
              channelId={null}
              addChannelOption={false}
            />
          </button>

          <Collapse in={isMessageListOpen} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              <List style={{ marginLeft: "10px" }}>
                {directMessagesList.map((user) => {
                  
                  const otherUserIndex = user.uids && user.uids[0] === myRcId && user.usersCount > 1 ? 1 : 0;
                  const otherUserId = user.uids[otherUserIndex];
                  const otherUsername = user.usernames[otherUserIndex];

                  return (
                    <div key={user._id} onClick={() => history.push({
                      pathname: `/chat`,
                      search: `?roomId=${user._id}&type=direct`,
                      state: {
                        userId: otherUserId
                      }
                    })}
                    >
                      <ListItem button className={`${roomId === user._id ? 'active' : ''}`}>
                        <Avatar style={{ width: "20px", height: "20px" }}
                          src={`https://apiexplorer.support.rocket.chat/avatar/${otherUsername}`}
                        />
                        <ListItemText
                          primary={otherUsername}
                          primaryTypographyProps={{ style: { paddingLeft: "7px", fontSize: "13px" } }}
                        />
                      </ListItem>
                    </div>
                  )
                })}
              </List>
            </List>
          </Collapse>
        </div>

      </div>

      <AddChannelModal open={open} handleClose={handleClose} />

    </>
  )
}

export default Sidebar;