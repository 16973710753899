import { Card as MuiCard, Box, Button, CssBaseline, Divider, FormControl, FormLabel, Link, TextField, Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import LinkedIcon from '../../../../components/Icons/LinkedIcon';
import GoogleIcon from '../../../../components/Icons/GoogleIcon';
import MicrosoftIcon from '../../../../components/Icons/MicrosoftIcon';
import { ILoginForm } from '../../../../types/auth';
import { Mixpanel } from '../../../../util/mixpanel';
import { startLogin } from '../../../../redux/actions/auth';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        width: '100%',
        maxWidth: '500px',
        padding: theme.spacing(4),
        gap: '8px',
        margin: 'auto',
        boxShadow:
            'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
        borderRadius: theme.spacing(1),
        backgroundColor: '#FFF'
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        minHeight: '100vh',
        alignItems: 'center',
        backgroundColor: '#EDF2F7',
        padding: theme.spacing(2),
    },
    title: {
        fontSize: '2.75rem',
        fontWeight: 700,
        textAlign: 'center',
        marginBottom: theme.spacing(2)
    },
    subTitle: {
        color: '#100F14',
        fontSize: '1.45rem',
        fontWeight: 600,
        textAlign: 'center',
    },
    titlePara: {
        fontSize: '1rem',
        color: '#49475A',
        textAlign: 'center',
        marginBottom: theme.spacing(2),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        '& .MuiOutlinedInput-root': {
            borderRadius: 6
        },
        '& .MuiFormLabel-root': {
            color: '#9794AA',
            marginBottom: theme.spacing(1),
        }
    },
    dividerContainer: {
        display: 'flex',
        alignItems: 'center',
        margin: `${theme.spacing(1)}px 0`,
    },
    dividerLine: {
        flexGrow: 1,
        height: '1px',
        backgroundColor: theme.palette.divider,
    },
    dividerText: {
        margin: `0 ${theme.spacing(1)}px`,
        color: '#706C7C',
        fontWeight: 600,
        fontSize: 18
    },
    submitBtn: {
        background: '#1F2937',
        color: '#fff',
        textTransform: 'capitalize',
        borderRadius: 6,
        padding: '11px 11px',
        marginBottom: theme.spacing(2),
        '&:hover': {
            background: '#2c3b50'
        },
    },
    socialLogin: {
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(1),
    },
    paragraphText: {
        display: 'block',
        textAlign: 'center',
        color: '#706C7C',
        fontWeight: 600,
    }
}));

interface PropsType {
    loginLoader: boolean;
    authError: string;
    startLogin: (authData: ILoginForm) => any;
}

export default function Login() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const inviteProgramText = searchParams.get("inviteProgram");

    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        Mixpanel.reset();
    }, [])

    const onSubmit = (data: any) => {
        dispatch(startLogin(data));
    };

    const { control, handleSubmit, errors } = useForm();

    return (
        <>
            <CssBaseline />
            <div className={classes.container}>
                <MuiCard className={classes.card}>
                    <Typography variant="h1" className={classes.title}>
                        Logo
                    </Typography>
                    <Typography className={classes.subTitle}>
                        Welcome to {inviteProgramText? `${inviteProgramText} Program` : 'MEOS'}  
                    </Typography>
                    <Typography className={classes.titlePara}>
                        Kindly fill in your details below to login
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit(onSubmit)}
                        className={classes.form}
                    >
                        <Controller
                            variant="outlined"
                            name="email"
                            control={control}
                            rules={{
                                required: 'Email is required.',
                            }}
                            defaultValue=""
                            as={
                                <TextField
                                    label="Email Address"
                                    required
                                    fullWidth
                                    placeholder="Your Email"
                                    name="email"
                                    variant="outlined"
                                    autoComplete="email"
                                />
                            }
                        />
                        <ErrorMessage
                            as={<Typography color="error" />}
                            errors={errors}
                            name="email"
                        />

                        <Controller
                            variant="outlined"
                            name="password"
                            control={control}
                            rules={{
                                required: 'Password is required.',
                            }}
                            defaultValue=""
                            as={
                                <TextField
                                    label="Password"
                                    required
                                    fullWidth
                                    placeholder="Password"
                                    name="password"
                                    variant="outlined"
                                    type='password'
                                />
                            }
                        />

                        <Button
                            type="submit"
                            fullWidth
                            size="small"
                            className={classes.submitBtn}
                        >
                            Login
                        </Button>
                    </Box>
                    <Link href="#" variant="body2" className={classes.paragraphText} >
                        Forgot Password
                    </Link>
                    <Box className={classes.dividerContainer}>
                        <div className={classes.dividerLine}></div>
                        <span className={classes.dividerText}>Or</span>
                        <div className={classes.dividerLine}></div>
                    </Box>
                    {/* <Typography align="center" className={classes.paragraphText}>
                        Login using your social account
                    </Typography>
                    <div className={classes.socialLogin}>
                        <IconButton>
                            <LinkedIcon />
                        </IconButton>
                        <IconButton>
                            <GoogleIcon />
                        </IconButton>
                        <IconButton>
                            <MicrosoftIcon />
                        </IconButton>
                    </div> */}
                    <Typography align="center" className={classes.paragraphText}>
                        Don’t have an account?{' '}
                        <Link style={{ color: '#7CA0BB', textDecoration: 'underline' }} href="#">Register</Link>
                    </Typography>
                </MuiCard>
            </div>
        </>
    );
}
