import { Box, Card, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react'
import { useStyles } from './style';
import soulImg from "../../../../assets/soul.svg";
import moneyImg from "../../../../assets/money.svg";
import mindImg from "../../../../assets/smile.svg";
import relationshipImg from "../../../../assets/relationship.svg";
import nutritionImg from "../../../../assets/nutrition.svg";
import bodyImg from "../../../../assets/body.svg";
import moment from 'moment';
import scoreImg from "../../../../assets/score.svg";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { setHighLightTab } from '../../../../redux/actions/myResultAction';

type HeatMapPerformanceTableProps = {
    weeklyData: any;
    isDashboard?: boolean;
}

const HeatMapPerformanceTable = ({ weeklyData, isDashboard }: HeatMapPerformanceTableProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {highLightTab} = useSelector((state: RootState) => state.myResultReducer);
    const weeks = weeklyData?.map(weekData => weekData.date);
    const categories = weeklyData && weeklyData.length > 0 && weeklyData[0]?.categories ? weeklyData[0].categories : [];
    
    const categoryIcons = {
        "mind": mindImg,
        "soul": soulImg,
        "money": moneyImg,
        "relationships": relationshipImg,
        "nutrition": nutritionImg,
        "body": bodyImg
    };

    const scoreColor = {
        1: "#FE4F4F",
        2: "#FFA66E",
        3: "#FBD230",
        4: "#3CAFF2",
        5: "#69E8B4"
    };

    const highLightCell = (index) => {
        return highLightTab === index ? classes.highLightCell : '';
    }

    return (
        <Card className={classes.card}>
            {weeks?.length === 0 ? (
                <Typography style={{ textAlign: "center" }}>No data Found</Typography>
            ) : <table className={classes.table}>
                <thead>
                    <tr>
                        <th className={classes.tableHeader}></th>
                        {weeks?.map((week, index) => (
                            <th key={index} onClick={() => !isDashboard && dispatch(setHighLightTab(index))} className={`${classes.weekHeader} ${highLightCell(index)}`}>{moment(week).format("MMM DD")}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th className={classes.categoryName}>
                            {weeklyData && (isDashboard ?
                                'Weekly Performance' :
                                <Box display="flex" alignItems="center" flexDirection="column">
                                    <img className={classes.categoryIcon} src={scoreImg} alt={"Score"} />
                                    <span className={classes.categoryName}>Score</span>
                                </Box>)}
                        </th>
                        {weeks?.map((week, index) => (
                            <td key={week}>
                                <Paper className={`${classes.grade} ${highLightCell(index)}`}>{weeklyData[index]?.weekGrade}</Paper>
                            </td>
                        ))}
                    </tr>
                    {categories.map((category, categoryIndex) => (
                        <tr key={categoryIndex}>
                            <td className={classes.tableCell}>
                                <Box display="flex" alignItems="center" flexDirection="column">
                                    <img className={classes.categoryIcon} src={categoryIcons[category]} alt={category} />
                                    <span className={classes.categoryName}>{category}</span>
                                </Box>
                            </td>
                            {weeks?.map((week, weekIndex) => {
                                const dataForWeek = weeklyData[weekIndex];
                                const score = dataForWeek ? dataForWeek.scores[categoryIndex] : null;
                                const backgroundColor = score ? scoreColor[score] : 'transparent';
                                const isHighlight = weekIndex === highLightTab ? "gray" : backgroundColor
                                return (
                                    <td key={weekIndex} className={`${classes.tableCell}`}>
                                        <Paper className={`${classes.scoreCell} ${highLightCell(weekIndex)}`} style={{ backgroundColor: isHighlight }}>
                                            {score}
                                        </Paper>
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
            }
        </Card>
    )
}

export default HeatMapPerformanceTable;