import * as React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Grid, Button, Card, CardActions, CardContent, CardHeader, Divider, TextField, Typography } from '@material-ui/core';
import { ErrorMessage } from '@hookform/error-message';
import { useDispatch } from 'react-redux';
import { changeUserPassword } from '../../../../../redux/actions/teamAction';

export default function ChangePasswordForm() {
    const dispatch = useDispatch();
    const userId = localStorage.getItem("user");
    const { control, handleSubmit, errors, reset } = useForm({
        defaultValues: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
        }
    });

    const onSubmit = async (data: any) => {
        dispatch(changeUserPassword(userId, data));

        reset({
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card style={{ marginTop: '20px' }}>
                <CardHeader subheader="Change your password" title="Password Change" />
                <Divider />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <Controller
                                name="currentPassword"
                                control={control}
                                rules={{ required: 'Current password is required.' }}
                                defaultValue=""
                                as={
                                    <TextField
                                        type="password"
                                        label="Current Password"
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.currentPassword}
                                    />
                                }
                            />
                            <ErrorMessage
                                as={<Typography color="error" />}
                                errors={errors}
                                name="currentPassword"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Controller
                                name="newPassword"
                                control={control}
                                rules={{ required: 'New password is required.' }}
                                defaultValue=""
                                as={
                                    <TextField
                                        type="password"
                                        label="New Password"
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.newPassword}
                                    />
                                }
                            />
                            <ErrorMessage
                                as={<Typography color="error" />}
                                errors={errors}
                                name="newPassword"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Controller
                                name="confirmPassword"
                                control={control}
                                rules={{
                                    required: 'Please confirm your new password.',
                                    validate: (value) => value === control.getValues('newPassword') || 'Passwords do not match.'
                                }}
                                defaultValue=""
                                as={
                                    <TextField
                                        type="password"
                                        label="Confirm New Password"
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.confirmPassword}
                                    />
                                }
                            />
                            <ErrorMessage
                                as={<Typography color="error" />}
                                errors={errors}
                                name="confirmPassword"
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <CardActions style={{ justifyContent: 'flex-end' }}>
                    <Button variant="contained" color="primary" type="submit">
                        Change Password
                    </Button>
                </CardActions>
            </Card>
        </form>
    );
}
