// export const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

import { SelectOption } from '../types/generics';
import { IPolicyDowntimeDaySchema, IPolicyDowntimeSchema } from '../types/policy';

export enum DAYS {
  mon = 'Monday',
  tue = 'Tuesday',
  wed = 'Wednesday',
  thu = 'Thursday',
  fri = 'Friday',
  sat = 'Saturday',
  sun = 'Sunday',
}

export const CATEGORY_OPTIONS: SelectOption[] = [
  {
    value: 'Gaming',
    label: 'Gaming',
  },
  {
    value: 'Entertainment',
    label: 'Entertainment',
  },
  {
    value: 'Social',
    label: 'Social Media',
  },
];

export enum Group {
  acd = 'acd',
  iOS = 'iOS',
  android = 'android',
  macOS = 'macOS',
  windows = 'windows',
  other = 'other',
}

export enum UserType {
  serviceAccount = 'service account',
  admin = 'admin',
  parent = 'parent',
  child = 'child',
  guest = 'guest',
  firmwareAdmin = 'firmware-admin',
  teamAdmin = 'team-admin',
  coach = 'coach',
  client = 'client',
  member = 'member'
}

export enum NotificationState {
  ack = 'ack',
  read = 'read',
  unread = 'unread',
  closed = 'closed',
}

export const DefaultDayDownTime = {
  from: '21:00',
  until: '07:00',
};

export const DefaultSpaceTime: IPolicyDowntimeDaySchema = {
  from: '15:00',
  until: '16:00',
}

export const DefaultBedTime: IPolicyDowntimeSchema = {
  mon: [
    {
      from: '21:00',
      until: '07:00',
    },
  ],
  tue: [
    {
      from: '21:00',
      until: '07:00',
    },
  ],
  wed: [
    {
      from: '21:00',
      until: '07:00',
    },
  ],
  thu: [
    {
      from: '21:00',
      until: '07:00',
    },
  ],
  fri: [
    {
      from: '21:00',
      until: '07:00',
    },
  ],
  sat: [
    {
      from: '21:00',
      until: '07:00',
    },
  ],
  sun: [
    {
      from: '21:00',
      until: '07:00',
    },
  ],
  label: 'Bedtime',
  inactive: false,
};

export const COUNTRIES = [{
  "name": "Afghanistan",
  "code": "AF"
}, {
  "name": "Åland Islands",
  "code": "AX"
}, {
  "name": "Albania",
  "code": "AL"
}, {
  "name": "Algeria",
  "code": "DZ"
}, {
  "name": "American Samoa",
  "code": "AS"
}, {
  "name": "Andorra",
  "code": "AD"
}, {
  "name": "Angola",
  "code": "AO"
}, {
  "name": "Anguilla",
  "code": "AI"
}, {
  "name": "Antarctica",
  "code": "AQ"
}, {
  "name": "Antigua and Barbuda",
  "code": "AG"
}, {
  "name": "Argentina",
  "code": "AR"
}, {
  "name": "Armenia",
  "code": "AM"
}, {
  "name": "Aruba",
  "code": "AW"
}, {
  "name": "Australia",
  "code": "AU"
}, {
  "name": "Austria",
  "code": "AT"
}, {
  "name": "Azerbaijan",
  "code": "AZ"
}, {
  "name": "Bahamas",
  "code": "BS"
}, {
  "name": "Bahrain",
  "code": "BH"
}, {
  "name": "Bangladesh",
  "code": "BD"
}, {
  "name": "Barbados",
  "code": "BB"
}, {
  "name": "Belarus",
  "code": "BY"
}, {
  "name": "Belgium",
  "code": "BE"
}, {
  "name": "Belize",
  "code": "BZ"
}, {
  "name": "Benin",
  "code": "BJ"
}, {
  "name": "Bermuda",
  "code": "BM"
}, {
  "name": "Bhutan",
  "code": "BT"
}, {
  "name": "Bolivia, Plurinational State of",
  "code": "BO"
}, {
  "name": "Bonaire, Sint Eustatius and Saba",
  "code": "BQ"
}, {
  "name": "Bosnia and Herzegovina",
  "code": "BA"
}, {
  "name": "Botswana",
  "code": "BW"
}, {
  "name": "Bouvet Island",
  "code": "BV"
}, {
  "name": "Brazil",
  "code": "BR"
}, {
  "name": "British Indian Ocean Territory",
  "code": "IO"
}, {
  "name": "Brunei Darussalam",
  "code": "BN"
}, {
  "name": "Bulgaria",
  "code": "BG"
}, {
  "name": "Burkina Faso",
  "code": "BF"
}, {
  "name": "Burundi",
  "code": "BI"
}, {
  "name": "Cambodia",
  "code": "KH"
}, {
  "name": "Cameroon",
  "code": "CM"
}, {
  "name": "Canada",
  "code": "CA"
}, {
  "name": "Cape Verde",
  "code": "CV"
}, {
  "name": "Cayman Islands",
  "code": "KY"
}, {
  "name": "Central African Republic",
  "code": "CF"
}, {
  "name": "Chad",
  "code": "TD"
}, {
  "name": "Chile",
  "code": "CL"
}, {
  "name": "China",
  "code": "CN"
}, {
  "name": "Christmas Island",
  "code": "CX"
}, {
  "name": "Cocos (Keeling) Islands",
  "code": "CC"
}, {
  "name": "Colombia",
  "code": "CO"
}, {
  "name": "Comoros",
  "code": "KM"
}, {
  "name": "Congo",
  "code": "CG"
}, {
  "name": "Congo, the Democratic Republic of the",
  "code": "CD"
}, {
  "name": "Cook Islands",
  "code": "CK"
}, {
  "name": "Costa Rica",
  "code": "CR"
}, {
  "name": "Côte d'Ivoire",
  "code": "CI"
}, {
  "name": "Croatia",
  "code": "HR"
}, {
  "name": "Cuba",
  "code": "CU"
}, {
  "name": "Curaçao",
  "code": "CW"
}, {
  "name": "Cyprus",
  "code": "CY"
}, {
  "name": "Czech Republic",
  "code": "CZ"
}, {
  "name": "Denmark",
  "code": "DK"
}, {
  "name": "Djibouti",
  "code": "DJ"
}, {
  "name": "Dominica",
  "code": "DM"
}, {
  "name": "Dominican Republic",
  "code": "DO"
}, {
  "name": "Ecuador",
  "code": "EC"
}, {
  "name": "Egypt",
  "code": "EG"
}, {
  "name": "El Salvador",
  "code": "SV"
}, {
  "name": "Equatorial Guinea",
  "code": "GQ"
}, {
  "name": "Eritrea",
  "code": "ER"
}, {
  "name": "Estonia",
  "code": "EE"
}, {
  "name": "Ethiopia",
  "code": "ET"
}, {
  "name": "Falkland Islands (Malvinas)",
  "code": "FK"
}, {
  "name": "Faroe Islands",
  "code": "FO"
}, {
  "name": "Fiji",
  "code": "FJ"
}, {
  "name": "Finland",
  "code": "FI"
}, {
  "name": "France",
  "code": "FR"
}, {
  "name": "French Guiana",
  "code": "GF"
}, {
  "name": "French Polynesia",
  "code": "PF"
}, {
  "name": "French Southern Territories",
  "code": "TF"
}, {
  "name": "Gabon",
  "code": "GA"
}, {
  "name": "Gambia",
  "code": "GM"
}, {
  "name": "Georgia",
  "code": "GE"
}, {
  "name": "Germany",
  "code": "DE"
}, {
  "name": "Ghana",
  "code": "GH"
}, {
  "name": "Gibraltar",
  "code": "GI"
}, {
  "name": "Greece",
  "code": "GR"
}, {
  "name": "Greenland",
  "code": "GL"
}, {
  "name": "Grenada",
  "code": "GD"
}, {
  "name": "Guadeloupe",
  "code": "GP"
}, {
  "name": "Guam",
  "code": "GU"
}, {
  "name": "Guatemala",
  "code": "GT"
}, {
  "name": "Guernsey",
  "code": "GG"
}, {
  "name": "Guinea",
  "code": "GN"
}, {
  "name": "Guinea-Bissau",
  "code": "GW"
}, {
  "name": "Guyana",
  "code": "GY"
}, {
  "name": "Haiti",
  "code": "HT"
}, {
  "name": "Heard Island and McDonald Islands",
  "code": "HM"
}, {
  "name": "Holy See (Vatican City State)",
  "code": "VA"
}, {
  "name": "Honduras",
  "code": "HN"
}, {
  "name": "Hong Kong",
  "code": "HK"
}, {
  "name": "Hungary",
  "code": "HU"
}, {
  "name": "Iceland",
  "code": "IS"
}, {
  "name": "India",
  "code": "IN"
}, {
  "name": "Indonesia",
  "code": "ID"
}, {
  "name": "Iran, Islamic Republic of",
  "code": "IR"
}, {
  "name": "Iraq",
  "code": "IQ"
}, {
  "name": "Ireland",
  "code": "IE"
}, {
  "name": "Isle of Man",
  "code": "IM"
}, {
  "name": "Israel",
  "code": "IL"
}, {
  "name": "Italy",
  "code": "IT"
}, {
  "name": "Jamaica",
  "code": "JM"
}, {
  "name": "Japan",
  "code": "JP"
}, {
  "name": "Jersey",
  "code": "JE"
}, {
  "name": "Jordan",
  "code": "JO"
}, {
  "name": "Kazakhstan",
  "code": "KZ"
}, {
  "name": "Kenya",
  "code": "KE"
}, {
  "name": "Kiribati",
  "code": "KI"
}, {
  "name": "Korea, Democratic People's Republic of",
  "code": "KP"
}, {
  "name": "Korea, Republic of",
  "code": "KR"
}, {
  "name": "Kuwait",
  "code": "KW"
}, {
  "name": "Kyrgyzstan",
  "code": "KG"
}, {
  "name": "Lao People's Democratic Republic",
  "code": "LA"
}, {
  "name": "Latvia",
  "code": "LV"
}, {
  "name": "Lebanon",
  "code": "LB"
}, {
  "name": "Lesotho",
  "code": "LS"
}, {
  "name": "Liberia",
  "code": "LR"
}, {
  "name": "Libya",
  "code": "LY"
}, {
  "name": "Liechtenstein",
  "code": "LI"
}, {
  "name": "Lithuania",
  "code": "LT"
}, {
  "name": "Luxembourg",
  "code": "LU"
}, {
  "name": "Macao",
  "code": "MO"
}, {
  "name": "Macedonia, the Former Yugoslav Republic of",
  "code": "MK"
}, {
  "name": "Madagascar",
  "code": "MG"
}, {
  "name": "Malawi",
  "code": "MW"
}, {
  "name": "Malaysia",
  "code": "MY"
}, {
  "name": "Maldives",
  "code": "MV"
}, {
  "name": "Mali",
  "code": "ML"
}, {
  "name": "Malta",
  "code": "MT"
}, {
  "name": "Marshall Islands",
  "code": "MH"
}, {
  "name": "Martinique",
  "code": "MQ"
}, {
  "name": "Mauritania",
  "code": "MR"
}, {
  "name": "Mauritius",
  "code": "MU"
}, {
  "name": "Mayotte",
  "code": "YT"
}, {
  "name": "Mexico",
  "code": "MX"
}, {
  "name": "Micronesia, Federated States of",
  "code": "FM"
}, {
  "name": "Moldova, Republic of",
  "code": "MD"
}, {
  "name": "Monaco",
  "code": "MC"
}, {
  "name": "Mongolia",
  "code": "MN"
}, {
  "name": "Montenegro",
  "code": "ME"
}, {
  "name": "Montserrat",
  "code": "MS"
}, {
  "name": "Morocco",
  "code": "MA"
}, {
  "name": "Mozambique",
  "code": "MZ"
}, {
  "name": "Myanmar",
  "code": "MM"
}, {
  "name": "Namibia",
  "code": "NA"
}, {
  "name": "Nauru",
  "code": "NR"
}, {
  "name": "Nepal",
  "code": "NP"
}, {
  "name": "Netherlands",
  "code": "NL"
}, {
  "name": "New Caledonia",
  "code": "NC"
}, {
  "name": "New Zealand",
  "code": "NZ"
}, {
  "name": "Nicaragua",
  "code": "NI"
}, {
  "name": "Niger",
  "code": "NE"
}, {
  "name": "Nigeria",
  "code": "NG"
}, {
  "name": "Niue",
  "code": "NU"
}, {
  "name": "Norfolk Island",
  "code": "NF"
}, {
  "name": "Northern Mariana Islands",
  "code": "MP"
}, {
  "name": "Norway",
  "code": "NO"
}, {
  "name": "Oman",
  "code": "OM"
}, {
  "name": "Pakistan",
  "code": "PK"
}, {
  "name": "Palau",
  "code": "PW"
}, {
  "name": "Palestine, State of",
  "code": "PS"
}, {
  "name": "Panama",
  "code": "PA"
}, {
  "name": "Papua New Guinea",
  "code": "PG"
}, {
  "name": "Paraguay",
  "code": "PY"
}, {
  "name": "Peru",
  "code": "PE"
}, {
  "name": "Philippines",
  "code": "PH"
}, {
  "name": "Pitcairn",
  "code": "PN"
}, {
  "name": "Poland",
  "code": "PL"
}, {
  "name": "Portugal",
  "code": "PT"
}, {
  "name": "Puerto Rico",
  "code": "PR"
}, {
  "name": "Qatar",
  "code": "QA"
}, {
  "name": "Réunion",
  "code": "RE"
}, {
  "name": "Romania",
  "code": "RO"
}, {
  "name": "Russian Federation",
  "code": "RU"
}, {
  "name": "Rwanda",
  "code": "RW"
}, {
  "name": "Saint Barthélemy",
  "code": "BL"
}, {
  "name": "Saint Helena, Ascension and Tristan da Cunha",
  "code": "SH"
}, {
  "name": "Saint Kitts and Nevis",
  "code": "KN"
}, {
  "name": "Saint Lucia",
  "code": "LC"
}, {
  "name": "Saint Martin (French part)",
  "code": "MF"
}, {
  "name": "Saint Pierre and Miquelon",
  "code": "PM"
}, {
  "name": "Saint Vincent and the Grenadines",
  "code": "VC"
}, {
  "name": "Samoa",
  "code": "WS"
}, {
  "name": "San Marino",
  "code": "SM"
}, {
  "name": "Sao Tome and Principe",
  "code": "ST"
}, {
  "name": "Saudi Arabia",
  "code": "SA"
}, {
  "name": "Senegal",
  "code": "SN"
}, {
  "name": "Serbia",
  "code": "RS"
}, {
  "name": "Seychelles",
  "code": "SC"
}, {
  "name": "Sierra Leone",
  "code": "SL"
}, {
  "name": "Singapore",
  "code": "SG"
}, {
  "name": "Sint Maarten (Dutch part)",
  "code": "SX"
}, {
  "name": "Slovakia",
  "code": "SK"
}, {
  "name": "Slovenia",
  "code": "SI"
}, {
  "name": "Solomon Islands",
  "code": "SB"
}, {
  "name": "Somalia",
  "code": "SO"
}, {
  "name": "South Africa",
  "code": "ZA"
}, {
  "name": "South Georgia and the South Sandwich Islands",
  "code": "GS"
}, {
  "name": "South Sudan",
  "code": "SS"
}, {
  "name": "Spain",
  "code": "ES"
}, {
  "name": "Sri Lanka",
  "code": "LK"
}, {
  "name": "Sudan",
  "code": "SD"
}, {
  "name": "Suriname",
  "code": "SR"
}, {
  "name": "Svalbard and Jan Mayen",
  "code": "SJ"
}, {
  "name": "Swaziland",
  "code": "SZ"
}, {
  "name": "Sweden",
  "code": "SE"
}, {
  "name": "Switzerland",
  "code": "CH"
}, {
  "name": "Syrian Arab Republic",
  "code": "SY"
}, {
  "name": "Taiwan, Province of China",
  "code": "TW"
}, {
  "name": "Tajikistan",
  "code": "TJ"
}, {
  "name": "Tanzania, United Republic of",
  "code": "TZ"
}, {
  "name": "Thailand",
  "code": "TH"
}, {
  "name": "Timor-Leste",
  "code": "TL"
}, {
  "name": "Togo",
  "code": "TG"
}, {
  "name": "Tokelau",
  "code": "TK"
}, {
  "name": "Tonga",
  "code": "TO"
}, {
  "name": "Trinidad and Tobago",
  "code": "TT"
}, {
  "name": "Tunisia",
  "code": "TN"
}, {
  "name": "Turkey",
  "code": "TR"
}, {
  "name": "Turkmenistan",
  "code": "TM"
}, {
  "name": "Turks and Caicos Islands",
  "code": "TC"
}, {
  "name": "Tuvalu",
  "code": "TV"
}, {
  "name": "Uganda",
  "code": "UG"
}, {
  "name": "Ukraine",
  "code": "UA"
}, {
  "name": "United Arab Emirates",
  "code": "AE"
}, {
  "name": "United Kingdom",
  "code": "GB"
}, {
  "name": "United States",
  "code": "US"
}, {
  "name": "United States Minor Outlying Islands",
  "code": "UM"
}, {
  "name": "Uruguay",
  "code": "UY"
}, {
  "name": "Uzbekistan",
  "code": "UZ"
}, {
  "name": "Vanuatu",
  "code": "VU"
}, {
  "name": "Venezuela, Bolivarian Republic of",
  "code": "VE"
}, {
  "name": "Viet Nam",
  "code": "VN"
}, {
  "name": "Virgin Islands, British",
  "code": "VG"
}, {
  "name": "Virgin Islands, U.S.",
  "code": "VI"
}, {
  "name": "Wallis and Futuna",
  "code": "WF"
}, {
  "name": "Western Sahara",
  "code": "EH"
}, {
  "name": "Yemen",
  "code": "YE"
}, {
  "name": "Zambia",
  "code": "ZM"
}, {
  "name": "Zimbabwe",
  "code": "ZW"
}];


export const statusColors = {
  online: 'green',
  away: 'orange',
  busy: 'red',
  offline: 'gray'
}

export const weeklySummaryData = {
  "type": "weekly-summary-data",
  "min": 1,
  "max": 5,
  "start": "2024-07-01",
  "end": "2024-09-30",
  "categories": ["Mind", "Soul", "Money", "Relationship", "Nutrition", "Body"],
  "data": [
    {
      "year": 2024,
      "weekNumber": 27,
      "start": "2024-07-01",
      "end": "2024-07-07",
      "scores": [2, 2, 2, 3, 2, 2],
      "weeklyGrade": "C",
      "weeklyGradeScore": 65,
      "weeklyGradeAttributes": ["inconsistent", "poor scores", "missed checkins"],
      "myFeedback": "It's a rough start, but every journey begins with the first step.",
      "teamFeedback": "We all have slow weeks. Let's pick up the pace next week.",
      "coachFeedback": "Focus on building a habit of daily check-ins.",
      "myPlan": "Commit to a daily check-in and small improvements in each area."
    },
    {
      "year": 2024,
      "weekNumber": 28,
      "start": "2024-07-08",
      "end": "2024-07-14",
      "scores": [2, 3, 2, 3, 3, 2],
      "weeklyGrade": "C+",
      "weeklyGradeScore": 68,
      "weeklyGradeAttributes": ["inconsistent", "missed checkins"],
      "myFeedback": "Slight improvement, but consistency is key.",
      "teamFeedback": "Some progress, but let's aim for more consistency.",
      "coachFeedback": "You’re making small steps forward, keep it up and stay focused.",
      "myPlan": "Continue daily check-ins and focus on improving one category."
    },
    {
      "year": 2024,
      "weekNumber": 29,
      "start": "2024-07-15",
      "end": "2024-07-21",
      "scores": [3, 3, 3, 3, 3, 3],
      "weeklyGrade": "B-",
      "weeklyGradeScore": 72,
      "weeklyGradeAttributes": ["inconsistent", "improving"],
      "myFeedback": "Nice to see some balanced effort across the board.",
      "teamFeedback": "Good work this week! Keep that momentum going.",
      "coachFeedback": "You’re starting to get into the groove. Let’s aim for more consistency.",
      "myPlan": "Focus on maintaining this balance and consistency."
    },
    {
      "year": 2024,
      "weekNumber": 30,
      "start": "2024-07-22",
      "end": "2024-07-28",
      "scores": [3, 3, 4, 3, 4, 3],
      "weeklyGrade": "B",
      "weeklyGradeScore": 75,
      "weeklyGradeAttributes": ["improving", "consistent"],
      "myFeedback": "Your effort is starting to show results. Keep pushing!",
      "teamFeedback": "The team is picking up. Let’s aim for even better next week.",
      "coachFeedback": "You’re making solid progress. Keep this up!",
      "myPlan": "Increase focus on nutrition and relationships for better results."
    },
    {
      "year": 2024,
      "weekNumber": 31,
      "start": "2024-07-29",
      "end": "2024-08-04",
      "scores": [3, 4, 4, 4, 4, 3],
      "weeklyGrade": "B+",
      "weeklyGradeScore": 80,
      "weeklyGradeAttributes": ["consistent", "good scores"],
      "myFeedback": "Great improvement this week, especially in your relationships.",
      "teamFeedback": "Solid week overall. Let’s keep this trend going.",
      "coachFeedback": "You’re on the right track. Continue building on this foundation.",
      "myPlan": "Maintain the momentum and work on improving body scores."
    },
    {
      "year": 2024,
      "weekNumber": 32,
      "start": "2024-08-05",
      "end": "2024-08-11",
      "scores": [4, 4, 4, 4, 4, 4],
      "weeklyGrade": "A-",
      "weeklyGradeScore": 85,
      "weeklyGradeAttributes": ["consistent", "good scores"],
      "myFeedback": "You’re really hitting your stride now. Keep up the good work!",
      "teamFeedback": "This was a strong week for everyone. Well done!",
      "coachFeedback": "Fantastic effort. Let’s aim for even higher next week.",
      "myPlan": "Continue with this effort and aim for slight improvements in each area."
    },
    {
      "year": 2024,
      "weekNumber": 33,
      "start": "2024-08-12",
      "end": "2024-08-18",
      "scores": [4, 4, 4, 5, 4, 4],
      "weeklyGrade": "A",
      "weeklyGradeScore": 88,
      "weeklyGradeAttributes": ["consistent", "great scores"],
      "myFeedback": "Fantastic job, especially in relationships. Keep this up!",
      "teamFeedback": "The team is doing great. Let’s push even further!",
      "coachFeedback": "You’re setting a great example. Keep going!",
      "myPlan": "Continue on this path and try to achieve consistency across all categories."
    },
    {
      "year": 2024,
      "weekNumber": 34,
      "start": "2024-08-19",
      "end": "2024-08-25",
      "scores": [4, 5, 4, 5, 4, 5],
      "weeklyGrade": "A",
      "weeklyGradeScore": 90,
      "weeklyGradeAttributes": ["consistent", "great scores"],
      "myFeedback": "Excellent work, especially in Soul and Body. Keep pushing!",
      "teamFeedback": "Another strong week. Let’s maintain this level.",
      "coachFeedback": "Outstanding! Keep striving for excellence.",
      "myPlan": "Keep the momentum and look for areas of further improvement."
    },
    {
      "year": 2024,
      "weekNumber": 35,
      "start": "2024-08-26",
      "end": "2024-09-01",
      "scores": [5, 5, 5, 5, 5, 4],
      "weeklyGrade": "A+",
      "weeklyGradeScore": 95,
      "weeklyGradeAttributes": ["consistent", "great scores", "improving"],
      "myFeedback": "Outstanding progress! You're at the top of your game.",
      "teamFeedback": "We’ve reached a new peak. Let’s stay here!",
      "coachFeedback": "Fantastic! Let’s keep this momentum going.",
      "myPlan": "Maintain excellence and ensure sustainability of these great results."
    },
    {
      "year": 2024,
      "weekNumber": 36,
      "start": "2024-09-02",
      "end": "2024-09-08",
      "scores": [2, 5, 1, 5, 3, 4],
      "weeklyGrade": "A+",
      "weeklyGradeScore": 98,
      "weeklyGradeAttributes": ["consistent", "great scores", "improving"],
      "myFeedback": "Incredible job! You’ve mastered this.",
      "teamFeedback": "We’re all performing at our best. Let’s keep it up.",
      "coachFeedback": "Amazing progress. Continue leading by example.",
      "myPlan": "Sustain this level of excellence and support others in achieving the same."
    },
    {
      "year": 2024,
      "weekNumber": 37,
      "start": "2024-09-09",
      "end": "2024-09-15",
      "scores": [5, 5, 5, 5, 5, 5],
      "weeklyGrade": "A+",
      "weeklyGradeScore": 98,
      "weeklyGradeAttributes": ["consistent", "great scores"],
      "myFeedback": "You’ve been consistent and it’s paying off. Fantastic!",
      "teamFeedback": "The team is thriving! Let’s continue this streak.",
      "coachFeedback": "You’ve set a new standard. Keep up the excellent work!",
      "myPlan": "Maintain this high level of performance and inspire others."
    },
    {
      "year": 2024,
      "weekNumber": 38,
      "start": "2024-09-16",
      "end": "2024-09-22",
      "scores": [5, 5, 5, 5, 5, 5],
      "weeklyGrade": "A+",
      "weeklyGradeScore": 99,
      "weeklyGradeAttributes": ["consistent", "great scores"],
      "myFeedback": "Flawless execution. You’re at the top of your game!",
      "teamFeedback": "We’re performing exceptionally well. Let’s keep setting new benchmarks!",
      "coachFeedback": "Perfect performance! Continue to maintain this standard.",
      "myPlan": "Keep up the excellent work and support your teammates to achieve similar results."
    },
    {
      "year": 2024,
      "weekNumber": 39,
      "start": "2024-09-23",
      "end": "2024-09-29",
      "scores": [5, 5, 5, 5, 5, 5],
      "weeklyGrade": "A+",
      "weeklyGradeScore": 100,
      "weeklyGradeAttributes": ["consistent", "great scores", "improving"],
      "myFeedback": "You’ve achieved perfection! Incredible work.",
      "teamFeedback": "This has been the best week yet. Let’s keep this momentum!",
      "coachFeedback": "You’ve reached the pinnacle. Now focus on sustaining this excellence.",
      "myPlan": "Maintain your top-tier performance and help others achieve similar success."
    }
  ]
};

export const dateRangeOptions = [
  { value: "last-4-weeks", label: "Last 4 weeks" },
  { value: "last-8-weeks", label: "Last 8 weeks" },
  { value: "this-quarter", label: "This Quarter" },
  { value: "last-quarter", label: "Last Quarter" },
  { value: "custom", label: "Custom" }
];

export const messageMap: { [key: string]: (username: string) => string } = {
  uj: (username) => `${username} has joined the channel`, // user joined
  ujt: (username) => `${username} has joined the team`, // user joined team
  ul: (username) => `${username} has left the channel`, // user left
  ult: (username) => `${username} has left the team`, // user left team
  ru: (username) => `${username} has been removed from the channel`, // user removed
  "removed-user-from-team": (username) => `${username} has been removed from the team`, // user removed from team
  au: (username) => `${username} has been added to the channel`, // user added
  "added-user-to-team": (username) => `${username} has been added to the team`, // user added to team
  mute_unmute: (username) => `${username} has changed mute settings`, // mute/unmute
  r: (username) => `${username} has renamed the channel`, // channel renamed
  ut: (username) => `${username} has joined the conversation`, // user joined conversation
  wm: (username) => `${username} has sent a welcome message`, // welcome message
  rm: (username) => `${username} has removed a message`, // message removed
  "subscription-role-added": (username) => `${username} has added a role`, // role added
  "subscription-role-removed": (username) => `${username} has removed a role`, // role removed
  "room-archived": (username) => `${username} has archived the room`, // room archived
  "room-unarchived": (username) => `${username} has unarchived the room`, // room unarchived
  "room_changed_privacy": (username) => `${username} has changed the room privacy`, // room privacy changed
  "room_changed_avatar": (username) => `${username} has changed the room avatar`, // room avatar changed
  "room_changed_topic": (username) => `${username} has changed the room topic`, // room topic changed
  "room_e2e_enabled": (username) => `${username} has enabled end-to-end encryption`, // E2E encryption enabled
  "room_e2e_disabled": (username) => `${username} has disabled end-to-end encryption`, // E2E encryption disabled
  "room-removed-read-only": (username) => `${username} has removed read-only status`, // removed read-only status
  "room-set-read-only": (username) => `${username} has set the room to read-only`, // set read-only status
  "room-disallowed-reacting": (username) => `${username} has disallowed reacting`, // disallowed reacting
  "room-allowed-reacting": (username) => `${username} has allowed reacting`, // allowed reacting
  "user-added-room-to-team": (username) => `${username} has added a room to the team`, // added room to team
  "user-converted-to-channel": (username) => `${username} has converted to a channel`, // converted to channel
  "user-converted-to-team": (username) => `${username} has converted to a team`, // converted to team
  "user-deleted-room-from-team": (username) => `${username} has deleted a room from the team`, // deleted room from team
  "user-removed-room-from-team": (username) => `${username} has removed a room from the team`, // removed room from team
  "room_changed_announcement": (username) => `${username} has changed the room announcement`, // room announcement changed
  "room_changed_description": (username) => `${username} has changed the room description`, // room description changed
  other: (username) => `Action by ${username}`, // fallback for unhandled types
};

export const staticActiveProgramEnrollment = {
  "_isArchived": false,
  "programId": "66ccebb70d346500216eafd3",
  "userId": "6725c159df1ca6002128991e",
  "teamId": "6725b2f29f104000215605b2",
  "role": "member",
  "invitationCode": "D2G-test-team",
  "status": {
    "activeProgramPhase": {
      "phaseName": "Phase 1 Test Onboarding",
      "phaseNumber": 1
    },
    "statusText": "active",
    "_id": "6726fd036c575c00214a03bc"
  },
  "statusHistory": [
    {
      "_id": "6726fd036c575c00214a03bd",
      "modifiedOn": "2024-11-03T04:33:07.281Z",
      "modifiedBy": "1f7646ae1f6aae000d08cccc"
    },
    {
      "_id": "6726fd036c575c00214a03bc",
      "modifiedOn": "2024-11-03T04:33:11.855Z",
      "modifiedBy": "1f7646ae1f6aae000d08cccc"
    }
  ],
  "dates": {
    "_id": "6726fd036c575c00214a03be",
    "startedOn": "2024-11-03T04:33:07.281Z",
    "lastUpdatedOn": "2024-11-03T04:33:07.281Z"
  },
  "createdAt": "2024-11-03T04:33:07.288Z",
  "updatedAt": "2024-11-03T04:33:11.857Z",
  "__v": 0
}
