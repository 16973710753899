import * as React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { handleDrawer } from '../../../redux/actions/drawerAction';
import { useStyles } from './style';
import { RootState } from '../../../redux/store';
import { IUser } from '../../../types/user';
import { Box, Button, Collapse, useMediaQuery, useTheme } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SearchIcon from '@material-ui/icons/Search';
import Avatar from '@material-ui/core/Avatar';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SettingIcon from '../../../components/Icons/SettingIcon';
import HelpIcon from '../../../components/Icons/HelpIcon';
import HamburgerIcon from '../../../components/Icons/HamburgerIcon';
import "./style.scss";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import UsersIcon from '../../../components/Icons/UsersIcon';
import ListIcon from '../../../components/Icons/ListIcon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { ArrowLeft, Build } from '@material-ui/icons';
import ProgramWizardDrawer from '../ProgramList/ProgramDrillDown/components/ProgramWizardDrawer';
import { getTimeBasedGreeting } from '../../../util/DateTimeUtil';
interface PropsType {
  isOpen: boolean;
  user: IUser;
  loader: string;
  handleDrawer: (isOpen: boolean) => any;
}

function ResponsiveDrawer(props: PropsType) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const user = useSelector((state: RootState) => state.auth.user);

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [settingOpen, setSettingOpen] = React.useState(false);
  const [toolMenuOpen, setToolMenuOpen] = React.useState(false);
  const [isRegisterModalOpen, setRegisterModalOpen] = React.useState(false);
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const handleNavigate = (url) => history.push(url);
  const toggleDrawer = () => () => {
    setDrawerOpen(!drawerOpen);
  };
  const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:3000';

  const onChangeRegisterModal = () => {
    setRegisterModalOpen(!isRegisterModalOpen);
  };

  const updateActiveClass=(index)=>{setActiveTabIndex(index)}


  const logOut = () => {
    localStorage.clear();
    window.location.href = '/login';
  }

 

  const handleSetting = () => {
    setSettingOpen(!settingOpen);
  }
  const handleToolMenu = () => {
    setToolMenuOpen(!toolMenuOpen);
  }
  
  React.useEffect(() => {
    if (!isMobile) {
      setDrawerOpen(false);
    }
  }, [isMobile]);

  const toolMenuItems = [
    {
      label: "Register Program Wizard",
      onClick: onChangeRegisterModal
    }
  ];

  const settingItems = [

    {
      label: 'General',
      onClick: () => handleNavigate('/admin/setting/general')
    },
    {
      label: 'Payment',
      onClick: () => handleNavigate('/admin/setting/payment')
    },
    {
      label: 'Subscription',
      onClick: () => handleNavigate('/admin/setting/subscription')
    },
  ];
  const sideBarItems = [
    { type: 'item', path: '/admin/dashboard', label: 'Program List', icon: <ListIcon />, onClick: () => handleNavigate(`/admin/dashboard`)},
    {
      type: 'collapse',
      label: 'Tools Menu',
      icon: <Build style={{ width: 20, height: 20 }} />,
      isOpen: toolMenuOpen,
      onClick: handleToolMenu,
      nestedItems: toolMenuItems.map((item: any) => ({
        label: item.label,
        onClick: item.onClick, // Assign the onClick directly to open the modal
      }))
    },
    {
      type: 'item',
      path: '/admin/users',
      label: 'Users',
      icon: <UsersIcon />,
      onClick: () =>{ handleNavigate('/admin/users')}

    },
    {
      type: 'collapse',
      path: '/admin/setting',
      label: 'Setting',
      icon: <SettingIcon />,
      isOpen: settingOpen,
      onClick: handleSetting,
      nestedItems: settingItems.map((item: any) => ({
        label: item.label,
        onClick: item.onClick, // Assign the onClick directly to open the modal
      }))

    },
    {
      type: 'item',
      path: '/admin/support',
      label: 'Support',
      icon: <HelpIcon />,
      onClick: () =>{ handleNavigate('/admin/support')}

    },
    {
      type: 'item',
      onClick: () => logOut(),
      label: 'Sign Out',
      icon: <ExitToAppIcon style={{ width: '24px', height: '21px' }} />,
    }
  ];

  return (
    <>
      {isMobile && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={toggleDrawer()}
          className={classes.hamburgerButton}
        >
          <HamburgerIcon />
        </IconButton>
      )}
      <Drawer
        className={`${classes.drawer} sidebar_Wrapper sidebar_Wrapper_admin`}
        variant={isMobile ? "temporary" : "permanent"}
        open={drawerOpen}
        onClose={toggleDrawer()}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <h2 className={classes.adminHeadingTitle}>MEOS Admin</h2>
        <List className={classes.list} >
          <ListItem>
            <Avatar
              alt={user?.name}
              className={classes.avatar}
              src={baseUrl + '/' + user?.avatarUrl}
            />
            <div>
              <span className={classes.greetingText}>{getTimeBasedGreeting()}!</span>
              <div className={classes.profileName}>{user && (user?.name + ' ' + user?.lastName)}</div>
            </div>
          </ListItem>
          <ListItem className={classes.search}>
            <SearchIcon fontSize='small' className={classes.searchIcon} />
            <InputBase
              className={classes.input}
              placeholder="Search"
              inputProps={{ 'aria-label': 'search' }}
              fullWidth
            />
          </ListItem>

          <Button onClick={() => history.push('/admin/dashboard')} className={classes.backBtn} color='default'>
            <ArrowLeft color='primary' /> Back to Dashboard
          </Button>

          {sideBarItems.map((item, index) => {
            switch (item.type) {
              case 'item':
                return (
                  <ListItem
                    key={index}
                    className={`${classes.listItem} ${activeTabIndex == index ? 'active' : ""}`}
                    button
                    onClick={() => {
                      item.onClick && item.onClick() 
                      updateActiveClass(index); 
                    }}
                                        
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText className={`${classes.listItemText}`}
                      secondary={item.label} />
                  </ListItem>
                );
              case 'collapse':
                return (
                  <React.Fragment key={index}>
                    <ListItem button    
                    onClick={() => {
                      item.onClick(); 
                      updateActiveClass(index); 
                    }}
                              
                    className={`${classes.listItem} ${activeTabIndex == index ? 'active' : ""}`}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText className={`${classes.listItemText}`} secondary={item.label} />
                      {item.isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItem>
                    <Collapse in={item.isOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {item?.nestedItems && item?.nestedItems.length > 0 && item?.nestedItems?.map((nestedItem, nestedIndex) => (
                          <ListItem
                            key={nestedIndex}
                            button
                            onClick={nestedItem.onClick}
                            className={`${classes.nested}`}
                          >
                            <ListItemText secondary={nestedItem.label} />
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </React.Fragment>
                );
              default:
                return null;
            }
          })}
        </List>
      </Drawer>

      <ProgramWizardDrawer open={isRegisterModalOpen} handleClose={onChangeRegisterModal} />
    </>
  );
}

const mapStateToProps = (store: RootState) => ({
  user: store.auth.user,
  isOpen: store.drawerReducer.isOpen,
  loader: store.drawerReducer.loader,
});

export default connect(mapStateToProps, {
  handleDrawer,
})(ResponsiveDrawer);
