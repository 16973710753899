import {
  GET_DOWN_TIME_OBJECT_DEFAULT,
  GET_DOWN_TIME_OBJECT_DEFAULT_FAILURE,
  GET_DOWN_TIME_OBJECT_DEFAULT_SUCCESS,
} from '../../types';
import { IAction, IDownTimeState } from '../../types/generics';

const initialState: IDownTimeState = {
  defaultDownTime: {
    label: 'Bedtime',
    mon: [
      {
        from: '2:00 PM',
        until: '6:00 PM',
      },
    ],
    tue: [
      {
        from: '2:00 PM',
        until: '6:00 PM',
      },
    ],
    wed: [
      {
        from: '2:00 PM',
        until: '6:00 PM',
      },
    ],
    thu: [
      {
        from: '2:00 PM',
        until: '6:00 PM',
      },
    ],
    fri: [
      {
        from: '2:00 PM',
        until: '6:00 PM',
      },
    ],
    sat: [
      {
        from: '2:00 PM',
        until: '6:00 PM',
      },
    ],
    sun: [
      {
        from: '2:00 PM',
        until: '6:00 PM',
      },
    ],
  },
  // downTime: {
  //   day: "Saturday",
  //   timeFrom: "2:00 PM",
  //   timeTo: "6:00 PM"
  // },
  isLoading: false,
  error: '',
};

const defaultDownTimeReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_DOWN_TIME_OBJECT_DEFAULT:
      return {
        ...state,
        isLoading: true,
      };
    case GET_DOWN_TIME_OBJECT_DEFAULT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        defaultDownTime: action.payload,
      };
    case GET_DOWN_TIME_OBJECT_DEFAULT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default defaultDownTimeReducer;
