import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useStyles from './style';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

interface PropsType {
  title: string;
  rightIcon?: JSX.Element;
  bottomSection?: JSX.Element;
  rightAction?: () => any;
  back?: () => any;
}

export default function ButtonAppBar({
  title,
  rightIcon,
  bottomSection,
  back,
}: PropsType) {
  const classes = useStyles()();

  return (
    <AppBar position="sticky" className={classes.appBar}>
      <Toolbar>
        {!!back && (
          <IconButton onClick={() => back()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M5.70679 9.70692C5.51926 9.89439 5.26495 9.99971 4.99979 9.99971C4.73462 9.99971 4.48031 9.89439 4.29279 9.70692L0.292786 5.70692C0.105315 5.51939 0 5.26508 0 4.99992C0 4.73475 0.105315 4.48045 0.292786 4.29292L4.29279 0.292919C4.48139 0.110761 4.73399 0.00996641 4.99619 0.0122448C5.25838 0.0145233 5.5092 0.119692 5.6946 0.305101C5.88001 0.490509 5.98518 0.741321 5.98746 1.00352C5.98974 1.26571 5.88894 1.51832 5.70679 1.70692L3.41379 3.99992H14.9998C15.265 3.99992 15.5194 4.10528 15.7069 4.29281C15.8944 4.48035 15.9998 4.7347 15.9998 4.99992C15.9998 5.26514 15.8944 5.51949 15.7069 5.70703C15.5194 5.89456 15.265 5.99992 14.9998 5.99992H3.41379L5.70679 8.29292C5.89426 8.48045 5.99957 8.73475 5.99957 8.99992C5.99957 9.26508 5.89426 9.51939 5.70679 9.70692Z" fill="black" />
            </svg>
          </IconButton>
        )}
        <Typography
          variant="h3"
          noWrap
          color="primary"
          className={classes.title}
        >
          {title}
        </Typography>
        {!!rightIcon && rightIcon}
      </Toolbar>
      {!!bottomSection && <div>{bottomSection}</div>}
    </AppBar>
  );
}
