import * as toastr from 'toastr';

import { IACDDevice, IDevice } from '../../types/device';
import {
  IAcdDeviceAppState,
  IAcdDeviceState,
  eAcdActionStatus,
} from '../../types/acitivity';
import { IAction, ResultResponse } from '../../types/generics';
import {
  acdOffApi,
  acdStateChangeApi,
  addAcdDeviceApi,
  getAcdDeviceApi,
  getAcdDevicesStates,
  getAllDevicesApi,
  getWeeklyAppActivity,
} from '../../api';
import { format, logger } from '../../datadogLogger';

import { Dispatch } from 'react';
import { Mixpanel } from '../../util/mixpanel';
import { RootState } from '../store';
import { UserType } from '../../constants/data';
import { getAcdDevices } from './parentSchedule';

export const GET_DEVICES_LOADER = 'GET_DEVICES_LOADER';
export const GET_DEVICES_SUCCESS = 'GET_DEVICES_SUCCESS';
export const GET_DEVICES_ERROR = 'GET_DEVICES_ERROR';
export const GET_ACD_DEVICES_LOADER = 'GET_ACD_DEVICES_LOADER';
export const GET_ACD_DEVICES_SUCCESS = 'GET_ACD_DEVICES_SUCCESS';
export const GET_ACD_DEVICES_ERROR = 'GET_ACD_DEVICES_ERROR';
export const ADD_ACD_LOADER = 'ADD_ACD_LOADER';
export const ADD_ACD_SUCCESS = 'ADD_ACD_SUCCESS';
export const ADD_ACD_ERROR = 'ADD_ACD_ERROR';
export const STATE_CHANGE_LOADING = 'STATE_CHANGE_LOADING';
export const STATE_CHANGE_SUCCESS = 'STATE_CHANGE_SUCCESS';
export const SATE_CHANGE_ERROR = 'SATE_CHANGE_ERROR';
export const ACD_OFF_LOADING = 'ACD_OFF_LOADING';
export const ACD_OFF_SUCCESS = 'ACD_OFF_SUCCESS';
export const ACD_OFF_ERROR = 'ACD_OFF_ERROR';
export const SELECT_ACD_HUB = 'SELECT_ACD_HUB';
export const GET_WEEKLY_APP_LIST_SUCCESS = 'GET_WEEKLY_APP_LIST_SUCCESS';
export const GET_WEEKLY_APP_LIST_FAIL = 'GET_WEEKLY_APP_LIST_FAIL';

export type UserID = {
  user: string;
};
// get All devices
export const getAllDevices = (teamId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(getAllLoading(true));
      const res = await getAllDevicesApi(teamId);
      if (res.success) {
        dispatch(getDevices(res.data));
      }
      dispatch(getAllLoading(false));
      logger.info(format(`Get all devices list for team=${teamId}`));
    } catch (error) {
      logger.error(format('Failed to get devices'), error);
      dispatch(getAllLoading(false));
    }
  };
};

export const getWeeklyAppList = (userId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(getAllLoading(true));
      const res = await getWeeklyAppActivity(userId);
      if (res.success) {
        dispatch(getApps(res.data));
      } else {
        dispatch(getDevicesError(res.message));
      }
      dispatch(getAllLoading(false));
      logger.info(format(`Get weekly app list for the user=${userId}`));
    } catch (error) {
      logger.error(format('Failed to get weekly app list'), error);
      dispatch(getDevicesError(error.toString()));
      dispatch(getAllLoading(false));
    }
  };
};

const getAllLoading = (isLoading: boolean) => {
  return {
    type: GET_DEVICES_LOADER,
    payload: isLoading,
  };
};

const getApps = (payload: any) => ({
  type: GET_WEEKLY_APP_LIST_SUCCESS,
  payload,
});

const getDevices = (payload: any) => ({
  type: GET_DEVICES_SUCCESS,
  payload,
});

const getDevicesError = (error: any) => ({
  type: GET_DEVICES_ERROR,
  payload: error,
});

const selectAcdHub = (payload: any) => ({
  type: SELECT_ACD_HUB,
  payload,
});
// get acd devices list

export const selectHub = (acdDevice: IACDDevice) => {
  return (dispatch: any) => {
    dispatch(selectAcdHub(acdDevice));
  };
};

export const acdDevicesList = (teamId: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      if(!teamId) return;
      dispatch(getAcdDevicesLoading(true));

      const res = await getAcdDeviceApi(teamId);
      if (res.success) {
        const devicesIds: string[] = [];
        const state = getState();
        const { user } = state.auth;
        const userDevices: IDevice[] = [];
        let newDevices: IDevice[] = [];
        if (user.type === UserType.child) {
          // eslint-disable-next-line array-callback-return
          res.data.map((device: IDevice) => {
            if (device.users.length === 0 || device.users.includes(user._id)) {
              devicesIds.push(device._id);
              userDevices.push(device);
            }
          });
        } else {
          res.data.forEach((device: IDevice) => {
            devicesIds.push(device._id);
            userDevices.push(device);
            if (
              !device.settings?.deviceSettings?._isConfigured &&
              device.apps.find((app) => !app.category) &&
              device.apps.find((app) => !app.label)
            ) {
              newDevices.push(device);
            }
          });
        }
        const allDevices = JSON.parse(JSON.stringify(userDevices));
        const response = await getAcdDevicesStates(devicesIds);

        const data = dataParsing(userDevices, response);

        dispatch(
          getAcdDevicesSuccess({
            acdDevices: data,
            allDevices,
            devicesIds,
            newDevices,
          })
        );
      } else {
        dispatch(getAcdDevicesError(res));
      }
      dispatch(getAcdDevicesLoading(false));
      logger.info(format(`Get acd devices list  for the team=${teamId}`));
    } catch (error) {
      logger.error(format('Failed to get acd devices'), error);
      dispatch(getAcdDevicesError(error));
      dispatch(getAcdDevicesLoading(false));
    }
  };
};

export const dataParsing = (
  data: IDevice[],
  response: ResultResponse<IAcdDeviceState>[]
): IDevice[] => {
  const statedApps: IAcdDeviceAppState[] = [];
  response.forEach((resp) => {
    if (resp.data.apps != null) {
      resp.data.apps.forEach((app) => {
        statedApps.push(app);
      });
    }
  });

  data.forEach((obj, index, devices) => {
    response.forEach((resp) => {
      if (resp.data.device === obj._id) {
        let device;
        device = {
          ...obj,
          expired: resp.data.expired,
          offline: resp.data.offline,
        };
        devices[index] = device;
      }
    });
    obj.apps.forEach((app, index, array) => {
      statedApps.forEach((statedApp) => {
        let obj;

        if (app.identifier === statedApp.identifier) {
          obj = {
            ...app,
            ...statedApp,
          };

          array[index] = obj;
        }
      });
    });
  });

  return data;
};

const getAcdDevicesLoading = (isLoading: boolean) => {
  return {
    type: GET_ACD_DEVICES_LOADER,
    payload: isLoading,
  };
};
export const getAcdDevicesSuccess = (payload: any) => ({
  type: GET_ACD_DEVICES_SUCCESS,
  payload,
});
export const getAcdDevicesError = (error: ResultResponse) => ({
  type: GET_ACD_DEVICES_ERROR,
  payload: error,
});

// add acd devices

export const addAcdDevice = (data: IDevice) => {
  return async (dispatch: any) => {
    try {
      dispatch(addDeviceLoader(true));
      const res = await addAcdDeviceApi(data);
      if (res.success) {
        dispatch(addAcdSuccess(res));
      } else {
        dispatch(addAcdError(res));
      }
      dispatch(addDeviceLoader(false));
    } catch (error) {
      logger.error(format('Failed to add new acd device'), error);
      dispatch(addAcdError(error));
      dispatch(addDeviceLoader(false));
    }
  };
};

const addDeviceLoader = (isLoading: boolean) => ({
  type: ADD_ACD_LOADER,
  payload: isLoading,
});

const addAcdSuccess = (payload: ResultResponse<IDevice>) => ({
  type: ADD_ACD_SUCCESS,
  payload,
});
const addAcdError = (error: any) => ({
  type: ADD_ACD_ERROR,
  payload: error,
});

// acd device state change

export const acdStateChange = (
  deviceId: string,
  identifier: string,
  data: UserID
) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      setTimeout(() => dispatch(deviceStateLoader('no loader')), 6000);
      dispatch(deviceStateLoader(identifier));
      Mixpanel.track("Port Open Initiated", { ...data, deviceId, identifier })
      const res = await acdStateChangeApi(deviceId, identifier, data);
      if (res.success) {
        Mixpanel.track("Port Open Success", { ...data, deviceId, identifier })
        const state = getState();
        const allAcd: IACDDevice[] = JSON.parse(
          JSON.stringify(state.devicesReducer.acdDevices)
        );

        const parsedData = dataParsingState(allAcd, res.data, deviceId);

        dispatch(stateChangeSuccess(parsedData));

        const teamId = localStorage.getItem('team');
        dispatch(getAcdDevices(teamId));
        setTimeout(() => dispatch(getAcdDevices(teamId)), 8000);
        setTimeout(() => dispatch(getAcdDevices(teamId)), 16000);
        // setTimeout(() => dispatch(getAcdDevices(teamId)), 20000);
      } else {
        toastr.error(res.message);
        dispatch(stateChangeError(res));
        Mixpanel.track("Port Open Failed", { ...data, deviceId, identifier })
      }
      logger.info(format('Device state changed.', deviceId));
    } catch (error) {
      logger.error(format('Failed to change device state', deviceId), error);
      Mixpanel.track("Port Open Failed", { ...data, deviceId, identifier })
      if (error.response.status === 405) {
        toastr.error(error.response?.data.message, '', {
          iconClass: 'toast-block',
        });
      } else {
        toastr.error(error.response?.data.message);
      }
      setTimeout(() => dispatch(deviceStateLoader('no loader')), 6000);
      dispatch(stateChangeError(error));
    }
  };
};

const dataParsingState = (
  data: IACDDevice[],
  response: IAcdDeviceState,
  deviceId: string
): IACDDevice[] => {
  const statedApps = response.apps;

  data.forEach((device) => {
    device.apps.forEach((app, index, array) => {
      statedApps.forEach((statedApp) => {
        let obj;

        if (app.identifier === statedApp.identifier) {
          obj = {
            ...app,
            ...statedApp,
          };

          array[index] = obj;
        } else if (device._id === deviceId) {
          obj = {
            ...app,
            state: 'off',
          };
          array[index] = obj;
        }
      });
    });
  });

  return data;
};

const deviceStateLoader = (isLoading: string) => ({
  type: STATE_CHANGE_LOADING,
  payload: isLoading,
});

const stateChangeSuccess = (payload: IACDDevice[]) => ({
  type: STATE_CHANGE_SUCCESS,
  payload,
});

const stateChangeError = (hasError: any) => ({
  type: SATE_CHANGE_ERROR,
  payload: hasError,
});

export const offAcd = (deviceId: string, data: UserID, identifier: string) => {
  return async (dispatch: Dispatch<IAction>, getState: () => RootState) => {
    try {
      dispatch(deviceStateLoader(identifier));
      Mixpanel.track("Port Closed Initiated", { ...data, deviceId })
      const res = await acdOffApi(deviceId, data);
      if (res.success) {
        const state = getState();
        const userDevices = state.devicesReducer.acdDevices;
        const parsedData = acdOffParser(userDevices, res.data);

        dispatch(acdOffSuccess(parsedData));
        Mixpanel.track("Port Closed Success", { ...data, deviceId })
      } else {
        Mixpanel.track("Port Closed Failed", { ...data, deviceId })
        dispatch(acdOffError(res));
      }
      dispatch(deviceStateLoader('no loader'));
      logger.info(format('Acd deviced off', deviceId));
    } catch (error) {
      logger.error(format('Failed to off Acd device', deviceId), error);
      Mixpanel.track("Port Closed Failed", { ...data, deviceId })
      if (error.response) {
        toastr.error(error.response?.data.message);
      }
      dispatch(acdOffError(error));
      dispatch(deviceStateLoader('no loader'));
    }
  };
};

const acdOffParser = (
  allDevices: IACDDevice[],
  targetDevice: IAcdDeviceState
): IACDDevice[] => {
  return allDevices.map((device) => {
    if (device._id === targetDevice.device) {
      device.apps.forEach((app, j, array) => {
        const obj = {
          ...app,
          state: 'off',
          user: '',
          started: '',
          status: eAcdActionStatus.unknown,
        };
        array[j] = obj;
      });
    }

    return device;
  });
};

const acdOffSuccess = (payload: IACDDevice[]) => ({
  type: ACD_OFF_SUCCESS,
  payload,
});

const acdOffError = (hasError: any) => ({
  type: ACD_OFF_ERROR,
  payload: hasError,
});
