import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Announcements from '../../../components/Announcements';
import ActionItems from '../../../components/ActionItems';
import { makeStyles } from '@material-ui/core/styles';
import Schedule from '../Schedule';
import MonthlyPerformanceCard from '../../../components/MonthlyPerformance';
import FeedbackView from '../MyResults/components/FeedBackView';
import PerformanceScoreCard from '../../../components/Results';
import IntroVideo from '../IntroVideo';
import { RootState } from '../../../redux/store';
import { useSelector, useDispatch } from 'react-redux';

import { IProgramState } from '../../../types/program';
import { IProgramEnrollment, IProgramEnrollmentState } from '../../../types/programEnrollment';
import MyEnrollmentHealthCheckModal from '../../../components/MyEnrollmentHealthCheckModal';
import { hideHealthCheckModal } from '../../../redux/actions/programEnrollmentAction';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    backgroundColor: '#F7F9FC',
  },
}));

const ClientDashboard = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    
    const programEnrollment = JSON.parse(localStorage.getItem('activeProgramEnrollment')) || {};
    const programs = useSelector((state: RootState) => state.programReducer.singleProgram);
    const myGoalsData = useSelector((state: RootState) => state.dashboardReducer.myGoalsData);
    const programId = programEnrollment?.programId;
    const userId = programEnrollment?.userId;
    const year = programEnrollment?.dates?.activeEnrollmentYear;
    const { announcementList } = useSelector((state: RootState) => state.programReducer)

    //for now hard code the video url based on program id
    const welcomeVideoURl = (programId==='6793f2a8747941002130916b') ? 'https://player.vimeo.com/video/1060337270?h=4f0dcbd0ae' : 'https://player.vimeo.com/video/1056339099?h=e87513a0da';

    const handleCloseModal = () => {
        console.log("Closing Health Check");
        //showHealthCheckModal = false;
        dispatch(hideHealthCheckModal());
    };
      

    // Avoid rendering warnings until myGoalsData is fully available
    const [warnings, setWarnings] = useState<string[]>([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
        if (myGoalsData) {
            setIsDataLoaded(true); // Mark as loaded when myGoalsData is available

            const totalActiveGoalsCount = myGoalsData.groups?.reduce(
                (count, group) => count + group.goals.filter(goal => goal.isActive).length,
                0
            ) || 0;

            let newWarnings: string[] = [];
            if (totalActiveGoalsCount === 0) {
                const msg = "You have not set any goals yet. Please set your quarterly goals in your backpack to get started.";
                if(!warnings || !warnings.includes(msg)) {
                  newWarnings.push(msg);
                }
            }
            
            setWarnings(newWarnings);
        }
    }, [myGoalsData]); // Run this effect only when myGoalsData changes

    // Compute totalGoalsCount safely
    const totalActiveGoalsCount = myGoalsData?.groups?.reduce(
      (count, group) => count + group.goals.filter(goal => goal.isActive).length,
      0
    ) || 0;


    if(totalActiveGoalsCount === 0) {
        warnings.push("You have not set any goals yet. Please set your quarterly goals in your backpack to get started.");
    }


    // Read the health check modal state from Redux.
    let showHealthCheckModal = useSelector((state: RootState) => state.programEnrollmentReducer.showHealthCheckModal);
    const healthIssues = useSelector((state: RootState) => state.programEnrollmentReducer.healthCheckIssues);
    
  return (

    <div className={classes.container}>
  
      {showHealthCheckModal &&
      <MyEnrollmentHealthCheckModal issues={healthIssues} onClose={handleCloseModal} />}

      {/* Only show warnings if data is loaded */}
      {isDataLoaded && warnings.length > 0 && (
                <div style={{ padding: '10px', backgroundColor: '#ffcccc', marginBottom: '20px' }}>
                    <h3>Warnings</h3>
                    <ul>
                        {warnings.map((warning, index) => (
                            <li key={index}>{warning}</li>
                        ))}
                    </ul>
                </div>
      )}
      {announcementList?.length > 0 &&<Grid item xs={12}>
        <Announcements />
      </Grid>
      }
      <Grid item xs={12}>
        <ActionItems />
      </Grid>
      <Grid container spacing={2} >
        <Grid item xs={12} md={6} sm={12}>
          <PerformanceScoreCard />
          <MonthlyPerformanceCard />
        </Grid>
        <Grid item xs={12} md={6} sm={12}>
          <IntroVideo videoUrl={welcomeVideoURl} />
        </Grid>
      </Grid>
      <Grid container spacing={2} >
        <Grid item xs={12} sm={12} md={6} lg={6}>
          
        </Grid>
      </Grid>
    </div>
  );
};

export default ClientDashboard;
