import React from 'react'

const ListIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 8H21M7 12H21M7 16H21M3 8H3.01M3 12H3.01M3 16H3.01" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default ListIcon;