import React from 'react'
import ButtonAppBar from '../../../components/AppBar'
import { Grid, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router';
import FeedbackView from './components/FeedBackView';
import { useStyles } from './style';
import ProgressCmp from './components/ProgressVIew';
import HeaderNotificationIcon from '../../../components/HeaderNotification';



const MyResults = () => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <>
            <ButtonAppBar title={"My Results"} back={() => history.go(-1)} rightIcon={<HeaderNotificationIcon/>} />
            <div className={classes.container}>
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <ProgressCmp />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <FeedbackView />
                    </Grid>
                </Grid>
            </div>

        </>
    )
}

export default MyResults