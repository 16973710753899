import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    drawer: {
        width: 280,
        flexShrink: 0,
        fontFamily: "Nunito Sans"
    },
    drawerPaper: {
        width: 280,
    },
    logo: {
        fontWeight: 500,
        textDecoration: "none",
        color: '#FFB819'
    },
    avatar: {
        marginRight: theme.spacing(2),
        width: theme.spacing(7),
        height: theme.spacing(7),
        borderRadius: "50% !important"
    },
    listItem: {
        textTransform: 'capitalize',
        borderRadius: 8,
        '&.active': {
            backgroundColor: '#232222',
            color: '#ffffff',
        },
    },
    listItemText: {
        fontFamily: "Nunito Sans",
        fontSize: '14px',
        marginLeft: theme.spacing(1),
        '&.active': {
            backgroundColor: '#232222',
            color: '#ffffff',
        },
    },
    nested: {
        paddingLeft: theme.spacing(4),
        '&.active': {
            backgroundColor: '#232222',
            color: '#ffffff',
        },
    },
    innernested: {
        paddingLeft: theme.spacing(6),
        '&.active': {
            backgroundColor: '#232222',
            color: '#ffffff',
        },
    },
    search: {
        position: 'relative',
        borderRadius: 8,
        backgroundColor: theme.palette.action.hover,
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1),
    },
    grey: {
        color: '#00000099',
        fontSize: "12px"
    },
    profileName: {
        color: "#000000DE",
        fontWeight: 500,
        textTransform: 'capitalize'
    },
    list: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 5,
        paddingRight: 5
    },
    input: {
        fontSize: "14px"
    },
    hamburgerButton: {
        position: 'absolute',
        top: 0,
        left: theme.spacing(1),
    }
}));