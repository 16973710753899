import { HANDLE_DRAWER } from '../actions/drawerAction';
import { IDrawerState } from '../../types/drawer';
import { IAction } from '../../types/generics';

const initState: IDrawerState = {
  isOpen: false,
  loader: '',
};

export default function (state = initState, action: IAction) {
  switch (action.type) {
    case HANDLE_DRAWER: {
      return {
        ...state,
        loader: new Date(),
        isOpen: action.payload,
      };
    }

    default:
      return state;
  }
}
