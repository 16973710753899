import React from 'react';
import { makeStyles } from '@material-ui/core/styles'; 

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
    },
    title: {
        fontSize: '24px',
        color: '#555',
        marginBottom: '16px',
    },
    message: {
        fontSize: '18px',
        color: '#777',
    }
});

const DefaultScreen = ({ featureName = "This feature" }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.title}>{featureName} is coming soon!</div>
            <div className={classes.message}>We're working hard to bring this feature to you. Stay tuned!</div>
        </div>
    );
};

export default DefaultScreen;
