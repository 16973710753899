import { createChannelRc, createDmRc, deleteDMMessageRc, getAutoCompleteUsersRc, getChannelInfoRc, getDMListRC, getDMMessagesRC, getJoinedChannelsListRc, getUserInfoRc, sendDMMessageRc, updateDMMessageRc, uploadFileToRoomRc } from "../../api";
import toastr from 'toastr';

export const GET_USERS_LIST_RC = 'GET_USERS_LIST_RC';
export const GET_USERS_LIST_ERROR_RC = 'GET_USERS_LIST_ERROR_RC';
export const GET_ROOM_DATA_RC = "GET_ROOM_DATA_RC";
export const GET_MESSAGES_RC = 'GET_MESSAGES_RC';
export const GET_MESSAGES_ERROR_RC = 'GET_MESSAGES_ERROR_RC';
export const SEND_MESSAGE_RC = 'SEND_MESSAGE_RC';
export const UPDATE_MESSAGE_RC = "UPDATE_MESSAGE_RC";
export const DELETE_MESSAGE_RC = 'DELETE_MESSAGE_RC';
export const GET_JOINED_CHANNELS_LIST_RC = 'GET_JOINED_CHANNELS_LIST_RC';
export const GET_JOINED_CHANNELS_LIST_ERROR_RC = 'GET_JOINED_CHANNELS_LIST_ERROR_RC';
export const CREATE_CHANNEL_RC = 'CREATE_CHANNEL_RC';
export const CREATE_CHANNEL_RC_LOADING = "CREATE_CHANNEL_RC_LOADING";
export const CREATE_CHANNEL_RC_ERROR = "CREATE_CHANNEL_RC_ERROR";
export const GET_AUTOCOMPLETE_USERS_LIST_RC = 'GET_AUTOCOMPLETE_USERS_LIST_RC';
export const GET_AUTOCOMPLETE_USERS_LIST_ERROR_RC = 'GET_AUTOCOMPLETE_USERS_LIST_ERROR_RC';
export const GET_CHAT_USERS_INFO_RC = 'GET_CHAT_USERS_INFO_RC';
export const GET_CHAT_USERS_INFO_ERROR_RC = 'GET_CHAT_USERS_INFO_ERROR_RC';
export const GET_CHANNEL_INFO_RC = 'GET_CHANNEL_INFO_RC';
export const GET_CHANNEL_INFO_ERROR_RC = 'GET_CHANNEL_INFO_ERROR_RC';
export const UPLOAD_FILE_TO_ROOM_RC = 'UPLOAD_FILE_TO_ROOM_RC';
export const UPLOAD_FILE_TO_ROOM_ERROR_RC = 'UPLOAD_FILE_TO_ROOM_ERROR_RC';
export const SET_IS_EDIT_MESSAGE_RC = "SET_IS_EDIT_MESSAGE_RC";
export const SET_ENTERED_TEXT_RC = "SET_ENTERED_TEXT_RC";
export const CANCEL_IS_EDIT_MESSAGE_RC = 'CANCEL_IS_EDIT_MESSAGE_RC';
export const SET_MESSAGE_ID_RC = 'SET_MESSAGE_ID_RC';
export const GET_USER_PROFILE_INFO_RC = "GET_USER_PROFILE_INFO_RC";
export const GET_USER_PROFILE_INFO_ERROR_RC = "GET_USER_PROFILE_INFO_ERROR_RC";
export const UPDATE_MESSAGE_LIST_SO_RC = "UPDATE_MESSAGE_LIST_SO_RC";
export const CREATE_DM_RC = 'CREATE_DM_RC';
export const CREATE_DM_RC_LOADING = 'CREATE_DM_RC_LOADING';
export const CREATE_DM_RC_ERROR = 'CREATE_DM_RC_ERROR';

export const getRCDmList = () => {
    return async (dispatch: any) => {
        try {
            const res = await getDMListRC();

            if (res) {
                dispatch({
                    type: GET_USERS_LIST_RC,
                    payload: res,
                });
            } else {
                dispatch({
                    type: GET_USERS_LIST_ERROR_RC,
                    payload: res,
                });
            }

        } catch (error) {
            console.error(error)
        }
    };
};

export const getChatMessages = (roomId: string, roomType: string, offset: number = 0, count: number = 50) => {
    return async (dispatch: any) => {
        try {
            let endPoint: string;

            if (roomType === "channel") {
                endPoint = "channels.messages";
            }
            if (roomType === "direct") {
                endPoint = "im.history"
            }

            const res: any = await getDMMessagesRC(endPoint, roomId, offset, count);

            if (res.success) {
                dispatch({
                    type: GET_MESSAGES_RC,
                    payload: res?.messages.reverse(),
                });
            }
        } catch (error) {
            dispatch({
                type: GET_MESSAGES_ERROR_RC,
                payload: error?.errorType,
            });
        }
    };
};

export const sendDmMessage = (body: any) => {
    return async (dispatch: any) => {
        try {
            const response: any = await sendDMMessageRc(body);
            dispatch({
                type: SEND_MESSAGE_RC,
                payload: response,
            });

        } catch (error) {
            console.error(error)
        }
    };
};

export const updateDmMessage = (body: any) => {
    return async (dispatch: any) => {
        try {
            const res = await updateDMMessageRc(body);
            dispatch({
                type: UPDATE_MESSAGE_RC,
                payload: res,
            });
        } catch (error) {
            console.error(error)
        }
    };
};

export const deleteDmMessage = (roomId: string, msgId: string) => {
    return async (dispatch: any) => {
        try {
            const payload = { roomId, msgId };
            const res = await deleteDMMessageRc(payload);
            dispatch({
                type: DELETE_MESSAGE_RC,
                payload: res,
            });
            toastr.success("Your entry has been deleted.");


        } catch (error) {
            console.error(error)
        }
    };
};

export const getRCJoinedChannelList = () => {
    return async (dispatch: any) => {
        try {
            const res = await getJoinedChannelsListRc();

            if (res) {
                dispatch({
                    type: GET_JOINED_CHANNELS_LIST_RC,
                    payload: res,
                });
            }
            else {
                dispatch({
                    type: GET_JOINED_CHANNELS_LIST_ERROR_RC,
                    payload: res,
                });
            }

        } catch (error) {
            console.error(error)
        }
    };
};

export const createRCChannel = (body: any) => {
    return async (dispatch: any) => {
        dispatch({ type: CREATE_CHANNEL_RC_LOADING });
        try {
            const res = await createChannelRc(body);
            if (res) {
                dispatch({
                    type: CREATE_CHANNEL_RC,
                    payload: res,
                });
                toastr.success("Channel Created Successfully");
            }

        } catch (error) {
            dispatch({
                type: CREATE_CHANNEL_RC_ERROR,
                payload: error.message
            });
        }
    };
};

export const getRcAutoCompleteUsersList = (search: string) => {
    return async (dispatch: any) => {
        try {

            const res = await getAutoCompleteUsersRc(search);

            if (res) {
                dispatch({
                    type: GET_AUTOCOMPLETE_USERS_LIST_RC,
                    payload: res,
                });
            } else {
                dispatch({
                    type: GET_AUTOCOMPLETE_USERS_LIST_RC,
                    payload: 'Failed to fetch users',
                });
            }
        } catch (error) {
            dispatch({
                type: GET_AUTOCOMPLETE_USERS_LIST_ERROR_RC,
                payload: error.message,
            });
        }
    };
};

export const getRCUserInfo = (id: string) => {
    return async (dispatch: any) => {
        try {

            const res = await getUserInfoRc(id);

            if (res) {
                dispatch({
                    type: GET_CHAT_USERS_INFO_RC,
                    payload: res,
                });
            } else {
                dispatch({
                    type: GET_CHAT_USERS_INFO_ERROR_RC,
                    payload: 'Failed to fetch user',
                });
            }
        } catch (error) {
            dispatch({
                type: GET_CHAT_USERS_INFO_ERROR_RC,
                payload: error.message,
            });
        }
    };
};

export const getRCChannelInfo = (roomId: string) => {
    return async (dispatch: any) => {
        try {

            const res = await getChannelInfoRc(roomId);

            if (res) {
                dispatch({
                    type: GET_CHANNEL_INFO_RC,
                    payload: res,
                });
            }
        } catch (error) {
            dispatch({
                type: GET_CHANNEL_INFO_ERROR_RC,
                payload: error?.errorType,
            });
        }
    };
};

export const uploadFileToRoom = (roomId: string, FormData: FormData) => {
    return async (dispatch: any) => {
        dispatch({ type: CREATE_CHANNEL_RC_LOADING });
        try {
            console.log("FormData", FormData)
            const res = await uploadFileToRoomRc(roomId, FormData);
            if (res) {
                dispatch({
                    type: UPLOAD_FILE_TO_ROOM_RC,
                    payload: res,
                });
            }

        } catch (error) {
            dispatch({
                type: UPLOAD_FILE_TO_ROOM_ERROR_RC,
                payload: error.message
            });
        }
    };
};

export const getRCUserProfileInfo = () => {
    return async (dispatch: any) => {
        try {

            const id = localStorage.getItem('rcUserId');

            const res = await getUserInfoRc(id);

            if (res) {
                dispatch({
                    type: GET_USER_PROFILE_INFO_RC,
                    payload: res,
                });
            }
        } catch (error) {
            dispatch({
                type: GET_USER_PROFILE_INFO_ERROR_RC,
                payload: error.message,
            });
        }
    };
};

export const createRC_DM = (username: string) => {
    return async (dispatch: any) => {
        dispatch({ type: CREATE_DM_RC_LOADING });
        try {
            const res = await createDmRc(username);
            if (res) {
                dispatch({
                    type: CREATE_DM_RC,
                    payload: res, 
                });
            }
            return res;
        } catch (error) {
            dispatch({
                type: CREATE_DM_RC_ERROR,
                payload: error.message,
            });
            toastr.error("Failed to create DM session");
        }
    };
};

export const setMessageEdit = () => ({
    type: SET_IS_EDIT_MESSAGE_RC,
    payload: true,
});

export const cancelMessageEdit = () => ({
    type: CANCEL_IS_EDIT_MESSAGE_RC,
    payload: false,
});

export const setMessageId = (payload: string) => ({
    type: SET_MESSAGE_ID_RC,
    payload
})

export const setEnteredText = (payload: string) => ({
    type: SET_ENTERED_TEXT_RC,
    payload,
});

export const updateMessageListSoRc = (payload: any) => ({
    type: UPDATE_MESSAGE_LIST_SO_RC,
    payload,
});