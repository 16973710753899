import React, { useEffect } from 'react'
import ButtonAppBar from '../../../components/AppBar'
import HeaderNotificationIcon from '../../../components/HeaderNotification'
import { useHistory } from 'react-router'
import { Grid, Card, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import { useStyles } from '../ClientDashboard/style';
import { Skeleton } from '@material-ui/lab';
import RedDotIcon from '../../../components/Icons/RedDotIcon';
import GreenDotIcon from '../../../components/Icons/GreenDotIcon';
import { getProgramActionItemsData, getProgramFormContextUserData, setProgramFormContext } from '../../../redux/actions/programAction';
import { staticActiveProgramEnrollment } from '../../../constants/data';

const ActionItems = () => {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const {programId} = JSON.parse(localStorage.getItem('activeProgramEnrollment')) ||  {};
    const { contextLoading, contexts } = useSelector((state: RootState) => state.programReducer);
    const { _id: userId } = useSelector((state: RootState) => state.auth.user);

    const handleNavigateContext = (item) => {
        const {sourceFormId, userId, contextId} = item;
        console.log(item);
        history.push(`/user/form/show?sourceFormId=${sourceFormId}&userId=${userId}&contextId=${contextId}`)
        dispatch(setProgramFormContext(item));
        dispatch(getProgramFormContextUserData(userId))
      }
    

    useEffect(() => {
        dispatch(getProgramActionItemsData(programId, userId))
    }, [dispatch]);

    return (
        <>
            <ButtonAppBar title={"Action"} back={() => history.go(-1)} rightIcon={<HeaderNotificationIcon />} />
            <div className={classes.container}>
                <Grid container spacing={2} className={classes.divider}>
                    <Grid item xs={12} md={6}>
                        <Typography className={classes.caption} variant="caption" >Past due ({contexts?.overDueContexts ? contexts?.overDueContexts?.length : 0})</Typography>
                        {contextLoading ? (
                            <>
                                {[...Array(3)].map((_, index) => (
                                    <Skeleton key={index} variant="rect" height={70} style={{ marginBottom: '10px' }} />
                                ))}
                            </>
                        ) : contexts && contexts?.overDueContexts?.length > 0 ?
                            contexts?.overDueContexts?.map((item: any) => {
                                const { label, sourceFormId, _id, userId, contextId, dueDate } = item;
                                return (
                                    <Card className={classes.actionCard}>
                                        <div className={classes.actionCardHeader}>
                                            <Typography variant="body1" className={classes.overdue}><span style={{ marginRight: '10px' }}><RedDotIcon /></span> {label}</Typography>
                                            <span className={classes.checkInbutton}
                                                onClick={() => handleNavigateContext(item)}>Checkin now</span>
                                        </div>
                                        <Typography variant="caption" className={classes.dueText}> {new Date(item.dueDate).toDateString()} (Overdue)</Typography>
                                    </Card>
                                )
                            }) : (
                                <Typography>No overdue action items available.</Typography>
                            )
                        }
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography className={classes.caption} variant="caption" >Upcoming ({contexts?.upcomingContexts ? contexts?.upcomingContexts?.length : 0})</Typography>
                        {contextLoading ? (
                            <>
                                {[...Array(3)].map((_, index) => (
                                    <Skeleton key={index} variant="rect" height={70} style={{ marginBottom: '10px' }} />
                                ))}
                            </>
                        ) : contexts && contexts?.upcomingContexts?.length > 0 ? contexts?.upcomingContexts?.map((item: any) => {
                            const { label, sourceFormId, _id, userId, contextId, dueDate } = item;
                            const daysUntilDue = Math.ceil((new Date(dueDate).getTime() - Date.now()) / (1000 * 60 * 60 * 24));

                            return <Card className={classes.actionCard}>
                                <div className={classes.actionCardHeader}>
                                    <Typography variant="body1" className={classes.overdue}><span style={{ marginRight: '10px' }}><GreenDotIcon /></span>  {label}</Typography>
                                    <span className={classes.checkInbutton} onClick={() => handleNavigateContext(item)} >Checkin now</span>
                                </div>
                                <Typography variant="caption" > Due in {daysUntilDue} days</Typography>
                            </Card>
                        }) :
                            (
                                <Typography className={classes.actionCard}>No upcoming action items available.</Typography>
                            )
                        }
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default ActionItems