import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { PulseLoader } from 'react-spinners';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';

import { colors } from '../../../constants/colors';
import { ITeamRegForm } from '../../../types/auth';
import { createTeam } from '../../../redux/actions/auth';
import { RootState } from '../../../redux/store';
import { Button, makeStyles, MenuItem, Select, Theme } from '@material-ui/core';
import { useHistory,useLocation } from 'react-router-dom';
import { COUNTRIES } from '../../../constants/data';

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  form: {
    maxWidth: 320,
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(5),
    margin: 'auto',
  },
  titleCon: {
    paddingTop: 12,
    fontFamily: 'MontserratSemiBold',
  },
  loginTitle: {
    color: '#3b3b3b',
    fontSize: 20,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  inputStyle: {
    border: 0,
    backgroundColor: 'white',
    color: '#9d9d9d',
  },
}))
interface PropsType {
  loginLoader: boolean;
  authError: string;
  userStatus: boolean;
  createTeam: (data: ITeamRegForm) => any;
}

function Register(props: PropsType) {
  const { loginLoader, createTeam, userStatus } = props;
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const location = useLocation(); 

  useEffect(() => {
    if (userStatus) {
    history.push('/user/dashboard');
    }
  }, [userStatus, history])

  const onSubmit = (data: ITeamRegForm) => {
    const queryParams = new URLSearchParams(location.search);  
    const invitation = queryParams.get('invitation'); 
    const request =queryParams.get('request'); 
    const team=queryParams.get('team');
    if(invitation) data.invitation=invitation
    if(team) data.teamNameOverride=team
    if(request) data.request=request
    createTeam(data);
  };

  const { control, handleSubmit, errors } = useForm();

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12} style={{ marginTop: '5vh' }}>
        <div style={{ textAlign: 'center' }}>
          <img
            style={{ width: 220 }}
            src="../../assets/logo.png"
            alt="user-avatar"
          />
        </div>
        <div className={classes.titleCon}>
          <h1 className={classes.loginTitle}>Create your team account</h1>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Controller
          className={classes.textField}
          variant="outlined"
          name="name"
          control={control}
          rules={{
            required: 'First Name is required.',
          }}
          defaultValue=""
          as={
            <OutlinedInput
              id="name"
              aria-describedby="name-helper-text"
              inputProps={{
                'aria-label': 'name',
              }}
              labelWidth={0}
              placeholder="First Name"
              className={classes.inputStyle}
            />
          }
        />
        <ErrorMessage
          as={<Typography color="error" />}
          errors={errors}
          name="name"
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          className={classes.textField}
          variant="outlined"
          name="lastName"
          control={control}
          rules={{
            required: 'Last Name is required.',
          }}
          defaultValue=""
          as={
            <OutlinedInput
              id="lastName"
              aria-describedby="last-name-helper-text"
              inputProps={{
                'aria-label': 'lastName',
              }}
              labelWidth={0}
              placeholder="Last Name"
              className={classes.inputStyle}
            />
          }
        />
        <ErrorMessage
          as={<Typography color="error" />}
          errors={errors}
          name="lastName"
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          className={classes.textField}
          variant="outlined"
          name="email"
          control={control}
          rules={{
            required: 'Email is required.',
          }}
          defaultValue=""
          as={
            <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                'aria-label': 'email',
              }}
              labelWidth={0}
              placeholder="Team Account Email"
              className={classes.inputStyle}
            />
          }
        />
        <ErrorMessage
          as={<Typography color="error" />}
          errors={errors}
          name="email"
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          className={classes.textField}
          variant="outlined"
          name="password"
          control={control}
          rules={{
            required: 'Password is required.',
          }}
          defaultValue=""
          as={
            <OutlinedInput
              name="password"
              className={classes.inputStyle}
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                    style={{
                      color: '#D3C3E6',
                    }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={0}
              placeholder="Password"
            />
          }
        />
        <ErrorMessage
          as={<Typography color="error" />}
          errors={errors}
          name="password"
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          className={classes.textField}
          variant="outlined"
          name="country"
          control={control}
          rules={{
            required: 'Country is required.',
          }}
          defaultValue="United States"
          as={
            <Select
              labelId="country-label"
              id="country-select-helper"
              name='country'
            >
              {COUNTRIES.map(({ name }, i) => (
                <MenuItem key={i} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          }
        />
        <ErrorMessage
          as={<Typography color="error" />}
          errors={errors}
          name="password"
        />
      </Grid>
      <Grid>
        <Typography variant='body2'>By signing up, you agree to DEFAULT's user agreement and privacy policy.</Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'center', margin: '32px 0 8px 0' }}>
        {loginLoader ? (
          <PulseLoader
            size={10}
            color={colors.appColor}
            loading={loginLoader}
          />
        ) : (
            <Button type="submit" variant='contained' color='primary' size='large' fullWidth>
              Sign Up
            </Button>
          )}
      </Grid>
      <Grid>
        <Link href="/login" variant="body2">
          Already have an account? Sign in
        </Link>
      </Grid>
    </form>
  );
}

const mapStateToProps = (state: RootState) => ({
  authError: state.auth.authError,
  loginLoader: state.auth.loginLoader,
  userStatus: state.auth.userStatus,
});


export default connect(mapStateToProps, { createTeam })(Register);



