import React from 'react';
import { Card, Typography, Grid, CircularProgress, Box, Link, Button } from '@material-ui/core';
import ChartIcon from '../Icons/ChartIcon';
import ArrowDownIcon from '../Icons/ArrowDownIcon';
import { useStyles } from './style';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useHistory } from 'react-router';

const PerformanceScoreCard = () => {
  const history = useHistory();
  const classes = useStyles();
  const {heatMapData} = useSelector((state: RootState) => state.dashboardReducer)


 
  const overallScore = heatMapData?.reduce((total, week) => total + week.weekGradeScore, 0) / heatMapData?.length;

  // Find the most recent score that has data for weekGradeScore
  const mostRecentScore = heatMapData && heatMapData.length > 0
    ? [...heatMapData].reverse().find(week => week.weekGradeScore !== undefined)?.weekGradeScore
    : null;

    /*
     const mostRecentScore = heatMapData && heatMapData.results && heatMapData.results.length > 0
    ? [...heatMapData.results].reverse().find(week => week.weekGradeScore !== undefined)?.weekGradeScore
    : null;
    */

  let validOverallScore = isNaN(overallScore) ? 0 : overallScore*100;
  let validMostRecentScore = isNaN(mostRecentScore) ? 0 : mostRecentScore*100;

  //for now, just use the most recent score
  validOverallScore = validMostRecentScore;

  const motivationalQuotes = {
    '-': [
        "The most recent week has no data."
    ],
    'D': [
        "Every step counts, keep pushing!",
        "Mistakes are proof you’re trying.",
        "Persistence is key.",
        "Small steps lead to big results.",
        "Stay focused on progress, not perfection.",
        "Keep going; improvement is just around the corner.",
        "Challenges are what make life interesting.",
        "Don't be discouraged; you are capable of amazing things.",
        "Push harder today if you want a different tomorrow.",
        "You are closer to your goals than you think!"
    ],
    'C': [
        "Good job, keep improving!",
        "You’re on the right track!",
        "One step closer to greatness!",
        "Your hard work is paying off.",
        "Keep up the good work!",
        "Success is the sum of small efforts, repeated daily.",
        "Believe in the process, and the progress will come.",
        "Every small victory leads to bigger ones.",
        "Stay committed to your path.",
        "With each step, you’re growing stronger!"
    ],
    'B': [
        "Well done! Keep aiming higher!",
        "You’re getting there!",
        "Great progress, stay consistent!",
        "Success is within reach!",
        "Keep up the momentum!",
        "You’re making great strides!",
        "Believe in yourself and keep going.",
        "The hard work is paying off; don’t stop now!",
        "Push for excellence, you’re almost there!",
        "You’re on the path to greatness!"
    ],
    'A': [
        "Fantastic! Keep up the great work!",
        "You’re doing amazing!",
        "Almost at the top!",
        "Outstanding effort!",
        "Keep shining, you're doing awesome!",
        "You’re setting a new standard!",
        "Your dedication is inspiring.",
        "Success is the reward of hard work and commitment.",
        "You’re achieving wonderful things, keep it up!",
        "Stay focused and keep excelling!"
    ],
    'A+': [
        "Excellent! You’re at the top!",
        "Keep setting the bar high!",
        "Incredible work, keep it up!",
        "You’re an inspiration!",
        "Amazing! Keep reaching for new heights!",
        "You’re a role model of success!",
        "Keep pushing boundaries, you’re unstoppable!",
        "You’re proving what’s possible!",
        "Your excellence is unmatched!",
        "Continue to lead the way, you’re extraordinary!"
    ]
};

// After calculating validOverallScore and determining the grade
let scoreLabel, color, grade, motivationalQuote;

if(validOverallScore == 0) {
    scoreLabel = 'No data';
    color = '#9A9898';
    grade = '-';
} else if (validOverallScore < 50) {
    scoreLabel = 'Poor';
    color = '#9A9898';
    grade = 'D';
} else if (validOverallScore < 65) {
    scoreLabel = 'Fair';
    color = '#736E6B';
    grade = 'C';
} else if (validOverallScore < 75) {
    scoreLabel = 'Good';
    color = '#5F5D55';
    grade = 'B';
} else if (validOverallScore < 85) {
    scoreLabel = 'Very good';
    color = '#394953';
    grade = 'A';
} else {
    scoreLabel = 'Excellent';
    color = '#191D1B';
    grade = 'A+';
}

  // Select a random motivational quote based on the grade
  const quotesForGrade = motivationalQuotes[grade];
  motivationalQuote = quotesForGrade[Math.floor(Math.random() * quotesForGrade.length)];

  return (
    <>
      <div className={classes.titleWrapper}>
        <Typography variant="h4" className={classes.typography} gutterBottom>
          <ChartIcon />  My progress <ArrowDownIcon />
        </Typography>
        <Button className={classes.moreInfoButton} onClick={() => history.push('/user/my-results')}>
          More  
        </Button>
      </div>
      <Typography className={classes.caption} variant="caption" gutterBottom>
        Performance scorecard
      </Typography>
      <Card className={classes.card}>
        <div className={classes.cardInnerWrapper}>
          <div className={classes.circularProgressWrapper}>
            <div className={classes.innerCircle}>
              <Typography variant="h5" className={classes.scoreText}>
                {grade}
              </Typography>
              <Typography variant="body2" className={classes.scoreText}>
                {scoreLabel}
              </Typography>
            </div>
          </div>
          <Box ml={2} width={200}>
            <Typography className={classes.questionHeading} variant="h6">What your score means</Typography>
            <Typography variant="body2" style={{margin: "10px 0"}}>
              Your most recent score is {scoreLabel}. {motivationalQuote}
            </Typography>
            {/* <Link style={{color: "#757575", fontSize: '14px', textDecoration: 'underline'}} href="#" >Read more</Link> */}
          </Box>
        </div>
        <Box className={classes.legend}>
          <div className={classes.legendItem}>
            <span className={`${classes.legendColorBox} ${classes.fairSpan}`} />
            Poor
          </div>
          <div className={classes.legendItem}>
            <span className={`${classes.legendColorBox} ${classes.fairSpan}`} />
            Fair
          </div>
          <div className={classes.legendItem}>
            <span className={`${classes.legendColorBox} ${classes.goodSpan}`} />
            Good
          </div>
          <div className={classes.legendItem}>
            <span className={`${classes.legendColorBox} ${classes.vgoodSpan}`} />
            Very good
          </div>
          <div className={classes.legendItem}>
            <span className={`${classes.legendColorBox} ${classes.excellentSpan}`} />
            Excellent
          </div>
        </Box>
      </Card>
    </>
  );
};

export default PerformanceScoreCard;
