import React, { useEffect, useState } from 'react';
import { Card, Typography, Grid, Box, List, ListItem, ListItemText, Link, Divider, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowRightIcon from '../../../components/Icons/ArrowRightIcon';
import CalenderIcon from '../../../components/Icons/CalenderIcon';
import ArrowDownIcon from '../../../components/Icons/ArrowDownIcon';
import { useStyles } from '../ClientDashboard/style';

const getSchedule = async () => {
  return [
      { id: 1, event: "Weekly Review", date: "2024-08-01" },
      { id: 2, event: "Daily Checkin", date: "2024-08-02" },
      { id: 3, event: "May Review", date: "2024-08-01" },
      { id: 4, event: "Quarterly Review", date: "2024-08-02" }
  ];
};

const Schedule = () => {
  const classes = useStyles();

  const [schedule, setSchdedule] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getSchedule();
      setSchdedule(data);
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className={classes.titleWrapper}>
        <Typography variant="h4" className={classes.typography} gutterBottom>
          <CalenderIcon />
          <span>My Schedule</span>
          <ArrowDownIcon />
        </Typography>
        <Button className={classes.moreInfoButton} >
          More  
        </Button>
      </div>
      <Typography variant="caption" className={classes.caption}  gutterBottom>
      A subtitle can be added here
      </Typography>
      <List style={{marginTop: '10px'}}>
        {
          schedule && schedule.length > 0 && 
          schedule.map((el) => (
            <Card key={el?.id} className={classes.scheduleCard}>
            <div className={`${classes.flex} ${classes.gap_10} ${classes.itemsCenter}`}><CalenderIcon />{el?.event}</div>
            <div className={`${classes.flex} ${classes.gap_10} ${classes.itemsCenter}`}>
              <Typography variant='body2'> {el?.date}</Typography>
              <ArrowRightIcon />
            </div>
          </Card>
          ))
        }
      </List>
    </div>
  );
};

export default Schedule;
