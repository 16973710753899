import React from 'react';
import { useStyles } from '../ClientDashboard/style';
import { Typography } from '@material-ui/core';
import ArrowDownIcon from '../../../components/Icons/ArrowDownIcon';


interface IntroVideoProps {
  videoUrl: string;
}

const IntroVideo: React.FC<IntroVideoProps> = ({ videoUrl }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.titleWrapper}>
        <Typography variant="h4" className={classes.typography} gutterBottom>
          <span>Welcome Message</span>
          <ArrowDownIcon />
        </Typography>
      </div>
      <iframe
        className={classes.videoContainer}
        src={videoUrl}
        referrerPolicy="no-referrer"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default IntroVideo;

