import {
    getDashboardBarChartApi,
    getDashboardHeatMapApi,
    getMyGoalsApi
} from '../../api';
import { logger, format } from '../../datadogLogger';

export const GET_DASHBOARD_HEATMAP = 'GET_DASHBOARD_HEATMAP';
export const GET_DASHBOARD_HEATMAP_LOADING = 'GET_DASHBOARD_HEATMAP_LOADING';
export const GET_DASHBOARD_HEATMAP_ERROR = 'GET_DASHBOARD_HEATMAP_ERROR';
export const GET_DASHBOARD_HEATMAP_BAR_CHART = 'GET_DASHBOARD_HEATMAP_BAR_CHART';
export const GET_DASHBOARD_HEATMAP_BAR_CHART_LOADING = 'GET_DASHBOARD_HEATMAP_BAR_CHART_LOADING';
export const GET_DASHBOARD_HEATMAP_BAR_CHART_ERROR = 'GET_DASHBOARD_HEATMAP_BAR_CHART_ERROR';

export const SET_SELECTED_RANGE = 'SET_SELECTED_RANGE';

export const GET_MY_GOALS = 'GET_MY_GOALS';
export const GET_MY_GOALS_LOADING = 'GET_MY_GOALS_LOADING';
export const GET_MY_GOALS_ERROR = 'GET_MY_GOALS_ERROR';

export const getMyGoalsData = (programId: string, userId: string) => {
    return async (dispatch: any) => {
        try {
            const res = await getMyGoalsApi(programId, userId);
            if (res) {
                dispatch(getMyGoalsSuccess(res));
            } else {
                dispatch(getMyGoalsError(res));
            }
            logger.info(format('Get team Users'), {});
        } catch (error) {
            logger.error('error while getting my goals', error);
            dispatch(getMyGoalsError(error));
        }
    };
}


export const getHeatMapData = (programId: string, userId: string, startTime, endTime) => {
    return async (dispatch: any) => {
        try {
            const res = await getDashboardHeatMapApi(programId, userId, startTime, endTime);
            if (res) {
                if(res.results) {
                    dispatch(getDashbordHeatMapSuccess(res.results));
                }
                else{
                    logger.debug(`No heatmap results found for programId: ${programId} and userId: ${userId}`);
                    dispatch(getDashbordHeatMapError(res));
                }
            } else {
                dispatch(getDashbordHeatMapError(res));
            }
            logger.info(format(`Get heatmap data for programId: ${programId} and userId: ${userId}`), {});
        } catch (error) {
            logger.error(`error while getting heatmap data for programId: ${programId} and userId: ${userId}`, error);
            dispatch(getDashbordHeatMapError(error));
        }
    };
};

export const getBarChartData = (programId: string, userId: string, startTime, endTime) => {
    return async (dispatch: any) => {
        try {
            const res = await getDashboardBarChartApi(programId, userId, startTime, endTime);
            if (res) {
                dispatch(getDashbordBarChartSuccess(res));
            } else {
                dispatch(getDashbordBarChartError(res));
            }
            logger.info(format('Get team Users'), {});
        } catch (error) {
            logger.error('error while getting team user', error);
            dispatch(getDashbordBarChartError(error));
        }
    };
};

const getMyGoalsSuccess = (payload: any) => ({
    type: GET_MY_GOALS,
    payload,
});

const getMyGoalsError = (error: any) => ({
    type: GET_MY_GOALS_ERROR,
    payload: error,
});

const getDashbordHeatMapSuccess = (payload: any) => ({
    type: GET_DASHBOARD_HEATMAP,
    payload,
});

const getDashbordHeatMapError = (error: any) => ({
    type: GET_DASHBOARD_HEATMAP_ERROR,
    payload: error,
});

const getDashbordBarChartSuccess = (payload: any) => ({
    type: GET_DASHBOARD_HEATMAP_BAR_CHART,
    payload,
});

const getDashbordBarChartError = (error: any) => ({
    type: GET_DASHBOARD_HEATMAP_BAR_CHART_ERROR,
    payload: error,
});

export const setSelectedDateRange = (payload) => ({
    type: SET_SELECTED_RANGE,
    payload
}) 