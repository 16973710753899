import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        fontFamily: 'Roboto',
        color: '#2C3345',
        maxWidth:'752px',
        margin: '0 0',
        padding: '20px',
        borderRadius: '8px',
    },
    formGroup: {
        padding: 10,
        background : "white",
        borderRadius: 8,
        marginBottom: theme.spacing(3),
    },
    formGroupInner: {
        padding: 0,
        background : "white",
        borderRadius: 8,
        marginTop: '5px',
        marginBottom: '5px',
    },
    label: {
        display: 'block',
        color: "#00000099",
        fontWeight: 'normal',
        marginBottom: theme.spacing(1),
    },
    labelInner: {
        color: "#00000099",
        fontWeight: 'normal',
        marginBottom: theme.spacing(1),
    },
    sectionLabel: {
        backgroundColor: '#f0f0f0',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(3),
        borderRadius: '4px',
    },
    textField: {
        width: '100% !important',
        padding: '10px',
        border: "none",
        outline: "none",
        borderBottom: '1px solid #ccc',
        borderRadius: 0,
        fontSize: '16px',
        boxSizing: 'border-box',
        transition: 'border-color 0.3s ease',
        marginBottom: 0,
    },
    controlHeading1 :{
        color: "#343C6A"
    },
    submitBtn : {
        background: "#3F4148",
        color: "white", 
    },
    image: {
        maxWidth: "100%",
        height: 'auto'
    },
    tableScoll: {
        width: '100%',
        overflowX: 'auto',
    },
    radioButton : {
        marginRight: 0,
        justifyContent : "center",
        width: '100%',
        border: "none",
        outline: "none",
        borderBottom: '1px solid #ccc',
        borderRadius: 0,
        fontSize: '16px',
        boxSizing: 'border-box',
        transition: 'border-color 0.3s ease',
        marginBottom: 0,
        marginLeft: 0
    },
    noDataFound :{
        display: 'flex',
        height: "100vh",
        justifyContent: 'center',
        alignItems: 'center'
    }
}));
