export const SET_WEEKLY_DATA = 'SET_WEEKLY_DATA';
export const SET_HIGHLIGHT_TAB = "SET_HIGHLIGHT_TAB";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";

export const setWeeklyData = (weeklyData) => ({
    type: SET_WEEKLY_DATA,
    payload: weeklyData,
});

export const setHighLightTab = (payload) => ({
    type: SET_HIGHLIGHT_TAB,
    payload
})

export const setActiveTab = (payload) => ({
    type: SET_ACTIVE_TAB,
    payload
})