import React, { useEffect } from 'react'
import { FormRenderer } from '../../../../User/Backpack'
import { Dialog } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { useStyles } from '../../../../User/Backpack/style';
import { getProgramFormContextData, getProgramFormStructure } from '../../../../../redux/actions/programFormAction';
import CustomDialogTitle from '../../../../Admin/ProgramList/ProgramDrillDown/components/DialogTitle';

interface PropsType {
  isModal: boolean;
  closeModal: () => any;
  sourceFormId: string;
  userId: string;
  contextId: string;
  programId: string;
  year?: number
}

const ActionItemFormModal = ({ isModal, closeModal, sourceFormId, userId, contextId, programId, year }: PropsType) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { formStructure, error, userformData } = useSelector((state: RootState) => state.programFormReducer)

  useEffect(() => {
    if (userId && sourceFormId) {
      dispatch(getProgramFormStructure(programId, sourceFormId, userId));
      dispatch(getProgramFormContextData(programId, sourceFormId, userId, contextId));
      return;
    }
  }, [isModal]);

  return (
    <>
      <Dialog
        open={isModal}
        onClose={closeModal}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <CustomDialogTitle onClose={() => closeModal()}>Submit Form</CustomDialogTitle>

        {formStructure &&
          <FormRenderer
            formResult={userformData}
            formDetail={formStructure}
            sourceFormId={sourceFormId}
            userId={userId}
            contextId={contextId}
            programId={programId}
            isOnboarding={true}
            year={year}
            openModal={isModal}
            onCloseModal={closeModal}
          />
        }

        {
          error && error !== null &&  <div className={classes.noDataFound}>No Form Found</div>
        }

      </Dialog>
    </>
  )
}

export default ActionItemFormModal