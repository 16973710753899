import {
  GET_USER_POLICIES,
  GET_USER_POLICIES_SUCCESS,
  GET_USER_POLICIES_FAILURE,
  POST_USER_POLICY,
  POST_USER_POLICY_SUCCESS,
  POST_USER_POLICY_FAILURE,
  DELETE_USER_POLICY,
  DELETE_USER_POLICY_SUCCESS,
  DELETE_USER_POLICY_FAILURE,
  CLEAR_USER_POLICY_OPERATION_RESULT,
  ADD_TIME_LIMIT_NODE_POLICY,
  ADD_TIME_LIMIT_NODE_POLICY_SUCCESS,
  ADD_TIME_LIMIT_NODE_POLICY_FAILURE,
} from '../../types';
import { IAction } from '../../types/generics';
import { IPolicyState } from '../../types/policy';

const initialState: IPolicyState = {
  allUserPolicies: [],
  userPolicy: null,
  isLoading: false,
  error: null,
  operationResult: {},
};

const policyReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_USER_POLICIES:
      return {
        ...state,
        isLoading: true,
      };
    case GET_USER_POLICIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allUserPolicies: action.payload,
      };
    case GET_USER_POLICIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case POST_USER_POLICY:
      return {
        ...state,
        isLoading: true,
      };
    case POST_USER_POLICY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allUserPolicies: [...state.allUserPolicies],
        operationResult: { result: POST_USER_POLICY_SUCCESS },
      };
    case POST_USER_POLICY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        operationResult: { result: POST_USER_POLICY_FAILURE },
      };
    case DELETE_USER_POLICY:
      console.log('hey check this state', state);
      // const tryGain = state;
      const temp = {
        ...state,
        isLoading: true,
        allUserPolicies: state.allUserPolicies.filter(
          (userPolicy) => userPolicy.label !== action.payload
        ),
      };
      console.log('hey check this', temp);
      // tryGain.allUserPolicies.data = temp;
      return temp;
    case DELETE_USER_POLICY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userPolicy: action.payload,
        operationResult: { result: DELETE_USER_POLICY_SUCCESS },
      };
    case DELETE_USER_POLICY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        operationResult: { result: DELETE_USER_POLICY_FAILURE },
      };
    case CLEAR_USER_POLICY_OPERATION_RESULT:
      return {
        ...state,
        operationResult: {},
      };

    case ADD_TIME_LIMIT_NODE_POLICY:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_TIME_LIMIT_NODE_POLICY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allUserPolicies: [...state.allUserPolicies],
        userPolicy: action.payload,
        operationResult: { result: ADD_TIME_LIMIT_NODE_POLICY_SUCCESS },
      };
    case ADD_TIME_LIMIT_NODE_POLICY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        operationResult: { result: ADD_TIME_LIMIT_NODE_POLICY_FAILURE },
      };
    default:
      return state;
  }
};

export default policyReducer;
