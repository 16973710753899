import {
  GET_USER_STATE,
  GET_STATE_LOADING,
  GET_STATE_ERROR,
  TEAM_USER_GET,
  TEAM_USER_ERROR,
  SET_USER,
  GET_DAILY_ACTIVITY_SUCCESS,
} from '../actions/childAction';
import { IChildState } from '../../types/child';
import { IAction } from '../../types/generics';
import { TEAM_USER_CREATE } from '../actions/teamAction';

const initState: IChildState = {
  getUserStateLoading: true,
  userState: null,
  selectedUser: null,
  userStateError: false,
  userStateErrorMsg: '',
  teamUsers: [],
  teamUserError: '',
  dailyActivity: [],
};

export default function (state = initState, action: IAction) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        selectedUser: action.payload,
      };
    case GET_STATE_LOADING: {
      return {
        ...state,
        getUserStateLoading: action.payload,
      };
    }
    case GET_USER_STATE:
      return {
        ...state,
        userState: action.payload,
        userStateError: false,
        getUserStateLoading: false,
      };
    case GET_STATE_ERROR:
      return {
        ...state,
        userStateError: true,
        userStateErrorMsg: action.payload.message,
        getUserStateLoading: false
      };
    case TEAM_USER_GET:
      return {
        ...state,
        teamUsers: action.payload,
      };
    case TEAM_USER_ERROR:
      return {
        ...state,
        teamUserError: action.payload,
      };
    case GET_DAILY_ACTIVITY_SUCCESS:
      return {
        ...state,
        dailyActivity: action.payload,
      };
    case TEAM_USER_CREATE: {
      return {
        ...state,
        selectedUser: action.payload
      }
    }
    default:
      return state;
  }
}
