import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import OnBoardingScreen1 from './FirstScreen';
import OnboardingScreen2 from './SecondScreen';
import OnboardingScreen3 from './ThirdScreen';
import { useDispatch, useSelector } from 'react-redux';
import { getProgramListContextData } from '../../../redux/actions/programFormAction';
import { RootState } from '../../../redux/store';
import { getProgramById } from '../../../redux/actions/programAction';
import { getUserProgramEnrollmentData } from '../../../redux/actions/programEnrollmentAction';
import ActionItemFormModal from './components/ActionItemFormModal';

const OnBoardingLayout = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { phaseNumber }: any = useParams();
    const [onboardingStep, setOnBoardingStep] = useState(parseInt(phaseNumber) || 1);
    const [isOpenFormModal, setIsOpenFormModal] = useState(false);
    const [modalData, setModalData] = useState({
        programId: null,
        userId: null,
        contextId: null,
        sourceFormId: null,
    });

    const programEnrollment = JSON.parse(localStorage.getItem('activeProgramEnrollment')) || {};
    const contextList = useSelector((state: RootState) => state.programFormReducer.contextList)
    const programs = useSelector((state: RootState) => state.programReducer.singleProgram);
    const userEnrollment = useSelector((state: RootState) => state.programEnrollmentReducer.enrollment)
    const userCurrentPhase = useSelector((state: RootState) => state.programEnrollmentReducer.activePhaseNumber);
    const totalSteps = 3;

    const phase = programs?.phases?.find((el) => el?.phaseNumber === parseInt(phaseNumber));
    const forms = phase?.forms || [];

    const programId = programEnrollment?.programId;
    const userId = programEnrollment?.userId;
    const year = programEnrollment?.dates?.activeEnrollmentYear;

    const filteredContexts = forms?.map(form => {
        const context = contextList?.find(ctx => ctx.sourceFormId === form.sourceFormId);
        if (context) {
            const phase = programs?.phases?.find(phase => phase.phaseNumber === context.phaseNumber);
            const group = phase?.groups && phase.groups.length > 0 ? form.group : null;
            return {
                ...context,
                group: group || 'Ungrouped'
            };
        }
    }).filter((context) => context);

    const groupedContexts = filteredContexts?.reduce((acc, context) => {
        if (!acc[context.group]) {
            acc[context.group] = [];
        }
        acc[context.group].push(context);
        return acc;
    }, {});

    const initialStepStatus = Array.from({ length: totalSteps }, (_, i) => ({
        [i + 1]: 'pending'
    })).reduce((acc, step) => ({ ...acc, ...step }), {});

    const [stepStatus, setStepStatus] = useState(initialStepStatus);

    const allowedPhases = Array.from({ length: userCurrentPhase }, (_, i) => i + 1);

    const redirectToPendingStep = () => {
        setOnBoardingStep(userCurrentPhase);
        history.push(`/onboarding/phase/${userCurrentPhase}`);
    };

    const handleChipClick = (context) => {
        const { programId, userId, contextId, sourceFormId } = context;
        setModalData({ programId, userId, contextId, sourceFormId });
        setIsOpenFormModal(true);
    };

    const handleCloseModal = () => {
        setIsOpenFormModal(!isOpenFormModal);
    };

    useEffect(() => {
        const currentPhase = parseInt(phaseNumber);
        if (!allowedPhases.includes(currentPhase)) {
            redirectToPendingStep();
        }
    }, [phaseNumber, userCurrentPhase, history, stepStatus]);

    useEffect(() => {
        const phase: any = location.pathname.split('/')[3];
        const validPhaseNumber = ["1", "2", "3"];
        if (!validPhaseNumber.includes(phase) && !userCurrentPhase && userCurrentPhase ===0 ) {
            history.push("/login");
            return;
        }
    }, [location]);

    useEffect(() => {
        if (programEnrollment) {
            dispatch(getProgramListContextData(programId, userId, year));
            dispatch(getProgramById(programId));
            dispatch(getUserProgramEnrollmentData(programId, userId))
        }
    }, []);

    const markCompleted = (step, next, status) => {
        if (step > 0 && next > 0) {
            setOnBoardingStep(next);
            localStorage.setItem("onBoardingStep", JSON.stringify(next));
            if (status) {
                setStepStatus((prevStatus) => ({
                    ...prevStatus,
                    [step]: status,
                }));
            }
            history.push(`/onboarding/phase/${next}`)
        } else if (step === 3 && status === 'completed') {
            history.push(`/user/dashboard`)
        }
    };

    return (
        <>
            {onboardingStep === 1 && <OnBoardingScreen1 phaseNumber={userCurrentPhase} markCompleted={markCompleted} phase={phase} groupedContexts={groupedContexts} handleChipClick={handleChipClick} />}
            {onboardingStep === 2 && <OnboardingScreen2 phaseNumber={userCurrentPhase} markCompleted={markCompleted} phase={phase} groupedContexts={groupedContexts} handleChipClick={handleChipClick} />}
            {onboardingStep === 3 && <OnboardingScreen3 phaseNumber={userCurrentPhase} markCompleted={markCompleted} />}

            <ActionItemFormModal
                isModal={isOpenFormModal}
                closeModal={handleCloseModal}
                programId={modalData.programId}
                userId={modalData.userId}
                contextId={modalData.contextId}
                sourceFormId={modalData.sourceFormId}
                year={year}
            />
        </>
    )
}

export default OnBoardingLayout