import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ImgIcon from '../../../../components/ImgIcon';
import Box from '@material-ui/core/Box';

export default function AlertDialog({ open, handleClose }: { open: boolean, handleClose: () => any }) {

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Box textAlign='center' padding={2}>
          <ImgIcon name='Time-inactive' width={100} />
          <DialogContentText id="alert-dialog-description">
            REQUEST SENT!
        </DialogContentText>
        </Box>
      </DialogContent>
    </Dialog>
  );
}