import axios, { AxiosResponse } from 'axios';
import { INSTANCE, baseURL } from './endpoint';

import { ITeamRegForm, ILoginForm } from '../types/auth';
import { IDevice, IDeviceEvent, IDeviceStatus, IFirmware } from '../types/device';
import { ResultResponse } from '../types/generics';
import { IAcdDeviceState, IUserPolicyState } from '../types/acitivity';
import { ITeam } from '../types/team';
import { IUser } from '../types/user';
import { IUserPolicy } from '../types/policy';
import { INotification } from '../types/notification';
import { IAutoCompleteUser, IChannel, ICreateChannel, IDirectMessage, IMessage, IMessageMD, IUpdateMessageRC, IUserInfo } from '../types/chat';
// import {AxiosResponse} from '../redux/types/generics';

const getToken = () => `Bearer ${localStorage.getItem('token')}`;

// things to do here, make return type simple
// export const getLogIn = (authData: ILoginForm): Promise<ResultResponse> =>
//   new Promise((resolve, reject) => {
//     INSTANCE({
//       method: "POST",
//       url: "/v1/authenticate",
//       data: authData,
//     })
//       .then((res) => res.data)
//       .catch((error) => {
//         reject(error)
//       });
//   });

// team register
export const createTeamApi = (data: ITeamRegForm): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: '/v1/register',
      data: data,
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        console.log("errror is",error)
        reject(error);
      });
  });

// login api`
export const getLogIn = (authData: ILoginForm): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: '/v1/authenticate',
      data: authData,
      
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const getUserApi = (userId: string): Promise<ResultResponse<IUser>> => {
  return new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/users/${userId}`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });
};

export const getTeamApi = (
  teamId: string
): Promise<ResultResponse<ITeam>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/teams/${teamId}`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const getProgramTeamsApi = (programId: string
): Promise<ResultResponse<ITeam[]>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/teams/program/${programId}`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const getProgramTeamUsersApi = (programId: string, userId: string
): Promise<ResultResponse<any[]>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/programEnrollment/${programId}/${userId}`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const getAllDevicesApi = (
  teamId: string
): Promise<ResultResponse<IDevice[]>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: '/v1/devices',
      params: {
        team: teamId,
      },
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const archiveDeviceApi = (deviceId: string) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'PUT',
      url: `/v1/devices/${deviceId}/archive`,
      headers: {
        Authorization: getToken(),
      }
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      })
  })

export const getAcdDeviceApi = (
  teamId: string
): Promise<ResultResponse<IDevice[]>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/devices`,
      params: {
        team: teamId,
        group: 'acd',
      },
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const getAcdDevicesStates = (
  ids: string[]
): Promise<ResultResponse<IAcdDeviceState>[]> => {
  const userReqs: Promise<
    AxiosResponse<ResultResponse<IAcdDeviceState>>
  >[] = [];
  ids.forEach((id) => {
    userReqs.push(
      axios.get(`${baseURL}/v1/activities/device/acd/${id}`, {
        headers: {
          Authorization: getToken(),
        },
      })
    );
  });
  // console.log("userReqs", userReqs);
  return new Promise((resolve, reject) => {
    axios
      .all(userReqs)
      .then((res) => {
        const data = res.map((value) => value.data);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getTeamUsersApi = (
  teamId: string
): Promise<ResultResponse<IUser[]>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: '/v1/users/',
      params: {
        team: teamId,
      },
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

// export const getUerDetailApi = (users,userId) =>
//   new Promise((resolve, reject) => {
//     axios
//       .all([getUserStateApi(userId), getUserPolicyApi(userId)])
//       .then((res) => resolve(res.data))
//       .catch(reject);
//   });

export const getUserStateApi = (
  users: any[]
): Promise<ResultResponse<IUserPolicyState>[]> => {
  const userReqs: Promise<
    AxiosResponse<ResultResponse<IUserPolicyState>>
  >[] = [];
  users.forEach((element) => {
    userReqs.push(
      axios.get(`${baseURL}/v1/activities/state/user/${element._id}`, {
        headers: {
          Authorization: getToken(),
        },
      })
    );
  });

  return new Promise((resolve, reject) => {
    axios
      .all(userReqs)
      .then((res) => {
        const data = res.map((item) => item.data);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// export const getUserStateApi = userId =>
//   new Promise((resolve, reject) => {
//     INSTANCE({
//       methos: "GET",
//       url: `/v1/activities/state/user/${userId}`,
//       headers: {
//         Authorization: getToken
//       }
//     })
//       .then((res) => resolve(res.data))
//       .catch(error => {
//   reject(error)
// });
//   });

export const getUserPolicyApi = (
  userId: string
): Promise<ResultResponse<IUserPolicy>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/policies/user/${userId}`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

/// add downtime
export const addDownTimeApi = (
  downtime: IUserPolicy,
  userId: string
): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    console.log('posting data to API ', downtime);
    INSTANCE({
      method: 'PUT',
      url: `/v1/policies/user/${userId}`,
      data: downtime,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

/// add timePolicies
export const addPolicyNodeApi = (
  timePolicies: IUserPolicy,
  userId: string
): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'PUT',
      url: `/v1/policies/user/${userId}`,
      data: timePolicies,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

// add Update user api

export const saveUserApi = (data: any): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'PUT',
      url: `/v1/users/${data._id}`,
      data,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

// change password user api 

export const changeUserPasswordApi = (userId: string, data: any): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: `/v1/users/${userId}/changePassword`,
      data,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const createUserApi = (data: any): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: `/v1/users/`,
      data,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

// add ACD device api

export const addAcdDeviceApi = (
  data: IDevice
): Promise<ResultResponse<IDevice>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: '/v1/devices',
      data,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const updateDeviceApi = (
  deviceId: string,
  data: any
): Promise<ResultResponse<IDevice>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'PUT',
      url: `/v1/devices/${deviceId}`,
      headers: {
        Authorization: getToken(),
      },
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

export const getDeviceApi = (
  deviceId: string
): Promise<ResultResponse<IDevice>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/devices/${deviceId}`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

export const getDeviceEvents = (
  deviceId: string
): Promise<ResultResponse<IDeviceEvent[]>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/devices/${deviceId}/log/event`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });


// turn off/on ACD device port

export const acdStateChangeApi = (
  deviceId: string,
  identifier: string,
  data: any
): Promise<ResultResponse<IAcdDeviceState>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'PUT',
      url: `/v1/activities/device/acd/${deviceId}/${identifier}`,
      data,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

// get user state

export const getChildStateApi = (userId: string): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/activities/state/user/${userId}`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

// turn off acd Port

export const acdOffApi = (
  deviceId: string,
  data: any
): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'PUT',
      url: `/v1/activities/device/acd/${deviceId}/off`,
      data,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

// get downtime list
export const getUserDownTimesApi = (
  teamId: string
): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: '/v1/devices',
      params: {
        team: teamId,
      },
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const getDeviceStatusList = (
  ids: string[]
): Promise<ResultResponse<IDeviceStatus[]>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/activities/device/status/list`,
      params: {
        devices: ids.join(','),
      },
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const getDeviceStatusById = (
  id: string
): Promise<ResultResponse<IDeviceStatus[]>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/activities/device/${id}`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });


// get weekly app acitvity
export const getWeeklyAppActivity = (userId: string): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/tracking/${userId}/WeeklyAppActivity`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

// get daily user state
export const getDailyUserStateApi = (userId: string): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/tracking/${userId}/DailyUserStateAndTotals`,
      params: {
        extended: true,
      },
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const restartHubApi = (
  data: any,
  deviceId: string
): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: `/v1/system/acd/${deviceId}/restart`,
      data,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

export const getLatestVersion = (
  deviceModel: string
): Promise<ResultResponse<IFirmware>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/fw/${deviceModel}/latest`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const updateFirmwareApi = (
  data: any,
  deviceId: string
): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: `/v1/system/acd/${deviceId}/firmwareUpdate`,
      data,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

export const getServerTime = (): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/ping`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

// notification apis
export const getNotificationsAPI = (): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/notifications`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const getNotificationByIdAPI = (id): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/notifications/${id}`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const createNotificationAPI = (
  data: INotification
): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: `/v1/notifications`,
      data,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const updateNotificationAPI = (
  data: INotification,
  id
): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'PUT',
      url: `/v1/notifications/${id}`,
      data,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const updateNotificationStateAPI = (
  state: string,
  id
): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'PUT',
      url: `/v1/notifications/${id}/state/${state}`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const updateNotificationStatesWithIdsAPI = (
  state: string,
  ids: string[]
): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'PUT',
      url: `/v1/notifications/list/state/${state}`,
      params: {
        ids: ids.join(','),
      },
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const requestTimeExtensionApi = (data: any): Promise<ResultResponse> => (
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: `/v1/policies/requests/timeExtension`,
      data,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  })
);

export const getTimeExtensionsApi = (): Promise<ResultResponse> => (
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/policies/requests/`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  })
);

export const approveTimeExtensionApi = (id, data): Promise<ResultResponse> => (
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'PUT',
      url: `/v1/policies/requests/${id}/timeExtension`,
      data,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  })
);

export const sendVerificationCodeApi = (data: { email: string }): Promise<ResultResponse> => (
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: `/v1/requestPasswordReset/`,
      data,
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  })
);

export const verifyCodeApi = (data: { email: string }): Promise<ResultResponse> => (
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: `/v1/verifyCode/`,
      data,
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  })
);

export const resetPasswordApi = (data: { email: string }): Promise<ResultResponse> => (
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: `/v1/resetPassword/`,
      data,
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  })
);

export const setPasswordApi = (data: { password: string, confirmPassword: string }): Promise<ResultResponse> => (
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: `/v1/setPassword`,
      data,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  })
);

export const validateEmailApi = (token: string): Promise<ResultResponse> => (
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: `/v1/users/validateEmail/${token}`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  })
);

export const getDMListRC = (
): Promise<IDirectMessage[]> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/messaging/dm-list`,
    })
      .then((res) => resolve(res.data.ims))
      .catch((error) => {
        reject(error);
      });

  });

export const createDmRc = (username: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: `/v1/messaging/dm-create`,
      data: { username },
    })
      .then((res: AxiosResponse<any>) => {
        resolve(res.data.room);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getDMMessagesRC = (
  url: string,
  roomId: string,
  offset: number,
  count: number
): Promise<IDirectMessage[]> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/messaging/dm-messages/${url}/${roomId}`,
      params: { offset, count },
    })
      .then((res) => {
        resolve(res.data)
      })
      .catch((error) => {
        console.error("Request failed", error);
        if (error.response) {
          console.error("Response data:", error.response?.data);
        } else {
          console.error("Error message:", error.message);
        }
        reject(error.response?.data);
      });
  });

export const sendDMMessageRc = (
  message: IMessage,
): Promise<IMessage[]> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: `/v1/messaging/dm-send`,
      data: { message }
    })
      .then((res) => resolve((res.data.message)))
      .catch((error) => {
        reject(error);
      });
  });

export const updateDMMessageRc = (
  message: IMessage,
): Promise<IUpdateMessageRC[]> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: `/v1/messaging/dm-update`,
      data: { message }
    })
      .then((res) => resolve(res.data.message))
      .catch((error) => {
        reject(error);
      });
  });

export const deleteDMMessageRc = (
  payload: any,
): Promise<IMessage[]> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: `/v1/messaging/dm-delete`,
      data: payload
    })
      .then((res) => resolve(res.data.message))
      .catch((error) => {
        reject(error);
      });
  });

export const getJoinedChannelsListRc = (
): Promise<IChannel[]> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/messaging/channels-list`,
    })
      .then((res) => resolve(res.data.channels))
      .catch((error) => {
        reject(error);
      });
  });

export const createChannelRc = (
  payload: ICreateChannel,
): Promise<IChannel[]> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: `/v1/messaging/channel-create`,
      data: payload
    })
      .then((res) => resolve(res.data.channel))
      .catch((error) => {
        reject(error);
      });
  });

export const getAutoCompleteUsersRc = (
  search: string,
): Promise<IAutoCompleteUser> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/messaging/auto-complete-users?search=${encodeURIComponent(JSON.stringify(search))}`,
    })
      .then((res) => resolve(res.data.items))
      .catch((error) => {
        reject(error);
      });
  });

export const getUserInfoRc = (
  id: string,
): Promise<IUserInfo[]> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/messaging/user-info/${id}`,
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const getChannelInfoRc = (
  roomId: string,
): Promise<IChannel[]> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/messaging/channel-info/${roomId}`,
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error.response.data);
      });
  });

export const uploadFileToRoomRc = (
  roomId: string,
  formData: FormData,
): Promise<any[]> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: `/v1/messaging/upload-file/${roomId}`,
      data: formData
    })
      .then((res) => resolve(res.data.message))
      .catch((error) => {
        reject(error);
      });
  });

export const accessFilesFromRC = (
  url: any,
): Promise<any> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/messaging/access-files/${url}`,
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

// Programs Forms Api 

export const getProgramApi = (
  programId: string
): Promise<ResultResponse<IUserPolicy>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/programs/${programId}`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });


export const getAllProgramsApi = (
): Promise<ResultResponse<any>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/programs`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });


export const getProgramFormListApi = (
  programId: string
): Promise<ResultResponse<any>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/programs/${programId}/forms`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const getProgramFormRenderView = (
  sourceFormId: string,
  programId: string
): Promise<ResultResponse<any>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/programs/${programId}/admin/preview/form/${sourceFormId}`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const getProgramFormStructureApi = (
  programId: string,
  sourceFormId: string,
  userId: string
): Promise<ResultResponse<any>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/programFormStructures/${programId}/${sourceFormId}/${userId}/structure`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        console.log("error111", error);
        reject(error);
      });
  });

export const submitProgramFormApi = (
  programId: string,
  sourceFormId: string,
  userId: string,
  contextId: string,
  payload: any,
): Promise<ResultResponse<any>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: `/v1/programFormStructures/${programId}/${sourceFormId}/${userId}/${contextId}/submit`,
      data: payload,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        console.log("error111", error);
        reject(error);
      });
  });

export const getDashboardHeatMapApi = (
  programId: string,
  userId: string,
  startTime: string,
  endTime: string
): Promise<any> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/results/heatMap/${programId}/${userId}/?start=${startTime}&end=${endTime}`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        console.log("error111", error);
        reject(error);
      });
  });

export const getDashboardBarChartApi = (
  programId: string,
  userId: string,
  startTime: string,
  endTime: string
): Promise<ResultResponse<any>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/results/barChart/${programId}/${userId}/?start=${startTime}&end=${endTime}`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        console.log("error111", error);
        reject(error);
      });
  });

export const getProgramFormContextDataApi = (
  programId: string,
  sourceFormId: string,
  userId: string,
  contextId: string
): Promise<ResultResponse<any>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/programFormStructures/${programId}/${sourceFormId}/${userId}/${contextId}/data`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        console.log("error111", error);
        reject(error);
      });
  });

export const getProgramMyBackpackApi = (
  programId: string,
  userId: string,
): Promise<ResultResponse<any>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/programs/${programId}/backpack/user/${userId}`,
      headers: {
        Authorization: getToken(),
      },
    })

      .then((res) => resolve(res?.data))
      .catch((error) => {
        console.log("error111", error);
        reject(error);
      });
  });

export const getProgramMyActionItemsApi = (
  programId: string,
  userId: string,
): Promise<ResultResponse<any>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/programs/${programId}/actionitems/user/${userId}`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res?.data))
      .catch((error) => {
        console.log("error111", error);
        reject(error);
      });
  });


export const getProgramAnnouncementsApi = (
  programId: string,
  userId: string,
): Promise<ResultResponse<any>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/programAnnouncement/program/${programId}/user/${userId}`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res?.data))
      .catch((error) => {
        console.log("error111", error);
        reject(error);
      });
  });

// Upload Profile Avatar 

export const uploadProfileAvatar = (
  userId: string,
  formData: FormData,
): Promise<any[]> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: `/v1/users/${userId}/uploadProfileAvatar`,
      data: formData,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const uploadFile = (
  formData: FormData,
): Promise<any[]> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: `/v1/users/uploadFile`,
      data: formData,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });


// Channel Initialization

export const initializeChannel = (
  userId: string,
): Promise<ResultResponse<any>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/messaging/initialize-channels/${userId}`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });


export const getUserProgramEnrollmentsApi = (
  userId: string,
): Promise<ResultResponse<any>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/programEnrollment/user/${userId}`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res?.data?.data))
      .catch((error) => {
        console.log("error111", error);
        reject(error);
      });
  });

export const getProgramContextListApi = (
  programId: string,
  userId: string,
  year: string
): Promise<ResultResponse<any>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/programFormContexts/${programId}/${userId}/${year}/list`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res?.data))
      .catch((error) => {
        console.log("error111", error);
        reject(error);
      });
  });


// /channels.messages

// //refresh Token Api
// export const getTokenRefreshApi = (refreshToken, userId, teamId) => {
//   //getToken = `Bearer ${token}`;
//   return new Promise((resolve, reject) => {
//     INSTANCE({
//       method: "POST",
//       url: `/v1/authenticate/token`,
//       data: { refreshToken, team:teamId, user: userId}
//     })
//       .then((res) => resolve(res.data))
//       .catch(reject);
//   });
// };

// let isAlreadyFetchingAccessToken = false;
// let subscribers: Subscriber[] = [];

// function onAccessTokenFetched(token: string, refreshToken: string) {
//   localStorage.setItem("token", token);
//   localStorage.setItem("refreshToken", refreshToken);
//   console.log("onAccessTokenFetched - Calling subscribers");
//   subscribers = subscribers.filter((callback) => callback(token));
// }

// function addSubscriber(callback: Subscriber) {
//   subscribers.push(callback);
// }

// axios.interceptors.response.use(function (response) {
//   return response
// }, function (error) {
//   const { config, response: { status } } = error
//   const originalRequest = config

//   if (status === 401) {
//     if (!isAlreadyFetchingAccessToken) {
//       isAlreadyFetchingAccessToken = true
//       store.dispatch("Auth/refreshToken").then((access_token) => {
//         isAlreadyFetchingAccessToken = false
//         onAccessTokenFetched(access_token)
//       })
//     }

//     const retryOriginalRequest = new Promise((resolve) => {
//       addSubscriber(access_token => {
//         originalRequest.headers.Authorization = 'Bearer ' + access_token
//         resolve(axios(originalRequest))
//       })
//     })
//     return retryOriginalRequest
//   }
//   return Promise.reject(error)
// })

// const handleApiError = (reject: any, error: any) => {
//   // todo: consider moving to api/index.js

//   // debugger;
//   const originalRequest = error.config;
//   if (error.response.status === 401 && !originalRequest._retry) {
//     //originalRequest._retry = true;

//     if (!isAlreadyFetchingAccessToken) {
//       isAlreadyFetchingAccessToken = true;

//       console.log("/v1/authenticate/token - START");
//       INSTANCE.post("/v1/authenticate/token", {
//         refreshToken: localStorage.getItem("refreshToken"),
//         user: localStorage.getItem("user"),
//         team: localStorage.getItem("team"),
//       })
//         .then((res) => {
//           console.log("/v1/authenticate/token - DONE");

//           isAlreadyFetchingAccessToken = false;
//           if (res.status === 201 || res.status === 200) {
//             onAccessTokenFetched(
//               res.data.data.token,
//               res.data.data.refreshToken
//             );
//           }
//         })
//         .catch((error) => {
//           console.log("error Stringy  = " + JSON.stringify(error));
//         });
//     }

//     const retryOriginalRequest = new Promise((resolve) => {
//       addSubscriber((access_token) => {
//         console.log("retrying");
//         originalRequest.headers.Authorization = "Bearer " + access_token;
//         resolve(INSTANCE(originalRequest));
//       });
//     });
//     return retryOriginalRequest;
//   }
//   reject(error);
// };


export const removeUserApi = (userId: string): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'DELETE',
      url: `/v1/users/${userId}`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const updateFormInProgramPhase = (programId: string, data: any): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: `/v1/programs/${programId}/updateForm`,
      data,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res?.data))
      .catch((error) => {
        reject(error);
      });
  });


export const getAllTeamsApi = (
): Promise<ResultResponse<ITeam[]>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/teams`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const archieveTeamApi = (teamId: string): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'PUT',
      url: `/v1/teams/${teamId}/archive`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const createProgramEnrollmentWithUserDataApi = (
  programId: string,
  teamId: string,
  data: any
): Promise<ResultResponse<any>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: `/v1/programEnrollment/${programId}/enrollment/${teamId}/data`,
      data: data,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res?.data?.data))
      .catch((error) => {
        console.log("error111", error);
        reject(error);
      });
  });

export const getUserTeamsApi = (
  userId: string
): Promise<ResultResponse<any>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/programEnrollment/${userId}/teams`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });


export const archieveUserApi = (userId: string): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'PUT',
      url: `/v1/users/${userId}/archive`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const unArchieveUserApi = (userId: string): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'PUT',
      url: `/v1/users/${userId}/unarchive`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const saveTeamsApi = (team: ITeam): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'PUT',
      url: `/v1/teams/${team?._id}`,
      data: team,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

// team register
export const inviteUserOnProgram = (data: any): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: '/v1/register',
      data: data,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });

export const getProgramEnrollmentUserApi = (
  programId: string,
  userId: string,
): Promise<ResultResponse<any>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/programEnrollment/${programId}/enrollment/${userId}`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res?.data?.data))
      .catch((error) => {
        console.log("error111", error);
        reject(error);
      });
  });

export const updateContextStatusApi = (
  programId: string,
  userId: string,
  year: string
): Promise<ResultResponse<any>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/programFormContexts/${programId}/${userId}/${year}/update/status`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res?.data?.data))
      .catch((error) => {
        console.log("error111", error);
        reject(error);
      });
  });

export const checkContextStatusApi = (
  programId: string,
  userId: string,
  year: string
): Promise<ResultResponse<any>> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'GET',
      url: `/v1/programFormContexts/${programId}/${userId}/${year}/check/status`,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res?.data?.data))
      .catch((error) => {
        console.log("error111", error);
        reject(error);
      });
  });

// team register
export const inviteMembers = (data: any): Promise<ResultResponse> =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'POST',
      url: '/v1/invite',
      data: data,
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => resolve(res.data))
      .catch((error) => {
        reject(error);
      });
  });
