import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Paper,
    TableBody,
    IconButton,
    Table,
    TableRow,
    TableCell,
    TableContainer,
    TableHead,
    Card,
    CardContent,
    Typography,
    withStyles,
    WithStyles,
    Avatar,
    Grid,
    Tabs,
    Tab,
} from '@material-ui/core';
import { DeleteOutline, Edit, RestoreFromTrash } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import { useHistory, useParams } from 'react-router';
import ButtonAppBar from '../../../../components/AppBar';
import HeaderNotificationIcon from '../../../../components/HeaderNotification';
import { useStyles } from '../style';
import CreateMember from './components/CreateMember';
import { useDispatch, useSelector } from 'react-redux';
import { getTeam, getTeamUsers } from '../../../../redux/actions/teamAction';
import { RootState } from '../../../../redux/store';
import { UserType } from '../../../../constants/data';
import moment from 'moment';
import { isAdmin } from '../../../../util/helpers';
import { archieveUserApi, unArchieveUserApi } from '../../../../api';
import toastr from 'toastr';
import { getProgramTeamUsers } from '../../../../redux/actions/programAction';
import { IUser } from '../../../../types/user';
import Show from '../../../../components/Show';
import CreateInvitationForm from './components/InviteMember';

const styles: any = (theme) => ({
    root: {
        margin: 0,
        position: 'relative',
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    modalTitle: {
        color: '#000000DE',
        fontWeight: 700
    }
});

const a11yProps = (index) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}
export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const MembersList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const teams = useSelector((state: RootState) => state.teamReducer.teams);
    const teamUsers = useSelector((state: RootState) => state.programReducer.teamUsersList);
    const authUser = useSelector((state: RootState) => state.auth.user);
    const { teamId, programId }: any = useParams();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenInvite, setModalOpenInvite] = useState(false);
    const [editData, setEditData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 5;
    const [tabValue, setTabValue] = useState(0);

    const handleClose = useCallback(() => {
        setModalOpen(false);
    }, [modalOpen]);

    const handleInviteClose = useCallback(() => {
        setModalOpenInvite(false);
    }, [modalOpenInvite]);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
        setCurrentPage(1);
    };
    
    const onEdit = (data) => {
        setModalOpen(true);
        setEditData(data);
    }

    useEffect(() => {
        if (authUser && isAdmin()) {
            dispatch(getProgramTeamUsers(programId, teamId))
            dispatch(getTeam(teamId));
        }
    }, [dispatch]);


    const startIdx = (currentPage - 1) * rowsPerPage;
    const endIdx = startIdx + rowsPerPage;
    const displayedUsers = teamUsers
        ?.filter((user: IUser) => (tabValue === 0 ? !user._isArchived : user._isArchived))
        .slice(startIdx, endIdx);

    console.log(displayedUsers, "displayedUsers")

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => setCurrentPage(value);

    const handleArchiveToggle = async (userId: string, _isArchived: boolean) => {
        try {
            if (_isArchived) {
                const r = await (unArchieveUserApi(userId));
                console.log("r", r)
                toastr.success('User unarchived successfully');
            } else {
                const r = await (archieveUserApi(userId));
                console.log("r", r)
                toastr.success('User archived successfully');
            }
            dispatch(getProgramTeamUsers(programId, teamId));
        } catch (error) {
            toastr.error('Error updating user status');
        }
    };

    return (
        <div>
            <ButtonAppBar title={teams ? `Team ${teams?.name}` : 'Team'} back={() => history.go(-1)} rightIcon={<HeaderNotificationIcon />} />
            <Box className={classes.container}>
                <Box style={{ marginBottom: 20, textAlign: 'right', display: 'flex', justifyContent: 'right', gap: 10 }}>
                    <Grid item xs={12} sm={12} md={3}>
                        <Button variant="contained" onClick={() => {
                            setEditData(null);
                            setModalOpen(true)
                        }} className={classes.button}>
                            Create Member
                        </Button>

                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Button variant="contained" onClick={() => {
                            setModalOpenInvite(true)
                        }} className={classes.button}>
                            Invite Member
                        </Button>
                    </Grid>

                </Box>


                <Box style={{ display: 'flex', gap: 5, marginBottom: 20 }}>
                    <Card style={{ flex: 1 }}>
                        <CardContent className={classes.countCardContentDiv}>
                            <Typography color="textSecondary" gutterBottom>No. of Members</Typography>
                            <Typography variant="h6" component="h4">{teamUsers?.filter((user: IUser) => !user._isArchived)?.length}</Typography>
                        </CardContent>
                    </Card>
                </Box>

                <Tabs
                    className={classes.tabsDiv}
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="All Users" {...a11yProps('1')} />
                    <Tab label="Archived Users" {...a11yProps('1')} />
                </Tabs>

                <TableContainer className={classes.tableContainer} component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Member Name</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Last Updated</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <Show when={displayedUsers && displayedUsers.length > 0}>
                                {displayedUsers.map((user: any, index) => {
                                    return (
                                        <>
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Box display={'flex'} alignItems={'center'}>
                                                        <Avatar
                                                            alt={user ? user.name : 'no name'}
                                                            src={user && process.env.REACT_APP_API_URL + '/' + user.avatarUrl}
                                                            style={{
                                                                height: 35,
                                                                width: 35,
                                                                cursor: 'Pointer',
                                                                borderRadius: '50% !important',
                                                                marginRight: 10
                                                            }}
                                                        />  {user.name + ' ' + user?.lastName}
                                                    </Box>
                                                </TableCell>
                                                <TableCell>{UserType[user?.type] ?? 'N/A'}</TableCell>
                                                <TableCell>{moment(user?.updatedAt).format('MMM DD, YYYY')}</TableCell>
                                                <TableCell>
                                                    <IconButton onClick={() => onEdit(user)}>
                                                        <Edit fontSize='small' style={{ color: '#333333' }} />
                                                    </IconButton>
                                                    <IconButton onClick={() => handleArchiveToggle(user?._id, user._isArchived)}>
                                                        {user._isArchived ? (
                                                            <RestoreFromTrash fontSize="small" color="primary" />
                                                        ) : (
                                                            <DeleteOutline fontSize="small" color="error" />
                                                        )}
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    )
                                })}
                            </Show>
                            <Show when={displayedUsers?.length === 0}>
                                <TableRow>
                                    <TableCell align='center' colSpan={4}>No records found</TableCell>
                                </TableRow>
                            </Show>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                    <Pagination
                        className={classes.pagination}
                        count={Math.ceil((teamUsers?.filter((user: IUser) => tabValue === 0 ? !user._isArchived : user._isArchived).length || 0) / rowsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                    />
                </Box>
            </Box>

            <CreateMember modalOpen={modalOpen} handleClose={handleClose} initialData={editData} />
            <CreateInvitationForm modalOpen={modalOpenInvite} handleClose={handleInviteClose}  />

        </div >
    );
};

export default MembersList;
