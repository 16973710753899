import React from 'react';
import { Avatar, Box, Button, makeStyles, Theme, Typography } from '@material-ui/core';
import { createSelector } from 'reselect';

import { IPolicyRequestRequester } from '../../../types/policyRequest';
import { IUser } from '../../../types/user';
import ImgIcon from '../../../components/ImgIcon';
import { optClasses } from '../../../util/lib';
import { RootState } from '../../../redux/store';
import { useSelector } from 'react-redux';
import { UserType } from '../../../constants/data';

interface IProps {
  requester: IPolicyRequestRequester;
  type: string;
  pending: boolean;
  onClick?: () => any;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  whiteColor: {
    color: 'white'
  },
  bold: {
    fontWeight: 'bold'
  },
  card: {
    backgroundColor: 'white',
    width: '100%',

    '&.active': {
      backgroundColor: `${theme.palette.primary.light}`,
    }
  },

}));

const selectRequestTeamMember = createSelector(
  (state: RootState) => state.teamReducer.teamUsers,
  (_, requester) => requester,
  (teamUsers: IUser[], requester: string) => teamUsers.filter(user => user._id === requester)
)

function RealTimeItem({ requester, type, onClick, pending, disabled = false }: IProps) {
  const classes = useStyles();
  const teamRequester = useSelector(state => selectRequestTeamMember(state, requester.requestedBy))[0];
  const authUser = useSelector((state: RootState) => state.auth.user);

  return (
    <Button
      fullWidth
      component='div'
      disabled={disabled}
      onClick={() => onClick()}
    >
      <Box
        display='flex'
        justifyContent='space-between'
        borderRadius={5}
        alignItems='center'
        padding={2}
        className={optClasses(classes.card, {
          active: pending === true
        })}
      >
        <Box display='flex' justifyContent='space-around'>
          {authUser.type === UserType.parent && (
            <Avatar>
              {teamRequester ? teamRequester.name[0] : 'U'}
            </Avatar>
          )}
          <Box marginLeft={2}>
            <Typography variant='h4' className={optClasses('', {
              [classes.whiteColor]: pending === true
            })}>
              {type === 'extension' ? 'Extra Time' : 'Special Access'}
            </Typography>
            <Typography variant='subtitle2' color='primary' className={optClasses(classes.bold, {
              [classes.whiteColor]: pending === true
            })}>{requester.data.category}</Typography>
          </Box>
        </Box>
        {pending ? (
          <ImgIcon name='Time Request' width={30} />
        ) : (
            <ImgIcon name='Time Approved' width={30} />
          )}
      </Box >
    </Button>
  );
}

export default RealTimeItem;