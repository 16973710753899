import { Button, Card, CardContent, Container, Grid, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import AddChannelModal from '../Sidebar/components/AddChannelModal'

const ChatDashboard = () => {
    const [open, setOpen] = useState<boolean>(false);

    const handleClose = () => {
        setOpen(!open);
    }
  return (
    <Container maxWidth="lg" style={{padding: '1rem'}}>
      <Typography variant="h2" align="center" gutterBottom>
        Welcome to Chat
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Create channels
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Create a public channel that new workspace members can join.
              </Typography>
              <Button onClick={() => setOpen(true)} variant="contained" color="primary" style={{ marginTop: '1rem' }}>
                Create channel
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <AddChannelModal open={open} handleClose={handleClose} />

    </Container>
  )
}

export default ChatDashboard