import { ErrorMessage } from '@hookform/error-message';
import { Card as MuiCard, Box, Button, CssBaseline, Divider, FormControl, FormLabel, Link, TextField, Typography, IconButton, OutlinedInput, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { setPasswordApi } from '../../../../api';
import toastr from 'toastr';

const useStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        width: '100%',
        maxWidth: '500px',
        padding: theme.spacing(4),
        gap: '8px',
        margin: 'auto',
        boxShadow:
            'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
        borderRadius: theme.spacing(1),
        backgroundColor: '#FFF'
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        minHeight: '100vh',
        alignItems: 'center',
        backgroundColor: '#EDF2F7',
        padding: theme.spacing(2),
    },
    title: {
        fontSize: '2.75rem',
        fontWeight: 700,
        textAlign: 'center',
        marginBottom: theme.spacing(2)
    },
    subTitle: {
        color: '#100F14',
        fontSize: '1.45rem',
        fontWeight: 600,
        textAlign: 'center',
    },
    titlePara: {
        fontSize: '1rem',
        color: '#49475A',
        textAlign: 'center',
        marginBottom: theme.spacing(2),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        '& .MuiOutlinedInput-root': {
            borderRadius: 6
        },
        '& .MuiFormLabel-root': {
            color: '#9794AA',
            marginBottom: theme.spacing(1),
        }
    },
    submitBtn: {
        background: '#1F2937',
        color: '#fff',
        textTransform: 'capitalize',
        borderRadius: 6,
        padding: '11px 11px',
        marginBottom: theme.spacing(2),
        '&:hover': {
            background: '#2c3b50'
        },
    },
}));

export default function SetPassword() {
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const onSubmit = async(data) => {
        try{
            setLoading(true);
            setPasswordApi({ ...data})
              .then((res) => {
                toastr.success('Password updated successfully.');
                history.push('/onboarding/welcome')
              })
              .catch((res) => {
                if (res.response) {
                  toastr.error(res.response.data.message);
                } else {
                  toastr.error(res.toString());
                }
              })
              .finally(() => setLoading(false))
        }
        catch(error) {
            console.error(error);
            toastr.error(error?.response?.data?.message || "An error occurred while updating the password.");
        }
    };

    const { control, handleSubmit, watch, errors } = useForm();
    const password = watch('password', '');

    useEffect(() => {

        const userData = JSON.parse(localStorage.getItem('authInfo'));

        if (!userData) {
            history.push("/login");
            return;
        };

        // if (userData && (userData?.isFirstTimeLogin === "true" )) {
        //     history.push("/login");
        //     return;
        // }

    }, []);

    return (
        <>
            <CssBaseline />
            <div className={classes.container}>
                <MuiCard className={classes.card}>
                    <Typography variant="h1" className={classes.title}>
                        Logo
                    </Typography>
                    <Typography className={classes.subTitle}>
                        Set a New Password
                    </Typography>
                    <Typography className={classes.titlePara}>
                        For your security, please set a new password
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit(onSubmit)}
                        className={classes.form}
                    >
                        <Controller
                            variant="outlined"
                            name="password"
                            control={control}
                            rules={{
                                required: 'Password is required.',
                            }}
                            defaultValue=""
                            as={
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'password',
                                    }}
                                    labelWidth={0}
                                    placeholder="Enter new password"
                                    type='password'
                                />
                            }
                        />
                        <ErrorMessage
                            as={<Typography color="error" />}
                            errors={errors}
                            name="password"
                        />

                        <Controller
                            variant="outlined"
                            name="confirmPassword"
                            control={control}
                            rules={{
                                required: 'Confirm password is required.',
                            }}
                            defaultValue=""
                            as={
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'password',
                                    }}
                                    labelWidth={0}
                                    placeholder="Enter confirm password"
                                    type='password'
                                />
                            }
                        />
                        <ErrorMessage
                            as={<Typography color="error" />}
                            errors={errors}
                            name="confirmPassword"
                        />


                        <Button
                            disabled={loading}
                            type="submit"
                            fullWidth
                            size="small"
                            className={classes.submitBtn}
                        >
                            Save New Password
                        </Button>
                    </Box>
                </MuiCard>
            </div>
        </>
    );
}
