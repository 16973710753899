import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { uploadFileToRoom } from '../../../../../../redux/actions/chat';

const UploadFile = ({ roomId, isModalOpen, handleChangeModal, selectedImg, setSelectedImg, file, setFile }) => {
    const dispatch = useDispatch();
    const [description, setDescription] = useState("");

    const handleSaveFile = () => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('description', description);
        dispatch(uploadFileToRoom(roomId, formData));
        handleChangeModal();
        setSelectedImg(null);
        setFile("");
        setDescription("");
    };

    return (
        <Dialog open={isModalOpen} onClose={handleChangeModal}>
            <DialogTitle>Upload File</DialogTitle>
            <DialogContent>
                <img src={selectedImg} alt="Selected File" style={{ width: '100%', height: 'auto' }} />
                <TextField
                    fullWidth
                    label="Description"
                    variant="outlined"
                    margin="normal"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleChangeModal}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={handleSaveFile}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default UploadFile