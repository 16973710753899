import {
  GET_TEAM,
  GET_TEAM_ERROR,
  GET_TEAM_LOADER,
  TEAM_USERS_STATE,
  GET_TEAM_USER_LOADER,
  PENDING_TEAM_STATUS,
  TEAM_USERS,
  GET_USER_POLICY_LOADING,
  USER_POLICY,
  USER_STATUS_ERROR,
  CHANGE_USER_PASSWORD_LOADER,
  CHANGE_USER_PASSWORD_ERROR,
  GET_ALL_TEAMS,
  GET_ALL_TEAMS_LOADER,
  ARCHIVE_USER,
  ARCHIVE_USER_ERROR,
  ARCHIVE_USER_LOADER,
} from '../actions/teamAction';
import { ITeamState } from '../../types/team';
import { IAction } from '../../types/generics';
import { IUserState, ITeamUserDetails } from '../../types/user';
import { IEnforcePolicyActionItem } from '../../types/acitivity';
import { GET_ALL_PROGRAMS_ERROR } from '../actions/programAction';

const iniState: ITeamState = {
  getTeamUserLoader: false,
  getTeamLoader: false,
  teams: {
    id: '',
    name: '',
    timezoneName: '',
    country: '',
    timezoneOffset: ''
  },
  getTeamErr: '',
  teamStatusLoading: false,
  teamUserStatus: [],
  teamUsers: [],
  teamUsersDetails: [],
  users: [],
  policyLoader: false,
  userPolicy: null,
  userStatusPoint: 'not done',
  userStateError: '',
  changePasswordLoading: false,
  changePasswordError: '',
  allTeams: [],
  getAllTeamErr: '',
  getAllTeamsLoader: false,

};

export default (state = iniState, action: IAction) => {
  switch (action.type) {
    case GET_TEAM:
      return {
        ...state,
        teams: action.payload,
      };
    case GET_TEAM_LOADER:
      return {
        ...state,
        getTeamLoader: action.payload,
      };
    case GET_TEAM_ERROR:
      return {
        ...state,
        getTeamErr: action.payload,
      };
    case PENDING_TEAM_STATUS:
      return {
        ...state,
        teamStatusLoading: action.payload,
      };
    case GET_TEAM_USER_LOADER:
      return {
        ...state,
        getTeamUserLoader: action.payload,
      };
    case TEAM_USERS_STATE:
      const { teamUsers } = state;
      const userStatus: IUserState[] = action.payload;
      const usersArray: ITeamUserDetails[] = userStatus.map((item) => {
        const limitations: IEnforcePolicyActionItem =
          item.userState &&
          item.userState.limits &&
          item.userState.limits.filter(
            (limitation) => limitation.item === 'today.overall'
          )[0];

        const user = teamUsers.filter((user) => user._id === item.userId)[0];
        return {
          user,
          limitations,
          limits: item.userState ? item.userState.limits : [],
          counter: item.userState ? item.userState.counter : null,
        };
      });

      return {
        ...state,
        getTeamUserLoader: false,
        teamUserStatus: action.payload,
        userStatusPoint: 'done',
        teamUsersDetails: usersArray,
      };
    case USER_STATUS_ERROR:
      return {
        ...state,
        userStatusPoint: 'error',
        userStateError: action.payload,
      };
    case TEAM_USERS:
      return {
        ...state,
        teamUsers: action.payload,
      };
    case GET_USER_POLICY_LOADING:
      return {
        ...state,
        policyLoader: action.payload,
      };
    case USER_POLICY:
      return {
        ...state,
        userPolicy: action.payload,
      };
    case CHANGE_USER_PASSWORD_LOADER:
      return {
        ...state,
        changePasswordLoading: action.payload,
      };
    case CHANGE_USER_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordError: action.payload,
      };
    case GET_ALL_TEAMS:
      return {
        ...state,
        allTeams: action.payload,
      };
    case GET_ALL_TEAMS_LOADER:
      return {
        ...state,
        getAllTeamsLoader: action.payload,
      };
    case GET_ALL_PROGRAMS_ERROR:
      return {
        ...state,
        getAllTeamErr: action.payload,
      };
    case ARCHIVE_USER:
      return {
        ...state,
        teamUsers: state.teamUsers.filter(user => user._id !== action.payload),
      };
    case ARCHIVE_USER_ERROR:
      return {
        ...state,
        userStateError: action.payload,
      };

    case ARCHIVE_USER_LOADER:
      return {
        ...state,
        archiveUserLoading: action.payload,
      };
    
    default:
      return state;
  }
};
