import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    titleWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '15px',
    },
    typography: {
        fontFamily: 'Nunito Sans',
        fontSize: '18px',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
    caption: {
        fontSize: '14px',
        textAlign: 'center',
        fontWeight: 500,
        marginBottom: 0,
    },
    header: {
        fontSize: 14,
        fontWeight: 500,
        textAlign: 'center',
        marginBottom: theme.spacing(0),
    },
    formControl: {
        margin: theme.spacing(1),
    },
    card: {
        padding: theme.spacing(2),
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        backgroundColor: '#f9f9f9',
        fontFamily: "Nunito Sans",
        minHeight: "360px",
        maxHeight: "375px",
        overflowY: "auto",
    },
    cardContainer: {
        display: 'flex',
        marginBottom: theme.spacing(1),
        padding: 10,
        borderRadius: 10,
        color : "#757575"
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginRight: theme.spacing(2),
        backgroundColor: '#E0E0E0',
    },
    content: {
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: "0 !important"
    },
    date: {
        fontSize: '0.875rem',
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    feedbackTitle: {
        fontSize: '1rem',
        fontWeight: 600,
        fontFamily: "Nunito Sans",
        marginBottom: theme.spacing(0.5),
        color: "#0F1828"
    },
    feedbackText: {
        fontSize: '0.875rem',
        color: '#555555',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        margin: 0
    },
    highLightCard: {
        background: "grey",
        color: "white"
    }
}));