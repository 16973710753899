import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        overflowX: 'auto',
    },
    categoryIcon: {
        width: 15,
        height: 15
    },
    weekHeader: {
        backgroundColor: '#F5F7FA',
        padding: theme.spacing(1),
        textAlign: 'center',
        fontWeight: 700,
        borderRadius: 0,
        marginBottom: "3px",
        boxShadow: "none",
        textDecoration: "underline",
        fontFamily: "Nunito Sans",
        position: 'sticky',
        cursor: "pointer",
        top: 0,
        zIndex: 2
    },
    grade: {
        backgroundColor: '#F5F7FA',
        padding: theme.spacing(2),
        textAlign: 'center',
        fontWeight: 500,
        borderRadius: 0,
        boxShadow: "none",
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        overflowX: 'auto',
    },
    tableHeader: {
        borderBottom: "none",
        padding: theme.spacing(1),
        textAlign: 'center',
        fontWeight: 'bold',
        position: 'sticky',
        top: 0,
        zIndex: 1
    },
    tableCell: {
        padding: theme.spacing(0.07),
        textAlign: 'center',
    },
    categoryName: {
        fontSize: 12,
        color: "#00000099",
        background: '#fff',
        zIndex: 1, 
    },
    scoreCell: (props: any) => ({
        backgroundColor: props.backgroundColor,
        padding: theme.spacing(1),
        textAlign: 'center',
        borderRadius: 0,
        boxShadow: "none",
    }),
    highLightCell: {
        background: "grey",
        color: 'black'
    },
    // New group styling classes
    groupSeparator: {
        height: theme.spacing(4),
    },
    groupHeader: {
        padding: theme.spacing(1, 2),
        backgroundColor: 'rgba(0, 0, 0, 0.03)',
        borderRadius: theme.spacing(1),
    },
    groupTitle: {
        fontWeight: 'bold',
        color: theme.palette.text.secondary,
    }
}));
