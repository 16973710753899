import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Item from './RequestItem';
import Alert from './ExtraTimeRequest/Alert';
import ExtraTimeRequest from './ExtraTimeRequest';
import { getPolicyRequests, showModal } from '../../../redux/actions/policyRequestAction';
import { RootState } from '../../../redux/store';
import { createSelector } from 'reselect';
import { IUser } from '../../../types/user';
import ApproveTimeRequest from './ApproveTimeRequest';
import { IPolicyRequest } from '../../../types/policyRequest';

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    width: '100%',
    boxSizing: 'border-box',
    justifyContent: 'center'
  },
  actionButton: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    flex: 1,
  },
  avatar: {
    margin: 'auto',
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: theme.spacing(2)
  }
}));

const selectRequestTeamMember = createSelector(
  (state: RootState) => state.teamReducer.teamUsers,
  (_, requester) => requester,
  (teamUsers: IUser[], requester: string) => teamUsers.filter(user => user._id === requester)
)

function Parent() {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const requests = useSelector((state: RootState) => state.policyRequests.requests);
  const open = useSelector((state: RootState) => state.policyRequests.openModal);
  const [selectedRequest, setSelectedRequest] = useState<IPolicyRequest>(null);
  const teamRequester = useSelector(state => selectRequestTeamMember(state, selectedRequest?.requester.requestedBy))[0];
  const [confirm, setConfirm] = useState<boolean>(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);

  const handleClose = () => {
    setConfirm(false);
  }



  const handleApproveOpen = (request) => {
    if (request.state !== 'requested')
      return;
    setSelectedRequest(request);
    setConfirm(true);
  }

  const handleAddTime = () => {
    setOpenApproveModal(true);
    setConfirm(false);
  }

  useEffect(() => {
    dispatch(getPolicyRequests());
  }, [dispatch]);

  return (
    <Box padding={2}>
      <Box marginTop={2}>
        <Typography variant='h2'>Requests</Typography>
        <Typography variant='caption'>Real Time requests are cleared each day at midnight</Typography>
        {requests.map((request) => (
          <Item
            onClick={() => handleApproveOpen(request)}
            requester={request.requester}
            key={request._id}
            type={request.requestType}
            pending={request.state === 'requested'}
          />
        ))}
      </Box>
      {selectedRequest && (
        <Dialog
          open={confirm}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Box textAlign='center' paddingTop={2}>
              <Avatar className={classes.avatar}>
                {teamRequester ? teamRequester.name[0] : 'U'}
              </Avatar>
              <DialogContentText id="alert-dialog-description">
                <Typography component='span' variant='h3'>{selectedRequest.requestType === 'extension' ? `Extra Time for ${selectedRequest.requester.data.category}` : `Access During Homework`}</Typography>
                <Typography component='span'>{selectedRequest.requester.reason}</Typography>
              </DialogContentText>
            </Box>
          </DialogContent>
          <DialogActions classes={{ root: classes.actions }}>
            <Button autoFocus onClick={handleClose} className={classes.actionButton} size='large' color="primary" variant='outlined'>
              Close
            </Button>
            <Button className={classes.actionButton} onClick={handleAddTime} size='large' color="primary" variant='contained'>
              Add Time
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <ApproveTimeRequest isOpen={openApproveModal} handleClose={() => setOpenApproveModal(false)} request={selectedRequest} />
      <ExtraTimeRequest isOpen={isOpen} handleClose={() => setIsOpen(false)} />
      <Alert open={open} handleClose={() => dispatch(showModal(false))} />
    </Box>
  );
}

export default Parent;