/*
 * File: /backpack/components/FormControl/index.tsx
 *
 * Overview:
 * This file defines a set of reusable form control components used to render dynamic forms.
 * The file is organized into several sections:
 *
 * 1. Basic Field Components:
 *    - FormGroup: A container for form fields with label and error message.
 *    - FormGroupInner: A sub-container used within FormGroup for nested fields.
 *    - InputField: A basic HTML input field.
 *    - TextAreaField: A basic HTML textarea field.
 *    - AutoHeightTextAreaField: A contentEditable div that behaves like a textarea with auto height.
 *    - SelectField: A basic select (dropdown) field.
 *    - AddressFields: A composite control rendering multiple InputFields for address details.
 *
 * 2. Main FormControlComponent:
 *    - This component receives a "question" object and based on its type, renders the appropriate control.
 *
 * 3. Supported Control Types (as defined in the switch statement within FormControlComponent):
 *    - control_textbox: Renders a textbox using AutoHeightTextAreaField.
 *    - control_textarea: Renders a textarea with inline edit capability.
 *    - control_button: Renders navigation buttons (Submit, Back, Cancel).
 *    - control_pagebreak: Renders a page break control with a submit button.
 *    - control_head: Renders a header with title and subheader.
 *    - control_radio: Renders a set of radio buttons.
 *    - control_checkbox: Renders a set of checkboxes.
 *    - control_dropdown: Renders a dropdown select.
 *    - control_number: Renders a numeric input.
 *    - control_datetime: Renders a datetime-local input.
 *    - control_fileupload: Renders a file input.
 *    - control_rating: Renders a star-based rating control.
 *    - control_scale: Renders a scale control with radio buttons and labels.
 *    - control_matrix: Renders a matrix control with various input types (radio, checkbox, dropdown, textbox, dynamic).
 *    - control_slider: Renders a slider (range input).
 *    - control_spinner: Renders a numeric spinner.
 *    - control_fullname: Renders a full name text input.
 *    - control_phone: Renders a telephone input.
 *    - control_email: Renders an email input.
 *    - control_address: Renders a composite address field.
 *    - control_payment: Renders payment fields (card number, expiration date, cvv).
 *    - control_signature: Renders a file input for signatures.
 *    - control_image: Renders an image uploader/viewer.
 *    - control_googlemap: Renders two inputs for latitude and longitude.
 *    - control_matrixdynamic: Renders a dynamic matrix table.
 *    - control_matrixdropdown: Renders a matrix control with dropdowns.
 *    - control_calculation: Renders a read-only calculation field.
 *    - control_text: Renders static HTML text.
 *    - control_widget: Renders a widget control, including a dynamic matrix for additional fields.
 *
 * This file uses Material-UI components, React Router hooks, and local styling.
 */

// -----------------------------------------------------------------------------
// Imports
// -----------------------------------------------------------------------------
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AddressFieldsProps, FormGroupProps, InputFieldProps, SelectFieldProps, TextAreaFieldProps } from './type';
import { TextField, FormControl, FormControlLabel, Radio, RadioGroup, FormLabel, Button, Input, Slider, Box } from '@material-ui/core';
import { useStyles } from '../../style';
import { useHistory } from 'react-router-dom';
import AppendForm from '../../../../OnBoarding/Screens/components/AppendForm';
import EditIcon from '@material-ui/icons/Edit';
import { CompareSharp } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useLocation } from "react-router-dom";


// -----------------------------------------------------------------------------
// Basic Field Components
// -----------------------------------------------------------------------------



/**
 * FormGroup
 * A container that wraps a form control, providing a label and error display.
 * If hoverText is provided, an info icon is rendered with a tooltip.
 */

const FormGroup: React.FC<FormGroupProps> = ({
    key,
    label,
    children,
    classes = {},
    error,
    hoverText
}) => {
    // Added state to manage the popover:
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    return (
        <div className={classes.formGroup || 'form-group'} key={key}>
            {label && (
                // Wrap label and icon in a flex container for alignment:
                <label
                    className={classes.label}
                    htmlFor={key}
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                    <span>{label}</span>
                    {hoverText && (
                        <>
                            <IconButton onClick={(event) => setAnchorEl(event.currentTarget)} size="small">
                                <InfoIcon />
                            </IconButton>
                            <Popover
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                onClose={() => setAnchorEl(null)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <div style={{ padding: 10, maxWidth: 300 }}>
                                    {/* Allow HTML content */}
                                    <div dangerouslySetInnerHTML={{ __html: hoverText }} />
                                    <IconButton onClick={() => setAnchorEl(null)} size="small" style={{ float: 'right' }}>
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </div>
                            </Popover>
                        </>
                    )}
                </label>
            )}
            {children}
            {error && <span style={{ color: 'red' }}>{error}</span>}
        </div>
    );
};

/**
 * FormGroupInner
 * A sub-container used within a form group for nesting additional fields.
 */
const FormGroupInner: React.FC<FormGroupProps> = ({ key, label, children, classes = {} }: any) => (
    <div className={classes.formGroupInner || 'form-group'} key={key}>
        {label && <label className={classes.label} htmlFor={key}>{label}</label>}
        {children}
    </div>
);

/**
 * InputField
 * A basic HTML input element supporting various types.
 */
const InputField: React.FC<InputFieldProps> = ({
    type, id, name, className, value, onChange, required,
    placeholder, readOnly, accept, min, max, hoverText, description
}) => {
    //console.log(`InputField [ id=${id}  name=${name}] - value: '${value}'`);
    return (
        <input
            type={type}
            id={id}
            name={name}
            className={className}
            value={value}
            onChange={onChange}
            required={required}
            placeholder={placeholder}
            readOnly={readOnly}
            accept={accept}
            min={min}
            max={max}
        />
    );
};

/**
 * TextAreaField
 * A basic HTML textarea element.
 */
const TextAreaField: React.FC<TextAreaFieldProps> = ({ type, id, name, className, value, onChange, required, placeholder, rows, cols }) => (
    <textarea
        id={id}
        name={name}
        className={className}
        value={value}
        onChange={onChange}
        required={required}
        placeholder={placeholder}
        rows={rows}
        cols={cols}
    />
);
/**
 * AutoHeightTextAreaField
 * A contentEditable div that simulates a textarea with auto height.
 * It updates its value via the onChange callback on blur.
 * Optionally accepts autoFocus, rows, and cols for future use.
 */
const AutoHeightTextAreaField = ({
    id,
    name,
    className,
    value,
    subLabel,
    description,
    onChange,
    placeholder,
    onBlur,
    rows,
    cols,
    autoFocus, // new optional prop
}: any) => {
    const content = useRef<string>(value);
    const divRef = useRef<HTMLDivElement>(null);

    // When autoFocus is true, focus the contentEditable div
    useEffect(() => {
        try {
            if (autoFocus && divRef.current) {
                divRef.current.focus();
            }
        } catch (error) {
            console.error("Error focusing the contentEditable div:", error);
        }
    }, [autoFocus]);

    //console.log(`          >>>> FF - AutoHeightTextAreaField [ id=${id}  name=${name}] - value: '${value}'`);
    return (
        <div>
            <div
                ref={divRef}
                role="textbox"
                contentEditable
                id={id}
                className={className + " text-area-auto-h-box"}
                onInput={(e) => {
                    //console.log(`         >>>> FF - AutoHeightTextAreaField [${name}] - onInput: '${e.currentTarget.innerText}'`);
                    if (e.currentTarget.innerText === '\n') {
                        e.currentTarget.innerText = '';
                    }
                    content.current = e.currentTarget.innerHTML;
                }}
                onBlur={() => {
                    //console.log(`         >>>> FF - AutoHeightTextAreaField [${name}] - onBlur: '${content.current}'`);
                    onChange({ target: { name, value: content.current } });
                    if (onBlur) {
                        onBlur();
                    }
                }}
                dangerouslySetInnerHTML={{ __html: value }}
                data-placeholder={placeholder}
                data-rows={rows}
                data-cols={cols}
                title={description} // Add hover text if description is passed
            />
            {(subLabel) ? <span className="sub-label">{(subLabel) ? subLabel : "no sublabel"}</span> : ""}
        </div>
    );
};


/**
 * SelectField
 * A basic select dropdown component.
 */
const SelectField: React.FC<SelectFieldProps> = ({
    name,
    value = '',
    options,
    onChange,
    className,
    required = false,
    id,
    placeholder,
}) => {
    return (
        <select
            name={name}
            value={value}
            onChange={onChange}
            className={className}
            required={required}
            id={id}
        >
            {placeholder && (
                <option value="" disabled>
                    {placeholder}
                </option>
            )}
            {options.map((option, index) => (
                <option key={index} value={option.value}>
                    {option.text}
                </option>
            ))}
        </select>
    );
};

/**
 * AddressFields
 * Renders a group of input fields for an address.
 */
const AddressFields: React.FC<AddressFieldsProps> = ({ commonProps, key, value, required }) => {
    const addressArr = ['street', 'city', 'state', 'zip', 'country'];
    return (
        <>
            {addressArr.map((el, index) => (
                <InputField
                    key={index}
                    type='text'
                    {...commonProps}
                    id={`${key}-${el}`}
                    name={`${key}[${el}]`}
                    value={value[el] || ''}
                    required={required}
                    placeholder={el}
                />
            ))}
        </>
    );
};

// -----------------------------------------------------------------------------
// Main Form Control Component
// -----------------------------------------------------------------------------

/**
 * FormControlComponent
 *
 * This component dynamically renders form controls based on the provided "question" object.
 * It supports a wide variety of control types such as:
 *
 * - Text Inputs: control_textbox, control_textarea, control_fullname, control_phone, control_email
 * - Buttons: control_button, control_pagebreak
 * - Headers: control_head
 * - Selections: control_radio, control_checkbox, control_dropdown, control_matrixdropdown
 * - Numeric Inputs: control_number, control_spinner, control_slider
 * - Date/Time Inputs: control_datetime
 * - File Inputs: control_fileupload, control_signature, control_image
 * - Specialized Controls: control_rating, control_scale, control_matrix, control_matrixdynamic,
 *   control_googlemap, control_calculation, control_text, control_widget, control_payment, control_address
 *
 * The component uses a switch statement to determine which control to render based on question.type.
 */
const FormControlComponent = ({
    question,
    formData,
    handleChange,
    setFormData,
    error,
    backButton = false,
    onNext,
    onBack,
    currentPageIndex,
    onFormDataChange
}) => {
    const {
        type, key, name, text, settings, required, readOnly, options,
        mcolumns, mrows, dcolumns, columns, rows, value, nextText, subLabel, description, hoverText
    } = question;
    const history = useHistory();
    const classes = useStyles();
    const commonProps = {
        id: key || name,
        name,
        className: classes.textField,
        value: formData[name] || '',
        description: question['description'] || null,
        subLabel: question['subLabel'] || null,
        hoverText: question['hoverText'] || null,
        onChange: handleChange,
        required: required === 'Yes',
        readOnly: readOnly === 'Yes',
    };
    const [editingField, setEditingField] = useState(null); // Track which field is in edit mode
    const hasRunRef = useRef(false);
    const location = useLocation();
    const isOnboarding = location.pathname.includes("/onboarding/phase/");
    const toggleEdit = (fieldName) => {
        setEditingField((prev) => (prev === fieldName ? null : fieldName));
    };
    const onEdit = (fieldName) => {
        setEditingField(fieldName);

    }

    const handleBackClick = () => {
        history.push('/user/dashboard');
    };

    const handleNextClick = () => {
        if (onNext) {
            onNext(); // Trigger next page update
        }
    };

    const handleBack = () => {
        if (onBack) {
            onBack(); // Trigger back page update
        }
    };

    useEffect(() => {
        if (commonProps.value.length > 120 && type === 'control_textarea' && !hasRunRef.current) {
            hasRunRef.current = true;
        }
    }, [commonProps.value, type]);

    // -------------------------------------------------------------------------
    // Render Control Based on Type
    // -------------------------------------------------------------------------

    // Helper function to remove extra props tha aren't supported by certain controls
    const sanitizeProps = (props: any) => {
        const { hoverText, description, subLabel, ...sanitized } = props;
        return sanitized;
    };

    switch (type) {
        case 'control_textbox':
            //console.log(`    >>> ${type} [${commonProps.name}] - label: ${text} - value: '${commonProps.value}'`);
            return (
                <FormGroup error={error} classes={classes} key={key} label={text} hoverText={hoverText || description}>

                    <AutoHeightTextAreaField
                        {...commonProps}
                        placeholder={question?.placeholder}
                        onChange={handleChange}
                        autoFocus  // now the field auto-focuses on render
                        onBlur={() => setEditingField(null)}
                    />
                </FormGroup>
            );

        case 'control_textarea':
            //console.log(`     >>> ${type} - label: ${text} - value: '${commonProps.value}'`);
            return (
                <Box onClick={() => onEdit(question.name)} >
                    <FormGroup error={error} classes={classes} key={key} label={text} hoverText={hoverText || description}>
                        {editingField === question.name ? (
                            <AutoHeightTextAreaField
                                {...commonProps}
                                placeholder={question?.placeholder}
                                onChange={handleChange}
                                autoFocus  // now the field auto-focuses on render
                                onBlur={() => setEditingField(null)}
                            />
                        ) : (
                            // Make this container focusable so that on focus or click it switches to edit mode.
                            <div
                                onFocus={() => toggleEdit(question.name)}
                                onClick={() => toggleEdit(question.name)}
                                tabIndex={0} // ensures the div can receive focus
                            >
                                <p className='text-break-all text-area-auto-h-box'>
                                    <span dangerouslySetInnerHTML={{ __html: commonProps.value }}></span>
                                </p>
                            </div>
                        )}
                        {question["subLabel"] && <p className={classes.label}>{question["subLabel"]}</p>}
                    </FormGroup>
                </Box>
            );

        case 'control_button':
            return (
                <div
                    className={`${classes.formGroup || "form-group"} ${!isOnboarding ? `${classes.nextBackbuttonGroup}` : `${classes.onboardingNextBackbuttonGroup}`}`}
                    key={key}
                >
                    {currentPageIndex > 0 && (
                        <button type="button" className="btn btn-primary cancelbtn" style={{ marginLeft: '10px' }} onClick={onBack}>
                            Back
                        </button>
                    )}

                    {backButton && (
                        <button type="button" className="btn btn-primary cancelbtn" style={{ marginLeft: '10px' }} onClick={handleBackClick}>
                            Cancel
                        </button>
                    )}
                    <button type="submit" className={`${!isOnboarding ? "btn btn-primary submitbtn" : "btn btn-primary submitOnboardingbtn"}`}
                    >
                        {text || 'Submit'}
                    </button>
                </div>
            );

        case 'control_pagebreak':
            return (<><div
                className={`form-group ${isOnboarding ? classes.onboardingNextBackbuttonGroup : classes.nextBackbuttonGroup
                    }`}
            >
                {currentPageIndex > 0 && (
                    <button type="button" className="btn btn-primary cancelbtn" onClick={onBack}>
                        Back
                    </button>
                )}

                <div className={backButton || currentPageIndex > 0 ? `${classes.nextBackbuttonGroup} form-group` : "form-group"} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div></div>
                    <button type="submit" className="btn btn-primary cancelbtn" onClick={handleNextClick}>
                        {nextText || 'Submit'}
                    </button>
                </div>

            </div>
            </>);

        case 'control_head':
            return (
                <div className="form-group">
                    <h2 className={classes.controlHeading1}>{text || ''}</h2>
                    <h4>{question.subHeader || ''}</h4>
                    {settings?.subHeader && <p>{settings.subHeader}</p>}
                </div>
            );

        case 'control_radio':
            const radioOptions = options ? options.split('|') : [];
            return (
                <FormGroup error={error} classes={classes} key={key} label={text} hoverText={hoverText || description}>
                    {radioOptions.map((option, index) => (
                        <label key={index}>
                            <input
                                type="radio"
                                name={name}
                                value={option}
                                checked={formData[name] === option}
                                onChange={handleChange}
                            />
                            {option}
                        </label>
                    ))}
                </FormGroup>
            );

        case 'control_checkbox':
            const checkboxOptions = options || [];
            return (
                <FormGroup error={error} classes={classes} label={text} key={key} hoverText={hoverText || description}>
                    {checkboxOptions.map((option, index) => (
                        <label key={index}>
                            <input
                                type="checkbox"
                                name={name}
                                value={option}
                                checked={formData[name]?.includes(option) || false}
                                onChange={handleChange}
                            />
                            {option}
                        </label>
                    ))}
                </FormGroup>
            );

        case 'control_dropdown':
            return (
                <FormGroup error={error} classes={classes} key={key} label={text} hoverText={hoverText || description}>
                    <select {...commonProps}>
                        {options.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </FormGroup>
            );

        case 'control_number':
            return (
                <FormGroup error={error} classes={classes} key={key} label={text}>
                    <InputField type="number" {...commonProps} min={0} />
                </FormGroup>
            );

        case 'control_datetime':
            return (
                <FormGroup error={error} classes={classes} key={key} label={text}>
                    <InputField type="datetime-local" {...commonProps} />
                </FormGroup>
            );

        case 'control_fileupload':
            return (
                <FormGroup error={error} key={key} label={text}>
                    <InputField type="file" {...commonProps} />
                </FormGroup>
            );

        case 'control_rating':
            return (
                <div className="form-group">
                    {text && <label>{text}</label>}
                    <div>
                        {Array.from({ length: 5 }, (_, index) => (
                            <span key={index} className={index < value ? 'star filled' : 'star'}>
                                ★
                            </span>
                        ))}
                    </div>
                </div>
            );

        case 'control_scale':
            const scaleRange = Array.from(
                { length: Number(question.scaleAmount) - Number(question.scaleFrom) + 1 },
                (_, i) => i + Number(question.scaleFrom)
            );
            return (
                <FormGroup error={error} key={key} label={text} hoverText={question['hoverText'] || question['description']}>
                    <div className="scale-container">
                        <div className="scale-buttons">
                            {scaleRange.map((val) => (
                                <label key={val} className={`scale-button ${Number(formData[name]) === Number(val) ? 'selected' : ''}`}>
                                    <input
                                        type="radio"
                                        {...sanitizeProps(commonProps)}
                                        id={name}
                                        name={name}
                                        value={val}
                                        checked={Number(formData[name]) === Number(val)}
                                        onChange={handleChange}
                                    //required={required !== 'Yes' ? true : false}
                                    />
                                    {val}
                                </label>
                            ))}
                        </div>
                        <div className="scale-labels">
                            <span className='scale-labels-text text-1'>{question.fromText}</span>
                            <span className='scale-labels-text text-2'>{question.toText}</span>
                        </div>
                    </div>
                </FormGroup>
            );

        case 'control_matrix':
            const matrixRows = mrows ? mrows.split('|') : [];
            const matrixColmns = mcolumns ? mcolumns.split('|') : [];
            const matrixColumnConfig = dcolumns ? JSON.parse(dcolumns) : [];
            let rateArray = Array.isArray(formData[name]) ? formData[name] : [];
            if (!Array.isArray(formData[name])) {
                matrixRows.forEach((rowData, rowIndex) => {
                    rateArray[rowIndex] = {};
                    let colObj = {};
                    matrixColmns.forEach((coldata) => {
                        let key = coldata.trim();
                        colObj[key] = false;
                    });
                    rateArray[rowIndex][rowData] = colObj;
                });
            }
            const handleMatrixChange = (e, rowIndex, colIndex) => {
                const { type, checked, value } = e.target;
                setFormData(prevData => ({
                    ...prevData,
                    [question.name]: {
                        ...prevData[question.name],
                        [rowIndex]: {
                            ...prevData[question.name]?.[rowIndex],
                            [colIndex]: type === 'checkbox' ? checked : value
                        }
                    }
                }));
            };
            const handleMatrixRadioChange = (e, rowIndex, colIndex, formName) => {
                const row = matrixRows[rowIndex];
                const columnData = matrixColmns[colIndex]['text'].trim();
                const updatedRateArray = [...rateArray];
                updatedRateArray[rowIndex] = { ...updatedRateArray[rowIndex] };
                updatedRateArray[rowIndex][row] = { ...updatedRateArray[rowIndex][row] };
                Object.keys(updatedRateArray[rowIndex][row]).forEach((key) => {
                    updatedRateArray[rowIndex][row][key] = key === columnData;
                });
                setFormData(prevData => ({
                    ...prevData,
                    [formName]: updatedRateArray,
                }));
            };
            return (
                <FormGroup error={error} key={key} label={text} classes={classes}>
                    <div className={classes.tableScoll}>
                        <table className="form-matrix">
                            <thead>
                                <tr>
                                    <th style={{ width: `${100 / matrixColumnConfig?.length}%` }}></th>
                                    {matrixColmns.map((col, index) => (
                                        <th key={index} style={{ width: `${100 / matrixColumnConfig?.length}%` }}>{col}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {matrixRows.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        <td>{row}</td>
                                        {matrixColumnConfig.map((col, colIndex) => {
                                            const inputType = question.inputType;
                                            const matrixValueInputValue = formData[name]?.[rowIndex]?.[colIndex];
                                            const matrixValue = (typeof matrixValueInputValue === 'object' && matrixValueInputValue !== null)
                                                ? matrixValueInputValue.value
                                                : matrixValueInputValue;
                                            switch (inputType) {
                                                case 'Radio Button':
                                                    return (
                                                        <td key={colIndex}>
                                                            <FormControlLabel
                                                                {...commonProps}
                                                                id={name}
                                                                className={classes.radioButton}
                                                                label={''}
                                                                name={`${name}[${rowIndex}]`}
                                                                value={col}
                                                                checked={
                                                                    rateArray[rowIndex] &&
                                                                    rateArray[rowIndex][row] &&
                                                                    rateArray[rowIndex][row][col.text?.trim()]
                                                                }
                                                                onChange={(e) => handleMatrixRadioChange(e, rowIndex, colIndex, name)}
                                                                control={<Radio />}
                                                            />
                                                        </td>
                                                    );
                                                case 'Check Box':
                                                    return (
                                                        <td key={colIndex}>
                                                            <input
                                                                type="checkbox"
                                                                name={`${name}`}
                                                                checked={matrixValue || false}
                                                                onChange={(e) => handleMatrixChange(e, rowIndex, colIndex)}
                                                            />
                                                        </td>
                                                    );
                                                case 'Drop Down':
                                                    return (
                                                        <td key={colIndex}>
                                                            <select
                                                                className={classes.textField}
                                                                name={`${name}`}
                                                                value={matrixValue || ''}
                                                                onChange={(e) => handleMatrixChange(e, rowIndex, colIndex)}
                                                            >
                                                                {col.options.split('|').map((option, optionIndex) => (
                                                                    <option key={optionIndex} value={option}>{option}</option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                    );
                                                case 'Text Box':
                                                    return (
                                                        <td key={colIndex}>
                                                            <AutoHeightTextAreaField
                                                                {...commonProps}
                                                                placeholder={""}
                                                                name={`${name}`}
                                                                value={matrixValue || ''}
                                                                onChange={(e) => handleMatrixChange(e, rowIndex, colIndex)}
                                                            />
                                                        </td>
                                                    );
                                                case 'Dynamic':
                                                    return (
                                                        <td key={colIndex}>
                                                            <AutoHeightTextAreaField
                                                                {...commonProps}
                                                                placeholder={""}
                                                                name={`${name}`}
                                                                value={matrixValue || ''}
                                                                onChange={(e) => handleMatrixChange(e, rowIndex, colIndex)}
                                                            />
                                                        </td>
                                                    );
                                                default:
                                                    return <td key={colIndex}>Unknown Type</td>;
                                            }
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </FormGroup>
            );

        case 'control_slider':
            return (
                <div className="form-group" key={key}>
                    {question.text && <label htmlFor={key}>{question.text}</label>}
                    <input
                        type="range"
                        {...commonProps}
                        min={question?.min || '0'}
                        max={question?.max || '100'}
                        defaultValue={question.value || '50'}
                    />
                </div>
            );

        case 'control_spinner':
            return (
                <div className="form-group" key={key}>
                    {question.text && <label htmlFor={key}>{question.text}</label>}
                    <input
                        type="number"
                        {...commonProps}
                        min={question.settings?.min || 0}
                        max={question.settings?.max || 100}
                        defaultValue={question.value || '10'}
                    />
                </div>
            );

        case 'control_fullname':
            return (
                <FormGroup error={error} classes={classes} key={key} label={question.text}>
                    <InputField {...commonProps} type="text" />
                </FormGroup>
            );

        case 'control_phone':
            return (
                <div className="form-group" key={key}>
                    {question.text && <label htmlFor={key}>{question.text}</label>}
                    <input type="tel" {...commonProps} defaultValue={question.value || ''} />
                </div>
            );

        case 'control_email':
            return (
                <div className="form-group" key={key} onClick={() => onEdit(question.name)}>
                    {question.text && <label htmlFor={key}>{question.text}</label>}
                    <input type="email" {...commonProps} defaultValue={question.value || ''} />
                </div>
            );

        case 'control_address':
            return (
                <FormGroup error={error} key={key} label={question.text}>
                    <AddressFields commonProps={commonProps} key={key} value={question.value} />
                </FormGroup>
            );

        case 'control_payment':
            return (
                <div className="form-group" key={key}>
                    {question.text && <label>{question.text}</label>}
                    <input
                        type="text"
                        name={`${key}[cardNumber]`}
                        placeholder="Card Number"
                        defaultValue={question.value?.cardNumber || ''}
                        required={question.required}
                    />
                    <input
                        type="text"
                        name={`${key}[expirationDate]`}
                        placeholder="Expiration Date"
                        defaultValue={question.value?.expirationDate || ''}
                        required={question.required}
                    />
                    <input
                        type="text"
                        name={`${key}[cvv]`}
                        placeholder="CVV"
                        defaultValue={question.value?.cvv || ''}
                        required={question.required}
                    />
                </div>
            );

        case 'control_signature':
            return (
                <FormGroup error={error} key={key} label={question.text} classes={classes}>
                    <InputField {...commonProps} type='file' accept=".png, .jpg, .jpeg" />
                </FormGroup>
            );

        case 'control_image':
            return (
                <div className="form-group" key={key}>
                    {question.src === "" ? (
                        <FormGroup error={error} classes={classes} key={key} label={question.text}>
                            <InputField type='file' {...commonProps} accept=".jpg, .jpeg, .png" />
                        </FormGroup>
                    ) : (
                        <img className={classes.image} width={question.width} height={question.height} src={question.src} alt={question.text} />
                    )}
                </div>
            );

        case 'control_googlemap':
            return (
                <FormGroup error={error} classes={classes} key={key} label={question.text}>
                    <div>
                        <InputField {...commonProps} type='text' name={`${key}[latitude]`} placeholder="Latitude" />
                        <InputField {...commonProps} type='text' name={`${key}[longitude]`} placeholder="Longitude" />
                    </div>
                </FormGroup>
            );

        case 'control_matrixdynamic':
            return (
                <FormGroup error={error} classes={classes} key={key} label={question.text}>
                    <table className="form-matrix">
                        <thead>
                            <tr>
                                {question.columns.map((col, index) => (
                                    <th key={index}>{col.text}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {question.rows.map((row, index) => (
                                <tr key={index}>
                                    {question.columns.map((col, colIndex) => (
                                        <td key={colIndex}>
                                            <InputField {...commonProps} type='text' name={`${key}[${index}][${colIndex}]`} />
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </FormGroup>
            );

        case 'control_matrixdropdown':
            return (
                <FormGroup error={error} key={key} label={question.text}>
                    <table className="form-matrix">
                        <thead>
                            <tr>
                                {question.columns.map((col, index) => (
                                    <th key={index}>{col.text}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {question.rows.map((row, index) => (
                                <tr key={index}>
                                    {question.columns.map((col, colIndex) => (
                                        <td key={colIndex}>
                                            <select name={`${key}[${index}][${colIndex}]`} defaultValue={question.value[index]?.[colIndex] || ''}>
                                                {col.options.map((option, optIndex) => (
                                                    <option key={optIndex} value={option.text}>
                                                        {option.text}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </FormGroup>
            );

        case 'control_calculation':
            return (
                <FormGroup error={error} classes={classes} key={key} label={question.text}>
                    <InputField {...commonProps} type="text" readOnly={true} />
                </FormGroup>
            );

        case 'control_text':
            return (
                <FormGroup error={error} key={key}>
                    <label>
                        {question.text && <div dangerouslySetInnerHTML={{ __html: question.text }} />}
                    </label>
                </FormGroup>
            );

        case 'control_widget':
            if (question.fields) {
                const fields = question.fields.split('\n');
                let obj = {};
                fields.forEach((field, index) => {
                    obj[field] = formData[`${question.name}-${index}`] || '';
                });
                formData[question.name] = obj;
                return (
                    <FormGroup error={error} classes={classes} key={key} label={question.text}>
                        {fields.map((field, index) => (
                            <FormGroupInner classes={classes} label={field} key={`${key}-${index}`}>
                                <AutoHeightTextAreaField
                                    {...commonProps}
                                    id={`${question.name}-${index}`}
                                    name={`${question.name}-${index}`}
                                    value={formData[`${question.name}-${index}`] || ''}
                                    placeholder={""}
                                    onChange={handleChange}
                                />
                            </FormGroupInner>
                        ))}
                    </FormGroup>
                );
            } else if (question.builderLabel === 'Matrix Dynamique' && question.cols && question.widgetType == 'field') {
                const colsArray = question?.cols?.split(',')?.map(col => col?.split(':')[0]);
                return (
                    <>
                        {question?.text && <p><b>{question.text}</b></p>}
                        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                            <thead>
                                <tr>
                                    {colsArray?.map((col, index) => (
                                        <th key={`header-${index}`} style={{ padding: '10px', textAlign: 'left' }}>
                                            {col}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <AppendForm
                                    colsArray={colsArray}
                                    parentformData={formData}
                                    handleChange={handleChange}
                                    commonProps={commonProps}
                                    InputField={InputField}
                                    FormGroup={FormGroup}
                                    FormGroupInner={FormGroupInner}
                                    classes={classes}
                                    name={question?.name}
                                    onFormDataChange={onFormDataChange}
                                />
                            </tbody>
                        </table>
                    </>
                );
            } else {
                return null;
            }

        default:
            return null;
    }
};

export default FormControlComponent;
