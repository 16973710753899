export const SET_CHANNEL_MEMBER_MODAL= 'OPEN_CHANNEL_MEMBER_MODAL'

export const GET_USER_POLICIES = 'GET_USER_POLICIES';
export const GET_USER_POLICIES_SUCCESS = 'GET_USER_POLICIES_SUCCESS';
export const GET_USER_POLICIES_FAILURE = 'GET_USER_POLICIES_FAILURE';

export const POST_USER_POLICY = 'POST_USER_POLICY';
export const POST_USER_POLICY_SUCCESS = 'POST_USER_POLICY_SUCCESS';
export const POST_USER_POLICY_FAILURE = 'POST_USER_POLICY_FAILURE';

export const UPDATE_USER_POLICY = 'UPDATE_USER_POLICY';
export const UPDATE_USER_POLICY_SUCCESS = 'UPDATE_USER_POLICY_SUCCESS';
export const UPDATE_USER_POLICY_FAILURE = 'UPDATE_USER_POLICY_FAILURE';

export const DELETE_USER_POLICY = 'DELETE_USER_POLICY';
export const DELETE_USER_POLICY_SUCCESS = 'DELETE_USER_POLICY_SUCCESS';
export const DELETE_USER_POLICY_FAILURE = 'DELETE_USER_POLICY_FAILURE';

export const RUNNING_LOADER_USER_POLICY = 'RUNNING_LOADER_USER_POLICY';

export const GET_DOWN_TIME_OBJECT_DEFAULT = 'GET_DOWN_TIME_OBJECT_DEFAULT';
export const GET_DOWN_TIME_OBJECT_DEFAULT_SUCCESS =
  'GET_DOWN_TIME_OBJECT_DEFAULT_SUCCESS';
export const GET_DOWN_TIME_OBJECT_DEFAULT_FAILURE =
  'GET_DOWN_TIME_OBJECT_DEFAULT_FAILURE';

export const CLEAR_USER_POLICY_OPERATION_RESULT =
  'CLEAR_USER_POLICY_OPERATION_RESULT';

export const GET_NAVIGATION_HISTORY = 'GET_NAVIGATION_HISTORY';
export const FAILURE_NAVIGATION_HISTORY = 'FAILURE_NAVIGATION_HISTORY';

export const ADD_TIME_LIMIT_NODE_POLICY = 'ADD_TIME_LIMIT_NODE_POLICY';
export const ADD_TIME_LIMIT_NODE_POLICY_SUCCESS =
  'ADD_TIME_LIMIT_NODE_POLICY_SUCCESS';
export const ADD_TIME_LIMIT_NODE_POLICY_FAILURE =
  'ADD_TIME_LIMIT_NODE_POLICY_FAILURE';


