import { INotificationState } from '../../types/notification';
import { IAction } from '../../types/generics';
import {
  SET_LOADING,
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_SUCCESS,
  UPDATE_NOTIFICATION_STATE_SUCCESS,
  UPDATE_NOTIFICATION_STATE_FAIL,
  CLOSE_NOTIFICATIONS_WITH_IDS_SUCCESS,
  CLOSE_NOTIFICATIONS_WITH_IDS_FAIL,
} from '../actions/notification';

const initialState: INotificationState = {
  notifications: [],
  notification: null,
  success: true,
  loading: false,
  error: '',
};

const notificationReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
        status: true,
        error: '',
      };
    case GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        status: false,
        error: action.payload,
      };
    case UPDATE_NOTIFICATION_STATE_SUCCESS:
      // const temp = state.notifications.map((item) => {
      //   if (item._id === action.payload._id)
      //     return action.payload;
      //   return item;
      // })
      return {
        ...state,
        // notifications: temp,
        notification: action.payload,
        status: true,
        error: '',
      };
    case UPDATE_NOTIFICATION_STATE_FAIL:
      return {
        ...state,
        status: false,
        error: action.payload,
      };
    case CLOSE_NOTIFICATIONS_WITH_IDS_SUCCESS:
      return {
        ...state,
        status: true,
        notifications: [],
      };
    case CLOSE_NOTIFICATIONS_WITH_IDS_FAIL:
      return {
        ...state,
        status: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default notificationReducer;
