import {
  GET_DEVICES_ERROR,
  GET_DEVICES_SUCCESS,
  GET_DEVICES_LOADER,
  GET_ACD_DEVICES_SUCCESS,
  GET_ACD_DEVICES_ERROR,
  GET_ACD_DEVICES_LOADER,
  STATE_CHANGE_LOADING,
  STATE_CHANGE_SUCCESS,
  SATE_CHANGE_ERROR,
  ACD_OFF_LOADING,
  ACD_OFF_SUCCESS,
  ACD_OFF_ERROR,
  SELECT_ACD_HUB,
  GET_WEEKLY_APP_LIST_SUCCESS,
} from '../actions/devicesAction';
import { GET_ACD_DEVICES_SCHEDULE } from '../actions/parentSchedule';
import { IDeviceState, IDeviceAction } from '../../types/device';

const initState: IDeviceState = {
  getAllLoader: true,
  allDevices: [],
  getDevicesError: '',
  getAcdDevicesLoader: true,
  acdDevices: [],
  getAcdDevicesError: '',
  acdStateChangeLoading: 'no loader',
  acdStateChangeError: false,
  acdStateErrorMsg: '',
  updator: new Date(),
  acdOffLoading: false,
  acdOffError: false,
  acdOffErrMsg: '',
  userDevices: [],
  devicesIds: [],
  selectedHub: null,
  apps: [],
  newDevices: [],
};

export default (state = initState, action: IDeviceAction) => {
  switch (action.type) {
    case GET_DEVICES_LOADER:
      return {
        ...state,
        getAllLoader: action.payload,
      };
    case GET_DEVICES_SUCCESS:
      return {
        ...state,
        allDevices: action.payload,
      };
    case GET_DEVICES_ERROR:
      return {
        ...state,
        getDevicesError: action.payload,
      };
    case GET_ACD_DEVICES_LOADER:
      return {
        ...state,
        getAcdDevicesLoader: action.payload,
      };
    case GET_ACD_DEVICES_SUCCESS:
      return {
        ...state,
        getAcdDevicesLoader: false,
        acdDevices: action.payload.acdDevices,
        userDevices: action.payload.userDevices,
        devicesIds: action.payload.devicesIds,
        newDevices: action.payload.newDevices,
        selectedHub: state.selectedHub
          ? state.selectedHub
          : action.payload.acdDevices && action.payload.acdDevices[0],
      };
    case GET_ACD_DEVICES_SCHEDULE:
      return {
        ...state,
        acdDevices: action.payload,
      };
    case GET_ACD_DEVICES_ERROR:
      return {
        ...state,
        getAcdDevicesLoader: false,
        getAcdDevicesError: action.payload,
      };

    case STATE_CHANGE_LOADING:
      return {
        ...state,
        acdStateChangeLoading: action.payload,
      };

    case STATE_CHANGE_SUCCESS:
      return {
        ...state,
        acdDevices: action.payload,
        acdStateChangeError: false,
        updator: new Date(),
      };
    case SATE_CHANGE_ERROR:
      return {
        ...state,
        acdStateChangeError: true,
        acdStateErrorMsg: action.payload.message,
        updator: new Date(),
      };

    case ACD_OFF_LOADING:
      return {
        ...state,
        acdOffLoading: action.payload,
        updator: new Date(),
      };
    case ACD_OFF_SUCCESS:
      return {
        ...state,
        acdOffError: false,
        acdDevices: action.payload,
        updator: new Date(),
      };

    case ACD_OFF_ERROR:
      return {
        ...state,
        acdOffError: true,
        acdOffErrMsg: action.payload.message,
      };
    case SELECT_ACD_HUB:
      return {
        ...state,
        selectedHub: action.payload,
      };
    case GET_WEEKLY_APP_LIST_SUCCESS:
      return {
        ...state,
        apps: action.payload,
      };
    default:
      return state;
  }
};
