import React from 'react'

const ChartIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 19.6953V13.6953C9 13.1649 8.78929 12.6562 8.41421 12.2811C8.03914 11.906 7.53043 11.6953 7 11.6953H5C4.46957 11.6953 3.96086 11.906 3.58579 12.2811C3.21071 12.6562 3 13.1649 3 13.6953V19.6953C3 20.2257 3.21071 20.7345 3.58579 21.1095C3.96086 21.4846 4.46957 21.6953 5 21.6953H7C7.53043 21.6953 8.03914 21.4846 8.41421 21.1095C8.78929 20.7345 9 20.2257 9 19.6953ZM9 19.6953V9.69531C9 9.16488 9.21071 8.65617 9.58579 8.2811C9.96086 7.90603 10.4696 7.69531 11 7.69531H13C13.5304 7.69531 14.0391 7.90603 14.4142 8.2811C14.7893 8.65617 15 9.16488 15 9.69531V19.6953M9 19.6953C9 20.2257 9.21071 20.7345 9.58579 21.1095C9.96086 21.4846 10.4696 21.6953 11 21.6953H13C13.5304 21.6953 14.0391 21.4846 14.4142 21.1095C14.7893 20.7345 15 20.2257 15 19.6953M15 19.6953V5.69531C15 5.16488 15.2107 4.65617 15.5858 4.2811C15.9609 3.90603 16.4696 3.69531 17 3.69531H19C19.5304 3.69531 20.0391 3.90603 20.4142 4.2811C20.7893 4.65617 21 5.16488 21 5.69531V19.6953C21 20.2257 20.7893 20.7345 20.4142 21.1095C20.0391 21.4846 19.5304 21.6953 19 21.6953H17C16.4696 21.6953 15.9609 21.4846 15.5858 21.1095C15.2107 20.7345 15 20.2257 15 19.6953Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default ChartIcon