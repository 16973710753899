import React, { useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    TextField,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import CustomDialogTitle from './DialogTitle';
import { IProgramForm } from '../../../../../types/program';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Typography } from '@material-ui/core';
import { useStyles } from '../../style';

type UpdateFormDialogProps = {
    open: boolean;
    handleClose: () => void;
    data: IProgramForm | null;
    onSave: (updatedData) => void;
};

const UpdateFormDialog = (props: UpdateFormDialogProps) => {
    const theme = useTheme();
    const classes = useStyles();
    const { open, handleClose, data, } = props;
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const { control, handleSubmit, errors, reset } = useForm({
        defaultValues: data || {},
    });

    useEffect(() => {
        reset(data);
    }, [data, reset]);


    const onSubmit = (formData) => {
        props.onSave({
            ...data,
            ...formData
        });
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullScreen={fullScreen} >
            <form onSubmit={handleSubmit(onSubmit)}>
                <CustomDialogTitle id="form-dialog-title">Edit Form</CustomDialogTitle>
                <DialogContent>
                    <Box display="flex" flexDirection="column" style={{ gap: 20 }}>
                        <DialogContentText>
                            Update the details of the form below. Make necessary changes and click "Save" to update the form.
                        </DialogContentText>
                        <Controller
                            name="label"
                            control={control}
                            rules={{ required: 'Form Label is required.' }}
                            defaultValue=""
                            as={
                                <TextField
                                    id="outlined-label"
                                    aria-describedby="outlined-label-helper-text"
                                    inputProps={{
                                        'aria-label': 'label',
                                    }}
                                    placeholder="Form Label"
                                    label="Form Label"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.label}
                                />
                            }
                        />
                        
                        <ErrorMessage
                            as={<Typography color="error" />}
                            errors={errors}
                            name="label"
                        />

                        <Controller
                            name="description"
                            control={control}
                            rules={{ required: 'Description is required.' }}
                            defaultValue=""
                            as={
                                <TextField
                                    id="outlined-description"
                                    aria-describedby="outlined-description-helper-text"
                                    inputProps={{
                                        'aria-label': 'description',
                                    }}
                                    placeholder="Description"
                                    label="Description"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.description}
                                />
                            }
                        />
                        <ErrorMessage
                            as={<Typography color="error" />}
                            errors={errors}
                            name="description"
                        />

                        <Controller
                            name="sequence"
                            control={control}
                            defaultValue=""
                            as={
                                <TextField
                                    id="outlined-sequence"
                                    aria-describedby="outlined-sequence-helper-text"
                                    inputProps={{
                                        'aria-label': 'sequence',
                                    }}
                                    placeholder="Sequence"
                                    label="Sequence"
                                    type="text"
                                    variant="outlined"
                                    inputMode='numeric'
                                    fullWidth
                                    error={!!errors.sequence}
                                />
                            }
                        />
                        <ErrorMessage
                            as={<Typography color="error" />}
                            errors={errors}
                            name="sequence"
                        />

                        <Controller
                            name="source"
                            control={control}
                            defaultValue=""
                            as={
                                <TextField
                                    id="outlined-source"
                                    aria-describedby="outlined-source-helper-text"
                                    inputProps={{
                                        'aria-label': 'source',
                                    }}
                                    placeholder="Source"
                                    label="Source"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.source}
                                />
                            }
                        />
                        <ErrorMessage
                            as={<Typography color="error" />}
                            errors={errors}
                            name="source"
                        />

                        <Controller
                            name="sourceFormId"
                            control={control}
                            defaultValue=""
                            as={
                                <TextField
                                    id="outlined-source-form-id"
                                    aria-describedby="outlined-source-form-id-helper-text"
                                    inputProps={{
                                        'aria-label': 'sourceFormId',
                                    }}
                                    placeholder="Source Form ID"
                                    label="Source Form ID"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.sourceFormId}
                                />
                            }
                        />
                        <ErrorMessage
                            as={<Typography color="error" />}
                            errors={errors}
                            name="sourceFormId"
                        />

                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit(onSubmit)} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default React.memo(UpdateFormDialog);
