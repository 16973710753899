import {
    getDashboardBarChartApi,
    getDashboardHeatMapApi,
} from '../../api';
import { logger, format } from '../../datadogLogger';

export const GET_DASHBOARD_HEATMAP = 'GET_DASHBOARD_HEATMAP';
export const GET_DASHBOARD_HEATMAP_LOADING = 'GET_DASHBOARD_HEATMAP_LOADING';
export const GET_DASHBOARD_HEATMAP_ERROR = 'GET_DASHBOARD_HEATMAP_ERROR';
export const GET_DASHBOARD_HEATMAP_BAR_CHART = 'GET_DASHBOARD_HEATMAP_BAR_CHART';
export const GET_DASHBOARD_HEATMAP_BAR_CHART_LOADING = 'GET_DASHBOARD_HEATMAP_BAR_CHART_LOADING';
export const GET_DASHBOARD_HEATMAP_BAR_CHART_ERROR = 'GET_DASHBOARD_HEATMAP_BAR_CHART_ERROR';
export const SET_SELECTED_RANGE = 'SET_SELECTED_RANGE';

export const getHeatMapData = (programId: string, userId: string, startTime, endTime) => {
    return async (dispatch: any) => {
        try {
            const res = await getDashboardHeatMapApi(programId, userId, startTime, endTime);
            if (res) {
                dispatch(getDashbordHeatMapSuccess(res));
            } else {
                dispatch(getDashbordHeatMapError(res));
            }
            logger.info(format('Get team Users'), {});
        } catch (error) {
            logger.error('error while getting team user', error);
            dispatch(getDashbordHeatMapError(error));
        }
    };
};

export const getBarChartData = (programId: string, userId: string, startTime, endTime) => {
    return async (dispatch: any) => {
        try {
            const res = await getDashboardBarChartApi(programId, userId, startTime, endTime);
            if (res) {
                dispatch(getDashbordBarChartSuccess(res));
            } else {
                dispatch(getDashbordBarChartError(res));
            }
            logger.info(format('Get team Users'), {});
        } catch (error) {
            logger.error('error while getting team user', error);
            dispatch(getDashbordBarChartError(error));
        }
    };
};

const getDashbordHeatMapSuccess = (payload: any) => ({
    type: GET_DASHBOARD_HEATMAP,
    payload,
});

const getDashbordHeatMapError = (error: any) => ({
    type: GET_DASHBOARD_HEATMAP_ERROR,
    payload: error,
});

const getDashbordBarChartSuccess = (payload: any) => ({
    type: GET_DASHBOARD_HEATMAP_BAR_CHART,
    payload,
});

const getDashbordBarChartError = (error: any) => ({
    type: GET_DASHBOARD_HEATMAP_BAR_CHART_ERROR,
    payload: error,
});

export const setSelectedDateRange = (payload) => ({
    type: SET_SELECTED_RANGE,
    payload
}) 