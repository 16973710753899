import { makeStyles, Theme } from '@material-ui/core/styles';

export const useChatStyle =  makeStyles((theme: Theme) => ({
    paperWrapper: {
        marginLeft: "280px",
        padding: '0',
        height: 'calc(100vh - 68px)',
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(100% - 280px)',
        [theme.breakpoints.down('md')]: {
            marginLeft: "280px",
            width: 'calc(100% - 200px)',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: "0",
            width: '100%',
        },
    },
    chatWrapper: {
        padding: '1rem',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        // height: 'calc(100vh - 100px)',
                height: 'calc(100vh - 130px)',
        boxShadow: "none",
        borderRadius: 0,
        background: 'transparent'
    }
}));
