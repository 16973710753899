import { Box, Card, FormControl, Grid, NativeSelect, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { dateRangeOptions, staticActiveProgramEnrollment } from '../../../../../constants/data';
import HeatMapPerformanceTable from '../../../../../components/MonthlyPerformance/components/table/heatMapTable';
import { useStyles } from '../../style';
import ArrowDownIcon from '../../../../../components/Icons/ArrowDownIcon';
import "../../style.scss";
import { useDispatch, useSelector } from 'react-redux';
import { setActiveTab, setHighLightTab } from '../../../../../redux/actions/myResultAction';
import useDateRange from '../../../../../util/useDateRange';
import { Bar } from 'react-chartjs-2';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { getBarChartData, getHeatMapData } from '../../../../../redux/actions/dashboardAction';
import { RootState } from '../../../../../redux/store';

const ProgressCmp = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {programId} = JSON.parse(localStorage.getItem('activeProgramEnrollment')) || {};
    const userId = localStorage.getItem("user");
    const { heatMapData, barChartData, selectedDateRange } = useSelector((state: RootState) => state.dashboardReducer)
    const { activeTab } = useSelector((state: RootState) => state.myResultReducer);
    const defaultDateRange = selectedDateRange || 'last-8-weeks';
    const {
        dateRange,
        selectedRange,
        customStartDate,
        customEndDate,
        handleRangeChange,
        handleCustomStartDateChange,
        handleCustomEndDateChange,
    } = useDateRange(defaultDateRange);

    const labels = barChartData && barChartData.length > 0 && barChartData?.map((week: any) => `Week ${week?.weekNumber}`);

    const categories = barChartData?.length > 0
        ? barChartData[0]?.categories
        : [];

    const data = {
        labels,
        datasets: categories.map((category, index) => ({
            label: category,
            data: barChartData?.map((week: any) => week.scores[index]),
            backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.5)`,
        })),
    };

    const options = {
        plugins: {
            title: {
                display: true,
                text: 'Weekly Summary Stacked Bar Chart',
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
            legend: {
                position: 'top',
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                beginAtZero: true,
                max: 5,
            },
        },
    };

    const changeActiveTab = (name) => {
        dispatch(setActiveTab(name));
        dispatch(setHighLightTab(null));
    }

    useEffect(() => {
        if (dateRange) {
            if (activeTab === 'bar-chart') {
                dispatch(getBarChartData(programId, userId, dateRange.start, dateRange.end))
                return;
            }
            else if (activeTab === 'heat-map') {
                dispatch(getHeatMapData(programId, userId, dateRange.start, dateRange.end))
                return;
            }
        }
    }, [dateRange, activeTab]);

    return (
        <div >
            <div className={classes.titleWrapper}>
                <Typography variant="h4" className={classes.typography} gutterBottom>
                    <span>My Progress</span>
                    <ArrowDownIcon />
                </Typography>
            </div>

            <div className={classes.titleWrapper}>
                <FormControl className={`${classes.formControl} formcontrol_select_input`}>
                    <NativeSelect
                        value={defaultDateRange}
                        onChange={handleRangeChange}
                        placeholder='Select Date Range'
                        variant='outlined'
                    >
                        <option value="" disabled>Select Date Range</option>
                        {dateRangeOptions.map((el: any, index: number) => (
                            <option key={index} value={el.value}>{el?.label}</option>
                        ))}
                    </NativeSelect>
                </FormControl>

                <Box display="flex">
                    <span className={`${activeTab === 'heat-map' && classes.activeTab}`} onClick={() => changeActiveTab("heat-map")}>
                        <VerifiedUserIcon className={classes.tabIcons} />
                    </span>
                    <span className={`${activeTab === 'bar-chart' && classes.activeTab}`} onClick={() => changeActiveTab("bar-chart")}>
                        <AssessmentIcon className={classes.tabIcons} />
                    </span>
                </Box>
            </div>
            <div>
                {selectedRange === 'custom' && (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify='flex-end' spacing={2} direction="row">
                            <Grid item xs={4}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-start"
                                    label="Start Date"
                                    value={customStartDate ? moment(customStartDate).toDate() : null}
                                    onChange={handleCustomStartDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-end"
                                    label="End Date"
                                    value={customEndDate ? moment(customEndDate).toDate() : null}
                                    onChange={handleCustomEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                )}
            </div>

            {
                activeTab === "heat-map" && <HeatMapPerformanceTable
                    weeklyData={heatMapData}
                />
            }

            {
                activeTab === "bar-chart" &&
                <Card className={classes.card}>
                    <Bar
                        data={data}
                        options={options} />
                </Card>
            }


        </div>
    )
}

export default ProgressCmp;