import React from 'react'

const OnBoardingPhase2Icon = () => {
    return (
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_211_395)">
                <path d="M60.5935 3.93755C58.0154 6.57818 57.3435 7.3438 57.3435 7.68755C57.3435 7.9063 57.6247 9.26568 57.9685 10.7032C58.3122 12.1407 58.5935 13.4063 58.5935 13.5469C58.5935 13.6719 54.4997 17.9063 49.4997 22.9376C32.5622 40.0157 33.1247 39.4219 33.1247 39.8907C33.1247 40.4688 33.7654 41.0938 34.3591 41.0938C34.8435 41.0938 34.3747 41.5469 56.7185 19.0313L60.3904 15.3282L63.3435 16.0157C64.9529 16.3907 66.3904 16.6563 66.5466 16.6094C66.6872 16.5626 68.2497 15.0782 70.0466 13.2969C73.031 10.3282 73.281 10.0469 73.281 9.5313C73.281 8.56255 73.1247 8.4688 70.4372 7.87505C69.3279 7.64068 68.4372 7.39068 68.4372 7.3438C68.4372 7.29693 69.4997 6.1563 70.781 4.79693C73.3279 2.14068 73.4685 1.89068 72.7341 1.17193C71.9841 0.421928 71.7341 0.562553 69.0466 3.23443C67.4529 4.81255 66.5466 5.5938 66.4997 5.45318C66.4685 5.32818 66.2029 4.29693 65.9372 3.1563C65.3904 0.921928 65.2029 0.625053 64.2966 0.625053C63.8904 0.609428 63.4216 1.0313 60.5935 3.93755Z" fill="#7CA0BB" />
                <path d="M31.3279 5.57823C27.3123 5.92198 22.7342 7.21886 19.0467 9.06261C8.7498 14.2189 1.76543 24.0001 0.218551 35.4689C-0.0314492 37.4064 -0.0626992 42.1876 0.156051 44.0626C1.59355 55.7032 8.57793 65.672 18.9217 70.8595C23.5154 73.172 28.0467 74.3126 33.3592 74.4845C37.7811 74.6251 41.4842 74.0939 45.6404 72.7032L47.8123 71.9845V71.0001C47.8123 69.5626 48.2967 68.4064 49.2967 67.3907C50.0936 66.6095 50.1248 66.547 50.3123 65.2814C50.5311 63.7032 51.0936 61.5314 51.6248 60.1095C52.0154 59.0939 51.9842 58.797 51.5467 59.3126C51.2186 59.7189 49.1092 61.2657 47.8123 62.0782C45.4217 63.547 42.1248 64.797 38.9842 65.422C36.8279 65.8439 31.7342 65.8126 29.5779 65.3439C20.2029 63.3282 12.9686 56.922 10.0154 48.0314C8.29668 42.9064 8.29668 37.0939 10.0154 31.9689C12.9686 23.0782 20.2029 16.672 29.5779 14.6564C31.9217 14.1564 36.8436 14.1407 39.2967 14.6407C43.0311 15.4064 46.5623 16.922 49.3436 18.9532C50.0154 19.4376 50.2186 19.5157 50.4061 19.3751C50.5311 19.2657 51.9061 17.8439 53.4686 16.2189L56.3279 13.2501L55.7811 12.7814C54.6092 11.7657 51.8436 10.0626 49.6404 8.96886C46.0154 7.18761 42.1561 6.07823 38.0936 5.64073C36.6873 5.48448 32.7967 5.45323 31.3279 5.57823Z" fill="#7CA0BB" />
                <path d="M30.8594 16.4845C20.9531 18.0939 13.3281 25.3751 11.1562 35.3126C10.9219 36.3751 10.8594 37.297 10.8594 40.0001C10.8594 42.7032 10.9219 43.6251 11.1562 44.6876C12.875 52.547 18.2188 59.0626 25.3125 61.9376C33.2344 65.1564 42.0156 64.0626 48.8438 59.0157C51 57.422 53.7344 54.3439 55 52.0939L55.375 51.4532L54.5 50.1407C53.5781 48.7501 52.2656 46.2189 51.7969 44.922C51.6406 44.4845 51.4531 44.0782 51.3906 44.0001C51.3281 43.922 51.125 44.3439 50.9531 44.9376C49.4219 50.0782 45.3438 54.3595 40.2344 56.1876C38 56.9845 36.2656 57.2345 33.8281 57.1407C26.1562 56.8282 19.8906 51.7501 17.7812 44.1095C17.4844 43.0626 17.4375 42.4845 17.4375 40.0001C17.4375 37.5157 17.4844 36.9376 17.7812 35.8907C18.6719 32.6407 20.2969 29.8595 22.5312 27.7189C25.7031 24.672 29.4062 23.0626 33.6875 22.8595C37.1719 22.6876 40.1562 23.4376 43.3281 25.2501L44.0781 25.6876L46.4375 23.3282L48.7969 20.9689L47.4531 20.0782C45 18.4845 42.2344 17.3439 39.2344 16.7032C37.2344 16.2814 32.875 16.1564 30.8594 16.4845Z" fill="#7CA0BB" />
                <path d="M58.75 19.6406C57.6719 20.75 56.3125 22.1406 55.7344 22.75L54.6562 23.8594L55.6719 25.3281C56.2188 26.1406 56.8281 27.0938 57.0156 27.4531L57.3438 28.125H62.1094C64.7344 28.125 66.875 28.0781 66.875 28C66.875 27.7188 66.1719 26.0938 65.3125 24.375C64.125 22 61.2031 17.6562 60.7969 17.6562C60.75 17.6562 59.8125 18.5469 58.75 19.6406Z" fill="#7CA0BB" />
                <path d="M31.4062 25.1405C26.6406 26.1874 22.7031 29.328 20.6875 33.6561C19.6562 35.9061 19.4531 36.953 19.4531 39.9999C19.4531 42.3905 19.5 42.8905 19.8125 43.9843C21.4375 49.6718 26 53.828 31.8281 54.9374C33.6406 55.2811 36.5781 55.1718 38.3594 54.6874C43.7344 53.2499 47.75 49.1718 49.25 43.6249C49.5 42.6718 49.5781 41.9218 49.5938 40.078L49.6094 37.7186L49.0312 37.0311C48.25 36.1249 47.8125 34.9218 47.8125 33.6718C47.8125 32.9374 47.7188 32.5311 47.4844 32.1249C47.2969 31.8124 47.1094 31.5624 47.0469 31.5624C47 31.5624 45.9062 32.6093 44.625 33.8905L42.2969 36.2343L42.625 37.2343C43.375 39.4999 43.2188 41.5311 42.1719 43.7343C40.4062 47.3749 36.4375 49.2811 32.5312 48.3593C29.6094 47.6718 27.3125 45.5155 26.3125 42.5468C25.9531 41.4686 25.9062 41.1561 25.9688 39.6874C26.0156 38.5311 26.1406 37.7811 26.375 37.1093C27.3438 34.4843 29.7031 32.3593 32.5 31.6405C33.7188 31.3124 35.9531 31.3905 37.0312 31.7811L37.7188 32.0468L40.1094 29.6561C41.4219 28.3436 42.5 27.2186 42.5 27.1405C42.5 26.9218 39.6562 25.6561 38.3594 25.3124C36.5469 24.828 33.2188 24.7343 31.4062 25.1405Z" fill="#7CA0BB" />
                <path d="M50.8906 27.6251C49.625 28.8907 48.5938 30.0157 48.5938 30.1407C48.5938 30.2501 48.9844 29.9844 49.4687 29.5469C50.5781 28.5313 51.7969 28.1251 53.7031 28.1251C54.8594 28.1251 55.0156 28.0938 54.9375 27.8751C54.8594 27.6563 53.2812 25.3126 53.2187 25.3126C53.2031 25.3126 52.1562 26.3438 50.8906 27.6251Z" fill="#7CA0BB" />
                <path d="M51.3903 30.4688C50.1559 31.125 49.5934 32.3281 49.734 33.9688C49.8434 35.1094 50.4059 36 51.3903 36.5156L52.0778 36.875H64.8278C76.9059 36.875 77.609 36.8594 78.2028 36.5781C78.9371 36.2344 79.5934 35.5781 79.8278 34.9531C79.9215 34.7031 79.9996 34 79.9996 33.375C79.9996 32.4062 79.9371 32.1562 79.5465 31.5312C79.2496 31.0625 78.859 30.7188 78.4059 30.4844C77.7184 30.1562 77.6871 30.1562 64.8278 30.1562C52.4528 30.1719 51.9371 30.1875 51.3903 30.4688Z" fill="#7CA0BB" />
                <path d="M32.3906 33.7501C30.7187 34.2969 29.2812 35.6094 28.4531 37.3438C28.0937 38.1094 28.0469 38.3907 28.0469 40.0001C28.0469 41.6094 28.0937 41.8907 28.4531 42.6563C29.3125 44.4532 30.75 45.7344 32.5312 46.2657C36.3594 47.4376 40.2969 45.0001 40.9687 41.0626C41.1406 40.0938 41.0469 38.5469 40.7969 38.1563C40.7031 38.0157 40.0625 38.5782 38.5781 40.0782C37.4219 41.2501 36.1875 42.3907 35.8281 42.6094C35.1094 43.0313 34 43.1251 33.2031 42.8126C31.6719 42.2344 30.7969 40.1251 31.5 38.7032C31.6406 38.4219 32.7344 37.2032 33.9531 35.9844C35.1719 34.7657 36.1094 33.7032 36.0312 33.6407C35.75 33.3594 33.375 33.4376 32.3906 33.7501Z" fill="#7CA0BB" />
                <path d="M52.2656 39.4688C52.8438 43.3907 55 48.1095 57.7031 51.297L58.5938 52.3282L57.875 53.1563C56.0312 55.3126 54.125 58.6876 53.2031 61.4688C52.7031 62.9532 52.0625 65.7501 52.2031 65.8282C52.2344 65.8438 53.2969 65.8438 54.5469 65.8126L56.8281 65.7657L57.1094 64.7345C57.4688 63.4063 58.6719 60.9532 59.5625 59.7657C61.0469 57.7657 64.0469 55.1563 64.875 55.1563C65.1406 55.1563 65.7344 55.4845 66.5156 56.0782C69.4531 58.2657 71.6562 61.3438 72.6562 64.6407L73 65.7813H75.0781C76.2188 65.7813 77.2656 65.8282 77.4062 65.8751C77.7031 65.9845 77.6719 65.8126 77.0938 63.2813C76.3438 60.0626 74.5312 56.4376 72.3125 53.6407L71.25 52.3282L72.2188 51.1251C75.0625 47.5626 76.7656 43.7501 77.5625 39.2345L77.6562 38.7032L77.0781 38.8126C76.75 38.8595 75.7812 38.9063 74.9062 38.9063H73.3281L73.2188 40.0313C72.8125 44.547 70.3594 48.797 66.5 51.7032C65.0312 52.797 64.875 52.8126 63.7969 52.0938C59.7812 49.3282 56.9062 44.6251 56.6094 40.2657L56.5156 38.9063H54.8438C53.9219 38.9063 52.9375 38.8595 52.6562 38.8126L52.1562 38.7032L52.2656 39.4688Z" fill="#7CA0BB" />
                <path d="M58.6719 40.7344C59.0312 43.625 60.6719 46.6563 63.1406 48.9688C64.7344 50.4844 64.8125 50.5 65.8438 49.7031C68.6406 47.5469 70.6562 44.0469 71.1562 40.5L71.25 39.8438H64.9062H58.5625L58.6719 40.7344Z" fill="#7CA0BB" />
                <path d="M63.7031 58.2344C61.5625 60.0156 59.5625 63.0312 59 65.3438L58.9062 65.7812H64.9219H70.9219L70.5 64.5469C69.875 62.7969 68.8594 61.1094 67.5938 59.7344C66.5625 58.625 65.0938 57.3438 64.875 57.3438C64.8125 57.3438 64.2812 57.75 63.7031 58.2344Z" fill="#7CA0BB" />
                <path d="M51.4844 68.1094C50.3281 68.625 49.6875 69.7344 49.6875 71.1719C49.6875 72.6094 50.3281 73.7188 51.4844 74.2344C52.0781 74.5156 52.8281 74.5312 64.9062 74.5312C77.6875 74.5312 77.7188 74.5312 78.4062 74.2031C78.8594 73.9688 79.25 73.625 79.5469 73.1562C79.9531 72.5156 80 72.2969 80 71.1719C80 70.0469 79.9531 69.8281 79.5469 69.1875C79.25 68.7188 78.8594 68.375 78.4062 68.1406C77.7188 67.8125 77.6875 67.8125 64.9062 67.8125C52.8281 67.8125 52.0781 67.8281 51.4844 68.1094Z" fill="#7CA0BB" />
            </g>
            <defs>
                <clipPath id="clip0_211_395">
                    <rect width="80" height="80" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default OnBoardingPhase2Icon