import React, { useEffect } from 'react';
import { Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from '../redux/store';
import { UserType } from '../constants/data';


interface ProtectedRouteProps {
  component: React.ComponentType<any>;
  userStatus: boolean;
  userRole?: string;
  requiredRole?: string | string[];
  exact?: boolean;
  activePhaseNumber: number;
  path: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  userStatus,
  userRole,
  requiredRole,
  activePhaseNumber,
  ...rest
}) => {

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
      const userData = JSON.parse(localStorage.getItem('authInfo') || 'null');
      const accessToken = localStorage.getItem('token');

      // Immediately redirect if there's no auth info or token.
      if (!userData || !accessToken) {
        history.replace('/login');
        return;
      }

      const { pathname } = location;
    
      if (pathname.startsWith('/user') && (pathname !== '/user/form/show')&&(activePhaseNumber <= 3 && activePhaseNumber>0 )) {
        history.push(`/onboarding/phase/${activePhaseNumber}`);
        return;
      }
      if(pathname.startsWith('/user') && (activePhaseNumber == null ))
      {
        history.push(`/user/dashboard`);
      }

  }, [activePhaseNumber, history]);
  return <Route
    {...rest}
    render={(props) =>
      userStatus ? (
        requiredRole ? (
          Array.isArray(requiredRole) ? (
            requiredRole.includes(userRole) ? (
              <Component {...props} />
            ) : (
              <Redirect to="/login" />
            )
          ) : (
            userRole === requiredRole ? (
              <Component {...props} />
            ) : (
              <Redirect to="/login" />
            )
          )
        ) : (
          <Component {...props} />
        )
      ) : (
        <Redirect to="/login" />
      )
    }
  />
};

const mapStateToProps = (state: RootState) => ({
  userStatus: state.auth.userStatus,
  userRole: state.auth.user?.type,
  activePhaseNumber: state.programEnrollmentReducer.activePhaseNumber,
});

export default connect(mapStateToProps)(ProtectedRoute);