import { Dialog, DialogTitle, DialogContent, FormControl, InputLabel, Input, InputAdornment, DialogActions, Button, IconButton, Select, ListItemText, Checkbox, makeStyles, Theme, createStyles, MenuItem } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import React, { useEffect, useState } from 'react'
import {  useDispatch, useSelector } from 'react-redux'
import { createRCChannel, getRcAutoCompleteUsersList } from '../../../../../../redux/actions/chat'
import { RootState } from '../../../../../../redux/store'
import { ICreateChannel } from '../../../../../../types/chat'
import { setDayOfYear } from 'date-fns'


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            width: "100%"
        },
        dialogTitle: {
            display: 'flex',
            justifyContent: "space-between",
            margin: 0, 
            padding: '16px',
            "& h2.MuiTypography-root": { 
                display: 'flex',
                fontWeight: 500,
                alignItems: 'center', 
                justifyContent: "space-between",
                flexGrow: 1
            }
        }
    }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const AddChannelModal = ({ handleClose, open }) => {
    const dispatch: any = useDispatch();
    const classes = useStyles();
    const [channelName, setChannelName] = useState("");
    const [error, setError] = useState("");
    const [personName, setPersonName] = React.useState<string[]>([]);
    const { loading, autoCompleteUsersList } = useSelector((state: RootState) => state.chatReducer);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setPersonName(event.target.value as string[]);
    };
    

    const handleSave = (e) => {
        e.preventDefault();
        if (!channelName.trim() || /\s/.test(channelName)) {
            setError("Channel name cannot contain white spaces.");
            return;
        }
        const payload : ICreateChannel = { name: channelName, members: personName, readOnly: false };
        dispatch(createRCChannel(payload)).then(() => {
            handleClose();
            setChannelName('');
            setPersonName([]);
        });
    }
    
    useEffect(() => {
        dispatch(getRcAutoCompleteUsersList(""));
        setChannelName('');
        setPersonName([]);
    }, [open]);


    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle className={classes.dialogTitle} id="customized-dialog-title">
                Create a channel
                <IconButton aria-label="close" onClick={handleClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <form onSubmit={handleSave}>
                <DialogContent dividers>
                    <FormControl variant="standard" style={{ width: '100%' }}>
                        <InputLabel htmlFor="input-with-icon-adornment">Name</InputLabel>
                        <Input
                            id="input-with-icon-adornment"
                            value={channelName}
                            onChange={(e) => {
                                setChannelName(e.target.value);
                                setError("");
                            }}
                            startAdornment={
                                <InputAdornment position="start">
                                    #
                                </InputAdornment>
                            }
                        />
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                    </FormControl>
                    <p style={{ color: '#b0b0b0' }}>Channels are where conversations happen around a topic. Use a name that is easy to find and understand.</p>
                    <FormControl  className={classes.formControl}>
                        <InputLabel id="demo-mutiple-checkbox-label">Members</InputLabel>
                        <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            value={personName}
                            onChange={handleChange}
                            placeholder='Add People'
                            input={<Input />}
                            renderValue={(selected) => (selected as string[]).join(', ')}
                            MenuProps={MenuProps}
                        >
                            {autoCompleteUsersList.map((user) => (
                                <MenuItem key={user?._id} value={user?.username}>
                                    <Checkbox checked={personName.indexOf(user?.username) > -1} />
                                    <ListItemText primary={user?.username} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        type='submit'
                        disabled={loading}
                    >
                        {loading ? 'Saving...' : 'Save changes'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default React.memo(AddChannelModal);
