import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AppBar from "../../../components/AppBar";
import { getTeamUsers } from '../../../redux/actions/teamAction';
import { RootState } from '../../../redux/store';
import { isTeamAdmin } from '../../../util/lib';
import ChildView from './Child';
import ParentView from './Parent'

function RealTime() {
  const dispatch = useDispatch();
  const authUser = useSelector((state: RootState) => state.auth.user);
  const users = useSelector((state: RootState) => state.teamReducer.teamUsers);

  useEffect(() => {
    if (!users.length) {
      dispatch(getTeamUsers(authUser._team));
    }
  });

  return (
    <div>
      <AppBar title='Real Time' />
      {isTeamAdmin(authUser.type) ? (
        <ParentView />
      ) : (
          <ChildView />
        )}
    </div>
  );
}

export default RealTime;