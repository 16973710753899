import React from 'react';
import { AppBar, Toolbar, Typography, LinearProgress } from '@material-ui/core';
import { useStyles } from '../../style';

function HeaderWithProgressBar({ logoText = "Logo", progressValue = 40 }) {
    const classes = useStyles();

    return (
        <div>
            <AppBar position="static" color="transparent" elevation={0}>
                <Toolbar className={classes.header}>
                    <Typography variant="h4">{logoText}</Typography>
                </Toolbar>
            </AppBar>
            <LinearProgress variant="determinate" value={progressValue} className={classes.progressBar} />
        </div>
    );
}

export default HeaderWithProgressBar;
