import { IAction } from '../../types/generics';
import { IDashboardState } from '../../types/dashboard';
import { GET_DASHBOARD_HEATMAP, GET_DASHBOARD_HEATMAP_BAR_CHART, GET_DASHBOARD_HEATMAP_BAR_CHART_ERROR, GET_DASHBOARD_HEATMAP_BAR_CHART_LOADING, GET_DASHBOARD_HEATMAP_ERROR, GET_DASHBOARD_HEATMAP_LOADING, GET_MY_GOALS, GET_MY_GOALS_ERROR, GET_MY_GOALS_LOADING, SET_SELECTED_RANGE } from '../actions/dashboardAction';

const initState: IDashboardState = {
  loading: false,
  error: "",
  myGoalsData: null,
  heatMapData: null,
  barChartData: null,
  selectedDateRange: ''
};

export default function (state = initState, action: IAction) {
  switch (action.type) {
    case GET_MY_GOALS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_MY_GOALS:
      return {
        ...state,
        myGoalsData: action.payload,
        error: '',
        loading: false,
      }; 
    
    case GET_MY_GOALS_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false
      }; 
    
    case GET_DASHBOARD_HEATMAP_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_DASHBOARD_HEATMAP:
      return {
        ...state,
        heatMapData: action.payload,
        error: '',
        loading: false,
      };
    case GET_DASHBOARD_HEATMAP_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false
      };
    case GET_DASHBOARD_HEATMAP_BAR_CHART_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_DASHBOARD_HEATMAP_BAR_CHART:
      return {
        ...state,
        barChartData: action.payload,
        error: '',
        loading: false,
      };
    case GET_DASHBOARD_HEATMAP_BAR_CHART_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false
      };
    case SET_SELECTED_RANGE: {
      return {
        ...state,
        selectedDateRange: action.payload
      }
    }  
    default:
      return state;
  }
}
