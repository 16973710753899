import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';

interface IconProps {
  name: string;
  active?: boolean;
  width?: number;
}

const useStyles = makeStyles({
  imageIcon: {
    // height: '100%',
  },
  iconRoot: {
    lineHeight: 1,
    textAlign: 'center',
    width: 'unset',
    height: 'unset',
    fontSize: 'initial',
    verticalAlign: 'middle',
  },
});

export default function ImgIcon(props: IconProps) {
  const classes = useStyles();
  const name = props.name + (props.active ? ' Active' : '');
  return (
    <Icon classes={{ root: classes.iconRoot }}>
      <img
        width={props.width}
        className={classes.imageIcon}
        src={`/assets/${name}.svg`}
        alt={props.name}
      />
    </Icon>
  );
}

ImgIcon.defaultProps = {
  width: 25,
};
