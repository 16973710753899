import React from 'react'

const OnBoardingPhase3Icon = () => {
    return (
        <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M59.5624 10.2344C56.4218 10.6719 53.6406 11.0938 53.3593 11.1563C52.0624 11.4531 50.8437 12.9375 50.7187 14.4063C50.6718 14.9375 50.9687 17.5 51.5624 21.6094C52.4374 27.7188 52.4999 28 52.9374 28.625C53.1874 29 53.7031 29.5 54.0624 29.7656C55.1249 30.5 55.9999 30.4688 62.9999 29.4688C69.6562 28.5156 70.0156 28.4063 70.9062 27.2188C71.8593 26 71.8593 25.7344 70.8749 18.7813C70.4062 15.375 69.9531 12.3281 69.8749 12.0156C69.4531 10.4688 67.9218 9.35939 66.2187 9.39064C65.6874 9.40627 62.7031 9.78127 59.5624 10.2344ZM66.0312 18.4531C66.3281 18.6875 66.4374 18.9219 66.4374 19.2969C66.4374 20.25 66.1406 20.3594 61.5624 20.9844C59.2968 21.3125 57.2499 21.5625 56.9999 21.5625C56.4999 21.5625 55.8124 20.9375 55.8124 20.4844C55.8124 20.3125 55.9687 19.9844 56.1718 19.7656C56.4999 19.375 56.7343 19.3281 60.5781 18.75C65.4531 18.0469 65.4999 18.0313 66.0312 18.4531Z" fill="#7CA0BB" />
            <path d="M14.8747 16.5C9.60907 17.2344 8.92157 17.375 8.15594 17.7813C7.18719 18.2813 6.39032 19.375 6.21844 20.4375C6.12469 21.0469 7.67157 32.7344 7.99969 33.9219C8.42157 35.4531 9.92157 36.5625 11.5934 36.5625C13.0153 36.5625 24.4841 34.8906 25.0622 34.5938C26.2028 34.0156 26.9841 32.9219 27.1559 31.6875C27.2809 30.7031 25.5622 18.7188 25.1716 17.8125C24.7497 16.875 24.3278 16.4375 23.3903 16C22.3122 15.4844 21.7341 15.5156 14.8747 16.5ZM16.8747 21.1406C17.1247 21.4063 17.2653 21.8906 17.4528 23.0938C17.5778 23.9688 17.6872 24.7031 17.6872 24.7344C17.6872 24.7656 18.4372 24.6875 19.3434 24.5625C21.1872 24.3125 21.6247 24.4063 21.9372 25.0625C22.1559 25.5625 21.8747 26.2969 21.3747 26.5313C21.1872 26.6094 20.3747 26.7656 19.5622 26.875C18.7497 26.9844 18.0622 27.0938 18.0309 27.1094C18.0153 27.125 18.1091 27.8906 18.2497 28.8125C18.4684 30.3438 18.4684 30.5156 18.2497 30.8594C17.9841 31.25 17.4528 31.4688 16.9684 31.375C16.4528 31.2813 16.1403 30.5781 15.9372 29.0625L15.7184 27.5781L15.1872 27.5938C14.8903 27.5938 14.0622 27.6563 13.3747 27.7344C12.2028 27.8438 12.0778 27.8281 11.7653 27.5156C11.3591 27.1094 11.3434 26.4063 11.7184 25.9531C11.9216 25.6719 12.3122 25.5625 13.6247 25.375C14.5309 25.2344 15.2809 25.1094 15.3122 25.0781C15.3434 25.0469 15.2653 24.3438 15.1403 23.4844C14.9059 21.875 14.9684 21.375 15.4684 21C15.8747 20.6875 16.4997 20.75 16.8747 21.1406Z" fill="#7CA0BB" />
            <path d="M71.0467 30.6249C70.0154 30.7812 55.0467 32.8749 37.7654 35.3124C13.0311 38.7812 6.28106 39.7655 5.96856 39.9843C5.43731 40.3593 5.40606 41.2187 5.79669 43.8749C6.10919 45.9999 6.23419 46.3437 6.84356 46.578C7.09356 46.6562 15.6717 45.5155 33.1873 43.0468C47.4842 41.0468 62.5154 38.9374 66.5936 38.3593C70.6717 37.7968 74.1717 37.2968 74.3748 37.2499C74.9686 37.1249 75.3436 36.6249 75.3436 35.953C75.3436 35.6093 75.2029 34.3593 75.0311 33.1718C74.5779 30.078 74.6092 30.1093 71.0467 30.6249Z" fill="#7CA0BB" />
            <path d="M39.8594 44.4531C36.7031 44.9063 34.0625 45.2969 33.9688 45.3281C33.875 45.3594 33.3906 46.0313 32.8906 46.8281C31.2969 49.3594 28.7188 52.2813 26.75 53.7969L26.2031 54.2188H40.5469H54.9062L54.0625 53.5625C53.0156 52.7344 51.75 51.4688 50.6562 50.1563C49.6406 48.9219 47.5156 45.7188 46.8906 44.4844C46.5 43.7188 46.375 43.5938 46 43.6094C45.7656 43.625 43 44 39.8594 44.4531Z" fill="#7CA0BB" />
            <path d="M15.1404 56.7188C13.4997 57 12.0935 58.0312 11.2654 59.5625L10.7341 60.5469L10.6872 64.25L10.6404 67.9531L8.46848 68C6.6091 68.0469 6.26535 68.0938 5.9841 68.3438C5.51535 68.7656 5.53098 69.8281 6.01535 70.2812L6.37473 70.625H40.5935H74.8279L75.156 70.2656C75.6247 69.7656 75.6247 68.75 75.1716 68.3438C74.8904 68.0938 74.5466 68.0469 72.6872 68L70.5154 67.9531L70.4685 64.25L70.4216 60.5469L69.8904 59.5625C69.0154 57.9688 67.6716 57.0156 65.8747 56.7188C64.7497 56.5312 16.2341 56.5469 15.1404 56.7188Z" fill="#7CA0BB" />
        </svg>
    )
}

export default OnBoardingPhase3Icon;