import "../style.css"
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import AddBoxIcon from '@material-ui/icons/AddBox';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import SidebarOption from './sidebarOption';
import { Avatar, Chip, Collapse, List, ListItem, ListItemText, Tooltip } from '@material-ui/core';
import { getRCDmList, getRCUserProfileInfo } from '../../../../redux/actions/chat';
import { RootState } from '../../../../redux/store';
import { useHistory, useLocation } from 'react-router';
// import AddChannelModal from './components/AddChannelModal';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { IRoomUserMEOS } from '../../../../types/app_chat';
import { getMEOSJoinedChannelList, getChannelMembersInfoMEO, getActiveMessage, getMEOSChannelMembers, displayMemberModal, activeChatRoom } from '../../../../redux/actions/app_chat';
import { getAvatarBackgroundColor, useChatStyle } from "../Chat/style";

const Sidebar = () => {
  const dispatch = useDispatch();
  const history: any = useHistory();
  const [isChannelListOpen, setIsChannelListOpen] = useState(true);
  const [isMessageListOpen, setIsMessageListOpen] = useState(true);
  // const [open, setOpen] = useState(false);
  const { channels, latestChatUser, activeMessages,activeChannelId} = useSelector((state: RootState) => state.appChatReducer);
  const location = useLocation<any>();
  const searchParams = new URLSearchParams(location.search);
  const roomId = searchParams.get('roomId');
  const userId = localStorage.getItem("user")
  const classes = useChatStyle();
  const collapseChannelsHandler = () => {
    setIsChannelListOpen(!isChannelListOpen);
  };
  const collapseMessageHandler = () => {
      dispatch(getMEOSChannelMembers(activeChannelId));   
      dispatch(displayMemberModal(true))
     setIsMessageListOpen(!isMessageListOpen);
  }
  // const handleClose = () => {
  //   setOpen(!open);
  // };
  useEffect(() => {
    dispatch(getChannelMembersInfoMEO())
    dispatch(getMEOSJoinedChannelList({ user_id: userId }));  // Changings
    dispatch(getRCUserProfileInfo());
  }, []);
  useEffect(() => {
    if (channels?.length > 0) {
      const publicChannel = channels[0]['room_id'];
      if (channels.length == 1 || roomId === 'GENERAL' && publicChannel['room_name']) {
        history.push(`/user/chat?roomId=${publicChannel['_id']}&type=channel`)
        dispatch(activeChatRoom(publicChannel['_id']))
      }
    }
  }, [channels, history]);
  const clickUsertoChat = (userId: string) => {
    if (activeMessages.length > 0) {
      const seenMessage = activeMessages.filter((id) => id !== userId)
      dispatch(getActiveMessage(seenMessage))
    }
    history.push(`/user/chat?roomId=${userId}&type=direct`)
  }
  return (
    <>
      <div className={`sidebar `}>
        {/* <div className="sidebar__section">
          <div className={`sidebarOption sidebar__group`}   >
            <InsertCommentIcon fontSize='small' className='sidebarOption__icon' />
            <h3 className='sidebarOption__title'>Threads</h3>
          </div>
          <div className={`sidebarOption sidebar__group`}   >
            <AlternateEmailIcon fontSize='small' className='sidebarOption__icon' />
            <h3 className='sidebarOption__title'>Mentions & reactions</h3>
          </div>
          <div className={`sidebarOption sidebar__group`}   >
            <TurnedInNotIcon fontSize='small' className='sidebarOption__icon' />
            <h3 className='sidebarOption__title'>Saved items</h3>
          </div>
          <div className={`sidebarOption sidebar__group`}   >
            <MoreVertIcon fontSize='small' className='sidebarOption__icon' />
            <h3 className='sidebarOption__title'>More</h3>
          </div>
        </div> */}

        <button
          onClick={collapseChannelsHandler}
          className='sidebar__collapse--button'
        >
          <SidebarOption
            Icon={isChannelListOpen ? ArrowDropDownIcon : ArrowRightIcon}
            title='Channels'
            addGroupOption
            channelId={null}
            addChannelOption={false}
          />
        </button>
        {/* Channel listing */}
        <Collapse in={isChannelListOpen} timeout='auto' unmountOnExit>
          <List component='div' disablePadding style={{ marginLeft: "10px" }}>
            {channels.length > 0 && channels?.map((channel: IRoomUserMEOS) => (
              <div key={channel?._id} onClick={() =>{history.push(`/user/chat?roomId=${channel.room_id._id}&type=channel`)
              dispatch(activeChatRoom(channel.room_id._id))
              }
                }>
                <ListItem button className={`${roomId === channel?.room_id?._id ? 'active channels' : 'channels'}`}>
                  <ListItemText
                    primary={`# ${channel?.room_id.room_name}`}
                    primaryTypographyProps={{ style: { paddingLeft: "7px", fontSize: "13px" } }}
                  />
                </ListItem>
              </div>
            ))}
            {/* <div
              className={`sidebarOption`}
              onClick={() => {
                setOpen(true)
              }}
            >
              <AddBoxIcon className='sidebarOption__icon' />
              <h3 className='sidebarOption__title'>Add Channel</h3>
            </div> */}
          </List>
        </Collapse>

        <div>
          <button
            onClick={collapseMessageHandler}
            className='sidebar__collapse--button'
          >
            <SidebarOption
              Icon={isMessageListOpen ? ArrowDropDownIcon : ArrowRightIcon}
              title='Direct messages'
              addGroupOption
              channelId={null}
              addChannelOption={false}
            />
          </button>
          {/* memberListing */}
          <Collapse in={isMessageListOpen} timeout='auto' unmountOnExit>
            <List component='div' disablePadding style={{ marginLeft: "10px" }}>
              {latestChatUser?.length > 0 && latestChatUser?.map((member: any, index) => {
                const name = member?.name || 'Unknown User';
                const bgColorClass = getAvatarBackgroundColor(name)

                return (
                  <div key={index} onClick={() => clickUsertoChat(member?.userId)}>
                    <ListItem button className={`${roomId === member?.userId ? 'active Message' : 'activeMessage'}`}>
                      <div className='userAvator'>
                        {
                          member?.avatarUrl ?
                            <Avatar
                              alt={name}
                              style={{ cursor: 'pointer' }}
                              src={`${process.env.REACT_APP_API_URL}/${member?.avatarUrl}`}
                            >
                            </Avatar>
                            :
                            <Avatar
                              alt={name}
                              style={{ cursor: 'pointer' }}
                              className={classes[bgColorClass]}
                            >
                              <span style={{ color: 'white', fontSize: '20px' }}>
                                {name.charAt(0).toUpperCase()}
                              </span>
                            </Avatar>

                        }

                      </div>
                      <ListItemText
                        className={`directMessageUser ${activeMessages?.includes(member?.userId) ? 'unSeenMessage' : ''
                          }`}
                        primary={<>
                          <div className={classes.messageWrapper}>
                          <span style={{ paddingLeft: '7px', fontSize: '16px' }}>
                            {member?.name}
                          </span>
                          {activeMessages?.includes(member?.userId)&&<Chip
                            label={activeMessages.filter(id => id === member.userId).length}
                            size="small"
                            style={{ marginRight: '8px', width:"30px"}}
                          />
                          }
                          </div> 
                        </>}
                        primaryTypographyProps={{ style: { paddingLeft: "7px", fontSize: "20px" } }}
                      />
                    </ListItem>
                  </div>
                )
              }
              )}
              {/* <div
                className={`sidebarOption`}
                onClick={() => {
                  setOpen(true)
                }}
              >
              </div> */}
            </List>
          </Collapse>
        </div>
      </div>
      {/* <AddChannelModal open={open} handleClose={handleClose} /> */}
    </>
  )
}

export default Sidebar;