import { FormControl, Grid, NativeSelect, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useStyles } from './style';
import "./style.scss";
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import HeatMapPerformanceTable from './components/table/heatMapTable';
import { dateRangeOptions, staticActiveProgramEnrollment } from '../../constants/data';
import useDateRange from '../../util/useDateRange';
import { useDispatch, useSelector } from 'react-redux';
import { getHeatMapData } from '../../redux/actions/dashboardAction';
import { RootState } from '../../redux/store';

const MonthlyPerformanceCard = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const userId = (localStorage.getItem("user"));
    const { heatMapData, selectedDateRange } = useSelector((state: RootState) => state.dashboardReducer)
    const {programId} = JSON.parse(localStorage.getItem('activeProgramEnrollment')) || {};  // TODO: Replace with service call


    const {
        dateRange,
        selectedRange,
        customStartDate,
        customEndDate,
        handleRangeChange,
        handleCustomStartDateChange,
        handleCustomEndDateChange,
    } = useDateRange('last-4-weeks');

    useEffect(() => {
        if(dateRange){
            dispatch(getHeatMapData(programId, userId, dateRange.start, dateRange.end))        }
    }, [dateRange]);

    return (
        <div className='monthly_performance_card'>
            <div className={classes.titleWrapper}>
                <Typography className={classes.header} variant="h6">Monthly Performance</Typography>
                <FormControl className={classes.formControl}>
                    <NativeSelect
                        className={classes.select}
                        value={selectedRange}
                        onChange={handleRangeChange}
                        placeholder='Select Date Range'
                    >
                        <option value="" disabled>Select Date Range</option>
                        {dateRangeOptions.map((el: any, index: number) => (
                            <option key={index} value={el.value}>{el?.label}</option>
                        ))}
                    </NativeSelect>
                </FormControl>
            </div>
            <div>
                {selectedRange === 'custom' && (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify='flex-end' spacing={2} direction="row">
                            <Grid item xs={4}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-start"
                                    label="Start Date"
                                    value={customStartDate ? moment(customStartDate).toDate() : null}
                                    onChange={handleCustomStartDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-end"
                                    label="End Date"
                                    value={customEndDate ? moment(customEndDate).toDate() : null}
                                    onChange={handleCustomEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                )}
            </div>

            <HeatMapPerformanceTable
                weeklyData={heatMapData}
                isDashboard={true}
            />

        </div>
    )
}

export default MonthlyPerformanceCard